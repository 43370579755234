import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ChevronRight = (props: SvgProps) => (
  <Svg width={5} height={8} fill="#00E6DB" viewBox="0 0 5 8" {...props}>
    <Path d="M.152.146a.5.5 0 0 0 0 .707l3.143 3.144L.152 7.14a.5.5 0 1 0 .706.706L4.354 4.35a.5.5 0 0 0 0-.706L.858.146a.5.5 0 0 0-.706 0Z" />
  </Svg>
)

export default ChevronRight
