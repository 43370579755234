import { TxKeyPath } from "../i18n"

interface IGenders {
  code: string
  text: TxKeyPath
}

export enum GenderEnum {
  Male = "male",
  Female = "female",
}

export const genders: IGenders[] = [
  {
    code: "male",
    text: "genders.male",
  },
  {
    code: "female",
    text: "genders.female",
  },
]
