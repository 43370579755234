import Constants from "expo-constants"
import * as Updates from "expo-updates"

export function getEnvironment() {
  let releaseChannel = Updates.releaseChannel
  if (Constants.manifest.extra?.webEnv === "production") {
    releaseChannel = "production"
  }

  if (__DEV__) {
    releaseChannel = "preview"
  }

  if (releaseChannel.startsWith("prod")) {
    return { env: "prod", apiUrl: "https://api.app.codeofsamsara.com/api/v1" }
  } else {
    return { env: "dev", apiUrl: "https://api.sansara.io/api/v1" }
  }
}
