import React, { FC } from "react"
import { ImageSourcePropType, View, ViewStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import { Screen, ScreenTitle, CategoryButton } from "../../components"
import { AcademyStackParamList, AcademyStackScreenProps, TabsParamList } from "../../navigators"
import { spacing } from "../../theme"
import { TxKeyPath } from "../../i18n"
import { useStores } from "../../models"

const questions = require("./images/questions.png")
const glossary = require("./images/glossary.png")
const videos = require("./images/videos.png")

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
}

interface IDataItem {
  id: number
  icon: ImageSourcePropType
  titleTx: TxKeyPath
  textTx: TxKeyPath
  link: keyof AcademyStackParamList
}

const data: IDataItem[] = [
  {
    id: 1,
    icon: questions,
    titleTx: "academyScreen.questionsHeading",
    textTx: "academyScreen.questionsDescription",
    link: "academyQuestionsIndex",
  },
  {
    id: 2,
    icon: glossary,
    titleTx: "academyScreen.glossaryHeading",
    textTx: "academyScreen.glossaryDescription",
    link: "academyGlossary",
  },
  /* SD-961{
    id: 3,
    icon: videos,
    titleTx: "academyScreen.videosHeading",
    textTx: "academyScreen.videosDescription",
    link: "academyVideoCategories",
  }, */
]

export const AcademyScreen: FC<AcademyStackScreenProps<"academyIndex">> = observer(
  ({ navigation }) => {
    const { meStore } = useStores()
    const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()
    const fromScreen = "academy"

    return (
      <View style={FULL}>
        <Screen style={CONTAINER} preset="scroll">
          <ScreenTitle
            headerTx="academyScreen.title"
            subHeaderTx="academyScreen.subtitle"
            main={!meStore.isGuest}
            goToSubscription={() =>
              rootNavigation.navigate("profile", {
                screen: "profileSubscriptionInfo",
                params: { fromScreen },
              })
            }
            goToNotifications={() => navigation.navigate("academyNotifications")}
          />

          {data.map((item) => (
            <CategoryButton
              key={item.id}
              icon={item.icon}
              titleTx={item.titleTx}
              textTx={item.textTx}
              onPress={() => navigation.navigate(item.link)}
            />
          ))}
        </Screen>
      </View>
    )
  },
)
