import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { WelcomeStoreModel } from "../welcome-store/welcome-store"
import { MeStoreModel } from "../me-store/me-store"
import { ProfileStoreModel } from "../profile-store/profile-store"
import { CommonStoreModel } from "../common-store/common-store"
import { ExtraOrderStoreModel } from "../extra-order-store/extra-order-store"
import { FormulaStoreModel } from "../formula-store/formula-store"
import { AlertStoreModel } from "../alert-store/alert-store"

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  welcomeStore: types.optional(WelcomeStoreModel, {} as any),
  meStore: types.optional(MeStoreModel, {} as any),
  profileStore: types.optional(ProfileStoreModel, {} as any),
  commonStore: types.optional(CommonStoreModel, {} as any),
  extraOrderStore: types.optional(ExtraOrderStoreModel, {} as any),
  formulaStore: types.optional(FormulaStoreModel, {} as any),
  alertStore: types.optional(AlertStoreModel, {} as any),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
