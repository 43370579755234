import React, { useRef, useState } from "react"
import { Animated, Easing, TouchableOpacity, View, ViewStyle } from "react-native"
import { color, spacing } from "../../../theme"
import { Text } from "../../text/text"
import { Icon } from "../../icon/icon"
import { RenderHtmlWrapper } from "../../render-html-wrapper/render-html-wrapper"

interface ForewordFragmentProps {
  content: IForewordFragment
}

export interface IForewordFragment {
  title?: string
  type: "foreword"
  content: string
}

export const ForewordFragment = (props: ForewordFragmentProps) => {
  const { content } = props

  const [expanded, setExpanded] = useState(false)
  const slideAnim = useRef(new Animated.Value(0)).current

  const slideUp = () => {
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: 200,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start()
  }

  const slideDown = () => {
    Animated.timing(slideAnim, {
      toValue: 1,
      duration: 400,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start()
  }

  const onPress = () => {
    if (expanded) {
      slideUp()
    } else {
      slideDown()
    }
    setExpanded(!expanded)
  }

  return (
    <View style={WRAPPER}>
      <TouchableOpacity activeOpacity={0.7} style={COLLAPSE_ITEM} onPress={onPress}>
        <View style={COLLAPSE_HEADER}>
          <View style={COLLAPSE_HEADER_WRAPPER}>
            <Icon icon="about" />
            <Text preset="h4" numberOfLines={3} style={COLLAPSE_HEADER_TEXT}>
              {content.title}
            </Text>
          </View>
          <Animated.View
            style={{
              transform: [
                {
                  rotateZ: slideAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["180deg", "0deg"],
                  }),
                },
              ],
            }}
          >
            <Icon icon="collapseArrow" svgProps={{ fill: "rgba(255, 255, 255, 0.5)" }} />
          </Animated.View>
        </View>
        <Animated.View
          style={[
            COLLAPSE_CONTENT,
            {
              opacity: slideAnim,
              maxHeight: slideAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 2000],
              }),
            },
          ]}
        >
          <View style={COLLAPSE_CONTENT_INNER}>
            <RenderHtmlWrapper html={content.content} />
          </View>
        </Animated.View>
      </TouchableOpacity>
    </View>
  )
}

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const COLLAPSE_ITEM: ViewStyle = {
  borderRadius: 8,
  borderWidth: 1,
  borderColor: color.primary,
  marginBottom: spacing[4],
  backgroundColor: color.palette.black500,
}
const COLLAPSE_HEADER: ViewStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[4],
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}
const COLLAPSE_HEADER_WRAPPER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
const COLLAPSE_HEADER_TEXT: ViewStyle = {
  paddingRight: spacing[3],
  paddingLeft: spacing[3],
}
const COLLAPSE_CONTENT: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const COLLAPSE_CONTENT_INNER: ViewStyle = {
  paddingBottom: spacing[4],
}
