import React, { useState } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { TxKeyPath } from "../../../i18n"
import { useStores } from "../../../models"
import { ExtraOrderUserTypeEnum } from "../../../models/extra-order-store/extra-order-store"
import { spacing } from "../../../theme"
import {
  Button,
  Screen,
  Header,
  ScreenTitle,
  FormRow,
  TextField,
  DatePicker,
  TimePicker,
  Select,
  PlaceOfBirth,
} from "../../../components"
import { genders } from "../../../constants/genders"
import {
  IPlaceOfBirth,
  PlaceOfBirthStore,
} from "../../../models/profile-store/place-of-birth-model"

interface IExtraOrderEditUserProp {
  title: TxKeyPath
  userType: ExtraOrderUserTypeEnum
  goBack(): void
}

export const ExtraOrderEditUser = observer(
  ({ title, userType, goBack }: IExtraOrderEditUserProp) => {
    const { extraOrderStore, alertStore } = useStores()
    const [userCardControl, setUserCardControl] = useState({
      firstName: extraOrderStore[userType].firstName,
      lastName: extraOrderStore[userType].lastName,
      birthDate: extraOrderStore[userType].dateOfBirth,
      birthTime: extraOrderStore[userType].timeOfBirth,
      gender: extraOrderStore[userType]?.gender,
      placeOfBirth: extraOrderStore[userType].placeOfBirth,
    })

    const handleChange = (key: string, value: string) => {
      setUserCardControl((prevState) => ({
        ...prevState,
        [key]: value,
      }))
    }

    const handlePlaceOfBirthChange = (key: string, value: IPlaceOfBirth) => {
      setUserCardControl((prevState) => ({
        ...prevState,
        [key]: value as PlaceOfBirthStore,
      }))
    }

    const saveChanges = () => {
      if (
        !userCardControl.firstName ||
        !userCardControl.lastName ||
        !userCardControl.birthDate ||
        !userCardControl.gender
      ) {
        alertStore.showError("Please fill in all required fields")
        return
      }

      extraOrderStore.updateUserCardValues(
        userCardControl.firstName,
        userCardControl.lastName,
        userCardControl.gender,
        userCardControl.birthDate,
        userCardControl.birthTime,
        userCardControl.placeOfBirth,
        userType,
      )

      goBack()
    }

    return (
      <Screen style={CONTAINER} preset="scroll">
        <Header leftIcon="close" onLeftPress={goBack} style={HEADER} />
        <ScreenTitle headerTx={title} style={TITLE} />

        <View style={CONTENT}>
          <FormRow preset="middle">
            <TextField
              value={userCardControl.firstName}
              onChangeText={(text) => handleChange("firstName", text.replace(/\d/gi, ""))}
              placeholderTx="extraOrderEditPerson.firstName"
              style={INPUT_WRAPPER}
            />
          </FormRow>
          <FormRow preset="middle">
            <TextField
              value={userCardControl.lastName}
              onChangeText={(text) => handleChange("lastName", text.replace(/\d/gi, ""))}
              placeholderTx="extraOrderEditPerson.lastName"
              style={INPUT_WRAPPER}
            />
          </FormRow>
          <FormRow preset="middle">
            <View style={[FORM_ROW, FORM_ROW_DATE]}>
              <DatePicker
                value={userCardControl.birthDate}
                placeholderTx="extraOrderEditPerson.birthDate"
                setValue={(value) => handleChange("birthDate", value)}
              />
            </View>
          </FormRow>
          <FormRow preset="middle">
            <View style={FORM_ROW}>
              <TimePicker
                value={userCardControl.birthTime}
                setValue={(value) => handleChange("birthTime", value)}
                placeholderTx="extraOrderEditPerson.timeOfBirth"
              />
            </View>
          </FormRow>
          <FormRow preset="middle">
            <View style={FORM_ROW}>
              <Select
                data={genders}
                selectedCode={userCardControl.gender}
                setSelectedCode={(value) => handleChange("gender", value)}
              />
            </View>
          </FormRow>
          <FormRow preset="middle">
            <PlaceOfBirth
              placeholderTx="extraOrderEditPerson.placeOfBirth"
              placeOfBirth={userCardControl.placeOfBirth}
              setPlaceOfBirth={(placeOfBirth) =>
                handlePlaceOfBirthChange("placeOfBirth", placeOfBirth)
              }
            />
          </FormRow>
        </View>
        <Button tx="extraOrderEditPerson.saveAndContinue" onPress={saveChanges} />
      </Screen>
    )
  },
)

const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
  paddingBottom: spacing[4],
}
const HEADER: ViewStyle = {
  paddingHorizontal: 0,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
  marginTop: spacing[5],
}
const CONTENT: ViewStyle = {
  flexGrow: 1,
}
const FORM_ROW: ViewStyle = {
  width: "100%",
}
const FORM_ROW_DATE: ViewStyle = {
  marginBottom: -spacing[5],
}
const INPUT_WRAPPER: ViewStyle = {
  width: "100%",
}
