import React, { FC } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import * as Updates from "expo-updates"
import Constants from "expo-constants"
import * as Application from "expo-application"
import { Text, Screen, CategoryButtonCompact, Header, ScreenTitle } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { IS_MOBILE_NON_EXPO } from "../../utils/dimension"
import { translate } from "../../i18n"
import { getEnvironment } from "../../utils/env"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingTop: spacing[4],
  paddingHorizontal: spacing[4],
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}
const PROFILE_CONTENT: ViewStyle = {
  marginBottom: spacing[5],
}

export const ProfileAboutAppScreen: FC<ProfileStackScreenProps<"profileAboutApp">> = observer(
  ({ navigation }) => {
    const { alertStore } = useStores()
    const goBack = () => navigation.goBack()

    const updateApp = async () => {
      try {
        const update = await Updates.checkForUpdateAsync()
        if (update.isAvailable) {
          await Updates.fetchUpdateAsync()
          Updates.reloadAsync()
        } else {
          alertStore.show("Up to date", "No update is available")
        }
      } catch (e) {
        alertStore.show("Failed to update app!", JSON.stringify(e))
      }
    }

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileAboutApp.title" style={TITLE} />

          <View style={PROFILE_CONTENT}>
            <View>
              {IS_MOBILE_NON_EXPO && (
                <CategoryButtonCompact
                  leftIcon="termsOfUse"
                  titleTx="profileAboutApp.updateApp"
                  onPress={updateApp}
                />
              )}
            </View>
          </View>

          <Text
            preset="default"
            text={`${translate("profileAboutApp.version")}: ${Constants.manifest.version}`}
          />
          <Text
            preset="default"
            text={`${translate("profileAboutApp.sdkVersion")}: ${Constants.manifest.sdkVersion}`}
          />
          <Text
            preset="default"
            text={`${translate("profileAboutApp.releaseChannel")}: ${Updates.releaseChannel}`}
          />
          <Text
            preset="default"
            text={`${translate("profileAboutApp.env")}: ${getEnvironment().env}`}
          />
          {!!Application.nativeBuildVersion && (
            <Text
              preset="default"
              text={`${translate("profileAboutApp.nativeBuildVersion")}: ${
                Application.nativeBuildVersion
              }`}
            />
          )}
        </Screen>
      </View>
    )
  },
)
