/* eslint-disable */
import React from "react"
import { View, ViewStyle } from "react-native"
import AbstractChart, {
  AbstractChartConfig,
  AbstractChartProps,
  DEFAULT_X_LABELS_HEIGHT_PERCENTAGE,
} from "react-native-chart-kit/dist/AbstractChart"
import { Circle, G, Line, Rect, Svg, Text as TextSVG } from "react-native-svg"

export interface ExtendedStackedBarChartData {
  labels: string[]
  legend: string[]
  data: number[][]
  barColors: string[]
  averageLine: number
  labelsXTitle: string
}

export interface StackedBarChartProps extends AbstractChartProps {
  data: ExtendedStackedBarChartData
  width: number
  height: number
  chartConfig: AbstractChartConfig
  hideLegend: boolean
  style?: Partial<ViewStyle>
  barPercentage?: number
  decimalPlaces?: number
  withVerticalLabels?: boolean
  withHorizontalLabels?: boolean
  segments?: number
  percentile?: boolean
  verticalLabelsHeightPercentage?: number

  formatYLabel?: (yLabel: string) => string
}

type StackedBarChartState = {}

export class ExtendedCustomStackedBarChart extends AbstractChart<
  StackedBarChartProps,
  StackedBarChartState
> {
  getBarPercentage = () => {
    const { barPercentage = 1 } = this.props.chartConfig
    return barPercentage
  }

  getBarRadius = (ret: string | any[], x: string | any[]) => {
    return this.props.chartConfig.barRadius && ret.length === x.length - 1
      ? this.props.chartConfig.barRadius
      : 0
  }

  renderBars = ({
    data,
    width,
    height,
    paddingTop,
    paddingRight,
    border,
    colors,
    verticalLabelsHeightPercentage,
  }: Pick<
    Omit<AbstractChartConfig, "data">,
    | "width"
    | "height"
    | "paddingRight"
    | "paddingTop"
    | "stackedBar"
    | "verticalLabelsHeightPercentage"
  > & {
    border: number
    colors: string[]
    data: number[][]
  }) =>
    data.map((columns: number[], index: number) => {
      const barWidth = 32 * this.getBarPercentage()
      const results = []
      const fac = 1
      let h = 0

      const sum = this.props.percentile ? columns.reduce((a, b) => a + b, 0) : border
      const barsAreaHeight = height * verticalLabelsHeightPercentage

      for (let z = 0; z < columns.length; z++) {
        h = barsAreaHeight * (columns[z] / sum)
        let xC =
          (paddingRight + (index * (width - paddingRight)) / data.length + barWidth / 2) * fac
        const y = barsAreaHeight - h + paddingTop

        if (z === 0) {
          xC += barWidth
        }

        results.push(
          <Rect
            key={Math.random()}
            x={xC}
            y={y}
            rx={this.getBarRadius(results, columns)}
            ry={this.getBarRadius(results, columns)}
            width={barWidth}
            height={h}
            fill={colors[z]}
          />,
        )

        results.push(
          <TextSVG
            key={Math.random()}
            x={xC + barWidth / 2}
            textAnchor="middle"
            y={y - 3}
            {...this.getPropsForLabels()}
          >
            {columns[z]}
          </TextSVG>,
        )
      }

      return results
    })

  renderLegend = ({
    legend,
    colors,
    height,
  }: Pick<AbstractChartConfig, "width" | "height"> & {
    legend: string[]
    colors: string[]
  }) =>
    legend.map((text: string, index: number) => {
      return (
        <G key={Math.random()} y={20 * index + 13}>
          <Circle r="7" fill={colors[index]} cx="20" cy={height + 3} />
          <TextSVG x="34" y={height + 7} {...this.getPropsForLabels()}>
            {text}
          </TextSVG>
        </G>
      )
    })

  renderCenterLine = ({ color, averageLine, paddingRight }) => {
    const paddingTop = 16
    const barsAreaHeight = this.props.height * DEFAULT_X_LABELS_HEIGHT_PERCENTAGE
    const average = (barsAreaHeight / 10) * (10 - averageLine) + paddingTop

    return (
      <G>
        <Line
          x1={paddingRight}
          y1={average}
          x2={this.props.width}
          y2={average}
          stroke={color}
          strokeDasharray="10"
        />
      </G>
    )
  }

  renderVerticalLine = ({
    color,
    width,
    height,
    paddingRight,
    paddingTop,
    verticalLabelsHeightPercentage,
  }) => {
    const barWidth = Math.floor((width - paddingRight) / (this.props.data.labels.length + 1))
    const barsAreaHeight = height * verticalLabelsHeightPercentage + paddingTop
    return (
      <G>
        <Rect x={32} width={barWidth} height={barsAreaHeight} fill={color} />
      </G>
    )
  }

  renderLabelXTitle = () => {
    return (
      <G>
        <TextSVG
          x={this.props.width / 2 - 10}
          y={this.props.height + 8}
          fill="rgb(198, 209, 225)"
          fontSize="10"
        >
          {this.props.data.labelsXTitle}
        </TextSVG>
      </G>
    )
  }

  render() {
    const paddingTop = 16
    const paddingRight = 32
    const barWidth = 32 * this.getBarPercentage()

    const {
      width,
      height,
      style = {},
      data,
      withHorizontalLabels = true,
      withVerticalLabels = true,
      segments,
      decimalPlaces,
      verticalLabelsHeightPercentage = DEFAULT_X_LABELS_HEIGHT_PERCENTAGE,
      formatYLabel = (yLabel: string) => {
        return yLabel
      },
      hideLegend = false,
    } = this.props

    const { borderRadius = 0 } = style
    const config = {
      width,
      height,
    }

    let max = 0

    for (let i = 0; i < data.data.length; i++) {
      const actual = data.data[i].reduce((pv, cv) => pv + cv, 0)

      if (actual > max) {
        max = actual
      }
    }

    const showLegend = !hideLegend && data.legend && data.legend.length !== 0
    const stackedBar = showLegend

    return (
      <View style={style}>
        <Svg height={height + 94} width={width}>
          {this.renderDefs({
            ...config,
            ...this.props.chartConfig,
          })}
          <Rect
            width="100%"
            height={height + 20}
            rx={borderRadius}
            ry={borderRadius}
            fill="url(#backgroundGradient)"
          />
          <G>
            {this.renderVerticalLines({
              ...config,
              paddingTop,
              paddingRight,
              data: data.data,
            })}
          </G>
          <G>
            {this.renderHorizontalLines({
              ...config,
              count: segments,
              paddingTop,
              paddingRight,
              verticalLabelsHeightPercentage,
            })}
          </G>
          <G>
            {withHorizontalLabels
              ? this.renderHorizontalLabels({
                  ...config,
                  count: segments,
                  data: [0, segments],
                  paddingTop,
                  paddingRight,
                  decimalPlaces,
                  verticalLabelsHeightPercentage,
                  formatYLabel,
                })
              : null}
          </G>
          <G>
            {withVerticalLabels
              ? this.renderVerticalLabels({
                  ...config,
                  labels: data.labels,
                  paddingRight: paddingRight + 8,
                  stackedBar,
                  paddingTop,
                  horizontalOffset: barWidth,
                  verticalLabelsHeightPercentage,
                })
              : null}
          </G>
          <G>
            {this.renderBars({
              ...config,
              data: data.data,
              border: segments,
              colors: this.props.data.barColors,
              paddingTop,
              paddingRight,
              stackedBar,
              verticalLabelsHeightPercentage,
            })}
          </G>
          {this.renderCenterLine({
            averageLine: this.props.data.averageLine,
            color: this.props.data.barColors[this.props.data.barColors.length - 1],
            paddingRight,
          })}
          {this.renderVerticalLine({
            color: this.props.data.barColors[this.props.data.barColors.length - 2],
            width: config.width,
            height: config.height,
            paddingRight,
            paddingTop,
            verticalLabelsHeightPercentage,
          })}
          {this.renderLabelXTitle()}
          {this.renderLegend({
            ...config,
            legend: data.legend,
            colors: this.props.data.barColors,
          })}
        </Svg>
      </View>
    )
  }
}
