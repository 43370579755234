import React, { FC, useState, useEffect } from "react"
import { View, ViewStyle, TextStyle, FlatList } from "react-native"
import { observer } from "mobx-react-lite"
import {
  Screen,
  Header,
  Text,
  CategoryButtonCompact,
  Icon,
  SkeletonContainer,
  SkeletonView,
} from "../../components"
import { AcademyStackScreenProps } from "../../navigators"
import { spacing, color } from "../../theme"
import { useStores } from "../../models"
import { parseAndFormatDateLocal } from "../../utils/date"

// SKELETON FOR BLOCKS
const BLOCKS_SKELETON_TITLE: ViewStyle = {
  marginBottom: spacing[4],
  width: "100%",
  height: 150,
  borderRadius: 16,
}

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingTop: spacing[4],
  paddingHorizontal: spacing[4],
}
const NOTIFICATION_TOP: ViewStyle = {
  flexDirection: "row",
  marginBottom: spacing[4],
}
const NOTIFICATION_TOP_ICON: ViewStyle = {
  width: 44,
  height: 44,
  justifyContent: "center",
  alignItems: "center",
  marginRight: spacing[4],
  borderRadius: 100,
  backgroundColor: color.palette.inputColor,
}
const NOTIFICATION_TITLE: TextStyle = {
  color: color.palette.white,
}
const NOTIFICATION_TOP_DATE: TextStyle = {
  color: color.palette.white,
  paddingTop: spacing[1],
}
const LOAD_MORE: TextStyle = {
  textAlign: "center",
  paddingVertical: spacing[3],
}
const EMPTY_NOTIFICATIONS: TextStyle = {
  textAlign: "center",
}

export const AcademyNotoficationsScreen: FC<AcademyStackScreenProps<"academyNotifications">> =
  observer(({ navigation, route }) => {
    const { commonStore } = useStores()

    const goBack = () =>
      route.params?.fromScreen
        ? navigation.getParent().navigate(route.params.fromScreen)
        : navigation.goBack()

    const [loading, setLoading] = useState(true)
    const [skip, setSkip] = useState(0)
    const [isAllLoaded, setIsAllLoaded] = useState(false)
    const [notifications, setNotifications] = useState([])

    const navigateTo = (id: string) => {
      setNotifications(
        notifications.map((n) => {
          if (n.id === id) {
            n.isRead = true
          }
          return n
        }),
      )
      navigation.navigate("academyNotificationsDetails", { id })
    }

    const loadMore = async () => {
      if (!isAllLoaded && skip > 0 && notifications.length) {
        const result = await commonStore.getAcademyAllNotification(skip)
        const data = result?.data?.data
        if (data) {
          const existingIds = notifications.map((n) => n.id)
          const newNotification = data.data.filter((n) => !existingIds.includes(n.id))
          setNotifications((current) => [...current, ...newNotification])

          const currentLast = data.pagination.page * data.pagination.pageSize
          setSkip(currentLast)
          if (currentLast >= data.pagination.total) {
            setIsAllLoaded(true)
          }
        }
      }
    }

    useEffect(() => {
      setLoading(true)
      ;(async () => {
        const result = await commonStore.getAcademyAllNotification(skip)
        const data = result?.data?.data

        if (data) {
          setNotifications(data.data)
          const currentLast = data.pagination.page * data.pagination.pageSize
          setSkip(currentLast)
          if (currentLast >= data.pagination.total) {
            setIsAllLoaded(true)
          }
          setLoading(false)
        }
      })()
    }, [])
    return (
      <>
        <View style={FULL}>
          <Header
            headerTx="academyNotificationsScreen.headerText"
            leftIcon="back"
            onLeftPress={goBack}
          />
          <Screen style={CONTAINER}>
            {loading ? (
              <SkeletonContainer>
                {[1, 2, 3].map((index) => (
                  <SkeletonView key={index} style={BLOCKS_SKELETON_TITLE} />
                ))}
              </SkeletonContainer>
            ) : notifications.length ? (
              <FlatList
                data={notifications}
                showsHorizontalScrollIndicator={false}
                keyExtractor={(item) => String(item.id)}
                renderItem={({ item }) => (
                  <View style={{ marginBottom: spacing[2] }}>
                    <CategoryButtonCompact onPress={() => navigateTo(item.id)}>
                      <View style={NOTIFICATION_TOP}>
                        <View style={NOTIFICATION_TOP_ICON}>
                          <Icon icon={item.isRead ? "readNotification" : "unreadNotification"} />
                        </View>
                        <View>
                          <Text preset="bold" text={item.title} style={NOTIFICATION_TITLE} />
                          <Text
                            preset="medium"
                            text={parseAndFormatDateLocal(item.created, "d MMMM yyyy")}
                            style={NOTIFICATION_TOP_DATE}
                          />
                        </View>
                      </View>
                      <View>
                        <Text text={item.text} />
                      </View>
                    </CategoryButtonCompact>
                  </View>
                )}
                ListFooterComponent={
                  <View>
                    {isAllLoaded ? (
                      <Text
                        preset="bold"
                        tx="academyNotificationsScreen.allLoaded"
                        style={LOAD_MORE}
                      />
                    ) : (
                      <Text
                        preset="bold"
                        tx="academyNotificationsScreen.loading"
                        style={LOAD_MORE}
                      />
                    )}
                  </View>
                }
                onEndReachedThreshold={0.4}
                onEndReached={loadMore}
              />
            ) : (
              <Text
                preset="h4Text"
                tx="academyNotificationsScreen.emptyNotifications"
                style={EMPTY_NOTIFICATIONS}
              />
            )}
          </Screen>
        </View>
      </>
    )
  })
