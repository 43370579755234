import React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { CategoryButtonCompact, Text } from "../../../components"
import { color, spacing } from "../../../theme"
import { translate } from "../../../i18n"

export interface ISubscriptionInfoBonuses {
  content: string
  remains: number
  template: "SoulMate" | "CodeOfSamsara"
}

interface IAvailableReportsProps {
  bonuses: ISubscriptionInfoBonuses[]
  goToExtraOrders(): void
  goToSoulMate(): void
}

const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
  marginBottom: spacing[2],
}
const CATEGORY_BUTTON_CONTENT: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: spacing[4],
}
const CATEGORY_BUTTON_CONTENT_TITLE: TextStyle = {
  width: "70%",
  color: color.palette.brightTurquoise,
}
const CATEGORY_BUTTON_CONTENT_COUNT: TextStyle = {
  color: color.palette.white,
}
const WARNING_TEXT: TextStyle = {
  paddingHorizontal: spacing[4],
  textAlign: "center",
}

export function AvailableReports(props: IAvailableReportsProps) {
  const { bonuses, goToExtraOrders, goToSoulMate } = props

  return (
    <View style={CONTAINER}>
      {!bonuses ? (
        <Text preset="h4" tx="availableReports.unavailable" style={WARNING_TEXT} />
      ) : (
        bonuses.map((bonus, index) => (
          <CategoryButtonCompact
            key={index}
            onPress={bonus.template === "SoulMate" ? goToSoulMate : goToExtraOrders}
          >
            <View style={CATEGORY_BUTTON_CONTENT}>
              <Text text={bonus.content} style={CATEGORY_BUTTON_CONTENT_TITLE} />
              <Text
                preset="medium"
                text={`${bonus.remains} ${translate("availableReports.available")}`}
                style={CATEGORY_BUTTON_CONTENT_COUNT}
              />
            </View>
          </CategoryButtonCompact>
        ))
      )}
    </View>
  )
}
