import React from "react"
import { TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { Icon, Text } from "../../../components"
import { color, spacing } from "../../../theme"
import { parseAndFormatDateUtc } from "../../../utils/date"

export enum SubscriptionStatus {
  Active = "Active",
  PastDue = "PastDue",
  PendingCancellation = "PendingCancellation",
  Cancelled = "Cancelled",
  Paused = "Paused",
  Expired = "Expired",
}

export const SUBSCRIPTION_STATUS_COLORS = [
  {
    label: SubscriptionStatus.Active,
    color: "#179B4C",
  },
  {
    label: SubscriptionStatus.PastDue,
    color: "#AD1519",
  },
  {
    label: SubscriptionStatus.PendingCancellation,
    color: "rgba(173, 21, 25, 0.5)",
  },
  {
    label: SubscriptionStatus.Cancelled,
    color: "rgba(173, 21, 25, 0.5)",
  },
  {
    label: SubscriptionStatus.Paused,
    color: "rgba(23, 155, 76, 0.5)",
  },
  {
    label: SubscriptionStatus.Expired,
    color: "rgba(60, 79, 109, 1)",
  },
]

const SUBSCRIPTION_ROW: ViewStyle = {
  flexDirection: "row",
  width: "100%",
  padding: spacing[4],
  marginBottom: spacing[3],
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: color.palette.black500,
  borderRadius: 10,
}
const SUBSCRIPTION_HEADER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
const DURATION_TEXT: TextStyle = {
  flexDirection: "row",
  marginRight: 8,
  color: color.palette.brightTurquoise,
  textTransform: "capitalize",
}
const STATUS_ITEM: TextStyle = {
  padding: 4,
  borderRadius: 4,
  color: color.palette.white,
}
const SUBSCRIPTION_BODY: ViewStyle = {
  flexDirection: "row",
  marginTop: 5,
}
const PRICE_TEXT: TextStyle = {
  color: color.palette.white,
}

export enum SubscriptionAction {
  Resume = "Resume",
  ChangePaymentMethod = "ChangePaymentMethod",
  Unsubscribe = "Unsubscribe",
}

export interface ISubscriptionInfoData {
  id: string
  duration: string
  price: string
  cancellationPending: boolean
  expiresAt: string
  status?: SubscriptionStatus
  statusChangedAt?: string
  paymentMethod?: string
  periodStart: string
  purchasedAt: string
  actions: { name: SubscriptionAction }[]
}

interface ISubscriptionHistoryProps {
  subscriptions: ISubscriptionInfoData[]
  goToSubscriptionDetails(subscriptionId: string): void
}

export function SubscriptionHistory(props: ISubscriptionHistoryProps) {
  const { subscriptions, goToSubscriptionDetails } = props

  const getStatusColor = (status: string) => {
    const x =
      SUBSCRIPTION_STATUS_COLORS.find((c) => c.label === status) ||
      SUBSCRIPTION_STATUS_COLORS[SUBSCRIPTION_STATUS_COLORS.length - 1]
    return x?.color
  }

  return (
    <View style={{ paddingHorizontal: spacing[4] }}>
      {subscriptions.map((subscription) => (
        <TouchableOpacity
          key={subscription.id}
          activeOpacity={0.7}
          style={SUBSCRIPTION_ROW}
          onPress={() => goToSubscriptionDetails(subscription.id)}
        >
          <View>
            <View style={SUBSCRIPTION_HEADER}>
              <Text preset="bold" text={subscription.duration} style={DURATION_TEXT} />
              <Text
                preset="mediumBold"
                text={subscription.status}
                style={[{ backgroundColor: getStatusColor(subscription.status) }, STATUS_ITEM]}
              />
            </View>
            <View style={SUBSCRIPTION_BODY}>
              <Text preset="mediumBold" tx="subscriptionHistory.expirationDate" />
              <Text
                preset="mediumBold"
                text={parseAndFormatDateUtc(subscription.expiresAt, "dd.MM.yyyy")}
              />
            </View>
          </View>
          {subscription.price !== "giftcard" ? (
            <Text preset="medium" text={subscription.price} style={PRICE_TEXT} />
          ) : (
            <Icon icon="present" />
          )}
        </TouchableOpacity>
      ))}
    </View>
  )
}
