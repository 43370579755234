import React, { useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Button, FormRow, Icon, Text, TextField } from "../../../components"
import { color, spacing } from "../../../theme"
import { useStores } from "../../../models"
import { FormulaHeading } from "./components/formula-heading"
import { FormulaCard } from "./components/formula-card"
import { translate } from "../../../i18n"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"

const MainImg = require("./images/energyWealthMain.png")
const CardImg = require("./images/energyWealthCard.png")

interface IFormula {
  key: string
  text: string
}

type IAlphabetKeys = {
  [key: string]: number
}

export const EnergyWealthByNameFormula = observer(() => {
  const { profileStore, formulaStore } = useStores()
  const [calculatedResult, setCalculatedResult] = useState<IFormula>()
  const [firstName, setFirstName] = useState(profileStore.firstName)
  const [lastName, setLastName] = useState(profileStore.lastName)

  const alphabetKeys: IAlphabetKeys[] = [
    { A: 1, J: 1, S: 1 },
    { B: 2, K: 2, T: 2 },
    { C: 3, L: 3, U: 3 },
    { D: 4, M: 4, V: 4 },
    { E: 5, N: 5, W: 5 },
    { F: 6, O: 6, X: 6 },
    { G: 7, P: 7, Y: 7 },
    { H: 8, Q: 8, Z: 8 },
    { I: 9, R: 9 },
  ]

  const energyWealthCalculate = async () => {
    if (!firstName || !lastName) {
      return
    }

    const fullName = firstName + lastName

    let key = fullName
      .replace(/\s+/g, "")
      .toUpperCase()
      .split("")
      .map((char) =>
        alphabetKeys.map((alphabet) => alphabet[char]).filter((f) => typeof f === "number"),
      )
      .map((m) => Number(m) || 0)
      .reduce((a: number, b: number) => a + b, 0)

    while (key > 22) {
      key -= 22
    }

    const meaningOfName = await formulaStore.getMeaningOfName(key.toString())

    setCalculatedResult({
      key: key.toString(),
      text: meaningOfName.htmlContent,
    })
  }

  return (
    <View>
      {!calculatedResult ? (
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          enableAutomaticScroll={true}
          scrollEnabled={true}
          keyboardShouldPersistTaps="handled"
          scrollToOverflowEnabled={false}
          showsVerticalScrollIndicator={false}
          keyboardOpeningTime={Number.MAX_SAFE_INTEGER}
          extraHeight={100}
          extraScrollHeight={-170}
        >
          <FormulaHeading
            title="energyWealthByNameFormulaScreen.title"
            description="energyWealthByNameFormulaScreen.description"
            image={MainImg}
          />

          {/* FORMS */}

          <View style={FORM_CONTAINER}>
            <FormRow preset="middle">
              <TextField
                value={firstName}
                onChangeText={(text) => setFirstName(text.replace(/\d/gi, ""))}
                placeholderTx="energyWealthByNameFormulaScreen.formFirstName"
                style={CONTACT_INPUT_WRAPPER}
              />
            </FormRow>
            <FormRow preset="middle">
              <TextField
                value={lastName}
                onChangeText={(text) => setLastName(text.replace(/\d/gi, ""))}
                placeholderTx="energyWealthByNameFormulaScreen.formLastName"
                style={CONTACT_INPUT_WRAPPER}
              />
            </FormRow>

            <Button style={BUTTON_CALCULATE} onPress={energyWealthCalculate}>
              <Text
                preset="h4"
                style={BUTTON_CALCULATE_TEXT}
                tx="freeFormulasButton.calculateNow"
              />
            </Button>
          </View>
        </KeyboardAwareScrollView>
      ) : (
        <>
          <FormulaCard
            title={translate("energyWealthByNameFormulaScreen.cardTitle", {
              fullName: `${firstName} ${lastName}`,
            })}
            htmlContent={calculatedResult.text}
            image={CardImg}
          />

          <Button preset="outline" onPress={() => setCalculatedResult(null)} style={BUTTON_RELOAD}>
            <Icon icon={"reload"} containerStyle={RELOAD_ICON} />
            <Text preset="h4" tx="freeFormulasButton.newCalculate" style={RELOAD_TEXT} />
          </Button>
        </>
      )}
    </View>
  )
})

// const KEYBOARD: ViewStyle = { width: "90%", marginBottom: 150 }
const FORM_CONTAINER: ViewStyle = { marginTop: spacing[7] }
const BUTTON_CALCULATE: ViewStyle = { marginTop: spacing[4] }
const BUTTON_CALCULATE_TEXT: TextStyle = { color: color.palette.black }
const BUTTON_RELOAD: ViewStyle = {
  justifyContent: "center",
  flexDirection: "row",
  backgroundColor: color.palette.black500,
  marginVertical: spacing[6],
}
const RELOAD_ICON: ViewStyle = { marginRight: spacing[2] }
const RELOAD_TEXT: TextStyle = { color: color.palette.brightTurquoise }

const CONTACT_INPUT_WRAPPER: ViewStyle = {
  width: "100%",
}
