import { ApiResponse } from "apisauce"
import { Platform } from "react-native"
import { Api } from "./api"
import { CommonResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { ApiRoutes } from "./api-config"
import { IPlaceOfBirth } from "../../models/profile-store/place-of-birth-model"

export interface IOrderPayload {
  firstName: string
  lastName: string
  gender: string
  birthDate: string
  birthTime: string
  placeOfBirth: IPlaceOfBirth
}

export class ExtraOrderApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getAvailable(onlySoulmate: boolean): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.EXTRA_ORDER_AVAILABLE,
        {
          onlySoulmate,
        },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async codeOfSamsaraDemoBlocks(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.CODE_OF_SAMSARA_DEMO_BLOCKS,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async codeOfSamsaraBlocks(orderId: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.CODE_OF_SAMSARA_BLOCKS_BY_ORDER(orderId),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getResult(orderId: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.EXTRA_ORDER_RESULT(orderId),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getHistory(onlySoulmate: boolean): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.EXTRA_ORDER_HISTORY,
        { onlySoulmate },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async submitOrder(
    product: string,
    useBonus: boolean,
    payload: IOrderPayload[],
  ): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(ApiRoutes.EXTRA_ORDER_ORDER, {
        platform: Platform.select({ android: "Android", ios: "IOS", web: "Web" }),
        product,
        useBonus,
        payload,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
