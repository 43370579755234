import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const TermsOfUse = (props: SvgProps) => (
  <Svg width={17} height={20} viewBox="0 0 17 20" fill="#00E6DB" {...props}>
    <Path d="M0 2.5A2.5 2.5 0 0 1 2.5 0H14a2.5 2.5 0 0 1 2.5 2.5v14.25a.75.75 0 0 1-.75.75H1.5a1 1 0 0 0 1 1h13.25a.75.75 0 1 1 0 1.5H2.5A2.5 2.5 0 0 1 0 17.5v-15ZM8.25 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM7.5 7.75v5a.75.75 0 1 0 1.5 0v-5a.75.75 0 0 0-1.5 0Z" />
  </Svg>
)

export default TermsOfUse
