import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics"
import { getEnvironment } from "../../utils/env"

const firebaseConfig = {
  apiKey: "AIzaSyBa7mna8Ju0iFnUJ3994RtHKoshHhJNVYM",
  authDomain: "sam8sara-firebase.firebaseapp.com",
  projectId: "sam8sara-firebase",
  storageBucket: "sam8sara-firebase.appspot.com",
  messagingSenderId: "88914417827",
  appId: "1:88914417827:web:fbf34859eaaa84e7c50716",
  measurementId: "G-ZDE8L65FBW",
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export async function logScreenView(screenName: string, screenClass: string) {
  await logEvent(analytics, "screen_view", {
    firebase_screen: screenName,
    firebase_screen_class: screenClass,
  })
}

export async function onSignIn(userId: string) {
  await Promise.all([
    setUserId(analytics, userId),
    setUserProperties(analytics, { env: getEnvironment().env }),
  ])
}
