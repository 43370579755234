import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Gift = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" {...props}>
    <Path
      d="M2.306 4.462s-.907.072-1.028.276c-.122.203-.13.517-.13.862 0 .346-.013 1.139-.013 1.139l.216.465s.041 5.933.041 6.117c0 .183 0 .407.264.549.265.142 6.984 3.387 7.193 3.475.317.13.467.081.467.081L16.782 4.94s-.024-.342-.335-.423c-.178-.045-14.14-.055-14.14-.055Z"
      fill="#F5B03E"
    />
    <Path
      d="M9.236 8.567c-.114.314-.062 3.556-.042 5.915.02 2.357-.06 2.845 0 2.926.06.082.225.003.69-.204.865-.382 5.02-2.438 5.325-2.621.305-.183 1.05-.561 1.172-.765.123-.204.189-2.101.23-3.624.04-1.524.081-2.744.081-2.744l.183-.548s-.04-1.482-.046-1.645c-.013-.27-.043-.41-.111-.376-.099.047-.92.72-3.094 1.778-1.366.662-4.309 1.691-4.388 1.908Z"
      fill="#E07F14"
    />
    <Path
      d="M9.256 1.576c-.609-.028-2.275.935-3.881 1.463-1.606.527-4.03 1.587-4.052 1.65-.02.06.976.506 1.612.81.755.362 3.638 1.728 4.165 1.951.529.224 1.789.747 1.991.731.284-.02 2.968-1.077 4.329-1.726 1.361-.65 3.063-1.657 3.085-1.902.01-.11-2.503-1.047-3.756-1.614-2.419-1.096-3.047-1.342-3.493-1.363Z"
      fill="#FDD717"
    />
    <Path
      d="M1.142 6.999c.021.264.166.358.534.519.369.16 7.334 3.778 7.494 3.778.16 0 .208-.573.032-.558-.095.008-2.383-1.079-4.52-2.164-1.815-.92-3.551-1.838-3.551-1.838l.01.263Z"
      fill="#E37D14"
    />
    <Path
      d="m16.876 6.89-7.51 3.819c-.073.036-.19.023-.19.023l-.006.564s.07.016.146-.008c.045-.014 7.345-3.793 7.378-3.804a.37.37 0 0 0 .202-.274.908.908 0 0 0-.02-.32Z"
      fill="#BA5E0D"
    />
    <Path
      d="m4.059 13.7 1.455 2.165.637.218s.052-2.362.073-4.113c.03-2.558.018-4.89.055-5.023.06-.224 2.891-1.142 2.891-1.142s3.198 1.003 3.3 1.226c.044.099.01 1.755 0 3.686-.013 2.439-.01 5.254-.01 5.254s.331-.002.37-.124c.041-.122.205-1.066.205-1.066l1.24-8.267.63-.813-.875-1.665-3.588-1.766-1.234.277-1.334-.204L3.64 5.385l.493 1 1.211.618L4.06 13.7Z"
      fill="#AF0F1B"
    />
    <Path
      d="M12.836 6.945c-.077.17-.032 8.938-.032 8.938s.386-.213.884-.452c.495-.238.851-.386.882-.51.031-.123.086-8.794.086-8.794l-1.82.818Z"
      fill="#DC0D28"
    />
    <Path
      d="M5.815 6.764c.046.05-.03 9.15-.124 9.17-.074.019-1.769-.738-1.769-.815 0-.078.137-8.773.137-8.773s1.618.263 1.756.418ZM9.173 2.004c-.468 0-.986.134-1.136.526-.15.392-.185.976-.185 1.412 0 .417-.067 1.486 1.22 1.47 1.286-.018 1.324-.494 1.344-1.546.017-.878-.1-1.304-.217-1.487-.104-.164-.45-.375-1.026-.375Z"
      fill="#FF2A23"
    />
    <Path
      d="M8.429 4.175c.292.017.418-.626.643-.977.225-.35.46-.585.375-.768-.069-.152-.68-.201-1.011.259-.232.325-.273 1.47-.007 1.486Z"
      fill="#FCC9D2"
    />
    <Path
      d="M10.312 2.309s.415.422.523 1.249c.108.827-.017 1.47-.017 1.47s2.062-.063 2.773.074c.702.136.947.446.947.67 0 .226-.462.407-.93.373-.468-.033-.784-.059-.844.06-.059.117.026.3.677.35.651.05 1.403-.043 1.637-.853.233-.81.242-2.213.159-3.224-.083-1.011-.266-1.59-.719-1.809-.477-.229-1.67-.267-2.798.367-1.128.635-1.408 1.273-1.408 1.273Z"
      fill="#FF2A23"
    />
    <Path
      d="M12.648 1.347c-.146-.188-.617-.183-.87.109-.266.309-.325 1.486.018 1.553.388.076.318-.56.602-.952.278-.384.392-.526.25-.71Z"
      fill="#FCC9D2"
    />
    <Path
      d="M7.929 2.35S6.349.51 4.545.537c-1.086.017-1.361.643-1.42.902-.06.258-.51 2.832.091 4.225.6 1.394 1.847 1.033 2.042.988.25-.06.783-.242.733-.435-.05-.193-1.821.5-1.838-.292-.017-.794 1.253-.878 1.838-.895.585-.017 1.587.117 1.587.117s-.175-.96-.059-1.645c.117-.685.41-1.152.41-1.152Z"
      fill="#FF2A23"
    />
    <Path
      d="M4.061 1.205c-.225.234-.318 1.58-.176 2.247.142.668.268.952.56.895.245-.05.392-1.36.51-1.821.124-.493.434-1.016.309-1.245-.192-.351-.968-.32-1.203-.076Z"
      fill="#FCC9D2"
    />
  </Svg>
)

export default Gift
