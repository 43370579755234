import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const TimeLight = (props: SvgProps) => (
  <Svg width={13} height={12} fill="#00E6DB" viewBox="0 0 13 12" {...props}>
    <Path d="M6.5 0a6 6 0 1 1 0 12 6 6 0 0 1 0-12Zm0 1a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-.498 2a.5.5 0 0 1 .491.41l.009.09V6H8a.5.5 0 0 1 .09.992L8 7H6.002a.5.5 0 0 1-.492-.41l-.008-.09v-3a.5.5 0 0 1 .5-.5Z" />
  </Svg>
)

export default TimeLight
