import React, { FC } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { ExtraOrderStackScreenProps } from "../../navigators"
import { PostView } from "../../components"

export const ExtraOrderCompatibilityResultsPostScreen: FC<
  ExtraOrderStackScreenProps<"extraOrderCompatibilityResultsPost">
> = observer(({ route, navigation }) => {
  const goBack = () => navigation.goBack()
  const navigationToNextPost = (orderId: string, id: string) =>
    navigation.push("extraOrderCompatibilityResultsPost", { orderId, id })

  return (
    <View style={FULL}>
      <PostView
        orderId={route.params.orderId}
        id={route.params.id}
        goBack={goBack}
        navigationToNextPost={navigationToNextPost}
      />
    </View>
  )
})

const FULL: ViewStyle = { flex: 1 }
