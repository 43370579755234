import React from "react"
import { View, ViewStyle } from "react-native"
import { spacing } from "../../../theme"
import { RenderHtmlWrapper } from "../../render-html-wrapper/render-html-wrapper"
import { Text } from "../../text/text"

interface HtmlFragmentProps {
  content: IHtmlFragment
}

export interface IHtmlFragment {
  title?: string
  type: "html"
  content: string
}

export const HtmlFragment = (props: HtmlFragmentProps) => {
  const { content } = props

  return (
    <View style={WRAPPER}>
      <Text preset="h3" text={content.title} />
      <RenderHtmlWrapper html={content.content} />
    </View>
  )
}

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
