import * as React from "react"
import { TouchableOpacity, View, ViewStyle } from "react-native"
import { color, spacing } from "../../theme"
import { CheckboxProps } from "./checkbox.props"
import { Icon } from "../icon/icon"

const ROOT: ViewStyle = {
  flexDirection: "row",
  paddingVertical: spacing[1],
}

const DIMENSIONS = { width: 20, height: 20 }

const OUTLINE: ViewStyle = {
  ...DIMENSIONS,
  marginTop: 2, // finicky and will depend on font/line-height/baseline/weather
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 2,
  borderColor: color.palette.brightTurquoise,
  borderRadius: 100,
}

export function Checkbox(props: CheckboxProps) {
  const rootStyle = [ROOT, props.style]
  const outlineStyle = [OUTLINE, props.outlineStyle]

  const onPress = props.onToggle ? () => props.onToggle && props.onToggle(!props.value) : null

  return (
    <TouchableOpacity
      activeOpacity={1}
      disabled={!props.onToggle}
      onPress={onPress}
      style={rootStyle}
    >
      {props.value ? <Icon icon="checkbox" /> : <View style={outlineStyle} />}
    </TouchableOpacity>
  )
}
