import React, { useState } from "react"
import { ImageStyle, ImageURISource, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { AutoImage, Button, DatePicker, Icon, Text } from "../../../components"
import { color, spacing } from "../../../theme"
import { useStores } from "../../../models"
import { FormulaHeading } from "./components/formula-heading"
import { FormulaCard } from "./components/formula-card"
import { translate } from "../../../i18n"

const MainImg = require("./images/pastLifeCountryMain.png")
const CardImg = require("./images/pastLifeCountryCard.png")
interface IFormula {
  key: string
  text: string
  mapImage: ImageURISource
}

export const PastLifeCountryFormula = observer(() => {
  const { profileStore, formulaStore } = useStores()
  const [dateOfBirth, setDateOfBirth] = useState(profileStore.dateOfBirth)
  const [calculatedResult, setCalculatedResult] = useState<IFormula>()

  const HTML_CONTENT = `
    "display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    "
  `
  const HTML_CONTENT_IMG = `"width: 24px; height: 24px; margin-right: 10px;"`

  const birthdayCalculate = async () => {
    if (dateOfBirth.length !== 10) {
      return
    }
    const keyArray = dateOfBirth.split("-").map((n: string) => Number(n) || 0)
    keyArray.splice(0, 1)
    const key = keyArray
      .reduce((month: number, day: number) => {
        let result = 0

        if (day > 22) {
          result = day - month - 22
        } else {
          result = day - month
        }

        if (result === 0) {
          result = 22
        }

        if (result < 0) {
          result = Math.abs(result)
        }
        return result
      })
      .toString()

    const countryOfPastLife = await formulaStore.getCountryOfPastLife(key)

    if (!countryOfPastLife) {
      return
    }

    const content = countryOfPastLife.htmlContent
      .replace(/<p>/g, `<p style=${HTML_CONTENT}>`)
      .replace(/<img/g, `<img style=${HTML_CONTENT_IMG}`)

    setCalculatedResult({
      key,
      text: content,
      mapImage: countryOfPastLife.media?.url,
    })
  }

  return (
    <View>
      {!calculatedResult ? (
        <>
          <FormulaHeading
            title="pastLifeCountryFormulaScreen.title"
            description="pastLifeCountryFormulaScreen.description"
            image={MainImg}
          />

          {/* FORMS */}
          <View style={FORM_CONTAINER}>
            <DatePicker
              placeholderTx="welcomeForm4Screen.dateOfBirth"
              value={dateOfBirth}
              setValue={setDateOfBirth}
            />

            <Button onPress={birthdayCalculate}>
              <Text
                preset="h4"
                style={BUTTON_CALCULATE_TEXT}
                tx="freeFormulasButton.calculateNow"
              />
            </Button>
          </View>
        </>
      ) : (
        <>
          <FormulaCard
            title={translate("pastLifeCountryFormulaScreen.cardTitle")}
            htmlContent={calculatedResult.text}
            image={CardImg}
          />

          {calculatedResult.mapImage && (
            <View style={MAP_CONTAINER}>
              <Text
                preset="h3"
                style={TEXT_CENTER}
                tx="pastLifeCountryFormulaScreen.yourCountries"
              />
              <AutoImage source={{ uri: `${calculatedResult.mapImage}` }} style={MAP_IMG} />
            </View>
          )}

          <Button preset="outline" onPress={() => setCalculatedResult(null)} style={BUTTON_RELOAD}>
            <Icon icon={"reload"} containerStyle={RELOAD_ICON} />
            <Text preset="h4" tx="freeFormulasButton.newCalculate" style={RELOAD_TEXT} />
          </Button>
        </>
      )}
    </View>
  )
})

const TEXT_CENTER: TextStyle = { textAlign: "center" }
const FORM_CONTAINER: ViewStyle = { marginTop: spacing[7] }
const BUTTON_CALCULATE_TEXT: TextStyle = { color: color.palette.black }
const BUTTON_RELOAD: ViewStyle = {
  justifyContent: "center",
  flexDirection: "row",
  backgroundColor: color.palette.black500,
  marginVertical: spacing[6],
}
const RELOAD_ICON: ViewStyle = { marginRight: spacing[2] }
const RELOAD_TEXT: TextStyle = { color: color.palette.brightTurquoise }
const MAP_CONTAINER: ViewStyle = {
  height: 280,
  marginTop: spacing[6],
}

const MAP_IMG: ImageStyle = {
  marginTop: spacing[4],
  maxHeight: 240,
  width: "100%",
  borderRadius: 8,
  resizeMode: "contain",
}
