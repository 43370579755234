import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
  SoulMateEditPersonScreen,
  SoulMateCompatibilityCalculationScreen,
  SoulMateCompatibilityScreen,
  SoulMateScreen,
} from "../screens"
import { translate } from "../i18n"
import { SoulMateStackParamList } from "./app-navigation-routes"

const Stack = createNativeStackNavigator<SoulMateStackParamList>()

export const SoulMateStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="soulMateIndex"
    >
      <Stack.Screen
        name="soulMateIndex"
        component={SoulMateScreen}
        options={{ title: translate("navigator.soulMateTitle") }}
      />
      <Stack.Screen
        name="soulMateCompatibility"
        component={SoulMateCompatibilityScreen}
        options={{ title: translate("navigator.soulMateTitle") }}
      />
      <Stack.Screen
        name="soulMateCompatibilityCalculation"
        component={SoulMateCompatibilityCalculationScreen}
        options={{ title: translate("navigator.soulMateTitle") }}
      />
      <Stack.Screen
        name="soulMateEditPerson"
        component={SoulMateEditPersonScreen}
        options={{ title: translate("navigator.soulMateTitle") }}
      />
    </Stack.Navigator>
  )
}
