import { LinearGradient } from "expo-linear-gradient"
import React from "react"
import { ImageStyle, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { Button } from "../button/button"
import { Icon } from "../icon/icon"
import { AutoImage } from "../auto-image/auto-image"
import { TxKeyPath } from "../../i18n"
import { convertHexToRGBA } from "../../utils/color"

interface CardProp {
  id: string
  emoji?: string
  title: string
  description: string
  backgroundUrl?: string
  color?: string
  buttonName: TxKeyPath
  buttonAction(card: any): void
}

export const Card = (prop: CardProp) => {
  const card = prop

  return (
    <View style={LIST_WRAPPER}>
      <AutoImage source={{ uri: card.backgroundUrl }} style={LIST_BACKGROUND} resizeMode="cover" />
      <LinearGradient
        start={[0, 0]}
        end={[1, 0]}
        colors={[convertHexToRGBA(card.color), convertHexToRGBA(card.color, 0.5)]}
        style={LIST_CONTAINER}
      >
        <TouchableOpacity onPress={() => card.buttonAction(card)} style={LIST_CONTENT}>
          {card.emoji && <Text text={card.emoji} style={CARD_ICON} />}

          <Text preset="h3" style={LIST_HEADING} numberOfLines={2} text={card.title} />
          <Text preset="defaultLight" numberOfLines={5} text={card.description} />
          <View style={BUTTON_CONTAINER}>
            <Button
              style={BUTTON_READ_MORE}
              preset="unstyled"
              onPress={() => card.buttonAction(card)}
            >
              <Text preset="defaultLight" tx={card.buttonName} />
              <Icon
                icon="next"
                svgProps={{ width: 8, height: 8, fill: color.palette.white }}
                containerStyle={ARROW_ICON}
              />
            </Button>
          </View>
        </TouchableOpacity>
      </LinearGradient>
    </View>
  )
}

const LIST_WRAPPER: ViewStyle = {
  position: "relative",
  overflow: "hidden",
  marginBottom: spacing[4],
  borderRadius: 16,
}
const LIST_BACKGROUND: ImageStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
}
const LIST_CONTAINER: ViewStyle = {
  alignItems: "center",
  flexDirection: "row",
  paddingHorizontal: 18,
  paddingVertical: 36,
}
const LIST_CONTENT: ViewStyle = { flex: 1 }
const LIST_HEADING: TextStyle = { marginBottom: spacing[2] }
const BUTTON_CONTAINER: ViewStyle = {
  marginTop: spacing[4],
  alignItems: "flex-start",
}
const BUTTON_READ_MORE: TextStyle = {
  paddingVertical: spacing[2],
  paddingHorizontal: spacing[4],
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  borderRadius: 18,
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}
const CARD_ICON: TextStyle = { marginBottom: spacing[4] }
const ARROW_ICON: ViewStyle = { marginLeft: spacing[2] }
