import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ArrowDown = (props: SvgProps) => (
  <Svg width={13} height={8} viewBox="0 0 13 8" fill="#ffffff" {...props}>
    <Path d="M1 1.175a.937.937 0 0 1 1.325-.05L7 5.534l4.675-4.409A.938.938 0 0 1 12.95 2.5l-5.312 5a.937.937 0 0 1-1.276 0l-5.312-5A.937.937 0 0 1 1 1.175Z" />
  </Svg>
)

export default ArrowDown
