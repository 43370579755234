import React, { FC, useCallback, useMemo, useState } from "react"
import { View, ViewStyle, TextStyle, ActivityIndicator, Linking } from "react-native"
import { useFocusEffect } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { TxKeyPath, translate } from "../../i18n"
import { Screen, Header, Text, ScreenTitle, Button, ConfirmationModal } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import {
  ISubscriptionInfoData,
  SUBSCRIPTION_STATUS_COLORS,
  SubscriptionAction,
  SubscriptionStatus,
} from "./components/subscription-history"
import { parseAndFormatDateUtc } from "../../utils/date"
import { IS_MOBILE } from "../../utils/dimension"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  padding: spacing[4],
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}
const SUBSCRIPTION: ViewStyle = {
  position: "relative",
  padding: spacing[4],
}
const SUBSCRIPTION_BORDER: ViewStyle = {
  opacity: 0.5,
  borderWidth: 2,
  borderRadius: 10,
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const SUBSCRIPTION_BG: ViewStyle = {
  opacity: 0.25,
  borderRadius: 10,
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const SUBSCRIPTION_HEADER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginBottom: spacing[4],
}
const DURATION_TEXT: TextStyle = {
  flexDirection: "row",
  marginRight: 8,
  color: color.palette.white,
  textTransform: "capitalize",
}
const STATUS_ITEM: TextStyle = {
  padding: 4,
  borderRadius: 4,
  color: color.palette.white,
}
const SUBSCRIPTION_INFO_TEXT: TextStyle = {
  color: color.palette.lightSteelBlue,
  marginBottom: spacing[1],
}
const BUTTON: ViewStyle = {
  marginTop: spacing[4],
  borderRadius: 10,
}
const BUTTON_TEXT: TextStyle = {
  fontSize: 14,
  lineHeight: 20,
}

export const ProfileSubscriptionDetailsScreen: FC<
  ProfileStackScreenProps<"profileSubscriptionDetails">
> = observer(({ navigation, route }) => {
  const { subscriptionId } = route.params
  const { profileStore, alertStore } = useStores()
  const goBack = () => navigation.goBack()

  const [resumeConfirmationVisible, setResumeConfirmationVisible] = useState(false)
  const [cancelConfirmationVisible, setCancelConfirmationVisible] = useState(false)
  const [subscription, setSubscription] = useState<ISubscriptionInfoData>()
  const [loading, setLoading] = useState(true)

  const statusColor = useMemo(() => {
    const x =
      SUBSCRIPTION_STATUS_COLORS.find((c) => c.label === subscription?.status) ||
      SUBSCRIPTION_STATUS_COLORS[SUBSCRIPTION_STATUS_COLORS.length - 1]
    return x?.color
  }, [subscription])

  const startPeriod = useMemo(() => {
    const start = parseAndFormatDateUtc(subscription?.periodStart, "dd.MM.yyyy")

    return start || ""
  }, [subscription])

  const periodString = useMemo(() => {
    const start = parseAndFormatDateUtc(subscription?.periodStart, "dd.MM.yyyy")
    const expiresAt = parseAndFormatDateUtc(subscription?.expiresAt, "dd.MM.yyyy")

    return expiresAt ? `${start} - ${expiresAt}` : ""
  }, [subscription])

  const statusChangedAt = useMemo(() => {
    const date = parseAndFormatDateUtc(subscription?.statusChangedAt, "dd.MM.yyyy")

    return date || ""
  }, [subscription])

  const statusChangedAtLabel = useMemo(() => {
    let label: TxKeyPath | "" = ""
    if (subscription?.status === SubscriptionStatus.PastDue) {
      label = "profileSubscriptionDetailsScreen.pastDueDate"
    } else if (subscription?.status === SubscriptionStatus.Cancelled) {
      label = "profileSubscriptionDetailsScreen.cancelledDate"
    } else if (subscription?.status === SubscriptionStatus.Expired) {
      label = "profileSubscriptionDetailsScreen.expiredDate"
    }

    return label
  }, [subscription])

  const canChangePaymentMethod = useMemo(() => {
    return subscription?.actions.some((a) => a.name === SubscriptionAction.ChangePaymentMethod)
  }, [subscription])

  const canUnsubscribe = useMemo(() => {
    return subscription?.actions.some((a) => a.name === SubscriptionAction.Unsubscribe)
  }, [subscription])

  const canResume = useMemo(() => {
    return subscription?.actions.some((a) => a.name === SubscriptionAction.Resume)
  }, [subscription])

  const updateBillingInfo = async () => {
    setLoading(true)
    const result = await profileStore.updatePaymentMethod()
    if (result.kind === "ok" && result?.data?.length > 0) {
      setLoading(false)
      IS_MOBILE ? Linking.openURL(result.data) : (window.location.href = result.data)
    }
  }

  const onResumeConfirmationResult = async (result: boolean) => {
    setResumeConfirmationVisible(false)
    if (result) {
      setLoading(true)
      const result = await profileStore.resumeStripe(subscriptionId)
      setLoading(false)
      if (result.kind === "ok") {
        alertStore.show(
          translate("profileSubscriptionDetailsScreen.resumeSuccessTitle"),
          translate("profileSubscriptionDetailsScreen.resumeSuccessText"),
        )
        navigation.navigate("profileSubscriptionInfo")
      } else {
        alertStore.showError(result?.message || "")
      }
    }
  }

  const resumeSubscription = () => {
    setResumeConfirmationVisible(true)
  }

  const onCancelConfirmationResult = async (result: boolean) => {
    setCancelConfirmationVisible(false)
    if (result) {
      setLoading(true)
      const result = await profileStore.unsubscribeStripe(subscriptionId)
      setLoading(false)
      if (result.kind === "ok") {
        alertStore.show(
          translate("profileSubscriptionDetailsScreen.unsubscribeSuccessTitle"),
          translate("profileSubscriptionDetailsScreen.unsubscribeSuccessText"),
        )
        navigation.navigate("profileSubscriptionInfo")
      } else {
        alertStore.showError(result?.message || "")
      }
    }
  }

  const unsubscribe = () => {
    setCancelConfirmationVisible(true)
  }

  useFocusEffect(
    useCallback(() => {
      ;(async () => {
        setLoading(true)
        const result = await profileStore.getMySubscriptionsList()

        if (result) {
          const foundSubscription = result?.data?.data?.subscriptions?.find(
            (s: any) => s?.id === subscriptionId,
          )
          setSubscription(foundSubscription)
          setLoading(false)
        }
      })()
    }, []),
  )

  return (
    <View style={FULL}>
      <Screen
        style={CONTAINER}
        preset="scroll"
        header={<Header leftIcon="back" onLeftPress={goBack} />}
      >
        {loading || !subscription ? (
          <ActivityIndicator size="large" color={color.primary} />
        ) : (
          <>
            <ScreenTitle
              headerText={`${translate("profileSubscriptionDetailsScreen.title")} ${
                profileStore.firstName
              }!`}
              subHeaderTx="profileSubscriptionDetailsScreen.subscriptionInfo"
              style={TITLE}
            />

            <View style={SUBSCRIPTION}>
              <View style={[SUBSCRIPTION_BG, { backgroundColor: statusColor }]} />
              <View style={[SUBSCRIPTION_BORDER, { borderColor: statusColor }]} />
              <View style={SUBSCRIPTION_HEADER}>
                <Text preset="bold" text={subscription.duration} style={DURATION_TEXT} />
                <Text
                  preset="mediumBold"
                  text={subscription.status}
                  style={[{ backgroundColor: statusColor }, STATUS_ITEM]}
                />
                <Text
                  preset="mediumBold"
                  text={translate("profileSubscriptionDetailsScreen.purchased", {
                    data: parseAndFormatDateUtc(subscription.purchasedAt, "dd.MM.yyyy"),
                  })}
                  style={[SUBSCRIPTION_INFO_TEXT, { marginLeft: "auto" } as TextStyle]}
                />
              </View>
              {!periodString && startPeriod ? (
                <Text
                  preset="mediumBold"
                  text={translate("profileSubscriptionDetailsScreen.startPeriod", {
                    data: startPeriod,
                  })}
                  style={SUBSCRIPTION_INFO_TEXT}
                />
              ) : null}
              {periodString ? (
                <Text
                  preset="mediumBold"
                  text={translate("profileSubscriptionDetailsScreen.period", {
                    data: periodString,
                  })}
                  style={SUBSCRIPTION_INFO_TEXT}
                />
              ) : null}
              {statusChangedAt && statusChangedAtLabel ? (
                <Text
                  preset="mediumBold"
                  text={translate(statusChangedAtLabel, {
                    data: statusChangedAt,
                  })}
                  style={SUBSCRIPTION_INFO_TEXT}
                />
              ) : null}
              {subscription?.paymentMethod ? (
                <Text
                  preset="mediumBold"
                  text={translate("profileSubscriptionDetailsScreen.cardNumber", {
                    data: subscription.paymentMethod,
                  })}
                  style={[SUBSCRIPTION_INFO_TEXT, { marginBottom: 0 } as TextStyle]}
                />
              ) : null}
            </View>

            {canChangePaymentMethod && (
              <Button
                preset="cancel"
                style={BUTTON}
                textStyle={BUTTON_TEXT}
                tx="profileSubscriptionDetailsScreen.updateBilling"
                onPress={updateBillingInfo}
              />
            )}

            {canUnsubscribe && (
              <Button
                preset="cancel"
                style={BUTTON}
                textStyle={BUTTON_TEXT}
                tx="profileSubscriptionDetailsScreen.unsubscribe"
                onPress={unsubscribe}
              />
            )}

            {canResume && (
              <Button
                preset="cancel"
                style={BUTTON}
                textStyle={BUTTON_TEXT}
                tx="profileSubscriptionDetailsScreen.subscriptionResume"
                onPress={resumeSubscription}
              />
            )}
          </>
        )}
        <ConfirmationModal
          titleTx="profileSubscriptionDetailsScreen.resumeConfirmTitle"
          subTitleTx="profileSubscriptionDetailsScreen.resumeConfirmText"
          visible={resumeConfirmationVisible}
          onResult={onResumeConfirmationResult}
        />
        <ConfirmationModal
          titleTx="profileSubscriptionDetailsScreen.unsubscribeConfirmTitle"
          subTitleTx="profileSubscriptionDetailsScreen.unsubscribeConfirmText"
          visible={cancelConfirmationVisible}
          onResult={onCancelConfirmationResult}
        />
      </Screen>
    </View>
  )
})
