import { observer } from "mobx-react-lite"
import React, { useEffect, useRef, useState } from "react"
import {
  Modal,
  View,
  ViewStyle,
  FlatList,
  TouchableOpacity,
  TextStyle,
  ActivityIndicator,
  Animated,
} from "react-native"
import { color, spacing } from "../../../theme"
import { GenderEnum } from "../../../constants/genders"
import { Text, Button, Icon } from "../../../components"
import { useWindowWidth } from "../../../utils/dimension"

interface PersonInfo {
  firstName: string
  lastName: string
  initial: string
  gender: string
}
export interface UserData {
  orderId: string
  me: PersonInfo
  partner?: PersonInfo
  title?: string
  color?: string
}

interface UserHistoryModalProps {
  visible: boolean
  closeModal(): void
  data: UserData[]
  onUserSelect(item: UserData): void
  selectedUser: string
}

const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const BG_OVERLAY: ViewStyle = {
  ...OVERLAY,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}
const MODAL_WRAPPER: ViewStyle = {
  position: "absolute",
  right: 0,
  left: 0,
  bottom: 0,
  paddingTop: spacing[4],
  paddingHorizontal: spacing[4],
  backgroundColor: color.background,
  maxHeight: "100%",
  maxWidth: "100%",
  marginHorizontal: "auto",
}
const LOADER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  padding: spacing[8],
}
const HEADER: ViewStyle = {
  flexDirection: "row",
  paddingBottom: spacing[4],
  alignItems: "center",
  justifyContent: "space-between",
}
const TITLE: ViewStyle = {
  flex: 1,
}
const MODAL_SUBTITLE: ViewStyle = {
  marginBottom: spacing[5],
}
const CLOSE_BUTTON: ViewStyle = { width: 17 }

const CALCULATIONS_ITEM: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing[4],
  padding: spacing[4],
  borderRadius: 16,
}
const CALCULATIONS_ITEM_TEXTS_ROW: ViewStyle = {
  flex: 1,
}
const CALCULATIONS_ITEM_NAMES_ROW: ViewStyle = {
  width: 70,
  position: "relative",
}
const CALCULATIONS_MALE_BG: ViewStyle = {
  backgroundColor: color.palette.blue,
}
const CALCULATIONS_FEMALE_BG: ViewStyle = {
  backgroundColor: color.palette.purple,
}
const CALCULATIONS_ITEM_NAMES_FIRST: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 100,
  zIndex: 0,
  position: "absolute",
  top: -18,
  right: 32,
  height: 32,
  width: 32,
}
const CALCULATIONS_ITEM_NAMES_SINGLE: ViewStyle = {
  right: -2,
}
const CALCULATIONS_ITEM_NAMES_SECOND: ViewStyle = {
  borderRadius: 100,
  zIndex: 1,
  position: "absolute",
  top: -21,
  right: -2,
  height: 38,
  width: 38,
  padding: 3,
}
const CALCULATIONS_ITEM_NAMES_SECOND_WRAPPER: ViewStyle = {
  borderRadius: 100,
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}
const INITIAL_TEXT: TextStyle = {
  color: color.palette.white,
}
const SELECTED_USER_ACTIVE: ViewStyle = {
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: color.palette.lightSteelBlue,
}
const SELECTED_USER: ViewStyle = {
  borderWidth: 0,
}

const BG_COLORS = ["#638F3C4D", "#AB40814D", "#4475AF4D", "#AD7A2C4D"]

export const UserHistoryModal = observer((props: UserHistoryModalProps) => {
  const { visible, closeModal, data, onUserSelect, selectedUser } = props
  const [loading, setLoading] = useState(true)
  const [activeItem, setActiveItem] = useState(selectedUser)
  const screenWidth = useWindowWidth()

  const getGenderBg = (gender: string) => {
    return gender === GenderEnum.Male ? CALCULATIONS_MALE_BG : CALCULATIONS_FEMALE_BG
  }

  const onUserChange = (item: UserData) => {
    setActiveItem(item.orderId)
    onUserSelect(item)
  }
  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }

  const fadeOut = () => {
    fadeAnim.setValue(0)
  }
  useEffect(() => {
    if (visible) {
      fadeIn()
      if (data) {
        setLoading(false)
      }
    } else {
      fadeOut()
    }
  }, [visible, data])

  return (
    <>
      {visible && (
        <Animated.View
          style={[
            BG_OVERLAY,
            {
              opacity: fadeAnim,
            },
          ]}
        />
      )}
      <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={closeModal}>
        <View style={[MODAL_WRAPPER, { maxWidth: screenWidth }]}>
          <View style={HEADER}>
            <Text preset="h3" tx="userHistoryModal.selectPerson" style={TITLE} numberOfLines={1} />
            <Button preset="unstyled" onPress={closeModal} style={CLOSE_BUTTON}>
              <Icon icon="close" />
            </Button>
          </View>
          <Text preset="default" tx="userHistoryModal.subtitle" style={MODAL_SUBTITLE} />
          {loading ? (
            <View style={LOADER}>
              <ActivityIndicator size="large" color={color.primary} />
            </View>
          ) : (
            <FlatList
              data={data}
              keyExtractor={(item) => String(item.orderId)}
              renderItem={({ item, index }) => (
                <TouchableOpacity activeOpacity={0.4} onPress={() => onUserChange(item)}>
                  <View
                    style={[
                      { backgroundColor: item.color || BG_COLORS[index] } as ViewStyle,
                      CALCULATIONS_ITEM,
                      activeItem === item.orderId ? SELECTED_USER_ACTIVE : SELECTED_USER,
                    ]}
                  >
                    <View style={CALCULATIONS_ITEM_TEXTS_ROW}>
                      <Text
                        preset="h4"
                        text={item.title || `${item.me.firstName} ${item.me.lastName}`}
                        numberOfLines={1}
                      />
                    </View>
                    <View style={CALCULATIONS_ITEM_NAMES_ROW}>
                      <View
                        style={[
                          CALCULATIONS_ITEM_NAMES_FIRST,
                          getGenderBg(item.me.gender),
                          !item.partner && CALCULATIONS_ITEM_NAMES_SINGLE,
                        ]}
                      >
                        <Text preset="bold" style={INITIAL_TEXT} text={item.me.initial} />
                      </View>
                      {item.partner && (
                        <View
                          style={[
                            { backgroundColor: item.color } as ViewStyle,
                            CALCULATIONS_ITEM_NAMES_SECOND,
                          ]}
                        >
                          <View
                            style={[
                              CALCULATIONS_ITEM_NAMES_SECOND_WRAPPER,
                              getGenderBg(item.partner.gender),
                            ]}
                          >
                            <Text preset="bold" style={INITIAL_TEXT} text={item.partner.initial} />
                          </View>
                        </View>
                      )}
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            />
          )}
        </View>
      </Modal>
    </>
  )
})
