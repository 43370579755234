import React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { color, spacing } from "../../../theme"
import { RenderHtmlWrapper } from "../../render-html-wrapper/render-html-wrapper"
import { Text } from "../../text/text"
import { Button } from "../../button/button"
import { TouchableOpacity } from "react-native-gesture-handler"
import { Icon } from "../../icon/icon"

interface BlurHtmlFragmentProps {
  content: IBlurHtmlFragment
  onUnlockRequest?: () => void
}

export interface IBlurHtmlFragment {
  title?: string
  type: "blurredHtml"
  content: string
}

export const BlurHtmlFragment = (props: BlurHtmlFragmentProps) => {
  const { content, onUnlockRequest } = props

  return (
    <View style={WRAPPER}>
      <View style={CONTENT}>
        <Text preset="h3" text={content.title} />
        <RenderHtmlWrapper html={content.content} />
        <View style={BLUR_BOTTOM} />
      </View>
      <TouchableOpacity activeOpacity={0.8} style={UNLOCK_BUTTON_WRAPPER} onPress={onUnlockRequest}>
        <Text style={UNLOCK_TEXT} preset="bold" tx="unlockBlockModal.unlockPromoText" />
        <Icon icon="arrowDown" svgProps={{ width: 24, height: 24, fill: color.primary }} />
        <Button
          activeOpacity={0.8}
          tx="common.unlock"
          style={UNLOCK_BUTTON}
          onPress={onUnlockRequest}
        />
      </TouchableOpacity>
    </View>
  )
}

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const CONTENT: ViewStyle = {
  minHeight: 100,
}
const BLUR_BOTTOM: any = {
  position: "absolute",
  bottom: 0,
  left: -spacing[4],
  right: -spacing[4],
  height: 100,
  WebkitMask: "linear-gradient(to bottom,transparent -65%, black 65%)", // Web only css
  mask: "linear-gradient(to bottom,transparent -65%, black 65%)", // Web only css
  backdropFilter: "blur(3px)", // Web only css
}
const UNLOCK_BUTTON_WRAPPER: ViewStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: spacing[4],
}
const UNLOCK_TEXT: TextStyle = {
  textAlign: "center",
  marginBottom: spacing[4],
  color: color.primary,
}
const UNLOCK_BUTTON: ViewStyle = {
  width: "100%",
  marginVertical: spacing[4],
  maxWidth: 280,
  marginHorizontal: 36,
}
