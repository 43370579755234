import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
  ProfileScreen,
  ProfileContactSupportScreen,
  ProfileUserAgreementScreen,
  ProfilePrivacyPolicyScreen,
  ProfileAboutScreen,
  ProfileSubscriptionInfoScreen,
  ProfilePromoCodeScreen,
  ProfilePromoCodeSuccessScreen,
  ProfileEditScreen,
  ProfileEditNameScreen,
  ProfileEditBirthNameScreen,
  ProfileEditGenderScreen,
  ProfileEditDateAndTimeScreen,
  ProfileEditPlaceOfBirthScreen,
  ProfileEditLanguageScreen,
  ProfileAboutAppScreen,
  ProfileSubscriptionDetailsScreen,
} from "../screens"
import { translate } from "../i18n"
import { ProfileStackParamList } from "./app-navigation-routes"

const Stack = createNativeStackNavigator<ProfileStackParamList>()

export const ProfileStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="profileIndex"
    >
      <Stack.Screen
        name="profileIndex"
        component={ProfileScreen}
        options={{ title: translate("navigator.profileTitle") }}
      />
      <Stack.Screen
        name="profileContactSupport"
        component={ProfileContactSupportScreen}
        options={{ title: translate("navigator.profileContactSupportTitle") }}
      />
      <Stack.Screen
        name="profileUserAgreement"
        component={ProfileUserAgreementScreen}
        options={{ title: translate("navigator.profileUserAgreementTitle") }}
      />
      <Stack.Screen
        name="profilePrivacyPolicy"
        component={ProfilePrivacyPolicyScreen}
        options={{ title: translate("navigator.profilePrivacyPolicyTitle") }}
      />
      <Stack.Screen
        name="profileAbout"
        component={ProfileAboutScreen}
        options={{ title: translate("navigator.profileAboutTitle") }}
      />
      <Stack.Screen
        name="profileSubscriptionInfo"
        component={ProfileSubscriptionInfoScreen}
        options={{ title: translate("navigator.profileSubscriptionInfoTitle") }}
      />
      <Stack.Screen
        name="profileSubscriptionDetails"
        component={ProfileSubscriptionDetailsScreen}
        options={{ title: translate("navigator.profileSubscriptionDetailsTitle") }}
      />
      <Stack.Screen
        name="profilePromoCode"
        component={ProfilePromoCodeScreen}
        options={{ title: translate("navigator.profilePromoCodeTitle") }}
      />
      <Stack.Screen
        name="profilePromoCodeSuccess"
        component={ProfilePromoCodeSuccessScreen}
        options={{ title: translate("navigator.profilePromoCodeTitle") }}
      />
      <Stack.Screen
        name="profileEdit"
        component={ProfileEditScreen}
        options={{ title: translate("navigator.profileTitle") }}
      />
      <Stack.Screen
        name="profileEditName"
        component={ProfileEditNameScreen}
        options={{ title: translate("navigator.profileTitle") }}
      />
      <Stack.Screen
        name="profileEditBirthName"
        component={ProfileEditBirthNameScreen}
        options={{ title: translate("navigator.profileTitle") }}
      />
      <Stack.Screen
        name="profileEditGender"
        component={ProfileEditGenderScreen}
        options={{ title: translate("navigator.profileTitle") }}
      />
      <Stack.Screen
        name="profileEditDateAndTime"
        component={ProfileEditDateAndTimeScreen}
        options={{ title: translate("navigator.profileTitle") }}
      />
      <Stack.Screen
        name="profileEditPlaceOfBirth"
        component={ProfileEditPlaceOfBirthScreen}
        options={{ title: translate("navigator.profileTitle") }}
      />
      <Stack.Screen
        name="profileEditLanguage"
        component={ProfileEditLanguageScreen}
        options={{ title: translate("navigator.profileTitle") }}
      />
      <Stack.Screen
        name="profileAboutApp"
        component={ProfileAboutAppScreen}
        options={{ title: translate("navigator.profileAboutAppTitle") }}
      />
    </Stack.Navigator>
  )
}
