import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Subscriptions = (props: SvgProps) => (
  <Svg width={14} height={20} fill="#00E6DB" viewBox="0 0 14 20" {...props}>
    <Path d="m12 19.245.002-6A7.966 7.966 0 0 1 7 15a7.966 7.966 0 0 1-5-1.754l.003 6a.75.75 0 0 0 1.181.612l3.817-2.687 3.818 2.687a.75.75 0 0 0 1.182-.613H12ZM14 7A7.002 7.002 0 0 0 2.05 2.05 7 7 0 1 0 14 7ZM7.229 3.642l.912 1.853 2.044.295c.21.03.293.288.142.435l-1.479 1.44.35 2.036a.255.255 0 0 1-.37.269L7 9.008l-1.827.962a.255.255 0 0 1-.37-.269l.35-2.035-1.48-1.44a.255.255 0 0 1 .142-.436l2.044-.295.912-1.853a.255.255 0 0 1 .458 0Z" />
  </Svg>
)

export default Subscriptions
