import React, { useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Button, DatePicker, Icon, Text } from "../../../components"
import { color, spacing } from "../../../theme"
import { useStores } from "../../../models"
import { FormulaHeading } from "./components/formula-heading"
import { FormulaCard } from "./components/formula-card"
import { FormulaSolutionSteps, ISolutionStepItem } from "./components/formula-solutions-steps"
import { translate } from "../../../i18n"
import { parseAndFormatDateLocalRaw } from "../../../utils/date"

const MainImg = require("./images/dateOfBirthMain.png")
const CardImg = require("./images/dateOfBirthCard.png")

interface IFormula {
  key: string
  text: string
}

export const DateOfBirthFormula = observer(() => {
  const { profileStore, formulaStore } = useStores()
  const [dateOfBirth, setDateOfBirth] = useState(profileStore.dateOfBirth)
  const [calculatedResult, setCalculatedResult] = useState<IFormula>()
  const [solutionSteps, setSolutionSteps] = useState<ISolutionStepItem[]>([])

  const calculateKey = (number: string): number => {
    let key = number
      .split("")
      .map((n: string) => Number(n) || 0)
      .reduce((a: number, b: number) => a + b, 0)

    if (Number(key) > 9) {
      key = calculateKey(key.toString())
    }

    return key
  }

  const birthdayCalculate = async () => {
    if (dateOfBirth.length !== 10) {
      return
    }

    const result = calculateKey(dateOfBirth).toString()

    const meaningOfBirthdate = await formulaStore.getMeaningOfBirthdate(result)

    setSolutionSteps(calculateSteps())
    setCalculatedResult({
      key: result,
      text: meaningOfBirthdate.htmlContent,
    })
  }

  const calculateSteps = () => {
    const steps: ISolutionStepItem[] = []

    const formats = [
      { format: "MM", type: "month" },
      { format: "dd", type: "day" },
      { format: "yyyy", type: "year" },
    ]

    for (const f of formats) {
      steps.push({
        numbers: parseAndFormatDateLocalRaw(dateOfBirth, f.format).split("").join(" + "),
        type: f.type,
      })
    }

    return steps
  }

  return (
    <View>
      {!calculatedResult ? (
        <>
          <FormulaHeading
            title={"dateOfBirthFormulaScreen.title"}
            description={"dateOfBirthFormulaScreen.description"}
            image={MainImg}
          />

          {/* FORMS */}
          <View style={FORM_CONTAINER}>
            <DatePicker
              placeholderTx="welcomeForm4Screen.dateOfBirth"
              value={dateOfBirth}
              setValue={setDateOfBirth}
            />
            <Button onPress={birthdayCalculate}>
              <Text
                preset="h4"
                style={BUTTON_CALCULATE_TEXT}
                tx="freeFormulasButton.calculateNow"
              />
            </Button>
          </View>
        </>
      ) : (
        <>
          <FormulaCard
            title={translate("dateOfBirthFormulaScreen.cardTitle")}
            htmlContent={calculatedResult.text}
            image={CardImg}
          />

          <FormulaSolutionSteps result={calculatedResult.key} steps={solutionSteps} />

          <Button preset="outline" onPress={() => setCalculatedResult(null)} style={BUTTON_RELOAD}>
            <Icon icon={"reload"} containerStyle={RELOAD_ICON} />
            <Text preset="h4" tx="freeFormulasButton.newCalculate" style={RELOAD_TEXT} />
          </Button>
        </>
      )}
    </View>
  )
})

const FORM_CONTAINER: ViewStyle = { marginTop: spacing[7] }
const BUTTON_CALCULATE_TEXT: TextStyle = { color: color.palette.black }
const BUTTON_RELOAD: ViewStyle = {
  justifyContent: "center",
  flexDirection: "row",
  backgroundColor: color.palette.black500,
  marginVertical: spacing[6],
}
const RELOAD_ICON: ViewStyle = { marginRight: spacing[2] }
const RELOAD_TEXT: TextStyle = { color: color.palette.brightTurquoise }
