import { useEffect, useRef } from "react"

export const useWebOnScroll = ({ onScroll, onScrollEnd }) => {
  const lastScrollEvent = useRef(null)
  const scrollEndTimeout = useRef(null)

  const handleWebScroll = (event) => {
    onScroll(event)

    const timestamp = Date.now()

    if (scrollEndTimeout.current) {
      clearTimeout(scrollEndTimeout.current)
    }

    if (lastScrollEvent.current) {
      // Scroll ended
      scrollEndTimeout.current = setTimeout(() => {
        if (lastScrollEvent.current === timestamp) {
          lastScrollEvent.current = null
          onScrollEnd({ event })
        }
      }, 500)
    }

    lastScrollEvent.current = timestamp
  }

  useEffect(() => {
    return () => {
      scrollEndTimeout.current && clearTimeout(scrollEndTimeout.current)
    }
  }, [])

  return handleWebScroll
}
