const ZODIACS = [
  {
    name: "aries",
    symbol: "♈",
    dateMin: "2000-03-21",
    dateMax: "2000-04-20",
  },
  {
    name: "taurus",
    symbol: "♉",
    dateMin: "2000-04-21",
    dateMax: "2000-05-21",
  },
  {
    name: "gemini",
    symbol: "♊",
    dateMin: "2000-05-22",
    dateMax: "2000-06-21",
  },
  {
    name: "cancer",
    symbol: "♋",
    dateMin: "2000-06-22",
    dateMax: "2000-07-22",
  },
  {
    name: "leo",
    symbol: "♌",
    dateMin: "2000-07-23",
    dateMax: "2000-08-22",
  },
  {
    name: "virgo",
    symbol: "♍",
    dateMin: "2000-08-23",
    dateMax: "2000-09-23",
  },
  {
    name: "libra",
    symbol: "♎",
    dateMin: "2000-09-24",
    dateMax: "2000-10-23",
  },
  {
    name: "scorpio",
    symbol: "♏",
    dateMin: "2000-10-24",
    dateMax: "2000-11-22",
  },
  {
    name: "sagittarius",
    symbol: "♐",
    dateMin: "2000-11-23",
    dateMax: "2000-12-21",
  },
  {
    name: "capricorn",
    symbol: "♑",
    dateMin: "2000-12-22",
    dateMax: "2000-01-20",
  },
  {
    name: "aquarius",
    symbol: "♒",
    dateMin: "2000-01-21",
    dateMax: "2000-02-19",
  },
  {
    name: "pisces",
    symbol: "♓",
    dateMin: "2000-02-20",
    dateMax: "2000-03-20",
  },
]

export const getZodiacSignByDate = (day: number, month: number) => {
  const date = new Date(`2000-${month}-${day}`)
  if (date.toString() === "Invalid Date") {
    return ""
  }

  const i = ZODIACS.findIndex((sign) => {
    const dateMin = new Date(sign.dateMin)
    const dateMax = new Date(sign.dateMax)

    return (
      (date.getDate() >= dateMin.getDate() && date.getMonth() === dateMin.getMonth()) ||
      (date.getDate() <= dateMax.getDate() && date.getMonth() === dateMax.getMonth())
    )
  })

  return ZODIACS[i].symbol
}
