import React from "react"
import { View, ViewStyle, TextStyle, ImageStyle, Pressable } from "react-native"
import { Text } from "../text/text"
import { AutoImage } from "../auto-image/auto-image"
import { useStores } from "../../models"
import { SubscriptionDuration } from "../../models/profile-store/profile-store"
import { color, spacing } from "../../theme"

const background = require("./images/subscribe-bg.png")

export interface SubscribeProps {
  onPress: () => void
}

const CONTAINER: ViewStyle = {
  borderRadius: 16,
  overflow: "hidden",
  height: 108,
  position: "relative",
}
const BACKGROUND: ImageStyle = {
  width: "100%",
  height: "100%",
}
const CONTENT: ViewStyle = {
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  padding: spacing[4],
  justifyContent: "space-between",
  alignItems: "flex-start",
}
const INFO: ViewStyle = {
  paddingVertical: spacing[2],
  paddingHorizontal: spacing[3],
  borderRadius: 24,
  backgroundColor: color.palette.white,
}
const INFO_TEXT: TextStyle = {
  color: color.palette.sapphireBlue,
}

export function Subscribe(props: SubscribeProps) {
  const { onPress } = props
  const { profileStore } = useStores()
  const currentSubscription = profileStore.subscriptionDuration

  return (
    <>
      {currentSubscription !== SubscriptionDuration.Annual ? (
        <Pressable onPress={onPress}>
          <View style={CONTAINER}>
            <AutoImage source={background} resizeMode="cover" style={BACKGROUND} />
            <View style={CONTENT}>
              <Text preset="h4" tx="subscribe.subscribe" />
              <View style={INFO}>
                <Text
                  preset="default"
                  tx={
                    currentSubscription === SubscriptionDuration.None
                      ? "subscribe.month"
                      : "subscribe.year"
                  }
                  style={INFO_TEXT}
                />
              </View>
            </View>
          </View>
        </Pressable>
      ) : null}
    </>
  )
}
