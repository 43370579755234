import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { AcademyVideoModel } from "./academy-videos"

export const AcademyVideoCategoryModel = types.model("AcademyVideoCategory").props({
  id: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  videos: types.optional(types.array(AcademyVideoModel), []),
})

export interface AcademyVideoCategoryStore extends Instance<typeof AcademyVideoCategoryModel> {}
export interface AcademyVideoCategoryStoreSnapshotOut
  extends SnapshotOut<typeof AcademyVideoCategoryModel> {}
export interface AcademyVideoCategoryStoreSnapshotIn
  extends SnapshotIn<typeof AcademyVideoCategoryModel> {}
export const createCharacterDefaultModel = () => types.optional(AcademyVideoCategoryModel, {})
