import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"

export const AcademyVideoModel = types.model("AcademyVideo").props({
  id: types.optional(types.string, ""),
  categoryIndex: types.maybeNull(types.number),
  preview: types.optional(types.string, ""),
  url: types.optional(types.string, ""),
  hls: types.optional(types.string, ""),
  views: types.optional(types.number, 0),
})

export interface AcademyVideoStore extends Instance<typeof AcademyVideoModel> {}
export interface AcademyVideoStoreSnapshotOut extends SnapshotOut<typeof AcademyVideoModel> {}
export interface AcademyVideoStoreSnapshotIn extends SnapshotIn<typeof AcademyVideoModel> {}
export const createCharacterDefaultModel = () => types.optional(AcademyVideoModel, {})
