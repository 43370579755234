import React from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import { HeaderProps } from "./header.props"
import { Text } from "../text/text"
import { Button } from "../button/button"
import { Icon } from "../icon/icon"
import { AutoImage } from "../auto-image/auto-image"
import { spacing } from "../../theme"
import { translate } from "../../i18n/"

// static styles
const ROOT: ViewStyle = {
  position: "relative",
  zIndex: 10,
  flexDirection: "row",
  paddingHorizontal: spacing[4],
  alignItems: "center",
  paddingVertical: spacing[4],
  justifyContent: "flex-start",
}
const TITLE_MIDDLE: ViewStyle = {
  flex: 1,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}
const TEXT_CENTER: TextStyle = { textAlign: "center" }
const ICON_WIDTH: ViewStyle = { width: 32 }
const TEXT_IMAGE_WIDTH: ViewStyle = { width: 25, alignItems: "center" }

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export function Header(props: HeaderProps) {
  const { onLeftPress, leftIcon, textImage, headerText, headerTx, style, titleStyle } = props
  const header = headerText || (headerTx && translate(headerTx)) || ""
  const textAlignStyle = TEXT_CENTER

  return (
    <View accessibilityRole="header" style={[ROOT, style]}>
      {leftIcon && (
        <View style={ICON_WIDTH}>
          <Button preset="unstyled" onPress={onLeftPress}>
            <Icon icon={leftIcon} />
          </Button>
        </View>
      )}
      <View style={TITLE_MIDDLE}>
        {textImage && (
          <View style={TEXT_IMAGE_WIDTH}>
            <AutoImage source={textImage} />
          </View>
        )}
        <Text style={[textAlignStyle, titleStyle]} preset="h4" text={header} numberOfLines={1} />
      </View>
      {leftIcon && <View style={ICON_WIDTH} />}
    </View>
  )
}
