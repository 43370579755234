import React from "react"
import { observer } from "mobx-react-lite"
import { DateOfBirthFormula } from "./date-of-birth-formula"
import { EnergyWealthByNameFormula } from "./energy-wealth-by-name-formula"
import { PastLifeCountryFormula } from "./past-life-country-formula"
import { ProfessionByBirthDateFormula } from "./profession-by-birth-date-formula"
import { MeaningBehindHouseNumberFormula } from "./meaning-behind-house-number-formula"

export enum FormulaTypes {
  DateOfBirth,
  EnergyWealthByName,
  PastLifeCountry,
  ProfessionByBirthDate,
  MeaningBehindHouseNumber,
}

interface FormulaScreenProps {
  type: FormulaTypes
}

export const FormulaScreen = observer((props: FormulaScreenProps) => {
  const { type } = props

  return (
    <>
      {(() => {
        switch (type) {
          case FormulaTypes.DateOfBirth:
            return <DateOfBirthFormula />
          case FormulaTypes.EnergyWealthByName:
            return <EnergyWealthByNameFormula />
          case FormulaTypes.PastLifeCountry:
            return <PastLifeCountryFormula />
          case FormulaTypes.ProfessionByBirthDate:
            return <ProfessionByBirthDateFormula />
          case FormulaTypes.MeaningBehindHouseNumber:
            return <MeaningBehindHouseNumberFormula />
          default:
            return null
        }
      })()}
    </>
  )
})
