import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import React from "react"
import { Linking, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { Icon, UserCard, Text, Screen, Header, ScreenTitle, Button } from "../../../components"
import { TxKeyPath } from "../../../i18n"
import { useStores } from "../../../models"
import {
  ExtraOrderUserTypeEnum,
  TemplateTypeEnum,
} from "../../../models/extra-order-store/extra-order-store"
import { TabsParamList } from "../../../navigators"
import { ApiRoutes } from "../../../services/api/api-config"
import { color, spacing } from "../../../theme"
import { parseAndFormatDateLocal } from "../../../utils/date"
import { IS_MOBILE } from "../../../utils/dimension"

interface CompatibilityCalculationProps {
  goBack(): void
  navigateToEditUser(userType: ExtraOrderUserTypeEnum, title: TxKeyPath): void
}

export const CompatibilityCalculation = observer((props: CompatibilityCalculationProps) => {
  const { profileStore, extraOrderStore, alertStore } = useStores()
  const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()

  const navigateToCompatibilityResult = (orderId: string, template: string) => {
    if (template === TemplateTypeEnum.CodeOfSamsara) {
      rootNavigation.navigate("home", {
        screen: "extraOrderStack",
        params: { screen: "extraOrderCompatibilityCode", params: { orderId } },
      })
    }
    if (template === TemplateTypeEnum.SoulMate) {
      rootNavigation.navigate("home", {
        screen: "extraOrderStack",
        params: {
          screen: "extraOrderCompatibilityResults",
          params: { orderId },
        },
      })
    }
  }

  const order = async () => {
    const isUser1Valid = extraOrderStore.isUserValid(ExtraOrderUserTypeEnum.User1)
    const isUser2Valid =
      extraOrderStore.numOfPersons === 1 ||
      extraOrderStore.isUserValid(ExtraOrderUserTypeEnum.User2)

    if (isUser1Valid && isUser2Valid) {
      calculationOrder()
    } else {
      alertStore.showError("Please fill partner card")
    }
  }

  const calculationOrder = async () => {
    if (extraOrderStore.useBonus) {
      const response = await extraOrderStore.orderCalculation()
      if (response?.orderId) {
        navigateToCompatibilityResult(response.orderId, response.template)
      }
    } else {
      const response = await extraOrderStore.orderCalculation()
      if (response?.orderId && response?.status === "PENDING") {
        const stripeUrl = ApiRoutes.EXTRA_ORDER_PAY_STRIPE_URL(profileStore.id, response.orderId)
        IS_MOBILE ? Linking.openURL(stripeUrl) : (window.location.href = stripeUrl)
      }
    }
  }

  const createUserCard = (userType: ExtraOrderUserTypeEnum) => {
    return (
      <>
        {extraOrderStore[userType]?.firstName?.length !== 0 ? (
          <UserCard
            firstName={extraOrderStore[userType].firstName}
            lastName={extraOrderStore[userType].lastName}
            fullName={`${extraOrderStore[userType].firstName} ${extraOrderStore[userType].lastName}`}
            userCardDefault={userType === ExtraOrderUserTypeEnum.User1}
            date={parseAndFormatDateLocal(extraOrderStore[userType].dateOfBirth, "MMMM d, yyyy")}
            time={extraOrderStore[userType].timeOfBirth}
            genderCode={extraOrderStore[userType].gender}
            editProfile={() => props.navigateToEditUser(userType, "extraOrderEditPerson.title")}
            deleteProfile={() => extraOrderStore.clearUserCardValues(userType)}
          />
        ) : (
          <TouchableOpacity
            style={ADD_USER_TAP}
            onPress={() => props.navigateToEditUser(userType, "extraOrderEditPerson.titleAdd")}
          >
            <Icon icon="tapSingle" />
            <Text
              preset="h4"
              tx={"extraOrderCompatibilityCalculationScreen.text"}
              numberOfLines={2}
              style={ADD_USER_TAP_TEXT}
            />
          </TouchableOpacity>
        )}
      </>
    )
  }

  return (
    <Screen style={CONTAINER} preset="scroll">
      <Header leftIcon="back" onLeftPress={() => props.goBack()} style={HEADER} />
      <ScreenTitle headerText={extraOrderStore.title} style={TITLE} />
      {createUserCard(ExtraOrderUserTypeEnum.User1)}

      <View style={ADD_USER_TAP_WRAPPER}>
        {extraOrderStore.numOfPersons === 2 && (
          <>
            <View style={ADD_USER_DECORATION}>
              <View style={[VERTICAL_LINE, VERTICAL_LINE_TOP]} />
              <Icon icon="addCircleLight" />
              <View style={[VERTICAL_LINE, VERTICAL_LINE_BOTTOM]} />
            </View>

            {createUserCard(ExtraOrderUserTypeEnum.User2)}
          </>
        )}
      </View>

      <View>
        <Text
          preset="defaultLight"
          text={`Cost: ${extraOrderStore.reportPrice}`}
          numberOfLines={1}
          style={TEXT_CENTER}
        />

        <Button
          tx="extraOrderCompatibilityScreen.calculateNow"
          onPress={order}
          style={BUTTON_CALCULATE_NOW}
        />
      </View>
    </Screen>
  )
})

const ADD_USER_TAP: ViewStyle = {
  flexDirection: "column",
  alignItems: "center",
  paddingHorizontal: spacing[4],
  paddingTop: 19,
  paddingBottom: spacing[4],
  backgroundColor: color.palette.black500,
  borderWidth: 2,
  borderStyle: "dashed",
  borderColor: color.palette.brightTurquoise,
  borderRadius: 8,
}
const ADD_USER_TAP_TEXT: TextStyle = {
  marginTop: 20,
  color: color.palette.brightTurquoise,
  textAlign: "center",
}
const TEXT_CENTER: TextStyle = {
  marginTop: spacing[4],
  textAlign: "center",
}
const CONTAINER: ViewStyle = {
  flexGrow: 1,
  paddingHorizontal: spacing[4],
  paddingBottom: spacing[4],
  justifyContent: "space-between",
}
const HEADER: ViewStyle = {
  paddingHorizontal: 0,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
  marginTop: spacing[5],
}
const ADD_USER_DECORATION: ViewStyle = {
  flexDirection: "column",
  alignItems: "center",
  marginVertical: spacing[4],
}
const VERTICAL_LINE: ViewStyle = {
  width: 2,
  height: 16,
  backgroundColor: color.palette.moderateBlue,
}
const VERTICAL_LINE_TOP: ViewStyle = {
  marginBottom: 10,
}
const VERTICAL_LINE_BOTTOM: ViewStyle = {
  marginTop: 10,
}
const ADD_USER_TAP_WRAPPER: ViewStyle = {
  flexGrow: 1,
}
const BUTTON_CALCULATE_NOW: ViewStyle = {
  marginTop: spacing[3],
}
