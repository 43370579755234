import React, { useCallback } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { DEFAULT_X_LABELS_HEIGHT_PERCENTAGE } from "react-native-chart-kit/dist/AbstractChart"
import { Line, G, Text as TextSVG } from "react-native-svg"
import { Text } from "../../text/text"
import { color, spacing, typography } from "../../../theme"
import { useWindowWidth } from "../../../utils/dimension"
import { texts } from "../../../styles"
import LineChart from "./extended"

export interface LineChartPoints {
  graphValuesY: number[]
  graphValuesX: number[]
}

interface LineChartProp {
  data: LineChartPoints[]
  legends: string[]
  labels: string[]
  age: number
  averageLine?: number[]
  averageLineLabels?: string[]
  labelsXTitle: string
  resolution: number
  xOffset?: number
}

const COLORS = {
  orange: "rgba(255, 198, 24, 1)",
  lightBlue: "rgba(0, 255, 255, 1)",
  red: "rgba(220,20,60, 1)",
  white: "rgba(255, 255, 255, 1)",
  transparent: "rgba(255, 255, 255, 0)",
}
const LINE_COLORS = [
  "rgba(0, 112, 192, 1)", // blue
  "rgba(119, 178, 81, 1)", // green
  "rgba(166, 108, 255, 1)", // purple
  "rgba(185, 0, 91, 1)", // red
]
const AVG_LINE_COLORS = [COLORS.orange, COLORS.lightBlue, COLORS.orange, COLORS.lightBlue]

export const CustomLineChart = ({
  data,
  legends,
  averageLine,
  averageLineLabels,
  labels,
  labelsXTitle,
  age,
  resolution,
  xOffset = 0,
}: LineChartProp) => {
  const screenWidth = useWindowWidth()
  const chartHeight = 260
  const chartWidth = screenWidth - 2 * spacing[4]
  const paddingRight = 30
  const paddingTop = 10

  const maxPointScale = 10
  const legendsArr =
    averageLine?.length && averageLineLabels?.length ? [...legends, ...averageLineLabels] : legends

  const linesDataset = data.map((item, i) => ({
    data: item.graphValuesY,
    dataX: item.graphValuesX,
    resolution,
    color: () => LINE_COLORS[i],
  }))

  const avgLinesDataset =
    averageLine?.map((avg, index) => ({
      data: [],
      color: () => AVG_LINE_COLORS[index],
    })) || []

  const chartData = {
    labels,
    datasets: [
      ...linesDataset,
      {
        data: [],
        color: () => COLORS.red,
      },
      ...avgLinesDataset,
      {
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 10],
        color: () => COLORS.transparent,
        withDots: false,
      },
    ],
  }

  const chartConfig = {
    backgroundGradientFrom: color.backgroundBlack500,
    backgroundGradientFromOpacity: 1,
    backgroundGradientTo: color.backgroundBlack500,
    backgroundGradientToOpacity: 1,
    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    strokeWidth: 1.5,
    decimalPlaces: 0,
    barPercentage: 0.5,
    useShadowColorFromDataset: false,
    propsForBackgroundLines: {
      strokeDasharray: "",
      strokeDashoffset: 15,
    },
    propsForDots: {
      r: "3",
    },
  }

  const renderCenterLine = ({ key, color, averageLine }) => {
    if (!averageLine) {
      return null
    }

    const barsAreaHeight = chartHeight * DEFAULT_X_LABELS_HEIGHT_PERCENTAGE
    const average = (barsAreaHeight / 10) * (10 - averageLine) + paddingTop

    return (
      <Line
        key={key}
        x1={paddingRight}
        y1={average}
        x2={chartWidth}
        y2={average}
        stroke={color}
        strokeDasharray="10"
      />
    )
  }

  const renderAgeLine = ({ color, age }) => {
    if (!age) {
      return null
    }

    const barsAreaHeight = chartHeight * DEFAULT_X_LABELS_HEIGHT_PERCENTAGE
    const cx = Math.floor(((chartWidth - paddingRight) / labels.length) * (age / 10) + paddingRight)

    return (
      <Line
        x1={cx}
        y1={0}
        x2={cx}
        y2={barsAreaHeight + paddingTop}
        stroke={color}
        strokeDasharray="10"
      />
    )
  }

  let invertIndex = []
  const getYDelta = (index: number) => {
    const offsetY = 11
    const offsetYNegative = -6

    const line1 = linesDataset[0]?.data[index] || -1
    const line2 = linesDataset[1]?.data[index] || -1
    if (line1 !== -1 && line2 !== -1) {
      const delta = Math.abs(line1 - line2)
      if (delta < 0.7) {
        if (invertIndex.includes(index)) {
          invertIndex = invertIndex.filter((e) => e !== index)
          return offsetY
        } else {
          invertIndex.push(index)
          return offsetYNegative
        }
      }
    }
    return offsetYNegative
  }

  const getDotXValueByIndex = useCallback(
    (index: number, indexData: number, indexDataX: number) => {
      const lineWeNeed = linesDataset.find(
        (l) => l.data[index] === indexData && l.dataX[index] === indexDataX,
      )
      const value =
        lineWeNeed && indexData !== null && lineWeNeed.dataX
          ? lineWeNeed.dataX[index] + xOffset
          : null
      return value
    },
    [data],
  )

  return (
    <View style={[CONTAINER, { width: chartWidth }]}>
      <View style={LEGENDS_CONTAINER}>
        {legendsArr.map((legend, index) => (
          <View key={index} style={LEGEND_WRAPPER}>
            <Text
              style={[
                LEGEND_CIRCLE,
                { backgroundColor: chartData.datasets[index].color() } as ViewStyle,
              ]}
            />
            <Text preset="medium" text={legend} />
          </View>
        ))}
      </View>
      <LineChart
        style={
          {
            fontFamily: typography.primary,
            paddingRight,
            paddingTop,
          } as ViewStyle
        }
        data={chartData}
        width={chartWidth}
        height={chartHeight}
        yAxisInterval={1}
        withShadow={false}
        chartConfig={chartConfig}
        segments={maxPointScale}
        getDotColor={(dataPoint) => (dataPoint === null ? COLORS.transparent : COLORS.white)}
        renderDotContent={({ x, y, index, indexData, indexDataX }) => (
          <TextSVG
            key={Math.random()}
            x={x}
            y={y + getYDelta(index)}
            fill="white"
            fontSize="8"
            textAnchor="middle"
          >
            {getDotXValueByIndex(index, indexData, indexDataX)}
          </TextSVG>
        )}
        decorator={() => (
          <G>
            {averageLine?.map((avg, index) =>
              renderCenterLine({
                key: index,
                averageLine: avg,
                color: AVG_LINE_COLORS[index],
              }),
            )}
            {renderAgeLine({
              age,
              color: COLORS.red,
            })}
          </G>
        )}
      />

      <View style={FOOTER}>
        <Text style={FOOTER_TEXT} text={labelsXTitle} />
      </View>
    </View>
  )
}

const CONTAINER: ViewStyle = {
  position: "relative",
  overflow: "hidden",
}
const LEGENDS_CONTAINER: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-around",
  alignItems: "center",
}
const LEGEND_WRAPPER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: spacing[1],
  marginRight: spacing[1],
}
const LEGEND_CIRCLE: ViewStyle = {
  width: 14,
  height: 14,
  marginRight: spacing[1],
  borderRadius: 14,
}
const FOOTER: ViewStyle = {
  position: "absolute",
  bottom: spacing[1],
  left: "50%",
}
const FOOTER_TEXT: TextStyle = {
  ...texts.SMALL_TEXT,
}
