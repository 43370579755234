import React from "react"
import { ImageSourcePropType, ImageStyle, TextStyle, View, ViewStyle } from "react-native"
import { LinearGradient } from "expo-linear-gradient"
import { AutoImage, RenderHtmlWrapper, Text } from "../../../../components"
import { spacing } from "../../../../theme"

interface IFormulaCardProps {
  htmlContent?: string
  title: string
  image: ImageSourcePropType
}

export function FormulaCard(props: IFormulaCardProps) {
  const gradientColors = ["rgba(0, 230, 219, 0)", "rgba(0, 230, 219, 0.25)"]

  return (
    <LinearGradient start={[-0.1, -0.2]} colors={gradientColors} style={CARD_CONTAINER}>
      <View style={CARD_CONTENT}>
        <Text preset="h4" style={LIST_HEADING} text={props.title} />
        <View>
          <RenderHtmlWrapper html={props.htmlContent} />
        </View>
      </View>
      <AutoImage source={props.image} style={CARD_IMAGE} />
    </LinearGradient>
  )
}

const LIST_HEADING: TextStyle = { marginBottom: spacing[3] }
const CARD_CONTAINER: ViewStyle = {
  flex: 1,
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 16,
  overflow: "hidden",
}
const CARD_CONTENT: TextStyle = {
  width: "60%",
  padding: 16,
  zIndex: 1,
}
const CARD_IMAGE: ImageStyle = {
  width: "45%",
  height: "100%",
  position: "absolute",
  right: 0,
  bottom: 0,
}
