import { TxKeyPath } from "../i18n"

interface ILocales {
  code: string
  text: TxKeyPath
}

export const locales: ILocales[] = [
  {
    code: "en",
    text: "languages.en",
  },
  /* {
    code: "es",
    text: "languages.es",
  }, */
]
