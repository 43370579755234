import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
  HomeScreen,
  HomeIntroScreen,
  HomeIntroDetailsScreen,
  MyCodeOfSamsaraScreen,
  MyCodeOfSamsaraDetailsScreen,
  HomeSubscriptionScreen,
  FreeFormulasScreen,
} from "../screens"
import { translate } from "../i18n"
import { ExtraOrderStack } from "./app-navigator-extra-order"
import { HomeStackParamList } from "./app-navigation-routes"

const Stack = createNativeStackNavigator<HomeStackParamList>()

export const HomeStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="homeIndex"
    >
      <Stack.Screen
        name="homeIndex"
        component={HomeScreen}
        options={{ title: translate("navigator.homeTitle") }}
      />
      <Stack.Screen
        name="homeIntro"
        component={HomeIntroScreen}
        options={{ title: translate("navigator.homeIntroTitle") }}
      />
      <Stack.Screen
        name="homeIntroDetails"
        component={HomeIntroDetailsScreen}
        options={{ title: translate("navigator.homeIntroTitle") }}
      />
      <Stack.Screen
        name="myCodeOfSamsara"
        component={MyCodeOfSamsaraScreen}
        options={{ title: translate("navigator.homeMyCodeOfSamsaraTitle") }}
      />
      <Stack.Screen
        name="myCodeOfSamsaraDetails"
        component={MyCodeOfSamsaraDetailsScreen}
        options={{ title: translate("navigator.homeMyCodeOfSamsaraTitle") }}
      />
      <Stack.Screen
        name="freeFormulasScreen"
        component={FreeFormulasScreen}
        options={{ title: translate("navigator.homeFreeFormulasTitle") }}
      />
      <Stack.Screen name="extraOrderStack" component={ExtraOrderStack} />
      <Stack.Screen
        name="homeSubscription"
        component={HomeSubscriptionScreen}
        options={{ title: translate("navigator.homeSubscriptionTitle") }}
      />
    </Stack.Navigator>
  )
}
