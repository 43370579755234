import { createNativeStackNavigator } from "@react-navigation/native-stack"
import React from "react"
import { translate } from "../i18n"
import {
  ExtraOrderCompatibilityCode,
  ExtraOrderCompatibilityCalculationScreen,
  ExtraOrderCompatibilityResultsPostScreen,
  ExtraOrderCompatibilityResultsScreen,
  ExtraOrderCompatibilityScreen,
  ExtraOrderEditUserScreen,
  ExtraOrderScreen,
} from "../screens"
import { ExtraOrderStackParamList } from "./app-navigation-routes"

const Stack = createNativeStackNavigator<ExtraOrderStackParamList>()

export const ExtraOrderStack = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName="extraOrderIndex">
      <Stack.Screen
        name="extraOrderIndex"
        component={ExtraOrderScreen}
        options={{ title: translate("navigator.extraOrderTitle") }}
      />
      <Stack.Screen
        name="extraOrderCompatibility"
        component={ExtraOrderCompatibilityScreen}
        options={{ title: translate("navigator.extraOrderTitle") }}
      />
      <Stack.Screen
        name="extraOrderCompatibilityCalculation"
        component={ExtraOrderCompatibilityCalculationScreen}
        options={{ title: translate("navigator.extraOrderTitle") }}
      />
      <Stack.Screen
        name="extraOrderEditUser"
        component={ExtraOrderEditUserScreen}
        options={{ title: translate("navigator.extraOrderTitle") }}
      />
      <Stack.Screen
        name="extraOrderCompatibilityResults"
        component={ExtraOrderCompatibilityResultsScreen}
        options={{ title: translate("navigator.extraOrderTitle") }}
      />
      <Stack.Screen
        name="extraOrderCompatibilityResultsPost"
        component={ExtraOrderCompatibilityResultsPostScreen}
        options={{ title: translate("navigator.extraOrderTitle") }}
      />
      <Stack.Screen
        name="extraOrderCompatibilityCode"
        component={ExtraOrderCompatibilityCode}
        options={{ title: translate("navigator.extraOrderTitle") }}
      />
    </Stack.Navigator>
  )
}
