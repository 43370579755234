/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React, { useEffect, useRef, useState } from "react"
import { NavigationContainer, DefaultTheme } from "@react-navigation/native"
import { Platform, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import i18n from "i18n-js"
import { navigationRef, useBackButtonHandler } from "./navigation-utilities"
import { color } from "../theme"
import { useStores } from "../models"
import { MAX_DESKTOP_WIDTH } from "../utils/dimension"
import { changeLanguage } from "../i18n"
import { AppTabs } from "./app-navigation-tabs"
import { WelcomeStack } from "./app-navigator-welcome"
import { AlertModal } from "../components"
import { logScreenView } from "../services/analytics/analytics"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

const appTheme = DefaultTheme
appTheme.colors.background = color.background

export const AppNavigator = observer((props: NavigationProps) => {
  useBackButtonHandler(canExit)
  const { meStore, profileStore } = useStores()
  const [languageLoading, setLanguageLoading] = useState(false)
  const [authLoading, setAuthLoading] = useState(true)
  const routeNameRef = useRef<string>()
  const { onStateChange, ...otherProps } = props

  const BACKGROUND_WRAPPER: ViewStyle = {
    flex: 1,
    alignItems: "center",
    backgroundColor: color.background, // Prevent the white flash
  }

  const CONTAINER_WRAPPER: ViewStyle = {
    flex: 1,
    width: "100%",
    ...Platform.select({
      web: {
        maxWidth: MAX_DESKTOP_WIDTH,
      },
    }),
  }

  useEffect(() => {
    ;(async () => {
      setLanguageLoading(true)
      if (Platform.OS === "web") {
        const urlParams = new URLSearchParams(window.location.search)
        const newRefreshToken = urlParams.get("refreshToken")

        if (newRefreshToken) {
          await meStore.setTokens("empty", decodeURIComponent(newRefreshToken))
          window.history.replaceState({}, document.title, "/")
        }
      }

      if (meStore.isGuest) {
        if (meStore.shouldLogOutGuest) {
          await meStore.logOut()
        } else {
          meStore.setShouldLogOutGuest(true)
        }
      } else {
        await meStore.callRefreshToken()
      }

      setAuthLoading(false)
    })()
  }, [meStore.isGuest])

  useEffect(() => {
    if (i18n.currentLocale() !== profileStore.preferredLocale) {
      setLanguageLoading(true)
      changeLanguage(profileStore.preferredLocale)
    }
  }, [profileStore.preferredLocale, setLanguageLoading])

  useEffect(() => {
    if (languageLoading) {
      setLanguageLoading(false)
    }
  }, [languageLoading])

  return authLoading ? (
    <View style={BACKGROUND_WRAPPER}></View>
  ) : (
    <NavigationContainer
      ref={navigationRef}
      theme={appTheme}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute().name
      }}
      onStateChange={(state) => {
        try {
          onStateChange(state)

          const previousRouteName = routeNameRef.current
          const currentRouteName = navigationRef.getCurrentRoute().name

          if (previousRouteName !== currentRouteName) {
            logScreenView(currentRouteName, currentRouteName)

            // Save the current route name for later comparison
            routeNameRef.current = currentRouteName
          }
        } catch {}
      }}
      {...otherProps}
    >
      <View style={BACKGROUND_WRAPPER}>
        {!languageLoading && (
          <View style={CONTAINER_WRAPPER}>
            {meStore.isAuthenticated ? <AppTabs /> : <WelcomeStack />}
            <AlertModal />
          </View>
        )}
      </View>
    </NavigationContainer>
  )
})

AppNavigator.displayName = "AppNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = [
  "welcome",
  "homeIndex",
  "profileIndex",
  "academyIndex",
  "soulMateIndex",
  "horoscopeIndex",
]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
