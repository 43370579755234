import React, { FC, useEffect, useState } from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field"
import { observer } from "mobx-react-lite"
import { Text, Screen, Stepper, Button, Icon } from "../../components"
import { color, spacing } from "../../theme"
import { WelcomeStackScreenProps } from "../../navigators"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  flex: 1,
  padding: spacing[4],
}
const TITLE: TextStyle = {
  marginBottom: spacing[2],
}
const CODE_CONTENT: ViewStyle = {
  flexGrow: 1,
  justifyContent: "center",
  marginBottom: 30,
}
const CODE_CONTENT_TITLE: TextStyle = {
  textAlign: "center",
  marginBottom: spacing[4],
}
const CODE_INPUTS_WRAPPER: ViewStyle = {
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: spacing[4],
}
const INPUT: TextStyle = {
  marginHorizontal: spacing[2],
  textAlign: "center",
  width: 55,
  height: 60,
  fontSize: 16,
  color: color.palette.white,
  lineHeight: 57,
  backgroundColor: color.palette.black500,
  borderRadius: 8,
  borderColor: color.palette.blackBorder,
  borderWidth: 2,
  overflow: "hidden",
}
const INPUT_FOCUSED: ViewStyle = {
  borderColor: color.palette.brightTurquoise,
}
const CODE_CONTENT_SUBTITLE: TextStyle = {
  textAlign: "center",
}
const CODE_CONTENT_LINK: TextStyle = {
  textAlign: "center",
  color: color.palette.brightTurquoise,
}
const BUTTON_CONTENT: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
}
const BUTTON_BACK: ViewStyle = {
  width: "35%",
}
const BUTTON_BACK_CONTENT: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const BUTTON_NEXT: ViewStyle = {
  width: "60%",
  justifyContent: "center",
}
const BUTTON_NEXT_CONTENT: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}
const BUTTON_NEXT_TEXT: TextStyle = {
  color: color.palette.black,
  flexGrow: 1,
  textAlign: "center",
}

export const WelcomeForm2Screen: FC<WelcomeStackScreenProps<"welcomeForm2">> = observer(
  ({ navigation }) => {
    const { welcomeStore, alertStore } = useStores()

    const [verify, setVerify] = useState({
      isValid: true,
      lockedOut: false,
      message: "",
    })
    const [counter, setCounter] = useState(1800) // 30min

    const CELL_COUNT = 5
    const [value, setValue] = useState("")
    const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT })
    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
      value,
      setValue,
    })

    const goBack = () => navigation.navigate("welcomeForm1")

    const nextStep = async () => {
      if (value.length !== 5) {
        alertStore.showError("The code must contain 5 digits. Please enter all digits")
        return
      }

      welcomeStore.step2(value)
      const verifyResult = await welcomeStore.verifyConfirmation()
      if (verifyResult?.data?.isSuccess) {
        setVerify(verifyResult.data?.data)
        if (verifyResult.data?.data.lockedOut) {
          setCounter(1800)
        }

        if (verifyResult.data?.data.isValid) {
          const result = await welcomeStore.login(welcomeStore.confirmationCode)
          if (result && result.message === "Customer not found") {
            navigation.navigate("welcomeForm3")
          } else if (result && result.message) {
            alertStore.showError(result.message)
          }
        } else {
          alertStore.showError(verifyResult.data?.data?.message)
        }
      } else {
        alertStore.showError(verifyResult.message)
      }
    }

    const close = () => navigation.navigate("welcome")

    useEffect(() => {
      const interval = setInterval(() => {
        if (counter <= 0) {
          setVerify({
            isValid: true,
            lockedOut: false,
            message: "",
          })
          clearInterval(interval)
        }
        setCounter(counter - 1)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [counter])

    return (
      <View style={FULL}>
        <Screen style={CONTAINER} preset="scroll">
          <Stepper step={2} onClosePress={close} stepTx="stepper.step2" />

          <View>
            <Text style={TITLE} preset="h2" tx="welcomeForm2Screen.title" />
            <Text preset="h4Text" tx="welcomeForm2Screen.subtitle" />
          </View>

          <View style={CODE_CONTENT}>
            <Text
              preset="h4Text"
              tx="welcomeForm2Screen.enterFiveDigits"
              style={CODE_CONTENT_TITLE}
            />
            <View style={CODE_INPUTS_WRAPPER}>
              <CodeField
                ref={ref}
                {...props}
                value={value}
                onChangeText={setValue}
                cellCount={CELL_COUNT}
                keyboardType="number-pad"
                textContentType="oneTimeCode"
                renderCell={({ index, symbol, isFocused }) => (
                  <Text
                    key={index}
                    onLayout={getCellOnLayoutHandler(index)}
                    style={[INPUT, isFocused && INPUT_FOCUSED]}
                  >
                    {symbol || (isFocused ? <Cursor /> : null)}
                  </Text>
                )}
              />
            </View>
            <Text preset="default" tx="welcomeForm2Screen.cantFind" style={CODE_CONTENT_SUBTITLE} />
            <Text
              preset="default"
              tx="welcomeForm2Screen.newEmail"
              style={CODE_CONTENT_LINK}
              onPress={() => navigation.navigate("welcomeForm1")}
            />
          </View>
          <View style={BUTTON_CONTENT}>
            <Button onPress={goBack} preset="cancel" style={BUTTON_BACK}>
              <View style={BUTTON_BACK_CONTENT}>
                <Icon icon="back" />
                <Text preset="h4" tx="common.back" />
              </View>
            </Button>
            <Button
              preset="primary"
              onPress={nextStep}
              style={[BUTTON_NEXT, verify.lockedOut && ({ opacity: 0.8 } as ViewStyle)]}
              disabled={verify.lockedOut}
            >
              <View style={BUTTON_NEXT_CONTENT}>
                {verify.lockedOut ? (
                  <Text
                    preset="h4"
                    text={`${Math.floor(counter / 60)
                      .toString()
                      .padStart(2, "0")}:${(counter % 60).toString().padStart(2, "0")}`}
                    style={BUTTON_NEXT_TEXT}
                  />
                ) : (
                  <Text preset="h4" tx="welcomeForm2Screen.nextStep" style={BUTTON_NEXT_TEXT} />
                )}
                <Icon icon="next" />
              </View>
            </Button>
          </View>
        </Screen>
      </View>
    )
  },
)
