import React, { FC, useState, useEffect, useRef } from "react"
import { View, ViewStyle, TextStyle, Animated } from "react-native"
import { observer } from "mobx-react-lite"
import {
  Screen,
  Header,
  Collapse,
  Text,
  SearchBar,
  SkeletonContainer,
  SkeletonView,
} from "../../components"
import { AcademyStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { useStores } from "../../models"

const glossaryHeader = require("./images/glossary-header.png")

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingTop: spacing[4],
  paddingHorizontal: spacing[4],
}
const FLAT_LIST_CONTAINER: ViewStyle = {
  paddingTop: 85,
}
const SECTION_SEPARATOR: ViewStyle = {
  marginBottom: spacing[2],
}
const SECTION_NAME: TextStyle = {
  marginBottom: spacing[2],
}
const LETTER_SKELETON: ViewStyle = {
  width: "5%",
  height: 25,
  marginBottom: spacing[4],
  borderRadius: 4,
}
const COLLAPSE_SKELETON: ViewStyle = {
  width: "100%",
  height: 56,
  marginBottom: spacing[4],
  borderRadius: 8,
}

export const AcademyGlossaryScreen: FC<AcademyStackScreenProps<"academyGlossary">> = observer(
  ({ navigation }) => {
    const { commonStore } = useStores()

    const goBack = () => navigation.goBack()

    const [glossariesData, setGlossariesData] = useState([])

    const [currentIndex, setCurrentIndex] = useState<number | string>()
    const scrollYValue = useRef(new Animated.Value(0)).current
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(true)
    const [filteredSections, setFilteredSections] = useState(glossariesData)

    const searchFilter = (text: string) => {
      const newData = glossariesData.reduce((r, { title, data }) => {
        const o = data.filter(({ header }) =>
          header.replace(/\s/g, "").toLowerCase().includes(text.replace(/\s/g, "").toLowerCase()),
        )

        if (o && o.length) r.push({ title, data: [...o] })
        return r
      }, [])
      setSearch(text)
      setFilteredSections(newData)
    }

    const clearSearch = () => {
      setSearch("")
      setFilteredSections(glossariesData)
    }

    const clampedScroll = Animated.diffClamp(
      Animated.add(
        scrollYValue.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
          extrapolateLeft: "clamp",
        }),
        new Animated.Value(0),
      ),
      0,
      70,
    )

    useEffect(() => {
      ;(async () => {
        const glossaries = await commonStore.getAcademyGlossaries()
        if (glossaries) {
          setGlossariesData(glossaries)
          setFilteredSections(glossaries)
          setLoading(false)
        }
      })()
    }, [])

    return (
      <View style={FULL}>
        <Screen
          header={
            <Header
              headerTx="academyGlossaryScreen.headerText"
              leftIcon="back"
              textImage={glossaryHeader}
              onLeftPress={goBack}
            />
          }
          style={CONTAINER}
        >
          {loading ? (
            <SkeletonContainer>
              <View>
                {[1, 2].map((index) => (
                  <View key={index}>
                    <SkeletonView style={LETTER_SKELETON} />
                    <SkeletonView style={COLLAPSE_SKELETON} />
                    <SkeletonView style={COLLAPSE_SKELETON} />
                  </View>
                ))}
              </View>
            </SkeletonContainer>
          ) : (
            <>
              <SearchBar
                searchText={search}
                onChangeText={searchFilter}
                clear={clearSearch}
                clampedScroll={clampedScroll}
              />
              <Animated.SectionList
                scrollEventThrottle={1}
                contentContainerStyle={FLAT_LIST_CONTAINER}
                onScroll={Animated.event(
                  [{ nativeEvent: { contentOffset: { y: scrollYValue } } }],
                  {
                    useNativeDriver: true,
                  },
                )}
                sections={filteredSections}
                keyExtractor={(item) => String(item.id)}
                renderSectionHeader={({ section: { title } }) => (
                  <Text preset="h2" text={title} style={SECTION_NAME} />
                )}
                SectionSeparatorComponent={() => <View style={SECTION_SEPARATOR}></View>}
                renderItem={({ item }) => (
                  <Collapse
                    data={item}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                  />
                )}
              />
            </>
          )}
        </Screen>
      </View>
    )
  },
)
