import React, { useEffect, useRef, useState } from "react"
import { Keyboard, Platform, ScrollView } from "react-native"
import { PullUp } from "./pull-up"

export interface PullUpScrollViewProps {
  pullUpIcon: string
  pullUpBackgroundUrl: string
  pullUpIsLastPost: boolean
  loadMore: () => void
  isFeedbackExpanded?: boolean
  children: React.ReactNode
}

export function PullUpScrollView(props: PullUpScrollViewProps) {
  const {
    pullUpIcon,
    pullUpBackgroundUrl,
    pullUpIsLastPost,
    loadMore,
    children,
    isFeedbackExpanded,
  } = props
  const [scrollViewHeight, setScrollViewHeight] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)
  const [bottomHit, setBottomHit] = useState(false)
  const [isKeyboardVisible, setKeyboardVisible] = useState(false)
  const scrollViewRef = useRef(null)

  useEffect(() => {
    if (isFeedbackExpanded) {
      scrollViewRef.current.scrollToEnd({ animated: true })
    }
  }, [isFeedbackExpanded])

  /* useEffect(() => {
    if (isFeedbackExpanded && bottomHit) {
      setBottomHit(false)
    }
  }, [isFeedbackExpanded, bottomHit]) */

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", () => {
      setKeyboardVisible(true)
    })
    const keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", () => {
      setKeyboardVisible(false)
    })

    return () => {
      keyboardDidHideListener.remove()
      keyboardDidShowListener.remove()
    }
  }, [])

  const loadMoreWrapper = () => {
    // if (isFeedbackExpanded) return

    if (!pullUpIsLastPost) {
      setBottomHit(false)
      setIsScrolling(false)
      loadMore()
    }
  }

  const onScrollEndDrag = (nativeEvent) => {
    const { layoutMeasurement, contentOffset, contentSize } = nativeEvent
    if (layoutMeasurement.height + contentOffset.y >= contentSize.height - 2) {
      setBottomHit(true)
    } else {
      setBottomHit(false)
    }
    setIsScrolling(false)
  }

  return (
    <ScrollView
      onLayout={(event) => setScrollViewHeight(event.nativeEvent.layout.height)}
      contentContainerStyle={Platform.OS !== "web" && { minHeight: scrollViewHeight + 50 }}
      onScrollBeginDrag={() => setIsScrolling(true)}
      onScrollEndDrag={(event) => onScrollEndDrag(event.nativeEvent)}
      scrollEventThrottle={16}
      ref={scrollViewRef}
    >
      {children}

      <PullUp
        visible={isScrolling || isKeyboardVisible}
        bottomHit={bottomHit}
        backgroundImg={pullUpBackgroundUrl ? { uri: pullUpBackgroundUrl } : null}
        icon={pullUpIcon}
        onPress={loadMoreWrapper}
        lastPost={pullUpIsLastPost}
      />
    </ScrollView>
  )
}
