import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ExtraOrderStackScreenProps } from "../../navigators"
import { View, ViewStyle } from "react-native"
import { ExtraOrderUserTypeEnum } from "../../models/extra-order-store/extra-order-store"
import { CompatibilityCalculation } from "./components/compatibility-calculation"
import { TxKeyPath } from "../../i18n"

export const ExtraOrderCompatibilityCalculationScreen: FC<
  ExtraOrderStackScreenProps<"extraOrderCompatibilityCalculation">
> = observer(({ navigation }) => {
  const editUserCard = (userType: ExtraOrderUserTypeEnum, title: TxKeyPath) =>
    navigation.navigate("extraOrderEditUser", {
      title,
      userType,
    } as any)

  return (
    <View style={FULL}>
      <CompatibilityCalculation
        goBack={() => navigation.goBack()}
        navigateToEditUser={(userType: ExtraOrderUserTypeEnum, title: TxKeyPath) =>
          editUserCard(userType, title)
        }
      />
    </View>
  )
})

const FULL: ViewStyle = { flex: 1 }
