import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const AddCircleLight = (props: SvgProps) => (
  <Svg width={28} height={28} fill="#00E6DB" viewBox="0 0 28 28" {...props}>
    <Path d="M13 9a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H9a1 1 0 0 1 0-2h4V9Zm15 5c0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0s14 6.268 14 14Zm-2 0c0-6.627-5.373-12-12-12S2 7.373 2 14s5.373 12 12 12 12-5.373 12-12Z" />
  </Svg>
)

export default AddCircleLight
