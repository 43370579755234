import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Checkbox = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="#00E6DB" {...props}>
    <Path d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm3.22 6.97-4.47 4.47-1.97-1.97a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.06 0l5-5a.75.75 0 1 0-1.06-1.06Z" />
  </Svg>
)

export default Checkbox
