import React, { useMemo, useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { color, spacing } from "../../../theme"
import { Text } from "../../text/text"
import { IPillData, Pills } from "../../pills/pills"
import { HtmlFragment, IHtmlFragment } from "./html-fragment"
import { ForewordFragment, IForewordFragment } from "./foreword-fragment"
import { GraphicFragment, IGraphicFragment } from "./graphic-fragment"
import { BlurHtmlFragment, IBlurHtmlFragment } from "./blur-html-fragment"

export type FragmentType =
  | IHtmlFragment
  | ITabsFragment
  | IForewordFragment
  | IGraphicFragment
  | IBlurHtmlFragment

interface ResultContentProps {
  fragment: FragmentType
  onUnlockRequest?: () => void
}

export const FragmentsContent = (props: ResultContentProps) => {
  const { fragment, onUnlockRequest } = props

  return (
    <>
      {(() => {
        switch (fragment.type) {
          case "tab":
            return <TabsFragment content={fragment} onUnlockRequest={onUnlockRequest} />
          case "html":
            return <HtmlFragment content={fragment} />
          case "foreword":
            return <ForewordFragment content={fragment} />
          case "graphic":
            return <GraphicFragment content={fragment} />
          case "blurredHtml":
            return <BlurHtmlFragment content={fragment} onUnlockRequest={onUnlockRequest} />
          default:
            return null
        }
      })()}
    </>
  )
}

// Resolve circular dependency
// tabs-fragment
interface TabsFragmentProps {
  content: ITabsFragment
  onUnlockRequest?: () => void
}

interface ITabsFragment {
  title?: string
  type: "tab"
  content: ITabsFragmentContent[]
}

interface ITabsFragmentContent {
  title: string
  items: any
}

export const TabsFragment = (props: TabsFragmentProps) => {
  const { content, onUnlockRequest } = props

  const [activeTab, setActiveTab] = useState(0)

  const tabsData = useMemo(() => {
    return content.content.map((c, index) => ({
      id: index,
      name: c.title,
    }))
  }, [content.content])

  const onTabPress = (data: IPillData) => {
    setActiveTab(data.id as number)
  }

  return (
    <View>
      <View style={PILLS_CONTAINER}>
        <Pills data={tabsData} activeId={activeTab} onPress={onTabPress} />
      </View>
      <View style={LIST_CONTENT}>
        <View style={TAB_HEADER}>
          <Text preset="h2" style={TAB_HEADER_TITLE} text={content.content[activeTab].title} />
          <View style={TAB_HEADER_LINE} />
        </View>
        {content.content[activeTab].items.map((item, index) => (
          <FragmentsContent key={index} fragment={item} onUnlockRequest={onUnlockRequest} />
        ))}
      </View>
    </View>
  )
}

const PILLS_CONTAINER: any = {
  position: "sticky", // web only
  top: 0,
  backgroundColor: color.background,
  zIndex: 9,
}
const TAB_HEADER: ViewStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginHorizontal: spacing[4],
  marginBottom: spacing[4],
}
const TAB_HEADER_TITLE: TextStyle = {
  textAlign: "center",
  color: color.primary,
  backgroundColor: color.backgroundBlack500,
  paddingHorizontal: spacing[3],
  paddingVertical: spacing[2],
  maxWidth: "70%",
  zIndex: 3,
}
const TAB_HEADER_LINE: ViewStyle = {
  position: "absolute",
  top: "50%",
  left: 0,
  right: 0,
  height: 1,
  backgroundColor: color.primary,
  zIndex: 1,
}
const LIST_CONTENT: ViewStyle = {
  paddingHorizontal: spacing[4],
  backgroundColor: color.backgroundBlack500,
  paddingVertical: spacing[5],
}
