import React, { useCallback, useEffect, useRef } from "react"
import { ViewStyle, TextStyle, TouchableOpacity, FlatList, StyleProp } from "react-native"
import { Text } from "../text/text"
import { color, spacing } from "../../theme"

export interface IPillData {
  id: number | string
  name: string
}

export interface PillsProps {
  data: IPillData[]
  activeId: number | string
  onPress(data: IPillData): void
  style?: StyleProp<ViewStyle>
}

const PILLS_LIST: ViewStyle = {
  flexGrow: 0,
  marginVertical: spacing[1],
  minHeight: 20 + 4 * spacing[2],
}
const PILLS_LIST_CONTAINER: ViewStyle = {
  paddingVertical: spacing[2],
}
const PILL_ITEM: ViewStyle = {
  marginRight: spacing[4],
  paddingHorizontal: spacing[3],
  paddingVertical: spacing[2],
  backgroundColor: color.palette.black500,
  borderRadius: 40,
}
const SELECTED_PILL_ITEM: ViewStyle = {
  ...PILL_ITEM,
  backgroundColor: color.palette.brightTurquoise,
}
const PILL_TITLE: TextStyle = {
  color: color.palette.white,
}
const SELECTED_PILL_TITLE: TextStyle = {
  color: color.palette.black500,
}
const FIRST_PILL_ITEM: ViewStyle = {
  marginLeft: spacing[4],
}
const LAST_PILL_ITEM: ViewStyle = {
  marginRight: spacing[4],
}

export function Pills(props: PillsProps) {
  const { data, activeId, style, onPress } = props

  const containerStyles = [PILLS_LIST, style]

  const flatListRef = useRef<FlatList>(null)

  const scrollToIndex = useCallback(
    (index) => {
      flatListRef.current?.scrollToIndex({ animated: true, index, viewOffset: spacing[4] })
    },
    [flatListRef],
  )

  useEffect(() => {
    const index = data.findIndex((d) => d.id === activeId)
    if (index >= 0) {
      scrollToIndex(index)
    }
  }, [activeId, flatListRef])

  return (
    <FlatList
      style={containerStyles}
      contentContainerStyle={PILLS_LIST_CONTAINER}
      ref={flatListRef}
      onScrollToIndexFailed={() => []}
      data={data}
      keyExtractor={(item) => String(item.id)}
      horizontal
      renderItem={({ item, index }) => (
        <TouchableOpacity
          onPress={() => {
            onPress(item)
          }}
          style={[
            activeId === item.id ? SELECTED_PILL_ITEM : PILL_ITEM,
            index === 0 ? FIRST_PILL_ITEM : {},
            index === data.length ? LAST_PILL_ITEM : {},
          ]}
        >
          <Text
            preset="bold"
            numberOfLines={1}
            style={activeId === item.id ? SELECTED_PILL_TITLE : PILL_TITLE}
          >
            {item.name}
          </Text>
        </TouchableOpacity>
      )}
    />
  )
}
