import React from "react"
import { View, ViewStyle, Modal, Pressable, TextStyle, Platform } from "react-native"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { useWindowWidth } from "../../utils/dimension"
import { Button } from "../button/button"
import { TxKeyPath } from "../../i18n"

interface ConfirmationModalProps {
  titleTx: TxKeyPath
  subTitleTx?: TxKeyPath
  visible: boolean
  onResult(result: boolean): void
  children?: React.ReactNode
}

const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}
const MODAL_CENTER: ViewStyle = {
  justifyContent: "center",
  alignItems: Platform.OS === "web" ? "center" : "stretch",
  height: "90%",
}
const CONTAINER: ViewStyle = {
  flexGrow: 0,
  width: "100%",
  maxHeight: "90%",
  paddingHorizontal: spacing[4],
  backgroundColor: color.palette.black500,
  borderRadius: 16,
  marginHorizontal: spacing[4],
}
const TITLE: TextStyle = {
  textAlign: "center",
  paddingVertical: spacing[4],
}
const MESSAGE: TextStyle = {
  textAlign: "center",
  paddingBottom: spacing[4],
}
const TWO_IN_ROW: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  marginVertical: spacing[4],
}
const TWO_IN_ROW_FIRST: ViewStyle = {
  width: "50%",
  paddingRight: spacing[3],
}
const TWO_IN_ROW_SECOND: ViewStyle = {
  width: "50%",
  paddingLeft: spacing[3],
}

export function ConfirmationModal(props: ConfirmationModalProps) {
  const { titleTx, subTitleTx, visible, onResult, children } = props
  const screenWidth = useWindowWidth()

  return (
    <>
      <Modal
        visible={visible}
        onRequestClose={() => onResult(false)}
        animationType="fade"
        transparent={true}
      >
        <View style={MODAL_CENTER}>
          <Pressable style={OVERLAY} onPress={() => onResult(false)} />
          <View style={[CONTAINER, { maxWidth: screenWidth }]}>
            <Text preset="h2" tx={titleTx} style={TITLE} />
            {subTitleTx ? <Text preset="default" tx={subTitleTx} style={MESSAGE} /> : null}
            {children}
            <View style={TWO_IN_ROW}>
              <View style={TWO_IN_ROW_FIRST}>
                <Button preset="cancel" tx="common.no" onPress={() => onResult(false)} />
              </View>
              <View style={TWO_IN_ROW_SECOND}>
                <Button preset="primary" tx="common.yes" onPress={() => onResult(true)} />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </>
  )
}
