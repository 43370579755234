import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const TapSingle = (props: SvgProps) => (
  <Svg width={20} height={26} fill="#00E6DB" viewBox="0 0 20 26" {...props}>
    <Path d="M9 2a6 6 0 0 0-4.88 9.491 5.5 5.5 0 0 0-2.123.38 8 8 0 1 1 14.584-1.312 5.601 5.601 0 0 0-.533-.13l-1.444-.28A5.999 5.999 0 0 0 9 2Zm-.998 6a1 1 0 0 1 2 0v4a1 1 0 0 0 .81.982l4.57.884a2.001 2.001 0 0 1 1.548 2.494l-1.52 5.525a.999.999 0 0 1-.882.732l-4.76.387s-.006-.002-.014-.008a.166.166 0 0 1-.057-.078c-.782-2.153-2.68-4.735-7.591-6.555a2 2 0 0 1 2.672-1.213l1.837.772A1 1 0 0 0 8.002 15V8Zm1-3a3 3 0 0 0-3 3v5.495l-.449-.189a3.999 3.999 0 0 0-5.41 2.642l-.09.332a1.51 1.51 0 0 0 .963 1.817c4.74 1.653 6.227 3.924 6.801 5.503.3.824 1.116 1.48 2.113 1.398l4.76-.388a3 3 0 0 0 2.649-2.194l1.52-5.526a4 4 0 0 0-3.097-4.988l-3.76-.727V8a3 3 0 0 0-3-3Z" />
  </Svg>
)

export default TapSingle
