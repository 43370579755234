import React, { useState } from "react"
import { TextStyle, View, ViewStyle, TouchableOpacity, Modal, Pressable } from "react-native"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { TxKeyPath } from "../../i18n"

export interface SelectDataProps {
  text: TxKeyPath
  code: string
}
interface TimeFormatSelectProps {
  selectedCode: string
  setSelectedCode(item: string): void
}

const SELECT: TextStyle = {
  color: color.palette.lightSteelBlue,
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[2],
  backgroundColor: color.palette.black500,
  borderWidth: 2,
  borderRadius: 8,
  borderColor: color.borderColor,
  flexDirection: "row",
  justifyContent: "space-between",
  minHeight: 60,
}
const SELECT_BTN: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const SELECT_BTN_TEXT: TextStyle = {
  fontSize: 16,
}
const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
}
const MODAL_WRAPPER: ViewStyle = {
  position: "absolute",
  top: "40%",
  left: 0,
  right: 0,
  justifyContent: "center",
  alignItems: "center",
}
const CONTAINER: ViewStyle = {
  width: "100%",
  maxWidth: 120,
  borderRadius: 8,
  alignItems: "center",
  paddingVertical: spacing[2],
  backgroundColor: color.palette.black500,
}
const ITEM: ViewStyle = {
  marginVertical: spacing[2],
}
const ITEM_TEXT: TextStyle = {}
const ITEM_TEXT_ACTIVE: TextStyle = {
  color: color.palette.brightTurquoise,
}

export function SelectTimeFormat(props: TimeFormatSelectProps) {
  const { selectedCode, setSelectedCode } = props

  const TIME_FORMATS = ["AM", "PM"]

  const [modalOpen, setModalOpen] = useState(false)

  const selectItems = (code: string) => {
    setSelectedCode(code)
    setModalOpen(false)
  }

  return (
    <>
      <TouchableOpacity activeOpacity={100} onPress={() => setModalOpen(true)} style={SELECT}>
        <View style={SELECT_BTN}>
          <Text text={selectedCode} style={SELECT_BTN_TEXT} />
          <Icon icon="arrowDown" svgProps={{ fill: color.palette.moderateBlue }} />
        </View>
      </TouchableOpacity>
      <Modal
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        animationType="fade"
        transparent={true}
      >
        <Pressable style={OVERLAY} onPress={() => setModalOpen(false)} />
        <View style={MODAL_WRAPPER}>
          <View style={CONTAINER}>
            {TIME_FORMATS.map((item) => (
              <View key={item} style={ITEM}>
                <TouchableOpacity onPress={() => selectItems(item)}>
                  <Text
                    preset="h4"
                    text={item}
                    style={selectedCode === item ? ITEM_TEXT_ACTIVE : ITEM_TEXT}
                  />
                </TouchableOpacity>
              </View>
            ))}
          </View>
        </View>
      </Modal>
    </>
  )
}
