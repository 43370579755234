import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Share = (props: SvgProps) => (
  <Svg width={40} height={40} fill="#171C26" viewBox="0 0 40 40" {...props}>
    <Path d="M14.747 12h3.464a.75.75 0 0 1 .102 1.493l-.102.008h-3.464a2.25 2.25 0 0 0-2.245 2.096l-.005.154v9.5a2.25 2.25 0 0 0 2.096 2.244l.154.005h9.5a2.25 2.25 0 0 0 2.245-2.096l.005-.154v-.497a.75.75 0 0 1 1.494-.102l.006.102v.497a3.75 3.75 0 0 1-3.55 3.745l-.2.005h-9.5a3.75 3.75 0 0 1-3.745-3.55l-.005-.2v-9.5a3.75 3.75 0 0 1 3.55-3.745l.2-.005h3.464-3.464Zm7.753 2.544v-2.793a.75.75 0 0 1 1.187-.61l.082.069 5.994 5.75c.28.267.306.7.077.996l-.077.085-5.994 5.753a.752.752 0 0 1-1.262-.434l-.007-.107V20.45l-.321-.006c-2.658-.009-4.93 1.082-6.865 3.3-.496.569-1.425.133-1.306-.611.827-5.14 3.6-8.046 8.19-8.56l.302-.03v-2.793 2.793Z" />
  </Svg>
)

export default Share
