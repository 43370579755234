import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { FormulaResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { ApiRoutes } from "./api-config"

export class FormulaApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getMeaningOfName(index: string): Promise<FormulaResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.FORMULA_GET_MEANING_OF_NAME(index),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const info = response.data.data

      return { kind: "ok", info }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getMeaningOfBirthdate(index: string): Promise<FormulaResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.FORMULA_GET_MEANING_OF_BIRTHDATE(index),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const info = response.data.data

      return { kind: "ok", info }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getCountryOfPastLife(index: string): Promise<FormulaResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.FORMULA_GET_COUNTRY_OF_PAST_LIFE(index),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const info = response.data.data

      return { kind: "ok", info }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getProfessionByBirthdate(index: string): Promise<FormulaResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.FORMULA_GET_PROFESSION_BY_BIRTHDATE(index),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const info = response.data.data

      return { kind: "ok", info }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getHouseNumberMeaning(index: string): Promise<FormulaResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.FORMULA_GET_HOUSE_NUMBER_MEANING(index),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const info = response.data.data

      return { kind: "ok", info }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
