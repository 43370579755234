import React from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { RenderHtmlWrapper, Text } from "../../../../components"
import { spacing } from "../../../../theme"

interface ResultContentHtmlProps {
  title: string
  content: string
}

export const ResultContentHtml = observer((props: ResultContentHtmlProps) => {
  const { title, content } = props

  return (
    <View style={WRAPPER}>
      <Text preset="h3" text={title} />
      <RenderHtmlWrapper html={content} />
    </View>
  )
})

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
