import React, { FC } from "react"
import { View, ViewStyle, ImageStyle, TextStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Header, AutoImage, Button, Text, ScreenTitle } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"

const gift = require("./images/gift.png")

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[4],
  flex: 1,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}
const WRAPPER: ViewStyle = {
  justifyContent: "center",
  flex: 1,
}
const IMAGE_ROW: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: spacing[3],
  marginBottom: spacing[6],
}
const IMAGE_WRAPPER: ViewStyle = {
  width: 93,
  height: 86,
}
const IMAGE: ImageStyle = {
  width: "100%",
  height: "100%",
}
const TITLE_ROW: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  marginBottom: spacing[6],
  paddingHorizontal: spacing[2],
}
const TEXT_ROW: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  marginBottom: spacing[6],
  paddingHorizontal: spacing[2],
}
const TEXT: TextStyle = {
  textAlign: "center",
}

export const ProfilePromoCodeSuccessScreen: FC<ProfileStackScreenProps<"profilePromoCodeSuccess">> =
  observer(({ navigation }) => {
    const goBack = () => navigation.goBack()

    return (
      <View style={FULL}>
        <Header leftIcon="back" onLeftPress={goBack} />
        <Screen style={CONTAINER} preset="scroll">
          <ScreenTitle headerTx="profilePromoCodeSuccessScreen.title" style={TITLE} />
          <View style={WRAPPER}>
            <View style={IMAGE_ROW}>
              <View style={IMAGE_WRAPPER}>
                <AutoImage source={gift} resizeMode="cover" style={IMAGE} />
              </View>
            </View>

            <View style={TITLE_ROW}>
              <Text preset="h2" tx="profilePromoCodeSuccessScreen.congrats" />
            </View>

            <View style={TEXT_ROW}>
              <Text preset="default" tx="profilePromoCodeSuccessScreen.text" style={TEXT} />
            </View>

            <Button
              tx="profilePromoCodeSuccessScreen.goToMySubscription"
              onPress={() => navigation.navigate("profileSubscriptionInfo")}
            />
          </View>
        </Screen>
      </View>
    )
  })
