import React, { FC, useState } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Button, ScreenTitle, Header, FormRow, PlaceOfBirth } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { useStores } from "../../models"
import { IPlaceOfBirth } from "../../models/profile-store/place-of-birth-model"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
  flex: 1,
}
const CONTENT: ViewStyle = {
  flexGrow: 1,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}

export const ProfileEditPlaceOfBirthScreen: FC<ProfileStackScreenProps<"profileEditPlaceOfBirth">> =
  observer(({ navigation }) => {
    const { profileStore, alertStore } = useStores()

    const [placeOfBirth, setPlaceOfBirth] = useState<IPlaceOfBirth>(profileStore.placeOfBirth)

    const goBack = () => navigation.goBack()

    const saveChanges = async () => {
      const result = await profileStore.updatePlaceOfBirth(placeOfBirth)
      if (result?.customer) {
        navigation.navigate("profileEdit")
      } else {
        alertStore.showError(result.message)
      }
    }

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileEditPlaceScreen.title" style={TITLE} />

          <View style={CONTENT}>
            <FormRow preset="middle">
              <PlaceOfBirth
                placeholderTx="profileEditPlaceScreen.place"
                placeOfBirth={placeOfBirth}
                setPlaceOfBirth={setPlaceOfBirth}
              />
            </FormRow>
          </View>

          <Button tx="profileEditPlaceScreen.save" onPress={saveChanges} />
        </Screen>
      </View>
    )
  })
