import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Time = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="#3C4F6D" {...props}>
    <Path d="M13.25 11.5h-4a.75.75 0 0 1-.75-.75v-6a.75.75 0 0 1 1.5 0V10h3.25a.75.75 0 1 1 0 1.5ZM10 0C4.478 0 0 4.478 0 10s4.478 10 10 10 10-4.478 10-10S15.522 0 10 0Z" />
  </Svg>
)

export default Time
