import React, { FC } from "react"
import {
  ImageSourcePropType,
  View,
  ViewStyle,
  TouchableOpacity,
  ImageStyle,
  TextStyle,
} from "react-native"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import { LinearGradient } from "expo-linear-gradient"
import { TxKeyPath, translate } from "../../i18n"
import { Screen, ScreenTitle, Text, AutoImage } from "../../components"
import { spacing } from "../../theme"
import { HomeStackScreenProps, TabsParamList } from "../../navigators"
import { useStores } from "../../models"

const formulas = require("./images/formulas.png")
const intro = require("./images/intro.png")
const codeOfSamsara = require("./images/code-of-samsara.png")
const soulMate = require("./images/soulmate.png")
const extra = require("./images/extra.png")
const demo = require("./images/demo.png")

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const LIST: ViewStyle = {
  marginBottom: spacing[4],
}
const LIST_BG: ViewStyle = {
  alignItems: "center",
  flexDirection: "row",
  paddingHorizontal: spacing[5],
  paddingVertical: spacing[4],
  borderRadius: 16,
}
const LIST_WRAPPER: ViewStyle = {
  position: "relative",
  marginBottom: 16,
}
const LIST_IMAGE_WRAPPER: ViewStyle = {
  width: 42,
  height: 42,
  marginRight: 24,
  marginTop: 15,
  borderRadius: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
const LIST_IMAGE: ImageStyle = {
  width: "100%",
  height: "100%",
}
const LIST_CONTENT: ViewStyle = {
  flex: 1,
}
const LIST_SUB_HEADING: TextStyle = {
  marginTop: 5,
}
const LIST_LOCK: TextStyle = {
  marginRight: spacing[5],
}
const LIST_LOCK_OVERLAY: ViewStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: 16,
}

function HomeItem({
  isLocked,
  onPress,
  rgba,
  icon,
  titleTx,
  textTx,
}: {
  isLocked: boolean
  onPress: () => void
  rgba: [number, number, number]
  icon: ImageSourcePropType
  titleTx: TxKeyPath
  textTx: TxKeyPath
}) {
  const gradientColor1 = `rgba(${rgba.join(",")}, 0.35)`
  const gradientColor2 = `rgba(${rgba.join(",")}, 0)`

  return (
    <TouchableOpacity activeOpacity={isLocked ? 0.6 : 0.2} style={LIST_WRAPPER} onPress={onPress}>
      <LinearGradient
        start={[-0.1, -0.2]}
        colors={[gradientColor1, gradientColor2]}
        style={LIST_BG}
      >
        <View style={LIST_IMAGE_WRAPPER}>
          <AutoImage source={icon} style={LIST_IMAGE} />
        </View>
        <View style={[LIST_CONTENT, { paddingRight: spacing[5] }]}>
          <Text preset="h4" tx={titleTx} numberOfLines={1} />
          <Text preset="default" style={LIST_SUB_HEADING} tx={textTx} numberOfLines={2} />
        </View>
      </LinearGradient>
      {isLocked && (
        <View style={LIST_LOCK_OVERLAY}>
          <Text preset="h3" style={LIST_LOCK} text={"🔒"} />
        </View>
      )}
    </TouchableOpacity>
  )
}

export const HomeScreen: FC<HomeStackScreenProps<"homeIndex">> = observer(({ navigation }) => {
  const { meStore, profileStore } = useStores()
  const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()
  const fromScreen = "home"

  const canNavigateNavigate = () => {
    if (meStore.isGuest) {
      meStore.setAuthModalVisible(true)
      return false
    }
    return true
  }

  return (
    <View style={FULL}>
      <Screen style={CONTAINER} preset="scroll">
        <ScreenTitle
          headerTx="homeScreen.title"
          subHeaderText={`${translate("homeScreen.subtitleText")} ${
            profileStore.firstName
          } ${translate("homeScreen.subtitleIcon")}`}
          goToSubscription={() =>
            rootNavigation.navigate("profile", {
              screen: "profileSubscriptionInfo",
              params: { fromScreen },
            })
          }
          goToNotifications={() =>
            rootNavigation.navigate("academy", {
              screen: "academyNotifications",
              params: { fromScreen },
            })
          }
          main={!meStore.isGuest}
        />
        <View style={LIST}>
          <HomeItem
            isLocked={false}
            rgba={[246, 72, 67]}
            icon={formulas}
            titleTx={"homeScreen.formulasHeading"}
            textTx={"homeScreen.formulasDescription"}
            onPress={() => navigation.navigate("freeFormulasScreen")}
          />
          <HomeItem
            isLocked={meStore.isGuest}
            rgba={[244, 217, 82]}
            icon={intro}
            titleTx={"homeScreen.introHeading"}
            textTx={"homeScreen.introDescription"}
            onPress={() => (canNavigateNavigate() ? navigation.navigate("homeIntro") : null)}
          />
          <HomeItem
            isLocked={meStore.isGuest}
            rgba={[239, 111, 245]}
            icon={codeOfSamsara}
            titleTx={"homeScreen.codeOfSamsaraHeading"}
            textTx={"homeScreen.codeOfSamsaraDescription"}
            onPress={() => (canNavigateNavigate() ? navigation.navigate("myCodeOfSamsara") : null)}
          />
          {profileStore.isNotSubscribed && (
            <HomeItem
              isLocked={meStore.isGuest}
              rgba={[78, 219, 219]}
              icon={demo}
              titleTx={"homeScreen.demoReportHeading"}
              textTx={"homeScreen.demoReportDescription"}
              onPress={() =>
                canNavigateNavigate()
                  ? rootNavigation.navigate("home", {
                      screen: "extraOrderStack",
                      params: {
                        screen: "extraOrderCompatibilityCode",
                        params: { orderId: "demo" },
                      },
                    })
                  : null
              }
            />
          )}
          <HomeItem
            isLocked={meStore.isGuest}
            rgba={[223, 220, 220]}
            icon={soulMate}
            titleTx={"homeScreen.soulMateHeading"}
            textTx={"homeScreen.soulMateDescription"}
            onPress={() => (canNavigateNavigate() ? rootNavigation.navigate("soulMate") : null)}
          />
          <HomeItem
            isLocked={meStore.isGuest}
            rgba={[219, 151, 78]}
            icon={extra}
            titleTx={"homeScreen.extraOrderHeading"}
            textTx={"homeScreen.extraOrderDescription"}
            onPress={() => (canNavigateNavigate() ? navigation.navigate("extraOrderStack") : null)}
          />
          {!profileStore.isNotSubscribed && (
            <HomeItem
              isLocked={meStore.isGuest}
              rgba={[78, 219, 219]}
              icon={demo}
              titleTx={"homeScreen.demoReportHeading"}
              textTx={"homeScreen.demoReportDescription"}
              onPress={() =>
                canNavigateNavigate()
                  ? rootNavigation.navigate("home", {
                      screen: "extraOrderStack",
                      params: {
                        screen: "extraOrderCompatibilityCode",
                        params: { orderId: "demo" },
                      },
                    })
                  : null
              }
            />
          )}
        </View>
      </Screen>
    </View>
  )
})
