import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const AddCircle = (props: SvgProps) => (
  <Svg width={20} height={20} fill="#091023" viewBox="0 0 20 20" {...props}>
    <Path d="M9 9V5h2v4h4v2h-4v4H9v-4H5V9h4Zm1 11C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 10 18Z" />
  </Svg>
)

export default AddCircle
