import React, { useEffect, useRef } from "react"
import {
  Animated,
  Modal,
  Pressable,
  View,
  ViewStyle,
  ScrollView,
  TextStyle,
  ImageStyle,
} from "react-native"
import { observer } from "mobx-react-lite"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { useStores } from "../../models"
import { color, spacing } from "../../theme"
import { useWindowWidth } from "../../utils/dimension"
import { WelcomeStackParamList } from "../../navigators/app-navigation-routes"
import { Button } from "../button/button"
import { Icon } from "../icon/icon"
import { Text } from "../text/text"
import { AutoImage } from "../auto-image/auto-image"
import { OAuthButtons } from "../oauth-buttons/oauth-buttons"

const letter = require("../../screens/welcome/images/letter.png")

export interface AuthModalProps {
  visible: boolean
  closeModal?(): void
}

const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const BG_OVERLAY: ViewStyle = {
  ...OVERLAY,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}
const CONTAINER: ViewStyle = {
  position: "absolute",
  right: 0,
  left: 0,
  bottom: 0,
  paddingTop: spacing[4],
  paddingBottom: spacing[5],
  paddingHorizontal: spacing[4],
  backgroundColor: color.background,
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  maxHeight: "100%",
  marginHorizontal: "auto",
}
const HEADER: ViewStyle = {
  flexDirection: "row",
  marginBottom: spacing[1],
  alignItems: "center",
  justifyContent: "flex-end",
}
const TITLE: TextStyle = {
  flex: 1,
  marginBottom: spacing[4],
  textAlign: "center",
}
const CLOSE_BUTTON: ViewStyle = { width: 18, height: 18 }
const PRIVACY_TEXT: TextStyle = {
  textAlign: "center",
  marginTop: spacing[4],
}
const EMAIL_BUTTON: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginBottom: spacing[4],
}
const EMAIL_BUTTON_IMAGE: ImageStyle = {
  marginRight: 11,
}

export const AuthModal = observer((props: AuthModalProps) => {
  const { meStore } = useStores()
  const screenWidth = useWindowWidth()
  const { visible, closeModal } = props

  const welcomeNavigation = useNavigation<NativeStackNavigationProp<WelcomeStackParamList>>()

  const goToRegistration = async () => {
    await meStore.resetAuth()
    tryRedirect()
  }

  const tryRedirect = () => {
    if (welcomeNavigation.getState().routeNames.includes("welcomeForm1")) {
      welcomeNavigation.navigate("welcomeForm1")
    } else {
      setTimeout(() => tryRedirect(), 20)
    }
  }

  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }

  const fadeOut = () => {
    fadeAnim.setValue(0)
  }

  useEffect(() => {
    if (visible) {
      fadeIn()
    } else {
      fadeOut()
    }
  }, [visible])

  return (
    <>
      {visible && (
        <Animated.View
          style={[
            BG_OVERLAY,
            {
              opacity: fadeAnim,
            },
          ]}
        />
      )}
      <Modal animationType="slide" transparent={true} visible={visible} onRequestClose={closeModal}>
        <Pressable style={OVERLAY} onPress={closeModal} />
        <View style={[CONTAINER, { maxWidth: screenWidth }]}>
          <View style={HEADER}>
            <Button preset="unstyled" onPress={closeModal} style={CLOSE_BUTTON}>
              <Icon icon="closeCircle" svgProps={{ fill: color.palette.white }} />
            </Button>
          </View>
          <Text preset="h2" tx="authModal.title" style={TITLE} />
          <ScrollView>
            <Button style={EMAIL_BUTTON} preset="outline" onPress={goToRegistration}>
              <AutoImage style={EMAIL_BUTTON_IMAGE} source={letter} />
              <Text preset="h4" tx="welcomeScreen.continueWithEmail" />
            </Button>
            <OAuthButtons />
            <Text style={PRIVACY_TEXT} preset="default" tx="authModal.privacy" />
          </ScrollView>
        </View>
      </Modal>
    </>
  )
})
