import React, { useState, useRef, useEffect, useCallback } from "react"
import { ViewStyle, TouchableOpacity, ImageStyle, Animated, StyleProp, View } from "react-native"
import { ResizeMode, Video as ExpoVideo } from "expo-av"
import { Icon } from "../icon/icon"
import { AutoImage } from "../auto-image/auto-image"

export interface VideoProps {
  src: string
  previewSrc?: string
  playAllowed?: boolean
  onPlay?(): void
  wrapperStyle?: StyleProp<ViewStyle>
  videoStyle?: StyleProp<ViewStyle>
}

const VIDEO_WRAPPER: ViewStyle = {
  position: "relative",
  borderRadius: 24,
  overflow: "hidden",
}
const VIDEO: ViewStyle = {
  height: 300,
}
const VIDEO_OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const PLAY_BUTTON: ViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginLeft: -32,
  marginTop: -32,
  zIndex: 2,
}
const PREVIEW_IMAGE: ImageStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
}

export function Video(props: VideoProps) {
  const { src, previewSrc, playAllowed = true, onPlay, wrapperStyle, videoStyle } = props

  const fadeAnim = useRef(new Animated.Value(1)).current
  const video = useRef(null)
  const [status, setStatus] = useState<any>()
  const [isPlaying, setIsPlaying] = useState(false)

  const playVideo = useCallback(() => {
    if (status?.isPlaying) {
      video.current.pauseAsync()
      setIsPlaying(false)
    } else {
      video.current.playAsync()
      setIsPlaying(true)
      onPlay?.()
    }
  }, [video, status, setIsPlaying, playAllowed, onPlay])

  useEffect(() => {
    if (!playAllowed) {
      if (status?.isPlaying) {
        video.current.pauseAsync()
        setIsPlaying(false)
      }
    }
  }, [status, video, setIsPlaying, playAllowed])

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start()
  }

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start()
  }

  useEffect(() => {
    if (isPlaying) {
      fadeOut()
    } else {
      fadeIn()
    }
  }, [isPlaying])

  return (
    <TouchableOpacity activeOpacity={1} onPress={playVideo} style={[VIDEO_WRAPPER, wrapperStyle]}>
      <ExpoVideo
        ref={(ref) => {
          video.current = ref
        }}
        source={{ uri: src }}
        resizeMode={ResizeMode.COVER}
        isLooping
        onPlaybackStatusUpdate={(status) => setStatus(() => status)}
        style={[VIDEO, videoStyle]}
      />
      <Animated.View
        style={[
          VIDEO_OVERLAY,
          {
            opacity: fadeAnim,
          },
        ]}
      >
        {previewSrc && <AutoImage style={PREVIEW_IMAGE} source={{ uri: previewSrc }} />}
        <View style={PLAY_BUTTON}>
          <Icon icon="play" />
        </View>
      </Animated.View>
    </TouchableOpacity>
  )
}
