import React from "react"
import { observer } from "mobx-react-lite"
import { TextStyle, View, ViewStyle } from "react-native"
import { Text } from "../../../../components"
import { color, spacing } from "../../../../theme"

interface IRatingProp {
  title: string
  percentage: string
}

interface ResultContentRatingProps {
  title: string
  content: IRatingProp
}

export const ResultContentRating = observer((props: ResultContentRatingProps) => {
  const { title, content } = props

  return (
    <View style={WRAPPER}>
      <Text preset="h3" text={title} />
      <View style={RATING_WRAPPER}>
        <Text preset="h4Text" text={content.title} style={RATING_TITLE} />
        <Text preset="h2" text={content.percentage} />
      </View>
    </View>
  )
})

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const RATING_WRAPPER: ViewStyle = {
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  paddingHorizontal: 63,
  paddingVertical: spacing[4],
  borderRadius: 8,
}
const RATING_TITLE: TextStyle = {
  marginBottom: spacing[1],
  color: color.palette.lightSteelBlue,
}
