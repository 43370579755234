import { Instance, SnapshotOut, types, flow, SnapshotIn } from "mobx-state-tree"
import { withEnvironment } from "../extensions/with-environment"
import { CustomerApi } from "./../../services/api/customer-api"
import { parseAndFormatDateUtc } from "../../utils/date"
import { IPlaceOfBirth, PlaceOfBirthModel } from "./place-of-birth-model"

export enum SubscriptionDuration {
  None = "None",
  Trial = "Trial",
  Monthly = "Monthly",
  Annual = "Annual",
}

export const ProfileStoreModel = types
  .model("ProfileStore")
  .props({
    id: types.optional(types.string, ""),
    firstName: types.optional(types.string, ""),
    lastName: types.optional(types.string, ""),
    birthFirstName: types.optional(types.string, ""),
    birthLastName: types.optional(types.string, ""),
    gender: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    dateOfBirth: types.optional(types.string, ""),
    timeOfBirth: types.optional(types.string, ""),
    placeOfBirth: types.maybeNull(PlaceOfBirthModel),
    preferredLocale: types.optional(types.string, ""),
    subscriptionDuration: types.optional(
      types.enumeration<SubscriptionDuration>(Object.values(SubscriptionDuration)),
      SubscriptionDuration.None,
    ),
    isConfirmed: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get isNotSubscribed() {
      return self.subscriptionDuration === SubscriptionDuration.None
    },
  }))
  .actions((self) => ({
    updateName: (firstName: string, lastName: string) => {
      self.firstName = firstName
      self.lastName = lastName
    },
    updateBirthName: (firstName: string, lastName: string) => {
      self.birthFirstName = firstName
      self.birthLastName = lastName
    },
    updateGender: (gender: string) => {
      self.gender = gender
    },
    updateDateAndTime: (dateOfBirth: string, timeOfBirth: string) => {
      self.dateOfBirth = dateOfBirth
      self.timeOfBirth = timeOfBirth
    },
    setPlaceOfBirth: (placeOfBirth: IPlaceOfBirth) => {
      self.placeOfBirth = placeOfBirth?.name
        ? {
            geonameId: placeOfBirth?.geonameId,
            toponymName: placeOfBirth?.toponymName,
            name: placeOfBirth?.name,
            displayName: placeOfBirth?.displayName,
          }
        : null
    },
    updatePreferredLocale: (preferredLocale: string) => {
      self.preferredLocale = preferredLocale
    },
  }))
  .actions((self) => ({
    clearProfile: () => {
      self.updateName("", "")
      self.updateBirthName("", "")
      self.updateDateAndTime("", "")
      self.placeOfBirth = null

      self.email = ""
      self.subscriptionDuration = SubscriptionDuration.None
    },
    getCustomer: flow(function* () {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.getCurrentCustomer()

      if (result.kind === "ok") {
        const data = result.customer.data
        const name = data.name
        const birthName = data.birthName

        self.id = data.id
        self.firstName = name.firstName
        self.lastName = name.lastName
        self.birthFirstName = birthName.firstName
        self.birthLastName = birthName.lastName
        self.gender = data.gender || ""
        self.email = data.email
        self.dateOfBirth = parseAndFormatDateUtc(data.dateOfBirth, "yyyy-MM-dd")
        self.timeOfBirth = data.timeOfBirth
        self.setPlaceOfBirth(data.placeOfBirth)
        self.preferredLocale = data.preferredLocale
        self.subscriptionDuration = data?.subscription?.duration
        self.isConfirmed = data.isConfirmed
        return true
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))
  .actions((self) => ({
    getSubscriptions: flow(function* () {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.getSubscriptions()

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getMySubscriptionsList: flow(function* () {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.getMySubscriptionsList()

      if (result.kind !== "ok") {
        __DEV__ && console.tron.log(result.kind)
      }

      return result
    }),
    unsubscribeStripe: flow(function* (subscriptionId: string) {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.unsubscribeStripe(subscriptionId)

      if (result.kind === "ok") {
        self.getCustomer()
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return result
      }
    }),
    resumeStripe: flow(function* (subscriptionId: string) {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.resumeStripe(subscriptionId)

      if (result.kind === "ok") {
        self.getCustomer()
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return result
      }
    }),
    updatePaymentMethod: flow(function* () {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.updatePaymentMethod()

      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return result
      }
    }),
    updateCustomerName: flow(function* (firstName: string, lastName: string) {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.updateCustomerName(self.id, firstName, lastName)
      if (result.kind === "ok") {
        self.getCustomer()
        return true
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    updateCustomerBirthName: flow(function* (birthFirstName: string, birthLastName: string) {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.updateCustomerBirthName(
        self.id,
        birthFirstName,
        birthLastName,
      )

      if (result.kind === "ok") {
        self.getCustomer()
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return result
      }
    }),
    updateCustomerGender: flow(function* (gender: string) {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.updateCustomerGender(self.id, gender)

      if (result.kind === "ok") {
        self.getCustomer()
        return true
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    updateCustomerDateAndTime: flow(function* (dateOfBirth: string, timeOfBirth: string) {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.updateCustomerDateAndTime(self.id, dateOfBirth, timeOfBirth)

      if (result?.kind === "ok") {
        self.getCustomer()
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return result
      }
    }),
    updatePlaceOfBirth: flow(function* (placeOfBirth: IPlaceOfBirth) {
      if (!placeOfBirth?.name) {
        placeOfBirth = { name: "" }
      }
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.updatePlaceOfBirth(self.id, placeOfBirth)

      if (result?.kind === "ok") {
        self.getCustomer()
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return result
      }
    }),
    updateCustomerPrefferedLocale: flow(function* (locale: string) {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.updateCustomerLocale(self.id, locale)

      if (result.kind === "ok") {
        self.getCustomer()
        return true
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))
  .actions((self) => ({
    sendSupport: flow(function* (message: string) {
      const customerApi = new CustomerApi(self.environment.api)
      const result = yield customerApi.sendSupport(message)

      if (result.kind === "ok") {
        return true
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))

export interface ProfileStore extends Instance<typeof ProfileStoreModel> {}
export interface ProfileStoreSnapshotOut extends SnapshotOut<typeof ProfileStoreModel> {}
export interface ProfileStoreSnapshotIn extends SnapshotIn<typeof ProfileStoreModel> {}
export const createProfileStoreDefaultModel = () => types.optional(ProfileStoreModel, {})
