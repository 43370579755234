import React, { useState } from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, Platform } from "react-native"
import DateTimePickerModal from "react-native-modal-datetime-picker"
import { TextInputMask } from "react-native-masked-text"
import { format, isAfter, isBefore, isMatch, isValid, parseISO } from "date-fns"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { color, spacing } from "../../theme"
import { translate, TxKeyPath } from "../../i18n"
import { texts } from "../../styles"

export interface DatePickerProps {
  placeholderTx: TxKeyPath
  value: string
  setValue(text: string): void
}

const INPUT_WRAPPER: ViewStyle = {
  position: "relative",
  marginBottom: spacing[5],
}
const INPUT_ICON: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 20,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
}
const INPUT: TextStyle = {
  ...texts.H4_TEXT,
  paddingLeft: 55,
  paddingVertical: spacing[2],
  backgroundColor: color.palette.black500,
  borderWidth: 2,
  borderRadius: 8,
  borderColor: color.borderColor,
  minHeight: 60,
}
const INPUT_WITH_PLACEHOLDER: TextStyle = {
  paddingTop: spacing[3],
  paddingBottom: 0,
}
const FIELD_LABEL: TextStyle = {
  position: "absolute",
  top: 10,
  left: 55,
  zIndex: 1,
}

// Todo support web
export function DatePicker(props: DatePickerProps) {
  const { placeholderTx, value, setValue } = props
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false)
  const [focused, setFocused] = useState(false)

  const minimumDate = new Date(1901, 0, 1)
  const maximumDate = new Date()

  const DATE_FORMAT = "yyyy-MM-dd"

  const showDatePicker = () => {
    setDatePickerVisibility(true)
  }

  const hideDatePicker = () => {
    setDatePickerVisibility(false)
  }
  const handleDateConfirm = (date: Date) => {
    setValue(format(date, DATE_FORMAT))
    hideDatePicker()
  }
  const onFocusChange = (isFocused: boolean) => {
    setFocused(isFocused)
    if (!isMatch(value, DATE_FORMAT)) {
      setValue("")
      return
    }

    let date = parseISO(value)
    if (!isValid(date)) {
      const tempSplit = value.split("-")
      if (tempSplit.length === 3) {
        date = parseISO(tempSplit[0] + "-" + tempSplit[1] + "-" + tempSplit[2].padStart(2, "0"))
      }

      if (!isValid(date)) {
        setValue("")
        return
      }
    }

    if (isAfter(date, maximumDate)) {
      setValue(format(maximumDate, DATE_FORMAT))
    } else if (isBefore(date, minimumDate)) {
      setValue(format(minimumDate, DATE_FORMAT))
    } else {
      setValue(format(date, DATE_FORMAT))
    }
  }

  return (
    <View style={INPUT_WRAPPER}>
      <TouchableOpacity activeOpacity={1} onPress={showDatePicker}>
        <Text
          preset="fieldLabel"
          text={
            placeholderTx && value?.length > 0 && value?.length < 10
              ? `${translate(placeholderTx)} (YYYY-MM-DD)`
              : value?.length > 0
              ? translate(placeholderTx)
              : ""
          }
          style={FIELD_LABEL}
        />
        <View style={INPUT_ICON}>
          <Icon icon="calendar" />
        </View>
        <View pointerEvents={Platform.OS === "web" ? "auto" : "box-only"}>
          <TextInputMask
            type={"datetime"}
            options={{
              format: DATE_FORMAT,
            }}
            value={value}
            onChangeText={(date) => setValue(date)}
            onFocus={() => onFocusChange(true)}
            onBlur={() => onFocusChange(false)}
            style={[INPUT, placeholderTx && value?.length > 0 && INPUT_WITH_PLACEHOLDER]}
            placeholder={!focused ? translate(placeholderTx) : "YYYY-MM-DD"}
            placeholderTextColor={color.palette.lighterGrey}
          />
        </View>
      </TouchableOpacity>
      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode="date"
        onConfirm={handleDateConfirm}
        onCancel={hideDatePicker}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
      />
    </View>
  )
}
