import React, { FC, useState, useCallback, useEffect } from "react"
import { FlatList, View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import {
  Header,
  Screen,
  ScreenTitle,
  SkeletonContainer,
  SkeletonView,
  Text,
  Video,
} from "../../components"
import { color, spacing } from "../../theme"
import { HomeStackScreenProps } from "../../navigators"
import { useStores } from "../../models"

const FULL: ViewStyle = {
  flex: 1,
}
const CONTAINER: ViewStyle = {
  backgroundColor: color.background,
}
const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
  flex: 1,
  flexGrow: 1,
}
const FLAT_LIST: ViewStyle = {
  paddingBottom: spacing[4],
}
const LIST_CONTAINER: ViewStyle = {
  marginBottom: spacing[4],
  padding: spacing[4],
  borderRadius: 24,
  backgroundColor: color.backgroundBlack500,
  overflow: "hidden",
}
const LIST_CONTENT_WRAPPER: ViewStyle = {
  marginTop: spacing[4],
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const LIST_CONTENT: ViewStyle = {
  width: "90%",
  paddingHorizontal: spacing[3],
  paddingBottom: spacing[1],
}
const SKELETON_TITLE: ViewStyle = {
  marginTop: 24,
  marginBottom: 15,
  width: "20%",
  height: 28,
}
const BLOCKS_SKELETON: ViewStyle = {
  marginBottom: 24,
  padding: spacing[4],
  justifyContent: "space-between",
  width: "100%",
  height: 380,
  borderRadius: 24,
  backgroundColor: color.palette.black500,
}
const BLOCKS_SKELETON_VIDEO: ViewStyle = {
  marginBottom: spacing[4],
  width: "100%",
  height: 300,
  borderRadius: 24,
}
const BLOCKS_SKELETON_TITLE: ViewStyle = {
  marginBottom: spacing[2],
  marginLeft: 8,
  width: "70%",
  height: 28,
}

export const HomeIntroScreen: FC<HomeStackScreenProps<"homeIntro">> = observer(({ navigation }) => {
  const goBack = () => navigation.goBack()

  const { commonStore } = useStores()

  const [blocks, setBlocks] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const blocks = await commonStore.getIntroBlocks()
      if (blocks) {
        setBlocks(blocks.data.data)
      }
      setLoading(false)
    })()
  }, [])

  const [visibleItems, setVisibleItems] = useState([])

  const onViewableItemsChanged = useCallback(
    ({ viewableItems }) => {
      if (viewableItems && viewableItems.length > 0) {
        setVisibleItems(viewableItems.map((viewableItem) => viewableItem.item.id))
      }
    },
    [setVisibleItems],
  )

  const onVideoPlay = useCallback(
    (itemId) => {
      setVisibleItems([itemId])
    },
    [visibleItems, setVisibleItems],
  )

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      setVisibleItems([])
    })

    return unsubscribe
  }, [navigation, setVisibleItems])

  const showDetails = (item) => {
    navigation.navigate("homeIntroDetails", { id: item.id })
  }

  return (
    <View style={FULL}>
      <Screen style={CONTAINER}>
        <Header leftIcon="back" onLeftPress={goBack} />
        <View style={WRAPPER}>
          {loading ? (
            <>
              <SkeletonContainer>
                <View>
                  <SkeletonView style={SKELETON_TITLE} />
                  {[1, 2].map((index) => (
                    <View style={BLOCKS_SKELETON} key={index}>
                      <SkeletonView style={BLOCKS_SKELETON_VIDEO} />
                      <SkeletonView style={BLOCKS_SKELETON_TITLE} />
                    </View>
                  ))}
                </View>
              </SkeletonContainer>
            </>
          ) : (
            <FlatList
              data={blocks}
              keyExtractor={(item) => String(item.id)}
              showsHorizontalScrollIndicator={false}
              viewabilityConfig={{
                itemVisiblePercentThreshold: 5,
              }}
              style={FLAT_LIST}
              ListHeaderComponent={<ScreenTitle headerTx="homePerfonalInfoScreen.headerText" />}
              onViewableItemsChanged={onViewableItemsChanged}
              renderItem={({ item }) => (
                <TouchableOpacity style={LIST_CONTAINER} onPress={() => showDetails(item)}>
                  <Video
                    previewSrc={item.media.preview}
                    src={item.media.url}
                    playAllowed={visibleItems.includes(item.id)}
                    onPlay={() => {
                      onVideoPlay(item.id)
                    }}
                  />
                  <View style={LIST_CONTENT_WRAPPER}>
                    <View style={LIST_CONTENT}>
                      <View>
                        <Text preset="h3" text={item.title} numberOfLines={1} />
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            />
          )}
        </View>
      </Screen>
    </View>
  )
})
