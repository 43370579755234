/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import "./utils/ignore-warnings"
import React, { useState, useEffect } from "react"
import { SafeAreaProvider, initialWindowMetrics } from "react-native-safe-area-context"
import { initFonts } from "./theme/fonts" // expo
import * as storage from "./utils/storage"
import { AppNavigator, RootParamList, useNavigationPersistence } from "./navigators"
import { RootStore, RootStoreProvider, setupRootStore } from "./models"
import { ToggleStorybook } from "../storybook/toggle-storybook"
import { ErrorBoundary } from "./screens/error/error-boundary"
import Constants from "expo-constants"
import * as Linking from "expo-linking"
import { PathConfigMap, LinkingOptions, getStateFromPath } from "@react-navigation/native"

// This puts screens in a native ViewController or Activity. If you want fully native
// stack navigation, use `createNativeStackNavigator` in place of `createStackNavigator`:
// https://github.com/kmagiera/react-native-screens#using-native-stack-navigator

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"

if (Constants.manifest) {
  Constants.manifest.originalFullName = "@sam8sara/sam8sara"
}

// Web linking configuration
const config: {
  screens: PathConfigMap<RootParamList>
} = {
  screens: {
    welcome: "welcome",
    welcomeForm1: "welcome/step1",
    welcomeForm2: "welcome/step2", // x
    welcomeForm3: "welcome/step3", // x
    welcomeForm4: "welcome/step4", // x
    welcomeForm5: "welcome/step5", // x
    home: {
      path: "",
      initialRouteName: "homeIndex",
      screens: {
        homeIndex: "",
        homeIntro: "intro",
        homeIntroDetails: "intro/:id",
        myCodeOfSamsara: "my-code-of-samsara",
        myCodeOfSamsaraDetails: "my-code-of-samsara/:orderId/:id",
        freeFormulasScreen: "free-formulas",
        extraOrderStack: {
          path: "extra-order",
          // @ts-ignore
          initialRouteName: "extraOrderIndex",
          screens: {
            extraOrderIndex: "",
            extraOrderCompatibility: "compatibility",
            extraOrderCompatibilityCalculation: "calculation", // x
            extraOrderEditUser: "edit-user", // x
            extraOrderCompatibilityResults: "result/:orderId",
            extraOrderCompatibilityResultsPost: "result/:orderId/:id",
            extraOrderCompatibilityCode: "code/:orderId",
          },
        },
        homeSubscription: "subscription/:subscriptionType",
      },
    },
    academy: {
      path: "academy",
      initialRouteName: "academyIndex",
      screens: {
        academyIndex: "",
        academyQuestionsIndex: "questions",
        academyGlossary: "glossary",
        academyVideoCategories: "videos",
        academyVideo: "videos/:categoryIndex/:videoIndex", // Todo
        academyNotifications: "notifications",
        academyNotificationsDetails: "notifications/:id",
      },
    },
    horoscope: {
      path: "horoscope",
      initialRouteName: "horoscopeIndex",
      screens: {
        horoscopeIndex: "",
      },
    },
    soulMate: {
      path: "soulmate",
      initialRouteName: "soulMateIndex",
      screens: {
        soulMateIndex: "",
        soulMateCompatibility: "compatibility",
        soulMateCompatibilityCalculation: "calculation", // x
        soulMateEditPerson: "edit-user", // x
      },
    },
    profile: {
      path: "profile",
      initialRouteName: "profileIndex",
      screens: {
        profileIndex: "",
        profileContactSupport: "contact-support",
        profileUserAgreement: "user-agreement",
        profilePrivacyPolicy: "privacy-policy",
        profileAbout: "about",
        profileSubscriptionInfo: "subscriptions",
        profileSubscriptionDetails: "subscriptions/:subscriptionId", // x
        profilePromoCode: "promo-code",
        profilePromoCodeSuccess: "promo-code-success",
        profileEdit: "edit-profile",
        profileEditName: "edit-profile/name",
        profileEditBirthName: "edit-profile/birth-name",
        profileEditGender: "edit-profile/gender",
        profileEditDateAndTime: "edit-profile/date-and-time",
        profileEditPlaceOfBirth: "edit-profile/place-of-birth",
        profileEditLanguage: "edit-profile/language",
        profileAboutApp: "about-app",
      },
    },
  },
}

const homePathState = {
  index: 0,
  routes: [
    {
      name: "home",
      state: {
        routes: [
          {
            name: "homeIndex",
            path: "/",
          },
        ],
      },
    },
  ],
}

/**
 * This is the root component of our app.
 */
function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)
  const {
    // initialNavigationState,
    // onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    ;(async () => {
      await initFonts() // expo
      setupRootStore().then(setRootStore)
    })()
  }, [])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!rootStore || !isNavigationStateRestored) return null

  const linking: LinkingOptions<any> = {
    prefixes: [Linking.createURL("/")],
    config,
    getStateFromPath: (path, config) => {
      // Video
      if (path.startsWith("/academy/videos/")) {
        return getStateFromPath("/academy/videos", config)
      }
      // Subscription
      if (path.startsWith("/profile/subscriptions/")) {
        return getStateFromPath("/profile/subscriptions", config)
      }
      // Extra order
      if (
        path.startsWith("/extra-order/calculation") ||
        path.startsWith("/extra-order/edit-user")
      ) {
        return getStateFromPath("/extra-order", config)
      }
      // Soulmate
      if (path.startsWith("/soulmate/calculation") || path.startsWith("/soulmate/edit-user")) {
        return getStateFromPath("/soulmate", config)
      }

      const redirectToHome =
        // home
        path === "/" ||
        path.startsWith("/?") ||
        // welcome
        path.startsWith("/welcome/step2") ||
        path.startsWith("/welcome/step3") ||
        path.startsWith("/welcome/step4") ||
        path.startsWith("/welcome/step5")

      return redirectToHome ? homePathState : getStateFromPath(path, config)
    },
  }

  // otherwise, we're ready to render the app
  return (
    <ToggleStorybook>
      <RootStoreProvider value={rootStore}>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <ErrorBoundary catchErrors={"always"}>
            <AppNavigator
              linking={linking}
              // initialState={initialNavigationState}
              // onStateChange={onNavigationStateChange}
            />
          </ErrorBoundary>
        </SafeAreaProvider>
      </RootStoreProvider>
    </ToggleStorybook>
  )
}

export default App
