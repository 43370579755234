import { getEnvironment } from "../../utils/env"
const API_URL = getEnvironment().apiUrl

export class ApiRoutes {
  public static readonly AUTH_LOGIN = "/Auth/Login"
  public static readonly AUTH_GOOGLE = "/Auth/Google"
  public static readonly AUTH_FACEBOOK = "/Auth/Facebook"
  public static readonly AUTH_APPLE = "/Auth/Apple"
  public static readonly AUTH_REGISTER = "/Auth/Register"
  public static readonly AUTH_SEND_CONFIRMATION = "/Auth/SendConfirmation"
  public static readonly AUTH_VERIFY_CONFIRMATION = "/Auth/VerifyConfirmation"
  public static readonly AUTH_REFRESH_TOKEN = "/Auth/RefreshToken"
  public static readonly AUTH_REVOKE_TOKEN = "/Auth/RevokeToken"

  public static GET_ABSOLUTE_URL(route: string) {
    return `${API_URL}${route}`
  }

  public static CUSTOMER_IS_EXISTS(login: string) {
    return `/Customer/IsExists/${login}`
  }

  public static readonly CUSTOMER_GET_CURRENT = "/Customer/my/info"
  public static readonly CUSTOMER_UPDATE_PAYMENT_METHOD = "/Customer/my/payment-method"

  public static CUSTOMER_UPDATE_NAME(id: string) {
    return `/Customer/${id}/name`
  }

  public static CUSTOMER_UPDATE_BIRTH_NAME(id: string) {
    return `/Customer/${id}/birthName`
  }

  public static CUSTOMER_UPDATE_GENDER(id: string) {
    return `/Customer/${id}/gender`
  }

  public static CUSTOMER_UPDATE_DATE_AND_TIME(id: string) {
    return `/Customer/${id}/birthDate`
  }

  public static CUSTOMER_UPDATE_PLACE_OF_BIRTH(id: string) {
    return `/Customer/${id}/birthPlace`
  }

  public static CUSTOMER_UPDATE_LOCALE(id: string) {
    return `/Customer/${id}/locale`
  }

  public static readonly CUSTOMER_SUPPORT = "/Customer/my/support"

  public static readonly CODE_OF_SAMSARA_DEMO_BLOCKS = "/Content/codeOfSamsara/demo/list"

  public static readonly CODE_OF_SAMSARA_BLOCKS = "/Content/my/codeOfSamsara/list"

  public static CODE_OF_SAMSARA_BLOCKS_BY_ORDER(orderId: string) {
    return `/Content/codeOfSamsara/byOrder/${orderId}/list`
  }

  public static CODE_OF_SAMSARA_SINGLE_BLOCK(orderId: string, id: string) {
    return `/Content/codeOfSamsara/byOrder/${orderId}?id=${id}`
  }

  public static CODE_OF_SAMSARA_UNLOCK_BLOCK(id: string) {
    return `/Content/my/codeOfSamsara/${id}/unlock`
  }

  public static readonly CODE_OF_SAMSARA_BLOCK_CAN_UNLOCK = "Content/my/codeOfSamsara/canUnlock"

  public static FORMULA_GET_MEANING_OF_NAME(index: string) {
    return `/Formula/MeaningOfName?index=${index}`
  }

  public static FORMULA_GET_MEANING_OF_BIRTHDATE(index: string) {
    return `/Formula/MeaningOfBirthdate?index=${index}`
  }

  public static FORMULA_GET_PROFESSION_BY_BIRTHDATE(index: string) {
    return `/Formula/ProfessionByBirthdate?index=${index}`
  }

  public static FORMULA_GET_COUNTRY_OF_PAST_LIFE(index: string) {
    return `/Formula/CountryOfPastLife?index=${index}`
  }

  public static FORMULA_GET_HOUSE_NUMBER_MEANING(index: string) {
    return `/Formula/MeaningOfApartmentNumber?index=${index}`
  }

  public static readonly INTRO_BLOCKS = "/Intro/blocks"

  public static INTRO_SINGLE_BLOCK(id: string) {
    return `/Intro/blocks/${id}`
  }

  public static readonly ACADEMY_GLOSSARIES = "/Academy/Glossaries"
  public static readonly ACADEMY_VIDEOS = "/Academy/Videos"
  public static readonly ACADEMY_QNACATEGORIES = "/Academy/QnACategories"
  public static readonly ACADEMY_NOTIFICATION_MY = "/Notification/my"
  public static readonly ACADEMY_NOTIFICATION_HAS_NEW = "/Notification/hasNew"
  public static ACADEMY_NOTIFICATION_GET_BY_ID(id: string) {
    return `/Notification/${id}`
  }

  public static ACADEMY_NOTIFICATION_MARK_READ(id: string) {
    return `/Notification/${id}/markRead`
  }

  public static readonly FEEDBACK = "Content/feedback"

  public static readonly SEND_GIFT_CARD_CODE = "/GiftCards/Redeem"

  public static readonly EXTRA_ORDER_AVAILABLE = "/Orders/available"

  public static readonly EXTRA_ORDER_HISTORY = "/Orders/history"

  public static EXTRA_ORDER_RESULT(orderId: string) {
    return ApiRoutes.GET_ABSOLUTE_URL(`/Content/soulmate/byOrder/${orderId}`)
  }

  public static PERSONAL_HOROSCOPE_RESULT = "/Horoscope/personal/my"

  public static PERSONAL_HOROSCOPE_BY_ORDER_ID(orderId: string) {
    return `/Horoscope/personal/${orderId}`
  }

  public static MUTUAL_HOROSCOPE_BY_ORDER_ID(orderId: string) {
    return `/Horoscope/mutual/byOrder/${orderId}`
  }

  public static readonly EXTRA_ORDER_ORDER = "/Orders"

  public static EXTRA_ORDER_PAY_STRIPE_URL(customerId: string, orderId: string) {
    return ApiRoutes.GET_ABSOLUTE_URL(`/Orders/stripe?customerId=${customerId}&orderId=${orderId}`)
  }

  public static readonly GET_SUBSCRIPTIONS = "/Subscriptions/available"

  public static readonly GET_MY_SUBSCRIPTIONS_LIST = "/Subscriptions/list"

  public static UNSUBSCRIBE_STRIPE(subscriptionId: string) {
    return ApiRoutes.GET_ABSOLUTE_URL(`/Subscriptions/${subscriptionId}/unsubscribe`)
  }

  public static RESUME_STRIPE(subscriptionId: string) {
    return ApiRoutes.GET_ABSOLUTE_URL(`/Subscriptions/${subscriptionId}/resume`)
  }

  public static PAY_STRIPE_SUBSCRIPTION_URL(customerId: string, product: string) {
    return ApiRoutes.GET_ABSOLUTE_URL(
      `/Subscriptions/stripe?customerId=${customerId}&product=${product}`,
    )
  }

  public static readonly TERMS_USER_AGREEMENT = "/Terms/UserAgreement"

  public static readonly TERMS_PRIVACY_POLICY = "/Terms/PrivacyPolicy"

  public static readonly DICTIONARY_CITIES = "/Dictionary/Cities"
}

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: 10000,
}
