import * as React from "react"
import { View, ViewStyle, TouchableWithoutFeedback } from "react-native"
import { SvgProps } from "react-native-svg"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Icon } from "../../../../components/icon/icon"
import { color, spacing } from "../../../../theme"
import { IconTypes } from "../../../../components/icon/icons"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { TabsParamList } from "../../../../navigators"

const FLEX_ROW_CENTER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}

const TAB_BAR: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingHorizontal: spacing[6],
  paddingTop: spacing[4],
  paddingBottom: spacing[6],
}
const TAB_ITEM: ViewStyle = {
  ...FLEX_ROW_CENTER,
  flex: 1,
}
const ICON_CONTAINER: ViewStyle = {
  ...FLEX_ROW_CENTER,
  width: "100%",
  height: "100%",
}
const ICON_WRAPPER: ViewStyle = {
  position: "relative",
  borderRadius: 40,
  width: 40,
  height: 40,
  overflow: "hidden",
}
const ICON: SvgProps = {
  width: 20,
  height: 20,
}

interface IconsMap {
  [key: string]: IconTypes
}
const ICONS_MAP: IconsMap = {
  soulMate: "soulMate",
  home: "logo",
  academy: "library",
  profile: "personCircle",
  horoscope: "horoscope",
}

const ICON_ACTIVE_COLOR: SvgProps = { fill: color.primary }

interface ICodeOfSamsaraRoutes {
  index: number
  name: string
  navigationRoute: string
}

const CodeOfSamsaraRoutesData: ICodeOfSamsaraRoutes[] = [
  {
    index: 1,
    name: "horoscope",
    navigationRoute: "horoscopeIndex",
  },
  {
    index: 2,
    name: "soulMate",
    navigationRoute: "soulMateIndex",
  },
  {
    index: 3,
    name: "home",
    navigationRoute: "homeIndex",
  },
  {
    index: 4,
    name: "academy",
    navigationRoute: "academyIndex",
  },
  {
    index: 5,
    name: "profile",
    navigationRoute: "profileIndex",
  },
]

export const MyCodeOfSamsaraTabBar = observer(() => {
  const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()

  return (
    <View style={TAB_BAR}>
      {CodeOfSamsaraRoutesData.map((route, index) => {
        const onPress = (routeName: any, screen: any) => {
          rootNavigation.navigate(routeName, {
            screen,
          })
        }

        return (
          <TouchableWithoutFeedback
            key={index}
            accessibilityRole="button"
            onPress={() => onPress(route.name, route.navigationRoute)}
            style={TAB_ITEM}
          >
            <View style={ICON_WRAPPER}>
              <Icon
                icon={ICONS_MAP[route.name]}
                containerStyle={ICON_CONTAINER}
                svgProps={{ ...(route.name === "home" ? ICON_ACTIVE_COLOR : {}), ...ICON }}
              />
            </View>
          </TouchableWithoutFeedback>
        )
      })}
    </View>
  )
})
