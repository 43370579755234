import React, { useState } from "react"
import { TextStyle, View, ViewStyle, TouchableOpacity, Modal, Pressable } from "react-native"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { TxKeyPath, translate } from "../../i18n"
import { useWindowWidth } from "../../utils/dimension"

export interface SelectDataProps {
  text: TxKeyPath
  code: string
}
interface SelectProps {
  data: SelectDataProps[]
  selectedCode: string
  setSelectedCode(item: string): void
}

const SELECT: TextStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[2],
  backgroundColor: color.palette.black500,
  borderWidth: 2,
  borderRadius: 8,
  color: color.palette.lightSteelBlue,
  borderColor: color.borderColor,
  flexDirection: "row",
  justifyContent: "space-between",
  minHeight: 60,
}
const SELECT_BTN: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const SELECT_BTN_TEXT: TextStyle = {
  fontSize: 16,
}
const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
}
const CONTAINER: ViewStyle = {
  position: "absolute",
  borderRadius: 8,
  top: "40%",
  left: spacing[4],
  right: spacing[4],
  alignItems: "center",
  paddingVertical: spacing[4],
  backgroundColor: color.palette.black500,
  marginHorizontal: "auto",
}
const ITEM: ViewStyle = {
  marginVertical: spacing[2],
}
const ITEM_TEXT: TextStyle = {}
const ITEM_TEXT_ACTIVE: TextStyle = {
  color: color.palette.brightTurquoise,
}

export function Select(props: SelectProps) {
  const { data, selectedCode, setSelectedCode } = props

  const screenWidth = useWindowWidth()

  const [modalOpen, setModalOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(selectedCode)

  const selectItems = (code: string) => {
    setActiveItem(code)
    setSelectedCode(code)
    setModalOpen(false)
  }

  const getSelectLabel = (code: string) => {
    return data.find((elem) => elem.code === code).text
  }

  return (
    <>
      <TouchableOpacity activeOpacity={100} onPress={() => setModalOpen(true)} style={SELECT}>
        <View style={SELECT_BTN}>
          <Text
            text={
              !selectedCode
                ? translate("welcomeForm3Screen.gender")
                : translate(getSelectLabel(selectedCode))
            }
            style={SELECT_BTN_TEXT}
          />
          <Icon icon="arrowDown" svgProps={{ fill: color.palette.moderateBlue }} />
        </View>
      </TouchableOpacity>
      <Modal
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        animationType="fade"
        transparent={true}
      >
        <Pressable style={OVERLAY} onPress={() => setModalOpen(false)} />
        <View style={[CONTAINER, { maxWidth: screenWidth }]}>
          {data.map((gender) => (
            <View key={gender.code} style={ITEM}>
              <TouchableOpacity onPress={() => selectItems(gender.code)}>
                <Text
                  preset="h4"
                  tx={gender.text}
                  style={activeItem === gender.code ? ITEM_TEXT_ACTIVE : ITEM_TEXT}
                />
              </TouchableOpacity>
            </View>
          ))}
        </View>
      </Modal>
    </>
  )
}
