import * as React from "react"
import { View } from "react-native"
import { IconProps } from "./icon.props"
import { icons } from "./icons"

export function Icon(props: IconProps) {
  const { icon, containerStyle, svgProps } = props

  const IconSVGComponent = icons[icon]

  return (
    <View style={containerStyle}>
      <IconSVGComponent {...svgProps} />
    </View>
  )
}
