import React, { FC, useState, useEffect } from "react"
import {
  FlatList,
  ImageStyle,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native"
import { observer } from "mobx-react-lite"
import {
  Screen,
  Header,
  Pills,
  Collapse,
  SkeletonContainer,
  SkeletonView,
  Icon,
} from "../../components"
import { AcademyStackScreenProps } from "../../navigators"
import { useStores } from "../../models"
import { color, spacing } from "../../theme"
import { translate } from "../../i18n"

const questionsHeader = require("./images/questions-header.png")

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const PILLS_SKELETON: ViewStyle = {
  flexDirection: "row",
  marginVertical: spacing[6],
}
const PILLS_SKELETON_ITEM: ViewStyle = {
  width: "30%",
  height: 36,
  marginRight: spacing[4],
  borderRadius: 40,
}
const COLLAPSE_SKELETON: ViewStyle = {
  width: "100%",
  height: 56,
  marginBottom: spacing[4],
  borderRadius: 8,
}
const SEARCH: ViewStyle = {
  marginBottom: spacing[4],
  paddingHorizontal: spacing[4],
  backgroundColor: color.palette.black500,
  borderRadius: 8,
}
const INPUT_WRAPPER: TextStyle = {
  width: "100%",
  marginVertical: spacing[3],
}
const INPUT: TextStyle = {
  width: "100%",
  paddingLeft: spacing[4],
  paddingRight: spacing[8],
  paddingVertical: spacing[3],
  fontSize: 16,
  color: color.palette.lightSteelBlue,
  backgroundColor: color.background,
  borderRadius: 8,
}
const BUTTON: ImageStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 16,
  zIndex: 1,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginVertical: spacing[3],
}

export const AcademyQuestionsScreen: FC<AcademyStackScreenProps<"academyQuestionsIndex">> =
  observer(({ navigation }) => {
    const { commonStore } = useStores()

    const goBack = () => navigation.goBack()

    const [qnas, setQnas] = useState([])
    const [qnasData, setQnasData] = useState([])
    const [qnasCategories, setQnasCategories] = useState([])
    const [activeCategory, setActiveCategory] = useState<number | string>()
    const [currentIndex, setCurrentIndex] = useState<number | string>()
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [filteredSections, setFilteredSections] = useState(qnasData)
    const [filterTypeSearch, setFilterTypeSearch] = useState(false)

    const searchFilter = (text: string) => {
      const newData = qnasData.reduce((r, data) => {
        const searchResult = data.header
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(text.replace(/\s/g, "").toLowerCase())

        if (searchResult) {
          r.push(data)
        }
        return r
      }, [])
      setSearch(text)
      setFilteredSections(newData)
      setFilterTypeSearch(true)
    }
    const clearSearch = () => {
      setSearch("")
      setFilterTypeSearch(false)
    }

    const filterQnas = (data) => {
      setFilterTypeSearch(false)
      setActiveCategory(data.id)
      setQnasData(qnas.filter((item) => item.category.toLowerCase() === data.name.toLowerCase()))
    }

    useEffect(() => {
      ;(async () => {
        const result = await commonStore.getAcademyQnACategories()
        if (result) {
          setQnasCategories(result.sortedQnasCategories)
          setQnas(result.sortedQnasCollapse)
          setQnasData(
            result.sortedQnasCollapse.filter(
              (item) =>
                item.category.toLowerCase() === result.sortedQnasCategories[0].name.toLowerCase(),
            ),
          )
          setActiveCategory(result.sortedQnasCategories[0].id)
          setLoading(false)
        }
      })()
    }, [])

    useEffect(() => {
      searchFilter(search)
    }, [qnasData])

    return (
      <View style={FULL}>
        <Screen
          header={
            <Header
              headerTx="academyQuestionsScreen.headerText"
              textImage={questionsHeader}
              leftIcon="back"
              onLeftPress={goBack}
            />
          }
        >
          {loading ? (
            <View style={CONTAINER}>
              <SkeletonContainer>
                <View>
                  <View style={PILLS_SKELETON}>
                    {[1, 2, 3].map((index) => (
                      <SkeletonView key={index} style={PILLS_SKELETON_ITEM} />
                    ))}
                  </View>
                  {[1, 2, 3, 4, 5].map((index) => (
                    <SkeletonView key={index} style={COLLAPSE_SKELETON} />
                  ))}
                </View>
              </SkeletonContainer>
            </View>
          ) : (
            <>
              <View>
                <Pills data={qnasCategories} activeId={activeCategory} onPress={filterQnas} />
              </View>
              <View style={CONTAINER}>
                <View style={SEARCH}>
                  <View style={INPUT_WRAPPER}>
                    <TextInput
                      value={search}
                      onChangeText={(text) => searchFilter(text)}
                      placeholder={translate("search.placeholder")}
                      placeholderTextColor={color.palette.lighterGrey}
                      underlineColorAndroid={color.transparent}
                      style={INPUT}
                    />
                    <View style={BUTTON}>
                      {search.length === 0 ? (
                        <Icon icon="search" />
                      ) : (
                        <TouchableOpacity activeOpacity={0.5} onPress={clearSearch}>
                          <Icon icon="close" />
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                </View>
                <FlatList
                  data={filterTypeSearch ? filteredSections : qnasData}
                  keyExtractor={(item) => String(item.id)}
                  renderItem={({ item }) => (
                    <Collapse
                      data={item}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                    />
                  )}
                />
              </View>
            </>
          )}
        </Screen>
      </View>
    )
  })
