import React, { useEffect, useState } from "react"
import {
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
  Modal,
  Pressable,
  FlatList,
  ActivityIndicator,
} from "react-native"
import { color, spacing } from "../../theme"
import { Icon } from "../icon/icon"
import { Text } from "../text/text"
import { TextField } from "../text-field/text-field"
import { translate, TxKeyPath } from "../../i18n"
import { useWindowWidth } from "../../utils/dimension"
import { texts } from "../../styles"
import { useDebounce } from "../../utils/use-debounce-hook"
import { useStores } from "../../models"
import { IPlaceOfBirth } from "../../models/profile-store/place-of-birth-model"

interface PlaceOfBirthProps {
  placeholderTx: TxKeyPath
  placeOfBirth: IPlaceOfBirth
  setPlaceOfBirth: (placeOfBirth: IPlaceOfBirth) => void
}

const LOADER: ViewStyle = {
  flex: 1,
  flexDirection: "row",
  justifyContent: "center",
  padding: spacing[4],
}
const SELECT_WRAPPER: ViewStyle = {
  position: "relative",
  width: "100%",
}
const SELECT: TextStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[2],
  backgroundColor: color.palette.black500,
  borderWidth: 2,
  borderRadius: 8,
  color: color.palette.lightSteelBlue,
  borderColor: color.borderColor,
  flexDirection: "row",
  justifyContent: "space-between",
  minHeight: 60,
  width: "100%",
}
const FIELD_LABEL: TextStyle = {
  position: "absolute",
  top: -2,
  left: 0,
  zIndex: 1,
}
const SELECT_BTN: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const SELECT_BTN_TEXT: TextStyle = {
  ...texts.H4_TEXT,
  paddingRight: spacing[6],
  color: color.palette.lightSteelBlue,
}
const SELECT_BTN_TEXT_VALUE: TextStyle = {
  ...texts.H4,
  paddingTop: spacing[3],
  paddingBottom: 0,
}
const CLEAR_INPUT_ICON: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  padding: spacing[4],
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
}
const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
}
const MODAL_CENTER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  paddingHorizontal: spacing[4],
  height: "100%",
}
const MODAL_HEADER: ViewStyle = {
  marginHorizontal: spacing[4],
  marginBottom: spacing[4],
}
const CONTAINER: ViewStyle = {
  flex: 1,
  borderRadius: 16,
  paddingVertical: spacing[3],
  backgroundColor: color.background,
  height: "90%",
}
const ITEM: ViewStyle = {
  width: "100%",
  paddingVertical: spacing[2],
  marginHorizontal: spacing[4],
  borderBottomColor: color.borderColor,
  borderBottomWidth: 1,
}
const ITEM_TEXT_OWN: TextStyle = {
  fontStyle: "italic",
}
const ITEM_TEXT_ACTIVE: TextStyle = {
  color: color.primary,
}
const NO_RESULTS: TextStyle = {
  flex: 1,
  padding: spacing[4],
  textAlign: "center",
}
const CLOSE_TEXT: TextStyle = {
  textTransform: "uppercase",
  color: color.primary,
  textAlign: "right",
  marginVertical: spacing[2],
  marginHorizontal: spacing[4],
}

export function PlaceOfBirth(props: PlaceOfBirthProps) {
  const { placeholderTx, placeOfBirth, setPlaceOfBirth } = props

  const { commonStore } = useStores()
  const screenWidth = useWindowWidth()

  const [modalOpen, setModalOpen] = useState(false)
  const [search, onSearch] = useState("")
  const debouncedSearch = useDebounce(search, 700)
  const [loading, setLoading] = useState(true)
  const [autocompleteList, setAutocompleteList] = useState<IPlaceOfBirth[]>()
  const selectedName = placeOfBirth?.displayName ? placeOfBirth?.displayName : placeOfBirth?.name

  const onSelect = (city: IPlaceOfBirth) => {
    setPlaceOfBirth(city)
    setModalOpen(false)
  }

  const updateAutocomplete = async () => {
    setLoading(true)
    if (debouncedSearch.length >= 2) {
      const cities = await commonStore.getCitiesAutocomplete(debouncedSearch)
      setAutocompleteList(cities)
    } else {
      setAutocompleteList([])
    }

    setLoading(false)
  }

  useEffect(() => {
    updateAutocomplete()
  }, [debouncedSearch])

  return (
    <>
      <View style={SELECT_WRAPPER}>
        <TouchableOpacity activeOpacity={1} onPress={() => setModalOpen(true)} style={SELECT}>
          <View style={SELECT_BTN}>
            {!!selectedName && <Text preset="fieldLabel" tx={placeholderTx} style={FIELD_LABEL} />}
            <Text
              text={!selectedName ? translate(placeholderTx) : selectedName}
              style={[SELECT_BTN_TEXT, !!selectedName && SELECT_BTN_TEXT_VALUE]}
              numberOfLines={1}
            />
            {!selectedName && (
              <Icon icon="arrowDown" svgProps={{ fill: color.palette.moderateBlue }} />
            )}
          </View>
        </TouchableOpacity>
        {selectedName && (
          <TouchableOpacity style={CLEAR_INPUT_ICON} onPress={() => onSelect(null)}>
            <Icon icon="closeCircle" />
          </TouchableOpacity>
        )}
      </View>
      <Modal
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        animationType="fade"
        transparent={true}
      >
        <View style={MODAL_CENTER}>
          <Pressable style={OVERLAY} onPress={() => setModalOpen(false)} />
          <View style={[CONTAINER, { maxWidth: screenWidth }]}>
            <View style={MODAL_HEADER}>
              <TextField
                placeholderTx={placeholderTx}
                textIcon="search"
                onChangeText={onSearch}
                value={search}
              />
            </View>
            {loading ? (
              <View style={LOADER}>
                <ActivityIndicator size="large" color={color.primary} />
              </View>
            ) : (
              <>
                {debouncedSearch.length < 2 ? (
                  <Text preset="h4Text" style={NO_RESULTS} tx="placeOfBirth.noResults" />
                ) : (
                  <FlatList
                    keyExtractor={(item) => String(item.geonameId)}
                    data={autocompleteList}
                    ListHeaderComponent={
                      <TouchableOpacity
                        style={ITEM}
                        onPress={() => onSelect({ name: debouncedSearch })}
                      >
                        <Text
                          preset="h4Text"
                          text={translate("placeOfBirth.useOwnName", { name: debouncedSearch })}
                          style={[
                            ITEM_TEXT_OWN,
                            !placeOfBirth?.geonameId &&
                              !!placeOfBirth?.name === debouncedSearch &&
                              ITEM_TEXT_ACTIVE,
                          ]}
                        />
                      </TouchableOpacity>
                    }
                    renderItem={({ item }) => {
                      return (
                        <TouchableOpacity style={ITEM} onPress={() => onSelect(item)}>
                          <Text
                            preset="h4Text"
                            text={item.displayName}
                            style={placeOfBirth?.geonameId === item.geonameId && ITEM_TEXT_ACTIVE}
                          />
                        </TouchableOpacity>
                      )
                    }}
                  />
                )}
              </>
            )}
            <Text
              preset="h4"
              tx="common.close"
              style={CLOSE_TEXT}
              onPress={() => setModalOpen(false)}
            />
          </View>
        </View>
      </Modal>
    </>
  )
}
