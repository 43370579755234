import React, { FC, useCallback, useMemo, useState } from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, Modal, Pressable } from "react-native"
import { useFocusEffect, useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import {
  Text,
  Screen,
  ScreenTitle,
  Icon,
  SkeletonContainer,
  SkeletonView,
  FragmentsContent,
  FragmentType,
} from "../../components"
import { HoroscopeStackScreenProps, TabsParamList } from "../../navigators"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { useWindowWidth } from "../../utils/dimension"
import { UserData, UserHistoryModal } from "./components/user-history-modal"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingTop: spacing[4],
}
const CONTENT: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: spacing[4],
  paddingHorizontal: spacing[4],
}
const SCREEN_TITLE: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const SELECT_DATE: ViewStyle = {
  width: "40%",
}
const SELECT_PERSON: ViewStyle = {
  width: "56%",
}
const SELECT: TextStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[2],
  backgroundColor: color.palette.black500,
  borderWidth: 2,
  borderRadius: 8,
  color: color.palette.lightSteelBlue,
  borderColor: color.borderColor,
  flexDirection: "row",
  justifyContent: "space-between",
  minHeight: 60,
}
const SELECT_CONTAINER: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const HOROSCOPE_CONTENT: ViewStyle = {
  paddingTop: spacing[4],
  backgroundColor: color.palette.black500,
}
const SELECT_TEXT: ViewStyle = {
  marginTop: 3,
}
const MODAL_CONTAINER: ViewStyle = {
  position: "absolute",
  borderRadius: 8,
  top: "40%",
  left: spacing[4],
  right: spacing[4],
  alignItems: "center",
  paddingVertical: spacing[4],
  backgroundColor: color.palette.black500,
  marginHorizontal: "auto",
}
const ITEM: ViewStyle = {
  marginVertical: spacing[2],
}
const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
}
const ITEM_TEXT: TextStyle = {}
const ITEM_TEXT_ACTIVE: TextStyle = {
  color: color.palette.brightTurquoise,
}
interface IHoroscopeContent {
  code: string
  fragments: FragmentType[]
  title: string
}

export const HoroscopeScreen: FC<HoroscopeStackScreenProps<"horoscopeIndex">> = observer(() => {
  const navigationLinks = useNavigation<NativeStackNavigationProp<TabsParamList>>()
  const screenWidth = useWindowWidth()
  const fromScreen = "horoscope"
  const { meStore, commonStore } = useStores()

  const [selectedDate, setSelectedDate] = useState("")
  const [selectedPerson, setSelectedPerson] = useState("")
  const [selectedPersonOrderId, setSelectedPersonOrderId] = useState("")
  const [content, setContent] = useState<IHoroscopeContent[]>(null)
  const [contentTitle, setContentTitle] = useState("")
  const [othersList, setOthersList] = useState(null)
  const [userHistoryShown, setUserHistoryShown] = useState(false)

  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [activeItem, setActiveItem] = useState("")

  const horoscopeContent = useMemo(() => {
    if (selectedDate && selectedPersonOrderId && content && !loading) {
      const data = content.find((d) => d.title === selectedDate)
      return data.fragments
    }
    return null
  }, [selectedDate, content, selectedPersonOrderId, loading])

  const refetchData = async (orderId: string, isPersonal: boolean) => {
    setLoading(true)
    const result = isPersonal
      ? await commonStore.getPersonalHoroscopeByOrderId(orderId)
      : await commonStore.getMutualHoroscopeByOrderId(orderId)

    if (result) {
      setSelectedPersonOrderId(result.current.orderId)
      setSelectedPerson(result.current.title)
      setContent(result.current.periods)
      setContentTitle(result.title)
      setSelectedDate(result.current.periods[0].title)
      setActiveItem(result.current.periods[0].title)
    }
    setLoading(false)
  }

  const selectItems = (code: string) => {
    setActiveItem(code)
    setSelectedDate(code)
    setModalOpen(false)
  }

  const onUserChange = async (item: UserData) => {
    setSelectedPerson(item.title || `${item.me.firstName} ${item.me.lastName}`)
    setSelectedPersonOrderId(item.orderId)
    setUserHistoryShown(false)
    await refetchData(item.orderId, !item.partner)
  }

  useFocusEffect(
    useCallback(() => {
      ;(async () => {
        setLoading(true)
        const result = await commonStore.getPersonalHoroscope()
        if (result) {
          setSelectedPersonOrderId(result.current.orderId)
          setSelectedPerson(result.current.title)
          setContent(result.current.periods)
          setContentTitle(result.title)
          setSelectedDate(result.current.periods[0].title)
          setActiveItem(result.current.periods[0].title)
          setOthersList(result.others)
        }
        setLoading(false)
      })()
    }, []),
  )

  return (
    <View style={FULL}>
      <Screen style={CONTAINER} preset="scroll">
        <ScreenTitle
          headerTx="horoscopeScreen.title"
          subHeaderTx="horoscopeScreen.subtitle"
          goToSubscription={() =>
            navigationLinks.navigate("profile", {
              screen: "profileSubscriptionInfo",
              params: { fromScreen },
            })
          }
          goToNotifications={() =>
            navigationLinks.navigate("academy", {
              screen: "academyNotifications",
              params: { fromScreen },
            })
          }
          main={!meStore.isGuest}
          style={SCREEN_TITLE}
        />
        {loading ? (
          <SkeletonContainer>
            <View style={BLOCKS_SKELETON}>
              <View style={BLOCKS_SKELETON_HEADER}>
                <SkeletonView style={BLOCKS_SKELETON_DATE} />
                <SkeletonView style={BLOCKS_SKELETON_PERSON} />
              </View>

              <View style={{ marginBottom: spacing[4] } as ViewStyle}>
                {[1, 2, 3, 4, 5].map((a) => {
                  return <SkeletonView key={a} style={BLOCKS_SKELETON_DESCRIPTION} />
                })}
              </View>
            </View>
          </SkeletonContainer>
        ) : (
          <>
            <View style={CONTENT}>
              <View style={SELECT_DATE}>
                <TouchableOpacity onPress={() => setModalOpen(true)} style={SELECT}>
                  <View style={SELECT_CONTAINER}>
                    <View>
                      <Text preset="medium" tx="horoscopeScreen.monthYearLabel" />
                      <Text preset="h4" text={selectedDate} style={SELECT_TEXT} />
                    </View>
                    <Icon icon="arrowDown" svgProps={{ fill: color.palette.moderateBlue }} />
                  </View>
                </TouchableOpacity>
              </View>
              <View style={SELECT_PERSON}>
                <TouchableOpacity onPress={() => setUserHistoryShown(true)} style={SELECT}>
                  <View style={SELECT_CONTAINER}>
                    <View>
                      <Text preset="medium" tx="horoscopeScreen.person" />
                      <Text preset="h4" text={selectedPerson} style={SELECT_TEXT} />
                    </View>
                    <Icon icon={"edit"} svgProps={{ fill: color.palette.brightTurquoise }} />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <View style={HOROSCOPE_CONTENT}>
              <FragmentsContent fragment={{ type: "html", content: `<h3>${contentTitle}</h3>` }} />
              {horoscopeContent?.map((c, index: number) => (
                <FragmentsContent key={index} fragment={c} />
              ))}
            </View>
            <Modal
              visible={modalOpen}
              onRequestClose={() => setModalOpen(false)}
              animationType="fade"
              transparent={true}
            >
              <Pressable style={OVERLAY} onPress={() => setModalOpen(false)} />
              <View style={[MODAL_CONTAINER, { maxWidth: screenWidth }]}>
                {content?.map((item) => (
                  <View key={item.code} style={ITEM}>
                    <TouchableOpacity onPress={() => selectItems(item.title)}>
                      <Text
                        preset="h4"
                        text={item.title}
                        style={activeItem === item.title ? ITEM_TEXT_ACTIVE : ITEM_TEXT}
                      />
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </Modal>
            <UserHistoryModal
              visible={userHistoryShown}
              data={othersList}
              closeModal={() => setUserHistoryShown(false)}
              onUserSelect={onUserChange}
              selectedUser={selectedPersonOrderId}
            />
          </>
        )}
      </Screen>
    </View>
  )
})

// SKELETON FOR BLOCKS
const BLOCKS_SKELETON: ViewStyle = {
  marginBottom: 24,
  paddingVertical: 24,
  paddingHorizontal: spacing[4],
}
const BLOCKS_SKELETON_HEADER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: spacing[5],
  width: "100%",
  height: 50,
}
const BLOCKS_SKELETON_DATE: ViewStyle = {
  width: "40%",
}
const BLOCKS_SKELETON_PERSON: ViewStyle = {
  width: "56%",
}
const BLOCKS_SKELETON_DESCRIPTION: ViewStyle = {
  width: "100%",
  height: 50,
  marginBottom: 7,
}
