import { Instance, SnapshotOut, types, flow, SnapshotIn, detach } from "mobx-state-tree"
import { withEnvironment } from "../extensions/with-environment"
import { CommonApi } from "../../services/api/common-api"
import { AcademyVideoCategoryModel } from "./academy-video-category"

export const CommonStoreModel = types
  .model("CommonStore")
  .props({
    academyCategories: types.optional(types.array(AcademyVideoCategoryModel), []),
    hasNewNotifications: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    getCodeOfSamsaraBlocks: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.codeOfSamsaraBlocks()

      if (result.kind === "ok") {
        const codeOfSamaraData = result.data.data
        const categories = codeOfSamaraData.categories.map((category) => {
          return {
            id: category.name,
            name: category.description,
          }
        })

        categories.unshift({ id: "numerology.available-reports", name: "Available reports" })

        const blocksData = []
        codeOfSamaraData.categories.forEach((category) =>
          category.blocks.map((block) =>
            blocksData.push({
              ...block,
              category: category.name,
            }),
          ),
        )

        return {
          orderId: codeOfSamaraData.orderId,
          categories,
          blocksData,
        }
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getCodeOfSamsaraSingleBlock: flow(function* (orderId: string, id: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.codeOfSamsaraSingleBlock(orderId, id)
      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    unlockBlock: flow(function* (blockId: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.unlockBlock(blockId)

      if (result.kind === "ok") {
        return result.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return { isSuccess: false, message: result.message }
      }
    }),
    getNumberOfBlocksToUnlock: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.canUnlockBlock()

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))
  .actions((self) => ({
    getIntroBlocks: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.introBlocks()

      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getIntroSingleBlock: flow(function* (id: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.introSingleBlock(id)

      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))
  .actions((self) => ({
    getAcademyGlossaries: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.academyGlossaries()

      const glossaries = result.data.data

      const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          ;(rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }

      if (result.kind === "ok") {
        const refactoredGlossaries = glossaries.map((glossary) => {
          return {
            id: glossary.name,
            header: glossary.name,
            content: glossary.description,
            letter: glossary.letter,
          }
        })
        const groupedGlossaries = groupBy(refactoredGlossaries, "letter")
        const result = Object.entries(groupedGlossaries).map(([key, value]) => {
          return {
            title: key,
            data: value,
          }
        })
        const sortedGlossaries = result.sort((a: any, b: any) => {
          if (a.title > b.title) return 1
          if (a.title < b.title) return -1
          return 0
        })
        return sortedGlossaries
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getAcademyVideos: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.academyVideos()

      if (result.kind === "ok" && result.data?.isSuccess) {
        const videos = result.data.data.map((category, index: number) => ({
          id: index.toString(),
          title: category.title,
          videos: category?.videos
            .filter((video: any) => video?.content?.preview && video?.content?.hls)
            .map((video: any, videoIndex: number) => ({
              id: `${index}_${videoIndex}`,
              categoryIndex: index,
              preview: video.content.preview,
              views: video.views,
              url: video?.content?.url || "",
              hls: video.content.hls,
            })),
        }))
        detach(self.academyCategories)
        self.academyCategories = videos || []
        return videos
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getAcademyQnACategories: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.academyQnACategories()

      const qnas = result.data.data

      if (result.kind === "ok") {
        const qnasCollapse = []
        qnas.forEach((elem) =>
          elem.qn_as.map((qna) => {
            return qnasCollapse.push({
              id: qna.question,
              header: qna.question,
              content: qna.answer,
              category: elem.name,
            })
          }),
        )
        const categoriesData = qnas.map((elem) => {
          return {
            id: elem.name,
            name: elem.name,
          }
        })
        const sortedQnasCategories = categoriesData.sort((a, b) => a.name.localeCompare(b.name))
        const sortedQnasCollapse = qnasCollapse.sort((a, b) => a.header.localeCompare(b.header))

        return sortedQnasCategories.length && sortedQnasCollapse.length
          ? {
              sortedQnasCategories,
              sortedQnasCollapse,
            }
          : false
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getAcademyAllNotification: flow(function* (skip: number) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.academyNotificationMy(skip)

      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    academyNotificationHasNew: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.academyNotificationHasNew()

      if (result.kind === "ok") {
        self.hasNewNotifications = result.data.data === true
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getAcademyNotificationById: flow(function* (id: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.academyNotificationById(id)

      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    markReadNotification: flow(function* (id: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.academyNotificationMarkRead(id)

      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getPersonalHoroscope: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.getPersonalHoroscope()

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getPersonalHoroscopeByOrderId: flow(function* (orderId: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.getPersonalHoroscopeByOrderId(orderId)

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getMutualHoroscopeByOrderId: flow(function* (orderId: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.getMutualHoroscopeByOrderId(orderId)

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))
  .actions((self) => ({
    sendFeedback: flow(function* (contentBlockId: string, isHelpful: boolean, comment: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.feedback(contentBlockId, isHelpful, comment)

      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))
  .actions((self) => ({
    sendGiftCardCode: flow(function* (code: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.sendGiftCardCode(code)

      if (result.kind === "ok") {
        return { isSuccess: result?.data?.isSuccess }
      } else {
        __DEV__ && console.tron.log(result.kind)
        return { isSuccess: false, message: result.message }
      }
    }),
  }))
  .actions((self) => ({
    getUserAgreement: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.getUserAgreement()

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getPrivacyPolicy: flow(function* () {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.getPrivacyPolicy()

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))
  .actions((self) => ({
    getCitiesAutocomplete: flow(function* (q: string) {
      const commonApi = new CommonApi(self.environment.api)
      const result = yield commonApi.getCitiesAutocomplete(q)

      if (result.kind === "ok") {
        return result.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))

export interface CommonStore extends Instance<typeof CommonStoreModel> {}
export interface CommonStoreSnapshotOut extends SnapshotOut<typeof CommonStoreModel> {}
export interface CommonStoreSnapshotIn extends SnapshotIn<typeof CommonStoreModel> {}
export const createCommonStoreDefaultModel = () => types.optional(CommonStoreModel, {})
