import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ChevronCircleRight = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="#3C4F6D" {...props}>
    <Path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10Zm1.5 0a8.5 8.5 0 1 0 17 0 8.5 8.5 0 0 0-17 0Zm6.47 4.53a.75.75 0 0 1 0-1.06L11.44 10 7.97 6.53a.75.75 0 0 1 1.06-1.06l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0Z" />
  </Svg>
)

export default ChevronCircleRight
