import React, { useEffect, useRef, useState } from "react"
import {
  View,
  ViewStyle,
  TextStyle,
  ImageStyle,
  ImageURISource,
  Pressable,
  Animated,
  Platform,
  Vibration,
} from "react-native"
import { spacing } from "../../theme"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { AutoImage } from "../auto-image/auto-image"

export interface PullUpProps {
  visible: boolean
  bottomHit: boolean
  backgroundImg: ImageURISource
  icon: string
  onPress: () => void
  lastPost?: boolean
}

const CONTAINER: ViewStyle = {
  alignItems: "center",
  paddingVertical: spacing[4],
}
const COLLAPSE_CONTENT: ViewStyle = {
  overflow: "hidden",
  marginTop: "auto",
}
const ARROW: ViewStyle = {
  marginBottom: spacing[4],
}
const TEXT: TextStyle = {
  marginBottom: spacing[4],
}
const BOTTOM: ViewStyle = {
  position: "relative",
  width: 56,
  height: 56,
  borderRadius: 100,
  overflow: "hidden",
}
const BOTTOM_IMAGE: ImageStyle = {
  width: "100%",
  height: "100%",
  resizeMode: "cover",
}
const BOTTOM_ICON: TextStyle = {
  position: "absolute",
  top: "33%",
  left: "33%",
}

export function PullUp(props: PullUpProps) {
  const { visible, bottomHit, backgroundImg, icon, onPress, lastPost = false } = props
  const isWeb = Platform.OS === "web"
  const [isAnimating, setIsAnimating] = useState(true)

  const slideAnim = useRef(new Animated.Value(0)).current

  const slideUp = () => {
    setIsAnimating(true)
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (finished) {
        setIsAnimating(false)
      }
    })
  }

  const slideDown = () => {
    setIsAnimating(true)
    Animated.timing(slideAnim, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (finished) {
        setIsAnimating(false)
      }
    })
  }

  useEffect(() => {
    if (visible || isWeb) {
      slideDown()
    } else {
      if (!bottomHit) {
        slideUp()
      }
    }
  }, [visible, bottomHit])

  useEffect(() => {
    if (!visible && !isAnimating && bottomHit) {
      Vibration.vibrate(5)
      onPress()
    }
  }, [visible, isAnimating, bottomHit])

  return (
    <Animated.View
      style={[
        COLLAPSE_CONTENT,
        {
          opacity: slideAnim,
          maxHeight: slideAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 200],
          }),
        },
      ]}
    >
      <Pressable onPress={onPress}>
        <View style={CONTAINER}>
          <Icon icon="arrowTop" containerStyle={ARROW} />
          <Text
            tx={
              lastPost
                ? "pullUp.lastPost"
                : Platform.OS === "web"
                ? "pullUp.clickToLoad"
                : "pullUp.pullUpToLoad"
            }
            style={TEXT}
          />
          <View style={BOTTOM}>
            {backgroundImg && <AutoImage source={backgroundImg} style={BOTTOM_IMAGE} />}
            <Text text={icon} style={BOTTOM_ICON} />
          </View>
        </View>
      </Pressable>
    </Animated.View>
  )
}
