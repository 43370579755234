import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Reload = (props: SvgProps) => (
  <Svg width="19" height="19" viewBox="0 0 19 19" fill="#00E6DB" {...props}>
    <Path d="M9.00002 1.49999C7.46933 1.50025 5.97536 1.96887 4.71875 2.84291C3.46213 3.71695 2.50304 4.95456 1.97027 6.38954C1.4375 7.82452 1.35656 9.38816 1.73831 10.8705C2.12007 12.3528 2.94624 13.6828 4.10589 14.682C5.26554 15.6811 6.70313 16.3014 8.22561 16.4598C9.7481 16.6181 11.2826 16.3068 12.623 15.5676C13.9634 14.8285 15.0455 13.6969 15.7241 12.3248C16.4027 10.9527 16.6452 9.40588 16.419 7.89199C16.352 7.43799 16.684 6.99999 17.143 6.99999C17.513 6.99999 17.839 7.25599 17.895 7.62299C18.2197 9.71802 17.7943 11.8604 16.694 13.6725C15.5936 15.4846 13.8889 16.8501 11.8802 17.5283C9.87158 18.2065 7.68801 18.1538 5.71441 17.3796C3.7408 16.6054 2.10386 15.1593 1.09213 13.2962C0.080405 11.4332 -0.241163 9.27278 0.184114 7.19583C0.609391 5.11889 1.75421 3.25875 3.41677 1.94329C5.07934 0.627829 7.15291 -0.0584929 9.27199 0.00529882C11.3911 0.0690906 13.4196 0.878901 15 2.29199V1.24999C15 1.05107 15.079 0.860308 15.2197 0.719656C15.3603 0.579003 15.5511 0.499986 15.75 0.499986C15.9489 0.499986 16.1397 0.579003 16.2803 0.719656C16.421 0.860308 16.5 1.05107 16.5 1.24999V4.24999C16.5 4.4489 16.421 4.63966 16.2803 4.78032C16.1397 4.92097 15.9489 4.99999 15.75 4.99999H12.75C12.5511 4.99999 12.3603 4.92097 12.2197 4.78032C12.079 4.63966 12 4.4489 12 4.24999C12 4.05107 12.079 3.86031 12.2197 3.71966C12.3603 3.579 12.5511 3.49999 12.75 3.49999H14.1C12.7144 2.21188 10.8919 1.49716 9.00002 1.49999V1.49999Z" />
  </Svg>
)

export default Reload
