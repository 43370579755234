import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ReadNotification = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <Path
      d="M8.331 2.938c0-1.108.902-2.01 2.01-2.01 1.107 0 2.009.902 2.009 2.01a2.01 2.01 0 0 1-4.019 0Zm1.17 0a.84.84 0 1 0 1.68 0 .84.84 0 0 0-1.68 0Z"
      fill="#ACACAC"
    />
    <Path
      d="M12.069 1.919s.089.355-.322.548c-.411.193-.683.05-.683.05a.827.827 0 0 1 .117.422.84.84 0 1 1-1.595-.37c-.697.331-1.227.048-1.227.048a1.99 1.99 0 0 0-.028.322c0 1.108.902 2.01 2.01 2.01 1.107 0 2.009-.902 2.009-2.011 0-.372-.103-.72-.281-1.02v.001Z"
      fill="#242424"
    />
    <Path
      d="M1.634 15.281c1.058-1.039 1.718-1.389 1.986-2.895.27-1.506.053-4.68 1.238-6.758 1.081-1.903 3.065-2.69 5.001-2.69L10 2.94l.14-.003c1.937 0 3.92.787 5.002 2.689 1.183 2.08.969 5.253 1.238 6.757.268 1.507.928 1.857 1.986 2.896.456.448 1.007 1.162 1.009 1.622.002.459-.233.626-.79.86-1.579.665-3.688 1.308-8.585 1.308s-7.006-.643-8.584-1.308c-.558-.234-.793-.4-.791-.86.002-.458.553-1.172 1.01-1.62Z"
      fill="#C4C4C4"
    />
    <Path
      d="M17.766 16.934c0-.692-3.477-1.253-7.766-1.253-4.29 0-7.766.561-7.766 1.253 0 .692 3.477 1.519 7.766 1.519 4.29 0 7.766-.826 7.766-1.519Z"
      fill="#828282"
    />
    <Path
      d="M14.663 6.998c.057.22.106.441.145.657.198 1.103.162 2.234.251 3.351.12 1.495.386 2.283.991 2.986.08.092-.002.234-.122.216-.808-.124-1.456-.247-2.195-.735-1.103-.726-1.375-2.093-1.383-3.322-.012-1.828.022-3.598-.123-4.27-.202-.936-.39-1.434-.696-1.889-.467-.694 1.347.269 1.632.483.792.598 1.246 1.56 1.5 2.523Z"
      fill="#ACACAC"
    />
    <Path
      d="M4.826 9.425c-.018-1.184-.009-2.41.463-3.497.283-.65.762-1.236 1.375-1.6.481-.286 1.46-.602 1.81.047.071.13.1.281.104.43.013.517-.256.995-.517 1.442-.772 1.325-1.08 2.773-1.488 4.242-.167.606-.38 1.21-.743 1.723-.25.352-1.643 1.602-1.324.472.306-1.092.338-2.122.32-3.26Z"
      fill="#D2D2D2"
    />
    <Path
      d="M11.42 16.69c-.001-.268-.146-.423-.48-.538-.695-.24-1.563-.196-2.07.076-.53.284-.162 1.861 1.13 1.861 1.292 0 1.422-1.194 1.42-1.398Z"
      fill="#ACACAC"
    />
    <Path
      d="M5.195 14.342c-1.372.24-2.365.76-2.795 1.183-.34.333-.34.595.26.322.45-.207 1.898-.625 3.165-.752 2.177-.219 3.52-.223 3.744-.219.523.011.567-.392-.49-.534-1.059-.14-2.512-.24-3.884 0Zm4.252 3.442c.181.125.422.186.625.102.203-.085.334-.342.234-.538a.603.603 0 0 0-.173-.187 2.504 2.504 0 0 0-.611-.352.624.624 0 0 0-.27-.06c-.093.006-.19.054-.225.14-.155.351.154.714.42.895Z"
      fill="#D2D2D2"
    />
  </Svg>
)

export default ReadNotification
