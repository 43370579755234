import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Letter = (props: SvgProps) => (
  <Svg width={18} height={15} viewBox="0 0 18 15" fill="#3C4F6D" {...props}>
    <Path d="M3 0a3 3 0 0 0-3 3v.301l9 4.847 9-4.845V3a3 3 0 0 0-3-3H3Z" />
    <Path d="M18 5.005 9.355 9.66a.75.75 0 0 1-.71 0L0 5.005V12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V5.005Z" />
  </Svg>
)

export default Letter
