import React, { useState } from "react"
import {
  ViewStyle,
  TextStyle,
  TextInput,
  ImageStyle,
  TouchableOpacity,
  Animated,
  View,
} from "react-native"
import { Icon } from "../icon/icon"
import { color, spacing } from "../../theme"
import { translate } from "../../i18n"

export interface SearchBarProps {
  clampedScroll?: Animated.AnimatedDiffClamp
  searchText: string
  onChangeText(text: string): void
  clear(): void
}

const SEARCH: ViewStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  alignItems: "center",
  paddingHorizontal: spacing[4],
  backgroundColor: color.palette.black500,
  zIndex: 1,
}

const INPUT_WRAPPER: TextStyle = {
  width: "100%",
  marginVertical: spacing[3],
  position: "relative",
}

const INPUT: TextStyle = {
  width: "100%",
  paddingLeft: spacing[4],
  paddingRight: spacing[8],
  paddingVertical: spacing[3],
  fontSize: 16,
  color: color.palette.lightSteelBlue,
  backgroundColor: color.background,
  borderRadius: 8,
}
const BUTTON: ImageStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 16,
  zIndex: 1,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginVertical: spacing[3],
}

export function SearchBar(props: SearchBarProps) {
  const { clampedScroll, searchText, onChangeText, clear } = props
  const [focused, setFocused] = useState<boolean>()

  const searchBarBasicTranslate = clampedScroll.interpolate({
    inputRange: [0, 75],
    outputRange: [0, -1],
    extrapolate: "clamp",
  })
  const searchBarTranslate = clampedScroll.interpolate({
    inputRange: [0, 75],
    outputRange: [0, -75],
    extrapolate: "clamp",
  })
  const searchBarOpacity = clampedScroll.interpolate({
    inputRange: [0, 30],
    outputRange: [1, 0],
    extrapolate: "clamp",
  })

  return (
    <Animated.View
      style={[
        SEARCH,
        focused
          ? {
              transform: [
                {
                  translateY: searchBarBasicTranslate,
                },
              ],
            }
          : {
              transform: [
                {
                  translateY: searchBarTranslate,
                },
              ],
              opacity: searchBarOpacity,
            },
      ]}
    >
      <View style={INPUT_WRAPPER}>
        <TextInput
          value={searchText}
          onChangeText={(text) => onChangeText(text)}
          placeholder={translate("search.placeholder")}
          placeholderTextColor={color.palette.lighterGrey}
          underlineColorAndroid={color.transparent}
          style={INPUT}
          onFocus={() => {
            setFocused(true)
          }}
          onBlur={() => {
            setFocused(false)
          }}
        />
        <View style={BUTTON}>
          {searchText.length === 0 ? (
            <Icon icon="search" />
          ) : (
            <TouchableOpacity activeOpacity={0.5} onPress={clear}>
              <Icon icon="close" />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </Animated.View>
  )
}
