// This is the first file that ReactNative will run when it starts up.
import { registerRootComponent } from "expo"
import { Platform } from "react-native"
import App from "./app/app.tsx"
import * as serviceWorkerRegistration from "./app/serviceWorkerRegistration"

registerRootComponent(App)
export default App

if (Platform.OS === "web") {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register()
}
