import React, { FC, useState } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Header, FormRow, TextField, Button, ScreenTitle } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[4],
  flex: 1,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}
const INPUT_ROW: ViewStyle = {
  flexGrow: 1,
  alignItems: "flex-start",
}
const INPUT_WRAPPER: ViewStyle = {
  width: "100%",
}

export const ProfilePromoCodeScreen: FC<ProfileStackScreenProps<"profilePromoCode">> = observer(
  ({ navigation }) => {
    const { commonStore, alertStore } = useStores()

    const [code, setCode] = useState("")

    const sendGiftCardCode = async () => {
      if (code.length >= 8) {
        const result = await commonStore.sendGiftCardCode(code)
        if (result.isSuccess) {
          navigation.navigate("profilePromoCodeSuccess")
        } else {
          alertStore.showError(result.message)
        }
      } else {
        alertStore.showError("Enter 8-digit gift code!")
      }
    }

    const goBack = () => navigation.goBack()

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profilePromoCodeScreen.title" style={TITLE} />
          <FormRow preset="middle" style={INPUT_ROW}>
            <TextField
              value={code}
              keyboardType={"number-pad"}
              maxLength={11}
              placeholderTx="profilePromoCodeScreen.placeholder"
              style={INPUT_WRAPPER}
              onChangeText={(text) => setCode(text)}
            />
          </FormRow>

          <Button tx="profilePromoCodeScreen.activate" onPress={sendGiftCardCode} />
        </Screen>
      </View>
    )
  },
)
