import { TextStyle } from "react-native"
import { color } from "../../theme"
import { texts } from "../../styles"

/**
 * All the variations of text styling within the app.
 *
 * You want to customize these to whatever you need in your app.
 */
export const presets = {
  default: texts.REGULAR_TEXT,
  defaultLight: {
    ...texts.REGULAR_TEXT,
    color: color.palette.white,
  },
  bold: { ...texts.REGULAR_TEXT, fontWeight: "bold" } as TextStyle,
  h2: texts.H2,
  h3: texts.H3,
  h4: texts.H4,
  h4Text: texts.H4_TEXT,
  medium: texts.MEDIUM_TEXT,
  mediumBold: texts.MEDIUM_BOLD,
  small: texts.SMALL_TEXT,
  italicText: texts.ITALIC_TEXT,

  fieldLabel: { ...texts.REGULAR_TEXT, fontSize: 13, color: color.dim } as TextStyle,
  secondary: { ...texts.REGULAR_TEXT, fontSize: 9, color: color.dim } as TextStyle,
}

/**
 * A list of preset names.
 */
export type TextPresets = keyof typeof presets
