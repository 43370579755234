import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const CollapseArrow = (props: SvgProps) => (
  <Svg width={13} height={8} viewBox="0 0 13 8" fill="#00E6DB" {...props}>
    <Path d="M.22 6.78a.75.75 0 0 0 1.06 0L6 2.06l4.72 4.72a.75.75 0 1 0 1.06-1.06L6.53.47a.75.75 0 0 0-1.06 0L.22 5.72a.75.75 0 0 0 0 1.06Z" />
  </Svg>
)

export default CollapseArrow
