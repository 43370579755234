import React, { FC, useState } from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import { observer } from "mobx-react-lite"
import {
  Screen,
  Button,
  ScreenTitle,
  Header,
  Text,
  Icon,
  DatePicker,
  TimePicker,
} from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
  flex: 1,
}
const CONTENT: ViewStyle = {
  flexGrow: 1,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}
const WARNING: ViewStyle = {
  marginTop: spacing[5],
  padding: spacing[4],
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "rgba(230, 97, 0, 0.1)",
  borderRadius: 10,
}
const WARNING_ICON: ViewStyle = {
  width: "10%",
}
const WARNING_TEXT: TextStyle = {
  width: "90%",
  color: "#E5D5CA",
}

export const ProfileEditDateAndTimeScreen: FC<ProfileStackScreenProps<"profileEditDateAndTime">> =
  observer(({ navigation }) => {
    const { profileStore, alertStore } = useStores()

    const [dateOfBirth, setDateOfBirth] = useState(profileStore.dateOfBirth)
    const [timeOfBirth, setTimeOfBirth] = useState(profileStore.timeOfBirth)

    const goBack = () => navigation.goBack()

    const saveChanges = async () => {
      if (dateOfBirth) {
        const result = await profileStore.updateCustomerDateAndTime(dateOfBirth, timeOfBirth)
        if (result?.customer) {
          navigation.navigate("profileEdit")
        } else {
          alertStore.showError(result.message)
        }
      } else {
        alertStore.showError("Please fill in Date of Birth")
      }
    }

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileEditDateAndTimeScreen.title" style={TITLE} />

          <View style={CONTENT}>
            <DatePicker
              value={dateOfBirth}
              setValue={setDateOfBirth}
              placeholderTx="welcomeForm4Screen.dateOfBirth"
            />
            <TimePicker
              value={timeOfBirth}
              setValue={setTimeOfBirth}
              placeholderTx="welcomeForm4Screen.timeOfBirth"
            />
            <View style={WARNING}>
              <Icon icon="warning" containerStyle={WARNING_ICON} />
              <Text tx="profileEditDateAndTimeScreen.warning" style={WARNING_TEXT} />
            </View>
          </View>

          <Button tx="profileEditDateAndTimeScreen.save" onPress={saveChanges} />
        </Screen>
      </View>
    )
  })
