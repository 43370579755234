import React, { FC, useState } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Button, ScreenTitle, Header, FormRow, TextField } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
  flex: 1,
}
const CONTENT: ViewStyle = {
  flexGrow: 1,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}
const INPUT_WRAPPER: ViewStyle = {
  width: "100%",
}

export const ProfileEditNameScreen: FC<ProfileStackScreenProps<"profileEditName">> = observer(
  ({ navigation }) => {
    const { profileStore } = useStores()

    const [firstName, setFirstName] = useState(profileStore.firstName)
    const [lastName, setLastName] = useState(profileStore.lastName)

    const goBack = () => navigation.goBack()

    const saveChanges = () => {
      profileStore.updateCustomerName(firstName, lastName)
      navigation.navigate("profileEdit")
    }

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileEditNameScreen.title" style={TITLE} />

          <View style={CONTENT}>
            <FormRow preset="middle">
              <TextField
                value={firstName}
                onChangeText={(text) => setFirstName(text.replace(/\d/gi, ""))}
                placeholderTx="profileEditNameScreen.firstName"
                style={INPUT_WRAPPER}
              />
            </FormRow>
            <FormRow preset="middle">
              <TextField
                value={lastName}
                onChangeText={(text) => setLastName(text.replace(/\d/gi, ""))}
                placeholderTx="profileEditNameScreen.lastName"
                style={INPUT_WRAPPER}
              />
            </FormRow>
          </View>

          <Button tx="profileEditNameScreen.save" onPress={saveChanges} />
        </Screen>
      </View>
    )
  },
)
