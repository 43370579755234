import React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { Text } from "../../../components"
import { spacing } from "../../../theme"

export function AboutSamsaraProfile() {
  return (
    <>
      <View>
        <Text preset="default" tx="profileAboutScreen.text1" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutScreen.text2" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutScreen.text3" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutScreen.text4" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutScreen.text5" style={ABOUT_TEXT} />
      </View>
      <View style={ABOUT_CONTENT}>
        <Text preset="h3" tx="profileAboutUsNumerology.title" style={ABOUT_TITLE} />
        <Text preset="default" tx="profileAboutUsNumerology.text1" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text2" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text3" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text4" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text5" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text6" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text7" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text8" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text9" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text10" style={ABOUT_TEXT} />
      </View>
      <View style={ABOUT_CONTENT}>
        <Text preset="h3" tx="profileAboutChineseAstrology.title" style={ABOUT_TITLE} />
        <Text preset="default" tx="profileAboutUsNumerology.text1" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text2" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text3" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text4" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text5" style={ABOUT_TEXT} />
        <Text preset="default" tx="profileAboutUsNumerology.text6" style={ABOUT_TEXT} />
      </View>
    </>
  )
}

const ABOUT_CONTENT: ViewStyle = {
  marginTop: spacing[4],
}
const ABOUT_TITLE: TextStyle = {
  marginBottom: spacing[5],
}
const ABOUT_TEXT: TextStyle = {
  marginBottom: spacing[4],
}
