import { addMinutes, format, isValid, parseISO } from "date-fns"

export const parseAndFormatDatePlaceholder = (
  date: string,
  formatS: string,
  placeholder: string,
  isUtc = false,
) => {
  const parsedDate = parseISO(date)
  if (isValid(parsedDate)) {
    // https://github.com/date-fns/date-fns/issues/1401
    return format(
      isUtc ? addMinutes(parsedDate, parsedDate.getTimezoneOffset()) : parsedDate,
      formatS,
    )
  }
  return placeholder
}

export const parseAndFormatDateLocal = (date: string, formatS: string) => {
  return parseAndFormatDatePlaceholder(date, formatS, "Invalid date", false)
}

export const parseAndFormatDateLocalRaw = (date: string, formatS: string) => {
  return parseAndFormatDatePlaceholder(date, formatS, "", false)
}

export const parseAndFormatDateUtc = (date: string, formatS: string) => {
  return parseAndFormatDatePlaceholder(date, formatS, "", true)
}
