import React from "react"
import { View, ViewStyle, TouchableOpacity } from "react-native"
import { CategoryButtonCompactProps } from "./category-button-compact.props"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { color, spacing } from "../../theme"
import { translate } from "../../i18n"

const LIST_CONTAINER: ViewStyle = {
  alignItems: "center",
  flexDirection: "row",
  marginBottom: 8,
  paddingHorizontal: 18,
  paddingVertical: 16,
  backgroundColor: color.backgroundBlack500,
  borderRadius: 8,
}
const LEFT_ICON: ViewStyle = {
  marginRight: spacing[5],
  width: 21,
  height: 21,
}
const LIST_CONTENT: ViewStyle = {
  flex: 1,
}

export function CategoryButtonCompact(props: CategoryButtonCompactProps) {
  const { titleTx, children, onPress, leftIcon } = props

  const title = translate(titleTx)
  const content = children || <Text preset="h4" text={title} numberOfLines={1} />

  return (
    <TouchableOpacity onPress={onPress} style={LIST_CONTAINER}>
      {leftIcon && <Icon icon={leftIcon} containerStyle={LEFT_ICON} />}
      <View style={LIST_CONTENT}>{content}</View>
      <Icon icon={"chevronCircleRight"} />
    </TouchableOpacity>
  )
}
