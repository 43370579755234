import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ExtraOrderStackScreenProps } from "../../navigators"
import { Results } from "./components/results/results"
import { View, ViewStyle } from "react-native"

export const ExtraOrderCompatibilityResultsScreen: FC<
  ExtraOrderStackScreenProps<"extraOrderCompatibilityResults">
> = observer(({ route }) => {
  const { orderId } = route.params

  return (
    <View style={FULL}>
      <Results orderId={orderId} />
    </View>
  )
})

const FULL: ViewStyle = { flex: 1 }
