import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { FlatList, ImageStyle, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import {
  Screen,
  Header,
  Text,
  Icon,
  SkeletonContainer,
  SkeletonView,
  AutoImage,
  Button,
} from "../../components"
import { AcademyStackScreenProps } from "../../navigators"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"

const videoHeader = require("./images/video-header.png")

const FULL: ViewStyle = { flex: 1 }
const CATEGORY_WRAPPER: ViewStyle = {
  marginBottom: spacing[5],
}
const SLIDER_HEADER: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingHorizontal: spacing[4],
  marginBottom: spacing[4],
}
const SLIDER_TITLE: TextStyle = {
  flexShrink: 1,
}
const SLIDER_BUTTONS: ViewStyle = {
  display: "flex",
  flexDirection: "row",
}
const SLIDER_BUTTON_NAV: ViewStyle = {
  alignItems: "center",
  paddingVertical: 7,
  marginLeft: spacing[2],
  width: 32,
  height: 32,
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  borderRadius: 32,
}
const VIDEO_WRAPPER: ViewStyle = {
  position: "relative",
  marginRight: spacing[4],
}
const VIDEO_FIRST: ViewStyle = {
  marginLeft: spacing[4],
}
const VIDEO_IMAGE: ImageStyle = {
  width: 130,
  height: 230,
  borderRadius: spacing[3],
}
const VIEWS_WRAPPER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  position: "absolute",
  bottom: 0,
  left: 0,
  padding: spacing[3],
}
const VIEWS_TEXT: TextStyle = {
  textShadowColor: "rgba(0, 0, 0, 0.3)",
  textShadowOffset: { width: 1, height: 1 },
  textShadowRadius: 0,
  marginLeft: spacing[2],
  color: color.palette.white,
}

const SKELETON_WRAPPER: ViewStyle = {
  width: "100%",
  paddingHorizontal: spacing[4],
  marginBottom: spacing[5],
}
const SKELETON_VIDEO_ROW: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
const SKELETON_TITLE: ViewStyle = {
  width: "70%",
  height: 25,
  marginBottom: spacing[4],
}
const SKELETON_VIDEO: ViewStyle = {
  width: 130,
  height: 230,
  marginRight: spacing[4],
}

export const AcademyVideoCategoriesScreen: FC<AcademyStackScreenProps<"academyVideoCategories">> =
  observer(({ navigation }) => {
    const { commonStore } = useStores()
    const flatListRefs = useRef<any>([])
    const [visibleIndex, setVisibleIndex] = useState([])
    const goBack = () => navigation.goBack()
    const [loading, setLoading] = useState(true)

    const onVideoPress = (categoryIndex: number, videoIndex: number) => {
      navigation.navigate("academyVideo", { categoryIndex, videoIndex })
    }

    const onPrev = (categoryIndex: number) => {
      if (flatListRefs.current[categoryIndex]) {
        flatListRefs.current[categoryIndex].scrollToIndex({
          index: Math.max(visibleIndex[categoryIndex] - 1, 0),
          viewOffset: 16,
        })
      }
    }

    const onNext = (categoryIndex: number) => {
      if (flatListRefs.current[categoryIndex]) {
        flatListRefs.current[categoryIndex].scrollToIndex({
          index: visibleIndex[categoryIndex] + 1,
          viewOffset: 16,
        })
      }
    }

    const onViewableItemsChanged = useCallback(({ viewableItems }) => {
      const visibleItems = viewableItems.filter((viewableItem) => viewableItem.isViewable)
      if (visibleItems?.length > 0) {
        const visibleIndexTemp = visibleIndex
        const categoryIndex = visibleItems[0].item.categoryIndex
        visibleIndexTemp[categoryIndex] = visibleItems[0].index
        setVisibleIndex(visibleIndexTemp)
      }
    }, [])

    useEffect(() => {
      setLoading(true)
      ;(async () => {
        const videos = await commonStore.getAcademyVideos()
        if (videos) {
          setLoading(false)
        }
      })()
    }, [])

    useEffect(() => {
      flatListRefs.current = flatListRefs.current.slice(0, commonStore.academyCategories.length)
    }, [commonStore.academyCategories])

    return (
      <View style={FULL}>
        <Screen
          header={
            <Header
              headerTx="academyVideoScreen.headerText"
              textImage={videoHeader}
              leftIcon="back"
              onLeftPress={goBack}
            />
          }
        >
          {loading ? (
            <SkeletonContainer>
              {[...Array(2).keys()].map((index) => {
                return (
                  <View key={index} style={SKELETON_WRAPPER}>
                    <SkeletonView style={SKELETON_TITLE} />
                    <View style={SKELETON_VIDEO_ROW}>
                      <SkeletonView style={SKELETON_VIDEO} />
                      <SkeletonView style={SKELETON_VIDEO} />
                      <SkeletonView style={SKELETON_VIDEO} />
                      <SkeletonView style={SKELETON_VIDEO} />
                    </View>
                  </View>
                )
              })}
            </SkeletonContainer>
          ) : (
            <FlatList
              style={FULL}
              data={commonStore.academyCategories}
              keyExtractor={(item) => item.id}
              renderItem={({ item: category, index: categoryIndex }) => (
                <View style={CATEGORY_WRAPPER}>
                  <View style={SLIDER_HEADER}>
                    <Text
                      style={SLIDER_TITLE}
                      preset="h4"
                      numberOfLines={1}
                      text={category.title}
                    />
                    <View style={SLIDER_BUTTONS}>
                      <Button
                        preset="unstyled"
                        style={SLIDER_BUTTON_NAV}
                        onPress={() => onPrev(categoryIndex)}
                      >
                        <Icon icon="arrowLeft" />
                      </Button>
                      <Button
                        preset="unstyled"
                        style={SLIDER_BUTTON_NAV}
                        onPress={() => onNext(categoryIndex)}
                      >
                        <Icon icon="arrowRight" />
                      </Button>
                    </View>
                  </View>
                  <FlatList
                    ref={(ref) => {
                      flatListRefs.current[categoryIndex] = ref
                    }}
                    data={category.videos}
                    keyExtractor={(item) => item.id}
                    horizontal={true}
                    viewabilityConfig={{
                      itemVisiblePercentThreshold: 20,
                    }}
                    onViewableItemsChanged={onViewableItemsChanged}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({ item: video, index }) => (
                      <TouchableOpacity
                        activeOpacity={0.8}
                        onPress={() => {
                          onVideoPress(categoryIndex, index)
                        }}
                        style={[VIDEO_WRAPPER, index === 0 && VIDEO_FIRST]}
                      >
                        <AutoImage source={{ uri: video.preview }} style={VIDEO_IMAGE} />
                        <View style={VIEWS_WRAPPER}>
                          <Icon icon="eye" />
                          <Text style={VIEWS_TEXT} preset="small" text={video.views.toString()} />
                        </View>
                      </TouchableOpacity>
                    )}
                  />
                </View>
              )}
            />
          )}
        </Screen>
      </View>
    )
  })
