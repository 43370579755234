import React, { FC, useState, useEffect } from "react"
import { View, ViewStyle, TextStyle, ImageStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Header, Text, SkeletonContainer, SkeletonView, AutoImage } from "../../components"
import { AcademyStackScreenProps } from "../../navigators"
import { spacing, color } from "../../theme"
import { useStores } from "../../models"
import { parseAndFormatDateLocal } from "../../utils/date"

const SKELETON_POST: ViewStyle = {
  width: "100%",
}
const SKELETON_POST_IMG: ViewStyle = {
  width: "100%",
  height: 310,
  marginBottom: spacing[4],
  backgroundColor: color.palette.black500,
}
const SKELETON_POST_CONTENT: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const SKELETON_POST_TITLE: ViewStyle = {
  width: "70%",
  height: 25,
  marginBottom: spacing[5],
}
const SKELETON_POST_TEXT: ViewStyle = {
  width: "100%",
  height: 11,
  marginBottom: spacing[2],
}

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingBottom: spacing[4],
  paddingHorizontal: spacing[4],
}
const DATE: TextStyle = {
  marginTop: spacing[4],
  color: color.palette.white,
}
const DATE_OVERLAP: TextStyle = {
  marginTop: -spacing[6],
  marginBottom: spacing[4],
}
const TITLE: TextStyle = {
  paddingVertical: spacing[4],
}
const IMAGE: ImageStyle = {
  width: "100%",
  height: 280,
}

interface IPost {
  id: string
  title: string
  text: string
  created: string
  picture: string
}

export const AcademyNotoficationsDetailsScreen: FC<
  AcademyStackScreenProps<"academyNotificationsDetails">
> = observer(({ route, navigation }) => {
  const { commonStore } = useStores()

  const { id } = route.params

  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState<IPost>({
    id: "",
    title: "",
    text: "",
    created: "",
    picture: null,
  })

  const goBack = () => navigation.goBack()

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      await commonStore.markReadNotification(id)
      const result = await commonStore.getAcademyNotificationById(id)
      const post = result?.data?.data
      if (post) {
        setPost({
          id: post.id,
          title: post.title,
          text: post.text,
          created: post.created,
          picture: post.picture,
        })
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      <View style={FULL}>
        <Header
          headerTx="academyNotificationDetailsScreen.headerText"
          leftIcon="back"
          onLeftPress={goBack}
        />
        <Screen preset="scroll">
          {loading ? (
            <SkeletonContainer>
              <View style={SKELETON_POST}>
                <SkeletonView style={SKELETON_POST_IMG} />
                <View style={SKELETON_POST_CONTENT}>
                  <SkeletonView style={SKELETON_POST_TITLE} />
                  {[...Array(10).keys()].map((index) => (
                    <SkeletonView key={index} style={SKELETON_POST_TEXT} />
                  ))}
                </View>
              </View>
            </SkeletonContainer>
          ) : (
            <>
              {post.picture && (
                <AutoImage resizeMode="cover" source={{ uri: post.picture }} style={IMAGE} />
              )}
              <View style={CONTAINER}>
                <Text
                  preset="medium"
                  text={parseAndFormatDateLocal(post.created, "d MMMM yyyy")}
                  style={[DATE, post.picture ? DATE_OVERLAP : {}]}
                />
                <Text preset="h2" text={post.title} style={TITLE} />
                <Text preset="h4Text" text={post.text} />
              </View>
            </>
          )}
        </Screen>
      </View>
    </>
  )
})
