import * as React from "react"
import { Animated, ViewProps } from "react-native"
import { SkeletonContext } from "./skeleton-container"

export interface SkeletonViewProps extends ViewProps {}

export function SkeletonView(props: SkeletonViewProps) {
  const { backgroundColor } = React.useContext(SkeletonContext)

  return <Animated.View {...props} style={[props.style, { backgroundColor }]} />
}
