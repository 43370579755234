import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
  AcademyScreen,
  AcademyQuestionsScreen,
  AcademyGlossaryScreen,
  AcademyVideoScreen,
  AcademyVideoCategoriesScreen,
  AcademyNotoficationsScreen,
  AcademyNotoficationsDetailsScreen,
} from "../screens"
import { translate } from "../i18n"
import { AcademyStackParamList } from "./app-navigation-routes"

const Stack = createNativeStackNavigator<AcademyStackParamList>()

export const AcademyStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="academyIndex"
    >
      <Stack.Screen
        name="academyIndex"
        component={AcademyScreen}
        options={{ title: translate("navigator.academyTitle") }}
      />
      <Stack.Screen
        name="academyQuestionsIndex"
        component={AcademyQuestionsScreen}
        options={{ title: translate("navigator.academyQuestionsTitle") }}
      />
      <Stack.Screen
        name="academyGlossary"
        component={AcademyGlossaryScreen}
        options={{ title: translate("navigator.academyGlossaryTitle") }}
      />
      <Stack.Screen
        name="academyVideo"
        component={AcademyVideoScreen}
        options={{ title: translate("navigator.academyVideoTitle") }}
      />
      <Stack.Screen
        name="academyVideoCategories"
        component={AcademyVideoCategoriesScreen}
        options={{ title: translate("navigator.academyVideoTitle") }}
      />
      <Stack.Screen
        name="academyNotifications"
        component={AcademyNotoficationsScreen}
        options={{ title: translate("navigator.academyNotificationsTitle") }}
      />
      <Stack.Screen
        name="academyNotificationsDetails"
        component={AcademyNotoficationsDetailsScreen}
        options={{ title: translate("navigator.academyNotificationsTitle") }}
      />
    </Stack.Navigator>
  )
}
