import React, { FC, useState } from "react"
import { ViewStyle, View, TextStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Header, FormRow, TextField, Button, ScreenTitle, Text } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[4],
  flex: 1,
}
const CONTACT_TITLE: ViewStyle = {
  paddingTop: 0,
}
const ROW: ViewStyle = {
  alignItems: "flex-start",
}
const CONTACT_INPUT_WRAPPER: ViewStyle = {
  width: "100%",
}
const SEND_BUTTON_WRAPPER: ViewStyle = {
  flex: 1,
}
const CONTACTS_WRAPPER: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}
const CONTACTS_TITLE: TextStyle = {
  marginRight: spacing[2],
}
const CONTACTS_CONTENT: TextStyle = {
  color: color.primary,
}

export const ProfileContactSupportScreen: FC<ProfileStackScreenProps<"profileContactSupport">> =
  observer(({ navigation }) => {
    const { profileStore, alertStore } = useStores()

    const [question, setQuestion] = useState("")

    const sendQuestion = async () => {
      if (question) {
        const message = `Question or problem: ${question}`
        const result = await profileStore.sendSupport(message)
        if (result) {
          alertStore.show("Success", "Thank you for your question!")
          navigation.navigate("profileIndex")
        } else {
          alertStore.showError("")
        }
      } else {
        alertStore.showError("Please, enter your topic and question")
      }
    }

    const goBack = () => navigation.goBack()

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileContactSupportScreen.title" style={CONTACT_TITLE} />
          <FormRow preset="middle" style={ROW}>
            <TextField
              value={question}
              onChangeText={(text) => setQuestion(text)}
              placeholderTx="profileContactSupportScreen.questionPlaceholder"
              style={CONTACT_INPUT_WRAPPER}
              multiline
              disableLabel
              disableClearButton
              maxLength={900}
              numberOfLines={6}
              textAlignVertical="top"
            />
          </FormRow>
          <View style={SEND_BUTTON_WRAPPER}>
            <Button preset="primary" tx="profileContactSupportScreen.send" onPress={sendQuestion} />
          </View>
          <View style={CONTACTS_WRAPPER}>
            <Text
              preset="default"
              tx="profileContactSupportScreen.socialApps"
              style={CONTACTS_TITLE}
            />
            <Text
              preset="defaultLight"
              tx="profileContactSupportScreen.socialNumber"
              style={CONTACTS_CONTENT}
            />
          </View>
          <View style={CONTACTS_WRAPPER}>
            <Text preset="default" tx="profileContactSupportScreen.email" style={CONTACTS_TITLE} />
            <Text
              preset="defaultLight"
              tx="profileContactSupportScreen.emailLink"
              style={CONTACTS_CONTENT}
            />
          </View>
        </Screen>
      </View>
    )
  })
