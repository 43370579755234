import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { translate } from "../i18n"
import { HoroscopeStackParamList } from "./app-navigation-routes"
import { HoroscopeScreen } from "../screens"

const Stack = createNativeStackNavigator<HoroscopeStackParamList>()

export const HoroscopeStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="horoscopeIndex"
    >
      <Stack.Screen
        name="horoscopeIndex"
        component={HoroscopeScreen}
        options={{ title: translate("navigator.horoscopeTitle") }}
      />
    </Stack.Navigator>
  )
}
