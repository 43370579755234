import React, { FC, useMemo, useRef, useState } from "react"
import { FlatList, View, ViewStyle, TextStyle, useWindowDimensions, Platform } from "react-native"
import { observer } from "mobx-react-lite"
import {
  Screen,
  Header,
  Text,
  Icon,
  Button,
  SkeletonContainer,
  SkeletonView,
} from "../../components"
import { AcademyStackScreenProps } from "../../navigators"
import { spacing, color } from "../../theme"
import { AcademyVideo } from "./components/academy-video"
import { useWindowWidth } from "../../utils/dimension"
import { useStores } from "../../models"

const videoHeader = require("./images/video-header.png")

const FULL: ViewStyle = { flex: 1 }
const VIDEO_ITEM: ViewStyle = {
  position: "relative",
  height: "100%",
  width: "100%",
  backgroundColor: "#000",
}
const VIDEO_VIEWS: ViewStyle = {
  position: "absolute",
  bottom: 0,
  left: 0,
  flexDirection: "column",
  alignItems: "center",
  padding: spacing[5],
}
const VIDEO_VIEWS_ICON: ViewStyle = {
  marginBottom: spacing[2],
}
const VIDEO_VIEWS_TEXT: TextStyle = {
  textShadowColor: "rgba(0, 0, 0, 0.3)",
  textShadowOffset: { width: 1, height: 1 },
  textShadowRadius: 0,
  color: color.palette.white,
}
const VIDEO_DOTS: ViewStyle = {
  position: "absolute",
  top: spacing[4],
  left: spacing[4],
  right: spacing[4],
  flexDirection: "row",
  overflow: "hidden",
}
const VIDEO_DOT: ViewStyle = {
  flex: 1,
  borderRadius: 5,
  minWidth: 8,
  height: 4,
  backgroundColor: color.palette.white,
  marginHorizontal: spacing[1],
  opacity: 0.5,
}
const VIDEO_DOT_ACTIVE: ViewStyle = {
  opacity: 1,
}
const SLIDER_BUTTON_LEFT: ViewStyle = {
  position: "absolute",
  top: "50%",
  left: spacing[4],
}
const SLIDER_BUTTON_RIGHT: ViewStyle = {
  position: "absolute",
  top: "50%",
  right: spacing[4],
}
const SLIDER_BUTTON_NAV: ViewStyle = {
  alignItems: "center",
  paddingVertical: 7,
  width: 32,
  height: 32,
  backgroundColor: color.palette.black500,
  borderRadius: 32,
}

const VIDEO_SKELETON: ViewStyle = {
  width: "100%",
  height: "100%",
  padding: spacing[4],
  backgroundColor: color.palette.black500,
}
const VIDEO_SKELETON_DOTS: ViewStyle = {
  marginBottom: spacing[4],
  width: "100%",
  height: 4,
  borderRadius: spacing[2],
}
const VIDEO_SKELETON_PLAY: ViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginLeft: -32,
  marginTop: -32,
  width: 64,
  height: 64,
  zIndex: 2,
  borderRadius: 32,
}
const VIDEO_SKELETON_VIEWS: ViewStyle = {
  position: "absolute",
  bottom: 0,
  left: 0,
  width: 35,
  height: 26,
  borderRadius: spacing[2],
  margin: spacing[5],
}

export const AcademyVideoScreen: FC<AcademyStackScreenProps<"academyVideo">> = observer(
  ({ navigation, route }) => {
    const { commonStore } = useStores()
    const { categoryIndex, videoIndex } = route.params

    const videoCategory = useMemo(() => {
      return commonStore.academyCategories[categoryIndex]
    }, [commonStore.academyCategories])

    const categoryVideosCount = useMemo(() => {
      return videoCategory?.videos?.length || 0
    }, [videoCategory])

    const goBack = () => navigation.goBack()
    const flatListRef = useRef<FlatList>(null)
    const [activeIndex, setActiveIndex] = useState(videoIndex)
    const [autoplayEnabled, setAutoplayEnabled] = useState(true)

    const screenWidth = useWindowWidth()
    const { height } = useWindowDimensions()
    const [screenHeight, setScreenHeight] = useState(height - 57)

    const onScroll = (event) => {
      const pageNumber = Math.min(
        Math.max(Math.floor(event.nativeEvent.contentOffset.x / screenWidth + 0.5), 0),
        categoryVideosCount,
      )
      if (pageNumber !== activeIndex) {
        setActiveIndex(pageNumber)
      }
    }

    const onMomentumScrollEnd = (event) => {
      const pageNumber = Math.min(
        Math.max(Math.floor(event.nativeEvent.contentOffset.x / screenWidth + 0.5), 0),
        categoryVideosCount,
      )
      if (pageNumber !== activeIndex) {
        setActiveIndex(pageNumber)
      }
    }

    const onPrev = () => {
      flatListRef.current?.scrollToIndex({
        index: Math.max(activeIndex - 1, 0),
      })
    }

    const onNext = () => {
      flatListRef.current?.scrollToIndex({
        index: Math.min(activeIndex + 1, videoCategory.videos.length - 1),
      })
    }

    return (
      <View style={FULL}>
        <Screen
          header={
            <Header
              headerText={videoCategory?.title}
              textImage={videoHeader}
              leftIcon="back"
              onLeftPress={goBack}
            />
          }
        >
          {!videoCategory ? (
            <SkeletonContainer>
              <View style={VIDEO_SKELETON}>
                <SkeletonView style={VIDEO_SKELETON_DOTS} />
                <SkeletonView style={VIDEO_SKELETON_PLAY} />
                <SkeletonView style={VIDEO_SKELETON_VIEWS} />
              </View>
            </SkeletonContainer>
          ) : (
            <View
              style={FULL}
              onLayout={({ nativeEvent }) => {
                const windowHeight = nativeEvent.layout.height
                setScreenHeight(windowHeight)
              }}
            >
              <FlatList
                ref={flatListRef}
                data={videoCategory.videos}
                horizontal={true}
                pagingEnabled={true}
                keyExtractor={(item) => item.id}
                showsHorizontalScrollIndicator={false}
                onScroll={onScroll}
                onMomentumScrollEnd={onMomentumScrollEnd}
                scrollEventThrottle={16}
                getItemLayout={(data, index) => ({
                  index,
                  length: screenWidth,
                  offset: screenWidth * index,
                })}
                initialScrollIndex={videoIndex}
                renderItem={({ item, index }) => (
                  <View style={[VIDEO_ITEM, { width: screenWidth, height: screenHeight }]}>
                    <AcademyVideo
                      srcHls={item.hls}
                      src={item.url}
                      playAllowed={activeIndex === index}
                      autoplayEnabled={autoplayEnabled}
                      setAutoplayEnabled={setAutoplayEnabled}
                    />

                    <View style={VIDEO_VIEWS}>
                      <Icon icon="views" containerStyle={VIDEO_VIEWS_ICON} />
                      <Text style={VIDEO_VIEWS_TEXT} preset="medium" text={item.views} />
                    </View>
                  </View>
                )}
              />

              {Platform.OS === "web" ? (
                <>
                  <View style={SLIDER_BUTTON_LEFT}>
                    <Button preset="unstyled" style={SLIDER_BUTTON_NAV} onPress={onPrev}>
                      <Icon icon="arrowLeft" />
                    </Button>
                  </View>
                  <View style={SLIDER_BUTTON_RIGHT}>
                    <Button preset="unstyled" style={SLIDER_BUTTON_NAV} onPress={onNext}>
                      <Icon icon="arrowRight" />
                    </Button>
                  </View>
                </>
              ) : null}

              <View style={VIDEO_DOTS}>
                {videoCategory?.videos?.map((_item, index) => (
                  <View
                    key={index}
                    style={[VIDEO_DOT, activeIndex === index ? VIDEO_DOT_ACTIVE : {}]}
                  ></View>
                ))}
              </View>
            </View>
          )}
        </Screen>
      </View>
    )
  },
)
