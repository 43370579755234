import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
  WelcomeScreen,
  WelcomeForm1Screen,
  WelcomeForm2Screen,
  WelcomeForm3Screen,
  WelcomeForm4Screen,
  WelcomeForm5Screen,
} from "../screens"
import { translate } from "../i18n"
import { WelcomeStackParamList } from "./app-navigation-routes"

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator<WelcomeStackParamList>()

export const WelcomeStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="welcome"
    >
      <Stack.Screen
        name="welcome"
        component={WelcomeScreen}
        options={{ title: translate("navigator.welcomeTitle") }}
      />
      <Stack.Screen
        name="welcomeForm1"
        component={WelcomeForm1Screen}
        options={{ title: translate("navigator.welcomeTitle") }}
      />
      <Stack.Screen
        name="welcomeForm2"
        component={WelcomeForm2Screen}
        options={{ title: translate("navigator.welcomeTitle") }}
      />
      <Stack.Screen
        name="welcomeForm3"
        component={WelcomeForm3Screen}
        options={{ title: translate("navigator.welcomeTitle") }}
      />
      <Stack.Screen
        name="welcomeForm4"
        component={WelcomeForm4Screen}
        options={{ title: translate("navigator.welcomeTitle") }}
      />
      <Stack.Screen
        name="welcomeForm5"
        component={WelcomeForm5Screen}
        options={{ title: translate("navigator.welcomeTitle") }}
      />
    </Stack.Navigator>
  )
}
