import React, { FC, useCallback, useState } from "react"
import {
  View,
  ViewStyle,
  TextStyle,
  ImageStyle,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { useFocusEffect, useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { translate } from "../../i18n"
import {
  Screen,
  Header,
  Text,
  ScreenTitle,
  AutoImage,
  Icon,
  PurchaseModal,
  Pills,
  IPillData,
  Button,
} from "../../components"
import { ProfileStackScreenProps, TabsParamList } from "../../navigators"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { AvailableReports, ISubscriptionInfoBonuses } from "./components/available-reports"
import { ISubscriptionInfoData, SubscriptionHistory } from "./components/subscription-history"
import { SubscriptionDuration } from "../../models/profile-store/profile-store"

const subscriptionBg = require("./images/subscription-bg.png")

const FULL: ViewStyle = { flex: 1 }
const FLEX: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
}
const TITLE: ViewStyle = {
  paddingTop: 0,
  paddingHorizontal: spacing[4],
}
const SUBSCRIPTION: ViewStyle = {
  position: "relative",
  marginHorizontal: spacing[4],
  marginBottom: spacing[4],
  borderRadius: 16,
  overflow: "hidden",
}

const SUBSCRIPTION_WRAPPER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: spacing[5],
}
const SUBSCRIPTION_BG: ImageStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
}
const SUBSCRIPTION_TITLE: TextStyle = {
  marginBottom: spacing[1],
}
const BUTTON_CALCULATE: ViewStyle = {}
const BUTTON_CALCULATE_TEXT: TextStyle = { color: color.palette.inputColor }

const data: IPillData[] = [
  {
    id: "1",
    name: "Subscription",
  },
  {
    id: "2",
    name: "Available reports",
  },
]

export const ProfileSubscriptionInfoScreen: FC<ProfileStackScreenProps<"profileSubscriptionInfo">> =
  observer(({ navigation, route }) => {
    const { profileStore } = useStores()
    const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()
    const goBack = () =>
      route.params?.fromScreen
        ? navigation.getParent().navigate(route.params.fromScreen)
        : navigation.goBack()

    const [purchaseVisible, setPurchaseVisible] = useState(false)
    const [activeTab, setActiveTab] = useState("1")
    const [bonuses, setBonuses] = useState<ISubscriptionInfoBonuses[]>()
    const [subscriptions, setSubscriptions] = useState<ISubscriptionInfoData[]>()
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const goToExtraOrders = () => {
      rootNavigation.navigate("home", {
        screen: "extraOrderStack",
        params: { screen: "extraOrderIndex" },
      })
    }

    const goToSoulMate = () => {
      rootNavigation.navigate("soulMate", {
        screen: "soulMateIndex",
      })
    }

    const goToSubscriptionDetails = (subscriptionId: string) => {
      navigation.navigate("profileSubscriptionDetails", {
        subscriptionId,
      })
    }

    const filterTab = (data: IPillData) => {
      setActiveTab(data.id as string)
    }

    useFocusEffect(
      useCallback(() => {
        ;(async () => {
          setLoading(true)
          setErrorMessage("")
          const result = await profileStore.getMySubscriptionsList()

          if (result) {
            setErrorMessage(!result?.data?.isSuccess ? result?.message : "")
            setBonuses(result.data.data.bonuses)
            setSubscriptions(result.data.data.subscriptions)
            setLoading(false)
          }
        })()
      }, []),
    )

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          {loading ? (
            <ActivityIndicator size="large" color={color.primary} />
          ) : (
            <>
              <ScreenTitle
                headerText={`${translate("profileSubscriptionInfoScreen.thankYou")} ${
                  profileStore.firstName
                }!`}
                style={TITLE}
              />

              {errorMessage?.length > 0 ? (
                <TouchableOpacity onPress={() => setPurchaseVisible(true)} style={SUBSCRIPTION}>
                  <AutoImage source={subscriptionBg} resizeMode="cover" style={SUBSCRIPTION_BG} />
                  <View style={SUBSCRIPTION_WRAPPER}>
                    <View style={FLEX}>
                      <Text preset="h2" text={errorMessage} style={SUBSCRIPTION_TITLE} />
                    </View>
                    <View>
                      <Icon icon="chevronCircleRight" svgProps={{ fill: color.palette.white }} />
                    </View>
                  </View>
                </TouchableOpacity>
              ) : null}

              {profileStore.subscriptionDuration !== SubscriptionDuration.Annual && (
                <View style={SUBSCRIPTION}>
                  <AutoImage source={subscriptionBg} resizeMode="cover" style={SUBSCRIPTION_BG} />
                  <View style={SUBSCRIPTION_WRAPPER}>
                    <View style={FLEX}>
                      <Text preset="h4" tx="profileSubscriptionInfoScreen.exploreMore" />
                    </View>
                    <Button
                      preset="primary"
                      style={BUTTON_CALCULATE}
                      onPress={() => setPurchaseVisible(true)}
                    >
                      <Text
                        preset="h4"
                        style={BUTTON_CALCULATE_TEXT}
                        tx="profileSubscriptionInfoScreen.purchaseNow"
                      />
                    </Button>
                  </View>
                </View>
              )}

              {bonuses?.length > 0 || subscriptions?.length > 0 ? (
                <>
                  <View>
                    <Pills data={data} activeId={activeTab} onPress={filterTab} />
                  </View>
                  {activeTab === "1" ? (
                    <SubscriptionHistory
                      goToSubscriptionDetails={goToSubscriptionDetails}
                      subscriptions={subscriptions}
                    />
                  ) : (
                    <AvailableReports
                      bonuses={bonuses}
                      goToExtraOrders={goToExtraOrders}
                      goToSoulMate={goToSoulMate}
                    />
                  )}
                </>
              ) : null}
            </>
          )}
          <PurchaseModal
            visible={purchaseVisible}
            closeModal={() => {
              setPurchaseVisible(false)
            }}
          />
        </Screen>
      </View>
    )
  })
