import React from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import { StepperProps } from "./stepper.props"
import { Text } from "../text/text"
import { Button } from "../button/button"
import { Icon } from "../icon/icon"
import { color, spacing } from "../../theme"
import { translate } from "../../i18n/"

const ROOT: ViewStyle = {
  marginBottom: spacing[6],
  padding: spacing[4],
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
}
const CLOSE_BUTTON: ViewStyle = {
  position: "absolute",
  top: 14,
  left: 8,
  zIndex: 10,
}
const TEXT_CONTAINER: ViewStyle = {
  flex: 1,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}
const DOTS_WRAPPER: ViewStyle = {
  flexDirection: "row",
}
const DOT: ViewStyle = {
  width: 5,
  height: 5,
  marginRight: spacing[2],
  backgroundColor: color.palette.moderateBlue,
  borderRadius: 100,
}
const DOT_ACTIVE: ViewStyle = {
  width: 20,
  height: 5,
  marginRight: spacing[2],
  backgroundColor: color.palette.brightTurquoise,
  borderRadius: 100,
}
const TEXT: TextStyle = {
  textAlign: "center",
  marginRight: spacing[3],
}

const stepDots = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]

export function Stepper(props: StepperProps) {
  const { stepText, stepTx, onClosePress, step } = props
  const text = stepText || (stepTx && translate(stepTx)) || ""
  return (
    <View style={ROOT}>
      <Button preset="unstyled" onPress={onClosePress} style={CLOSE_BUTTON}>
        <Icon icon="close" />
      </Button>
      <View style={TEXT_CONTAINER}>
        <Text preset="small" text={text} style={TEXT} />
        <View style={DOTS_WRAPPER}>
          {stepDots.map((dot) => (
            <View key={dot.id} style={[DOT, step === dot.id && DOT_ACTIVE]} />
          ))}
        </View>
      </View>
    </View>
  )
}
