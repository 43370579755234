import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { CommonResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { ApiRoutes } from "./api-config"

export class CommonApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async codeOfSamsaraBlocks(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.CODE_OF_SAMSARA_BLOCKS,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async codeOfSamsaraSingleBlock(orderId: string, id: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.CODE_OF_SAMSARA_SINGLE_BLOCK(orderId, id),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async unlockBlock(id: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.CODE_OF_SAMSARA_UNLOCK_BLOCK(id),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async canUnlockBlock(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.CODE_OF_SAMSARA_BLOCK_CAN_UNLOCK,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async introBlocks(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(ApiRoutes.INTRO_BLOCKS)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async introSingleBlock(id: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.INTRO_SINGLE_BLOCK(id),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async academyGlossaries(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(ApiRoutes.ACADEMY_GLOSSARIES)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async academyVideos(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(ApiRoutes.ACADEMY_VIDEOS)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async academyQnACategories(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.ACADEMY_QNACATEGORIES,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async academyNotificationHasNew(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.ACADEMY_NOTIFICATION_HAS_NEW,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async academyNotificationMy(skip: number): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.ACADEMY_NOTIFICATION_MY,
        {
          skip,
        },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async academyNotificationById(id: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.ACADEMY_NOTIFICATION_GET_BY_ID(id),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async academyNotificationMarkRead(id: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.ACADEMY_NOTIFICATION_MARK_READ(id),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async feedback(
    contentBlockId: string,
    isHelpful: boolean,
    comment: string,
  ): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(ApiRoutes.FEEDBACK, {
        contentBlockId,
        isHelpful,
        comment,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async sendGiftCardCode(code: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.SEND_GIFT_CARD_CODE,
        {
          code,
        },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getUserAgreement(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(ApiRoutes.TERMS_USER_AGREEMENT)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getPrivacyPolicy(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(ApiRoutes.TERMS_PRIVACY_POLICY)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getCitiesAutocomplete(q: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(ApiRoutes.DICTIONARY_CITIES, {
        q,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getPersonalHoroscope(): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.PERSONAL_HOROSCOPE_RESULT,
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getPersonalHoroscopeByOrderId(orderId: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.PERSONAL_HOROSCOPE_BY_ORDER_ID(orderId),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getMutualHoroscopeByOrderId(orderId: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.MUTUAL_HOROSCOPE_BY_ORDER_ID(orderId),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
