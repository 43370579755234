import React, { useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Button, FormRow, Icon, Text, TextField } from "../../../components"
import { color, spacing } from "../../../theme"
import { useStores } from "../../../models"
import { FormulaHeading } from "./components/formula-heading"
import { FormulaCard } from "./components/formula-card"
import { FormulaSolutionSteps, ISolutionStepItem } from "./components/formula-solutions-steps"
import { translate } from "../../../i18n"

const MainImg = require("./images/meaningHouseNumberMain.png")
const CardImg = require("./images/meaningHouseNumberCard.png")

interface IFormula {
  key: string
  text: string
}

export const MeaningBehindHouseNumberFormula = observer(() => {
  const { formulaStore } = useStores()
  const [houseNumber, setHouseNumber] = useState("")
  const [calculatedResult, setCalculatedResult] = useState<IFormula>()
  const [solutionSteps, setSolutionSteps] = useState<ISolutionStepItem[]>([])

  const calculateKey = (number: string): number => {
    let key = number
      .split("")
      .map((n: string) => Number(n) || 0)
      .reduce((a: number, b: number) => a + b, 0)

    if (Number(key) > 9) {
      key = calculateKey(key.toString())
    }

    return key
  }

  const houseNumberCalculate = async () => {
    if (isNaN(+houseNumber)) {
      return
    }

    const result = calculateKey(houseNumber).toString()

    const meaningOfHouseNumber = await formulaStore.getHouseNumberMeaning(result)

    setSolutionSteps(calculateSteps())
    setCalculatedResult({
      key: result,
      text: meaningOfHouseNumber.htmlContent,
    })
  }

  const calculateSteps = () => {
    const steps: ISolutionStepItem[] = []

    steps.push({
      numbers: houseNumber.split("").join(" + "),
      type: "number of house or apartment",
    })

    return steps
  }

  return (
    <View>
      {!calculatedResult ? (
        <>
          <FormulaHeading
            title={"meaningBehindHouseNumberFormulaScreen.title"}
            description={"meaningBehindHouseNumberFormulaScreen.description"}
            image={MainImg}
          />

          {/* FORMS */}
          <View style={FORM_CONTAINER}>
            <FormRow preset="middle">
              <TextField
                value={houseNumber}
                onChangeText={(text) => setHouseNumber(text.replace(/[^0-9]/g, ""))}
                placeholderTx="meaningBehindHouseNumberFormulaScreen.placeholder"
                style={NUMBER_INPUT_WRAPPER}
              />
            </FormRow>
            <Button onPress={houseNumberCalculate}>
              <Text
                preset="h4"
                style={BUTTON_CALCULATE_TEXT}
                tx="freeFormulasButton.calculateNow"
              />
            </Button>
          </View>
        </>
      ) : (
        <>
          <FormulaCard
            title={translate("meaningBehindHouseNumberFormulaScreen.cardTitle")}
            htmlContent={calculatedResult.text}
            image={CardImg}
          />

          <FormulaSolutionSteps result={calculatedResult.key} steps={solutionSteps} />

          <Button preset="outline" onPress={() => setCalculatedResult(null)} style={BUTTON_RELOAD}>
            <Icon icon={"reload"} containerStyle={RELOAD_ICON} />
            <Text preset="h4" tx="freeFormulasButton.newCalculate" style={RELOAD_TEXT} />
          </Button>
        </>
      )}
    </View>
  )
})

const FORM_CONTAINER: ViewStyle = { marginTop: spacing[7] }
const BUTTON_CALCULATE_TEXT: TextStyle = { color: color.palette.black }
const BUTTON_RELOAD: ViewStyle = {
  justifyContent: "center",
  flexDirection: "row",
  backgroundColor: color.palette.black500,
  marginVertical: spacing[6],
}
const RELOAD_ICON: ViewStyle = { marginRight: spacing[2] }
const RELOAD_TEXT: TextStyle = { color: color.palette.brightTurquoise }
const NUMBER_INPUT_WRAPPER: TextStyle = {
  width: "100%",
  color: color.palette.lightSteelBlue,
  opacity: 0.7,
}
