import { Instance, SnapshotOut, types, flow, SnapshotIn } from "mobx-state-tree"
import { withEnvironment } from "../extensions/with-environment"
import { FormulaApi } from "../../services/api/formula-api"

export const FormulaStoreModel = types
  .model("FormulaStore")
  .props({})
  .extend(withEnvironment)
  .actions((self) => ({
    getMeaningOfName: flow(function* (index: string) {
      const formulaApi = new FormulaApi(self.environment.api)
      const result = yield formulaApi.getMeaningOfName(index)

      if (result.kind === "ok") {
        return result.info
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getMeaningOfBirthdate: flow(function* (index: string) {
      const formulaApi = new FormulaApi(self.environment.api)
      const result = yield formulaApi.getMeaningOfBirthdate(index)

      if (result.kind === "ok") {
        return result.info
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getProfessionByBirthdate: flow(function* (index: string) {
      const formulaApi = new FormulaApi(self.environment.api)
      const result = yield formulaApi.getProfessionByBirthdate(index)

      if (result.kind === "ok") {
        return result.info
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getCountryOfPastLife: flow(function* (index: string) {
      const formulaApi = new FormulaApi(self.environment.api)
      const result = yield formulaApi.getCountryOfPastLife(index)

      if (result.kind === "ok") {
        return result.info
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getHouseNumberMeaning: flow(function* (index: string) {
      const formulaApi = new FormulaApi(self.environment.api)
      const result = yield formulaApi.getHouseNumberMeaning(index)

      if (result.kind === "ok") {
        return result.info
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))

export interface FormulaStore extends Instance<typeof FormulaStoreModel> {}
export interface FormulaStoreSnapshotOut extends SnapshotOut<typeof FormulaStoreModel> {}
export interface FormulaStoreSnapshotIn extends SnapshotIn<typeof FormulaStoreModel> {}
export const createFormulaStoreDefaultModel = () => types.optional(FormulaStoreModel, {})
