import React, { useCallback, useRef, useState } from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, Keyboard } from "react-native"
import { Text } from "../text/text"
import { Button } from "../button/button"
import { TextField } from "../text-field/text-field"
import { FormRow } from "../form-row/form-row"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"

export interface FeedbackProps {
  contentId: string
  isExpanded: boolean
  setIsExpanded: (value: boolean | null) => void
}

const IS_SENT_CONTAINER: ViewStyle = {
  width: "100%",
  alignItems: "center",
  paddingVertical: spacing[5],
  marginBottom: spacing[4],
}
const CONTAINER: ViewStyle = {
  marginVertical: spacing[5],
  padding: spacing[4],
  backgroundColor: color.palette.black500,
  borderRadius: 16,
}
const TITLE: ViewStyle = {
  flexDirection: "row",
  marginBottom: spacing[2],
}
const SUBTITLE: TextStyle = {
  marginBottom: spacing[3],
}
const INTERACT_BUTTONS_ROW: ViewStyle = {
  flexDirection: "row",
}
const INTERACT_BUTTON: ViewStyle = {
  flexDirection: "row",
  paddingVertical: spacing[2],
  paddingHorizontal: spacing[3],
  borderRadius: 30,
  borderWidth: 2,
  borderColor: color.palette.brightTurquoise,
}
const INTERACT_BUTTON_FIRST: ViewStyle = {
  marginRight: spacing[4],
}
const INTERACT_BUTTON_ACTIVE: ViewStyle = {
  backgroundColor: color.palette.brightTurquoise,
}
const INTERACT_BUTTON_TEXT: TextStyle = {
  color: color.palette.brightTurquoise,
}
const INTERACT_BUTTON_TEXT_ACTIVE: TextStyle = {
  color: color.palette.black500,
}
const INPUT_WRAPPER: ViewStyle = {
  marginTop: spacing[4],
  width: "100%",
}
const INPUT: TextStyle = {
  fontSize: 14,
}
const COLLAPSE_BLOCK: ViewStyle = {
  overflow: "hidden",
}
const SEND_BUTTON: ViewStyle = {
  width: "40%",
}

export function Feedback(props: FeedbackProps) {
  const { contentId, isExpanded, setIsExpanded } = props

  const { commonStore } = useStores()

  const feedbackRefHeight = useRef<any>()
  const [message, setMessage] = useState("")
  const [isHelpful, setIsHelpful] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const onYes = () => {
    if (isHelpful === false) {
      setIsHelpful(true)
      setIsExpanded(true)
    } else {
      setIsExpanded(!isExpanded)
    }
  }

  const onNo = () => {
    if (isHelpful === true) {
      setIsHelpful(false)
      setIsExpanded(true)
    } else {
      setIsExpanded(!isExpanded)
    }
  }

  const isUIHelpful = useCallback(
    (value: boolean) => {
      return isExpanded && value === isHelpful
    },
    [isExpanded, isHelpful],
  )

  const sendFeedback = () => {
    commonStore.sendFeedback(contentId, isHelpful, message)
    setIsSent(true)
    Keyboard.dismiss()
  }

  return (
    <>
      {isSent ? (
        <View style={IS_SENT_CONTAINER}>
          <Text preset="h4" tx="feedback.thankYou" />
        </View>
      ) : (
        <View style={CONTAINER}>
          <View style={TITLE}>
            <Text text="96% " preset="default" />
            <Text tx="feedback.title" preset="default" />
          </View>
          <Text tx="feedback.subtitle" preset="h4" style={SUBTITLE} />
          <View style={INTERACT_BUTTONS_ROW}>
            <TouchableOpacity onPress={onYes}>
              <View
                style={[
                  INTERACT_BUTTON,
                  INTERACT_BUTTON_FIRST,
                  isUIHelpful(true) && INTERACT_BUTTON_ACTIVE,
                ]}
              >
                <Text
                  tx="feedback.yes"
                  style={isUIHelpful(true) ? INTERACT_BUTTON_TEXT_ACTIVE : INTERACT_BUTTON_TEXT}
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={onNo}>
              <View style={[INTERACT_BUTTON, isUIHelpful(false) && INTERACT_BUTTON_ACTIVE]}>
                <Text
                  tx="feedback.no"
                  style={isUIHelpful(false) ? INTERACT_BUTTON_TEXT_ACTIVE : INTERACT_BUTTON_TEXT}
                />
              </View>
            </TouchableOpacity>
          </View>
          <View
            ref={feedbackRefHeight}
            style={[
              COLLAPSE_BLOCK,
              {
                height: isExpanded ? feedbackRefHeight.current.scrollHeight : 0,
              } as ViewStyle,
            ]}
          >
            <FormRow preset="middle">
              <TextField
                value={message}
                style={INPUT_WRAPPER}
                inputStyle={INPUT}
                placeholderTx="feedback.placeholder"
                textAlignVertical="top"
                multiline
                disableLabel
                disableClearButton
                maxLength={200}
                numberOfLines={6}
                onChangeText={setMessage}
              />
            </FormRow>
            <Button tx="feedback.send" style={SEND_BUTTON} onPress={sendFeedback} />
          </View>
        </View>
      )}
    </>
  )
}
