import React, { FC } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { SoulMateStackScreenProps } from "../../navigators"
import { CalculationType } from "../extra-order/components/calculation-type"

export const SoulMateCompatibilityScreen: FC<SoulMateStackScreenProps<"soulMateCompatibility">> =
  observer(({ navigation }) => {
    return (
      <View style={FULL}>
        <CalculationType
          goBack={() => navigation.goBack()}
          navigateToCalculation={() => navigation.navigate("soulMateCompatibilityCalculation")}
        />
      </View>
    )
  })

const FULL: ViewStyle = { flex: 1 }
