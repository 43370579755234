import React, { useCallback, useEffect, useRef } from "react"
import { ViewStyle, TextStyle, FlatList, ImageStyle, View, Pressable } from "react-native"
import { Text } from "../../../../components"
import { color, spacing } from "../../../../theme"

interface IResultTabsData {
  id: string
  title: string
  emoji: string
}

interface ResultTabsProps {
  data: IResultTabsData[]
  activeTabId: string
  onPress(tabId: string): void
}

export function ResultTabs(props: ResultTabsProps) {
  const { data, activeTabId, onPress } = props

  const flatListRef = useRef<FlatList>(null)

  const scrollToIndex = useCallback(
    (index) => {
      flatListRef.current?.scrollToIndex({ animated: true, index })
    },
    [flatListRef],
  )

  useEffect(() => {
    const index = data.findIndex((d) => d.id === activeTabId)
    if (index >= 0) {
      scrollToIndex(index)
    }
  }, [activeTabId, flatListRef])

  return (
    <FlatList
      style={TABS_LIST}
      contentContainerStyle={TABS_LIST_CONTAINER}
      ref={flatListRef}
      onScrollToIndexFailed={() => []}
      data={data}
      keyExtractor={(item) => item.id}
      horizontal
      renderItem={({ item }) => (
        <Pressable
          onPress={() => {
            onPress(item.id)
          }}
          style={[TAB_ITEM, activeTabId === item.id && SELECTED_TAB_ITEM]}
        >
          <Text text={item.emoji} style={TAB_ICON} />
          <Text
            preset="mediumBold"
            numberOfLines={2}
            style={[TAB_TITLE, activeTabId === item.id && SELECTED_TAB_TITLE]}
          >
            {item.title}
          </Text>
          {activeTabId === item.id && <View style={TAB_ITEM_TRIANGLE}></View>}
        </Pressable>
      )}
    />
  )
}

const TABS_LIST: ViewStyle = {
  flexGrow: 0,
  marginVertical: spacing[2],
  paddingHorizontal: spacing[4],
  minHeight: 80 + 3 * spacing[2],
}
const TABS_LIST_CONTAINER: ViewStyle = {
  paddingBottom: spacing[4],
}
const TAB_ITEM: ViewStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: 116,
  minWidth: 70,
  marginRight: spacing[4],
  padding: spacing[2],
  backgroundColor: color.palette.black,
  borderWidth: 2,
  borderColor: color.palette.greyBorder,
  borderRadius: 8,
}
const SELECTED_TAB_ITEM: ViewStyle = {
  borderColor: "transparent",
  backgroundColor: color.palette.brightTurquoise,
}
const TAB_ICON: ImageStyle = {
  marginBottom: spacing[1],
}
const TAB_TITLE: TextStyle = {
  lineHeight: 16,
  color: color.palette.lightSteelBlue,
  textAlign: "center",
}
const SELECTED_TAB_TITLE: TextStyle = {
  color: color.palette.black500,
}
const TAB_ITEM_TRIANGLE: ViewStyle = {
  position: "absolute",
  width: 20,
  height: 20,
  backgroundColor: color.palette.brightTurquoise,
  borderBottomRightRadius: 5,
  bottom: -10,
  left: "auto",
  transform: [{ rotate: "45deg" }],
}
