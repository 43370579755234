import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SoulMate = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#C6D1E1" {...props}>
    <Path d="m12.82 5.58-.82.822-.824-.824a5.375 5.375 0 0 0-7.601 7.602l7.895 7.895a.75.75 0 0 0 1.06 0l7.902-7.897a5.376 5.376 0 0 0-5.866-8.766 5.38 5.38 0 0 0-1.746 1.167v.001Z" />
  </Svg>
)

export default SoulMate
