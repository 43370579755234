import React from "react"
import { useWindowDimensions } from "react-native"
import RenderHtml, { defaultSystemFonts, MixedStyleDeclaration } from "react-native-render-html"
import { texts } from "../../styles"
import { color, typography } from "../../theme"

export interface RenderHtmlWrapperProps {
  html: string
}

const baseStyle = {
  fontFamily: typography.primary,
  fontSize: 14,
  lineHeight: 20,
  color: color.text,
}

const tagsStyles = {
  h1: texts.H2 as MixedStyleDeclaration,
  h2: texts.H2 as MixedStyleDeclaration,
  h3: texts.H3 as MixedStyleDeclaration,
  h4: texts.H4 as MixedStyleDeclaration,
  h5: texts.H4 as MixedStyleDeclaration,
  h6: texts.H4 as MixedStyleDeclaration,

  bold: texts.BOLD as MixedStyleDeclaration,
  strong: texts.BOLD as MixedStyleDeclaration,
  b: texts.BOLD as MixedStyleDeclaration,

  a: {
    color: color.palette.white,
    textDecorationColor: color.palette.white,
  },

  ul: {
    paddingLeft: 10,
  },
  li: {
    paddingLeft: 6,
  },
}

const systemFonts = [typography.primary, typography.secondary, ...defaultSystemFonts]

export function RenderHtmlWrapper(props: RenderHtmlWrapperProps) {
  const { html, ...rest } = props
  const { width } = useWindowDimensions()

  return (
    <RenderHtml
      contentWidth={width}
      source={{ html }}
      systemFonts={systemFonts}
      baseStyle={baseStyle}
      tagsStyles={tagsStyles}
      {...rest}
    />
  )
}
