import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ArrowLeft = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="#00E6DB" {...props}>
    <Path d="m7 3 .715.696L3.925 7.5H14v1H3.925l3.79 3.787L7 13 2 8l5-5Z" />
  </Svg>
)

export default ArrowLeft
