import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ArrowRight = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="#00E6DB" {...props}>
    <Path d="m9 3-.715.696 3.79 3.804H2v1h10.075l-3.79 3.787L9 13l5-5-5-5Z" />
  </Svg>
)

export default ArrowRight
