import { flow } from "mobx"
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { ExtraOrderApi } from "../../services/api/extra-order-api"
import { withEnvironment } from "../extensions/with-environment"
import { withRootStore } from "../extensions/with-root-store"
import { PlaceOfBirthStore } from "../profile-store/place-of-birth-model"
import { ExtraOrderUserModel } from "./extra-order"

export enum ExtraOrderUserTypeEnum {
  User1 = "user1",
  User2 = "user2",
}

export enum OrderTypeEnum {
  extra = "extraOrder",
  soulMate = "soulmMate",
}

export enum TemplateTypeEnum {
  CodeOfSamsara = "CodeOfSamsara",
  SoulMate = "SoulMate",
}

export const ExtraOrderStoreModel = types
  .model("ExtraOrderStore")
  .props({
    title: types.optional(types.string, ""),
    orderType: types.optional(types.string, ""),
    numOfPersons: types.optional(types.integer, 1),
    reportProduct: types.optional(types.string, ""),
    reportPrice: types.optional(types.string, ""),
    useBonus: types.optional(types.boolean, false),
    compatibilityType: types.optional(types.string, ""),
    user1: types.optional(ExtraOrderUserModel, {}),
    user2: types.optional(ExtraOrderUserModel, {}),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .actions((self) => ({
    init: (title: string, compatibilityType: string, numOfPersons: number) => {
      self.title = title
      self.numOfPersons = numOfPersons

      if (compatibilityType !== self.compatibilityType) {
        self.compatibilityType = compatibilityType

        if (self.orderType === OrderTypeEnum.soulMate) {
          self.rootStore.extraOrderStore.updateUserCardValues(
            self.rootStore.profileStore.firstName,
            self.rootStore.profileStore.lastName,
            self.rootStore.profileStore.gender,
            self.rootStore.profileStore.dateOfBirth,
            self.rootStore.profileStore.timeOfBirth,
            self.rootStore.profileStore.placeOfBirth,
            ExtraOrderUserTypeEnum.User1,
          )
        } else {
          self.rootStore.extraOrderStore.clearUserCardValues(ExtraOrderUserTypeEnum.User1)
        }
        self.rootStore.extraOrderStore.clearUserCardValues(ExtraOrderUserTypeEnum.User2)
        self.rootStore.extraOrderStore.clearSelectedReport()
      }
    },
    setReport: (reportProduct: string, reportPrice: string, useBonus: boolean) => {
      self.reportProduct = reportProduct
      self.reportPrice = reportPrice
      self.useBonus = useBonus
    },
    setOrder: (type: OrderTypeEnum) => {
      self.orderType = type
    },
  }))
  .actions((self) => ({
    updateUserCardValues: (
      firstName: string,
      lastName: string,
      gender: string,
      dateOfBirth: string,
      timeOfBirth: string,
      placeOfBirth: PlaceOfBirthStore,
      userType: ExtraOrderUserTypeEnum,
    ) => {
      self[userType].firstName = firstName.trim()
      self[userType].lastName = lastName.trim()
      self[userType].gender = gender
      self[userType].dateOfBirth = dateOfBirth
      self[userType].timeOfBirth = timeOfBirth

      const placeOfBirthClone = placeOfBirth?.name ? { ...placeOfBirth } : null
      self[userType].placeOfBirth = placeOfBirthClone
    },
    clearUserCardValues: (userType: ExtraOrderUserTypeEnum) => {
      self[userType].firstName = ""
      self[userType].lastName = ""
      self[userType].gender = ""
      self[userType].dateOfBirth = ""
      self[userType].timeOfBirth = ""
      self[userType].placeOfBirth = null
    },
    isUserValid: (userType: ExtraOrderUserTypeEnum) => {
      const user = self[userType]

      if (!user.firstName.trim() || !user.lastName.trim() || !user.dateOfBirth || !user.gender) {
        return false
      }
      return true
    },
    clearSelectedReport: () => {
      self.reportProduct = ""
      self.reportPrice = ""
      self.useBonus = false
    },
  }))
  .actions((self) => ({
    getCalculationTypes: flow(function* () {
      const extraOrdersApi = new ExtraOrderApi(self.environment.api)
      const result = yield extraOrdersApi.getAvailable(self.orderType === OrderTypeEnum.soulMate)

      if (result.kind === "ok") {
        const calculationTypes = result.data.data
        return calculationTypes
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    orderCalculation: flow(function* () {
      const payload = [
        {
          firstName: self[ExtraOrderUserTypeEnum.User1].firstName,
          lastName: self[ExtraOrderUserTypeEnum.User1].lastName,
          gender: self[ExtraOrderUserTypeEnum.User1].gender,
          birthDate: self[ExtraOrderUserTypeEnum.User1].dateOfBirth,
          birthTime: self[ExtraOrderUserTypeEnum.User1].timeOfBirth,
          placeOfBirth: self[ExtraOrderUserTypeEnum.User1].placeOfBirth,
        },
      ]
      if (self[ExtraOrderUserTypeEnum.User2].firstName) {
        payload.push({
          firstName: self[ExtraOrderUserTypeEnum.User2].firstName,
          lastName: self[ExtraOrderUserTypeEnum.User2].lastName,
          gender: self[ExtraOrderUserTypeEnum.User2].gender,
          birthDate: self[ExtraOrderUserTypeEnum.User2].dateOfBirth,
          birthTime: self[ExtraOrderUserTypeEnum.User2].timeOfBirth,
          placeOfBirth: self[ExtraOrderUserTypeEnum.User2].placeOfBirth,
        })
      }

      const extraOrdersApi = new ExtraOrderApi(self.environment.api)
      const result = yield extraOrdersApi.submitOrder(self.reportProduct, self.useBonus, payload)

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getCodeOfSamsaraDemoBlocks: flow(function* () {
      const extraOrdersApi = new ExtraOrderApi(self.environment.api)
      const result = yield extraOrdersApi.codeOfSamsaraDemoBlocks()

      if (result.kind === "ok") {
        const codeOfSamaraData = result.data.data
        const categories = codeOfSamaraData.categories.map((category) => {
          return {
            id: category.name,
            name: category.description,
          }
        })

        categories.unshift({ id: "numerology.available-reports", name: "Available reports" })

        const blocksData = []
        codeOfSamaraData.categories.forEach((category) =>
          category.blocks.map((block) =>
            blocksData.push({
              ...block,
              category: category.name,
            }),
          ),
        )

        return {
          orderId: codeOfSamaraData.orderId,
          title: codeOfSamaraData.title,
          fullName: codeOfSamaraData.fullName,
          categories,
          blocksData,
        }
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getCodeOfSamsaraBlocks: flow(function* (orderId: string) {
      const extraOrdersApi = new ExtraOrderApi(self.environment.api)
      const result = yield extraOrdersApi.codeOfSamsaraBlocks(orderId)

      if (result.kind === "ok") {
        const codeOfSamaraData = result.data.data
        const categories = codeOfSamaraData.categories.map((category) => {
          return {
            id: category.name,
            name: category.description,
          }
        })

        categories.unshift({ id: "numerology.available-reports", name: "Available reports" })

        const blocksData = []
        codeOfSamaraData.categories.forEach((category) =>
          category.blocks.map((block) =>
            blocksData.push({
              ...block,
              category: category.name,
            }),
          ),
        )

        return {
          orderId: codeOfSamaraData.orderId,
          title: codeOfSamaraData.title,
          fullName: codeOfSamaraData.fullName,
          categories,
          blocksData,
        }
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getResult: flow(function* (orderId: string) {
      const extraOrdersApi = new ExtraOrderApi(self.environment.api)
      const result = yield extraOrdersApi.getResult(orderId)

      if (result.kind === "ok") {
        return result.data.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    getHistory: flow(function* () {
      const extraOrdersApi = new ExtraOrderApi(self.environment.api)
      const result = yield extraOrdersApi.getHistory(self.orderType === OrderTypeEnum.soulMate)

      if (result.kind === "ok") {
        return result.data?.data
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))

type ExtraOrderStoreType = Instance<typeof ExtraOrderStoreModel>
export interface ExtraOrderStore extends ExtraOrderStoreType {}
type ExtraOrderStoreSnapshotType = SnapshotOut<typeof ExtraOrderStoreModel>
export interface ExtraOrderStoreSnapshot extends ExtraOrderStoreSnapshotType {}
export const createExtraOrderStoreDefaultModel = () => types.optional(ExtraOrderStoreModel, {})
