import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ArrowTop = (props: SvgProps) => (
  <Svg width={17} height={18} viewBox="0 0 17 18" fill="#fff" {...props}>
    <Path d="M15.716 7.296a1 1 0 1 1-1.424 1.403L9 3.33V17a1 1 0 1 1-2 0V3.335L1.715 8.7A1 1 0 0 1 .291 7.297L7.113.372a1.25 1.25 0 0 1 1.78 0l6.823 6.925v-.001Z" />
  </Svg>
)

export default ArrowTop
