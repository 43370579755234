import { observer } from "mobx-react-lite"
import React, { useEffect, useRef, useState } from "react"
import {
  ActivityIndicator,
  Animated,
  Modal,
  Platform,
  Pressable,
  TextStyle,
  View,
  ViewStyle,
} from "react-native"
import { Alert } from "../alert/alert.web"
import { Button } from "../button/button"
import { Text } from "../text/text"
import { PurchaseModal } from "../purchase-modal/purchase-modal"
import { useStores } from "../../models"
import { color, spacing } from "../../theme"
import { useWindowWidth } from "../../utils/dimension"

interface UnlockBlockModalProps {
  visible: boolean
  blockTitle: string
  blockId: string
  closeModal(): void
  onUnlock?: (blockId: string) => void
}

export const UnlockBlockModal = observer((props: UnlockBlockModalProps) => {
  const { visible, blockTitle, blockId, closeModal, onUnlock } = props

  const screenWidth = useWindowWidth()

  const { commonStore } = useStores()
  const [purchaseVisible, setPurchaseVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [numberOfUnlocks, setNumberOfUnlocks] = useState(0)
  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }

  const fadeOut = () => fadeAnim.setValue(0)

  const getNumberOfUnlocks = async () => {
    setLoading(true)
    const result = await commonStore.getNumberOfBlocksToUnlock()
    if (result) {
      setNumberOfUnlocks(result.numOfBlocks)
      if (result.numOfBlocks === 0) {
        // setTimeout to fix IOS bug
        setTimeout(() => {
          setPurchaseVisible(true)
        }, 1000)
        closeModal()
      } else {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (visible) {
      getNumberOfUnlocks()
      fadeIn()
    } else {
      fadeOut()
    }
  }, [visible])

  const unlock = async () => {
    const result = await commonStore.unlockBlock(blockId)
    if (result.isSuccess) {
      onUnlock?.(blockId)
      closeModal()
    } else {
      Alert.alert("Error", result.message)
    }
  }

  return (
    <>
      {visible && (
        <Animated.View
          style={[
            BG_OVERLAY,
            {
              opacity: fadeAnim,
            },
          ]}
        />
      )}
      <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={closeModal}>
        <Pressable style={OVERLAY} onPress={closeModal} />
        <View style={MODAL_CENTER}>
          <View style={[CONTAINER, { maxWidth: screenWidth - 2 * spacing[4] }]}>
            {loading ? (
              <>
                <View style={HEADER}>
                  <Text preset="h2" tx="unlockBlockModal.title" style={HEADER_TITLE} />
                </View>
                <View style={LOADER}>
                  <ActivityIndicator size="large" color={color.primary} />
                </View>
              </>
            ) : (
              <>
                <View style={HEADER}>
                  <Text preset="h2" tx="unlockBlockModal.title" style={HEADER_TITLE} />
                  <Text preset="h4" text={blockTitle} style={HEADER_BLOCK_TITLE} />
                  <Text preset="default" tx="unlockBlockModal.subtitle" style={HEADER_SUB_TITLE} />
                  <Text preset="h4" text={numberOfUnlocks.toString()} style={HEADER_NUMBER} />
                </View>
                <Button style={BUTTON_SELECT} preset="primary" tx="common.open" onPress={unlock} />
              </>
            )}

            <Button preset="cancel" tx="common.cancel" onPress={closeModal} />
          </View>
        </View>
      </Modal>
      <PurchaseModal
        visible={purchaseVisible}
        closeModal={() => {
          setPurchaseVisible(false)
        }}
      />
    </>
  )
})

const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const BG_OVERLAY: ViewStyle = {
  ...OVERLAY,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}
const MODAL_CENTER: ViewStyle = {
  justifyContent: "center",
  alignItems: Platform.OS === "web" ? "center" : "stretch",
  height: "90%",
}
const CONTAINER: ViewStyle = {
  flexGrow: 0,
  width: "100%",
  maxHeight: "90%",
  paddingHorizontal: spacing[4],
  backgroundColor: color.palette.black500,
  borderRadius: 16,
  marginHorizontal: spacing[4],
}
const HEADER: TextStyle = {
  textAlign: "center",
  paddingVertical: spacing[5],
}
const HEADER_TITLE: TextStyle = {
  textAlign: "center",
  paddingBottom: spacing[5],
}
const HEADER_BLOCK_TITLE: TextStyle = {
  textAlign: "center",
  paddingBottom: spacing[4],
}
const HEADER_SUB_TITLE: TextStyle = {
  textAlign: "center",
  paddingVertical: spacing[4],
}
const HEADER_NUMBER: TextStyle = {
  textAlign: "center",
}
const LOADER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  padding: spacing[4],
}
const BUTTON_SELECT: ViewStyle = {
  marginTop: spacing[2],
  marginBottom: spacing[1],
}
