import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Eye = (props: SvgProps) => (
  <Svg width={10} height={8} fill="#fff" viewBox="0 0 10 8" {...props}>
    <Path d="M3.67 4c0 .364.135.714.376.972.24.257.567.402.908.402.34 0 .668-.145.908-.402.241-.258.377-.608.377-.972s-.136-.714-.377-.972a1.243 1.243 0 0 0-.908-.402c-.34 0-.667.145-.908.402-.241.258-.376.608-.376.972Zm6.264-.317C8.847 1.233 7.204 0 5 0S1.153 1.233.066 3.685a.783.783 0 0 0 0 .632C1.153 6.767 2.796 8 5 8s3.847-1.233 4.934-3.685a.787.787 0 0 0 0-.632ZM4.954 6.16C3.84 6.16 2.936 5.193 2.936 4s.903-2.16 2.018-2.16c1.115 0 2.019.967 2.019 2.16s-.904 2.16-2.019 2.16Z" />
  </Svg>
)

export default Eye
