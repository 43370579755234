import React from "react"
import { observer } from "mobx-react-lite"
import { ResultContentHtml } from "./result-content-html"
import { ResultContentSlider } from "./result-content-slider"
import { ResultContentRating } from "./result-content-rating"
import { ResultContentGraphic } from "./result-content-graphic"

interface ResultContentProps {
  block: any
}

export const ResultContent = observer((props: ResultContentProps) => {
  const { block } = props

  return (
    <>
      {(() => {
        switch (block.type) {
          case "html":
            return <ResultContentHtml title={block.title} content={block.content} />
          case "slides":
            return <ResultContentSlider title={block.title} content={block.content} />
          case "rating":
            return <ResultContentRating title={block.title} content={block.content} />
          case "graphic":
            return <ResultContentGraphic title={block.title} content={block.content} />
          default:
            return null
        }
      })()}
    </>
  )
})
