import React, { FC, useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle } from "react-native"
import { SwiperFlatList } from "react-native-swiper-flatlist"
import { Header, IPillData, Pills, Screen, Text } from "../../components"
import { HomeStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { useWindowWidth } from "../../utils/dimension"
import { FormulaScreen, FormulaTypes } from "./free-formulas/formula-screen"

interface IFreeFormula {
  id: number
  name: string
  type: FormulaTypes
}

const FreeFormulasData: IFreeFormula[] = [
  {
    id: 1,
    name: "What is hidden in your Date of Birth",
    type: FormulaTypes.DateOfBirth,
  },
  {
    id: 2,
    name: "Your country in a past life",
    type: FormulaTypes.PastLifeCountry,
  },
  {
    id: 3,
    name: "Name meaning",
    type: FormulaTypes.EnergyWealthByName,
  },
  {
    id: 4,
    name: "Career based on your date of birth",
    type: FormulaTypes.ProfessionByBirthDate,
  },
  {
    id: 5,
    name: "The meaning of your house or apartment number",
    type: FormulaTypes.MeaningBehindHouseNumber,
  },
]

export const FreeFormulasScreen: FC<HomeStackScreenProps<"freeFormulasScreen">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()

    const screenWidth = useWindowWidth()

    const [activeCategory, setActiveCategory] = useState<string | number>(1)
    const scrollRef = useRef<SwiperFlatList>(null)

    const filterCategory = (data: IPillData) => {
      setActiveCategory(data.id)
      const index = FreeFormulasData.findIndex((d) => d.id === data.id)
      if (index >= 0) {
        scrollRef.current?.scrollToIndex({ index })
      }
    }

    const onChangeIndex = (index: number) => {
      setActiveCategory(FreeFormulasData[index].id)
    }

    return (
      <View style={FULL}>
        <Screen
          header={
            <Header headerTx="homeScreen.formulasHeading" leftIcon="back" onLeftPress={goBack} />
          }
          preset="scroll"
        >
          <View style={CONTAINER}>
            <Text preset="h3" tx="freeFormulasScreen.headerText" />
          </View>
          <View>
            <Pills data={FreeFormulasData} activeId={activeCategory} onPress={filterCategory} />
          </View>

          <SwiperFlatList
            ref={scrollRef}
            onChangeIndex={({ index }) => onChangeIndex(index)}
            getItemLayout={(data, index) => ({
              length: screenWidth,
              offset: screenWidth * index,
              index,
            })}
            data={FreeFormulasData}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <View style={[FORMULA_CONTAINER, { width: screenWidth }]}>
                <FormulaScreen type={item.type} />
              </View>
            )}
          />
        </Screen>
      </View>
    )
  },
)

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = { paddingHorizontal: spacing[4] }
const FORMULA_CONTAINER: ViewStyle = {
  ...CONTAINER,
}
