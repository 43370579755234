import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Warning = (props: SvgProps) => (
  <Svg width={22} height={20} viewBox="0 0 22 20" fill="#E66100" {...props}>
    <Path d="M9.909.782a2.25 2.25 0 0 1 2.975.74l.083.138 7.759 14.009a2.25 2.25 0 0 1-1.814 3.334l-.154.006H3.242A2.25 2.25 0 0 1 1.2 15.812l.072-.143L9.03 1.659c.205-.369.51-.673.879-.877ZM11 14.002a1 1 0 0 0-.951 1.388A1 1 0 1 0 11 14.002Zm-.002-8.004a1 1 0 0 0-.993.884L10 6.998V12l.007.117a1 1 0 0 0 1.987 0L12 12V6.998l-.007-.117a1 1 0 0 0-.994-.883h-.001Z" />
  </Svg>
)

export default Warning
