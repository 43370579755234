import * as React from "react"
import Svg, { SvgProps, G, Rect, Path } from "react-native-svg"

const Play = (props: SvgProps) => (
  <Svg width={64} height={64} fill="#ffffff" viewBox="0 0 64 64" {...props}>
    <G>
      <Rect width={64} height={64} rx={32} fillOpacity={0.32} />
      <Path d="M25 25.274c0-1.707 1.826-2.792 3.325-1.977l12.362 6.726c1.566.853 1.566 3.101 0 3.953l-12.362 6.726c-1.499.816-3.325-.27-3.325-1.976V25.274Z" />
    </G>
  </Svg>
)

export default Play
