import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Calendar = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="#3C4F6D" {...props}>
    <Path d="M18 5.5v9.25A3.25 3.25 0 0 1 14.75 18H3.25A3.25 3.25 0 0 1 0 14.75V5.5h18ZM4.25 12a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM9 12a1.25 1.25 0 1 0 0 2.5A1.25 1.25 0 0 0 9 12ZM4.25 7.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM9 7.5A1.25 1.25 0 1 0 9 10a1.25 1.25 0 0 0 0-2.5Zm4.75 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm1-7.5A3.25 3.25 0 0 1 18 3.25V4H0v-.75A3.25 3.25 0 0 1 3.25 0h11.5Z" />
  </Svg>
)

export default Calendar
