import * as React from "react"
import { View, ViewStyle, TouchableWithoutFeedback } from "react-native"
import { SvgProps } from "react-native-svg"
import { BottomTabBarProps } from "@react-navigation/bottom-tabs"
import { CommonActions } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Icon } from "../icon/icon"
import { color, spacing } from "../../theme"
import { IconTypes } from "../icon/icons"
import { useStores } from "../../models"
import { getActiveRouteName } from "../../navigators/navigation-utilities"
import { SubscriptionDuration } from "../../models/profile-store/profile-store"
import { PurchaseModal } from "../purchase-modal/purchase-modal"

const FLEX_ROW_CENTER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}

const TAB_BAR: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: color.palette.black500,
  paddingHorizontal: spacing[6],
  paddingTop: spacing[4],
  paddingBottom: spacing[6],
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
}
const TAB_BAR_HIDDEN: ViewStyle = {
  display: "none",
}
const TAB_ITEM: ViewStyle = {
  ...FLEX_ROW_CENTER,
  flex: 1,
}
const ICON_CONTAINER: ViewStyle = {
  ...FLEX_ROW_CENTER,
  width: "100%",
  height: "100%",
}
const ICON_WRAPPER: ViewStyle = {
  position: "relative",
  borderRadius: 40,
  width: 40,
  height: 40,
  overflow: "hidden",
}
const ICON: SvgProps = {
  width: 20,
  height: 20,
}

interface IconsMap {
  [key: string]: IconTypes
}
const ICONS_MAP: IconsMap = {
  soulMate: "soulMate",
  home: "logo",
  academy: "library",
  profile: "personCircle",
  horoscope: "horoscope",
}

const ICON_ACTIVE_COLOR: SvgProps = { fill: color.primary }

export const AppTabBar = observer((props: BottomTabBarProps) => {
  const { state, descriptors, navigation } = props
  const { meStore, profileStore } = useStores()
  const [purchaseVisible, setPurchaseVisible] = React.useState(false)

  return (
    <View
      style={[
        TAB_BAR,
        getActiveRouteName(state) === "academyVideo" ||
        getActiveRouteName(state) === "myCodeOfSamsara"
          ? TAB_BAR_HIDDEN
          : {},
      ]}
    >
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key]
        const isFocused = state.index === index

        const onPress = () => {
          if (meStore.isGuest && ["soulMate", "horoscope"].includes(route.name)) {
            meStore.setAuthModalVisible(true)
            return
          }

          if (
            route.name === "horoscope" &&
            profileStore.subscriptionDuration === SubscriptionDuration.None
          ) {
            setPurchaseVisible(true)
            return
          }

          // Reset to initial screen
          navigation.dispatch({
            ...CommonActions.reset({
              index: 0,
              routes: [{ name: route.name }],
            }),
          })

          /* const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          })

          if (!isFocused && !event.defaultPrevented) {
            // The `merge: true` option makes sure that the params inside the tab screen are preserved
            navigation.navigate({ name: route.name, merge: true, params: {} })
          } */
        }

        /* const onLongPress = () => {
          navigation.emit({
            type: "tabLongPress",
            target: route.key,
          })
        } */

        return (
          <TouchableWithoutFeedback
            key={index}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            onPress={onPress}
            style={TAB_ITEM}
          >
            <View style={ICON_WRAPPER}>
              <Icon
                icon={ICONS_MAP[route.name]}
                containerStyle={ICON_CONTAINER}
                svgProps={{ ...(isFocused ? ICON_ACTIVE_COLOR : {}), ...ICON }}
              />
            </View>
          </TouchableWithoutFeedback>
        )
      })}
      <PurchaseModal
        visible={purchaseVisible}
        closeModal={() => {
          setPurchaseVisible(false)
        }}
      />
    </View>
  )
})
