import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ChevronRight = (props: SvgProps) => (
  <Svg width={18} height={17} viewBox="0 0 18 17" fill="#091023" {...props}>
    <Path d="M10.704 1.284a1 1 0 1 0-1.403 1.424L14.67 8H1a1 1 0 0 0 0 2h13.665L9.3 15.285a1 1 0 0 0 1.403 1.424l6.925-6.822a1.25 1.25 0 0 0 0-1.78l-6.925-6.823h.001Z" />
  </Svg>
)

export default ChevronRight
