import { ViewStyle } from "react-native"
import { spacing } from "../../theme"

const ROOT: ViewStyle = {
  marginBottom: spacing[5],
}

export const PRESETS = {
  bottom: {
    ...ROOT,
  },
  middle: {
    ...ROOT,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  } as ViewStyle,
}

export type FormRowPresets = keyof typeof PRESETS
