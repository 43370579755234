import React, { FC } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { SoulMateStackScreenProps, TabsParamList } from "../../navigators"
import { OrderTypeEnum } from "../../models/extra-order-store/extra-order-store"
import { StartCalculation } from "../extra-order/components/start-calculation"

export const SoulMateScreen: FC<SoulMateStackScreenProps<"soulMateIndex">> = observer(
  ({ navigation }) => {
    const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()
    const goBack = () => rootNavigation.navigate("home", { screen: "homeIndex" })

    return (
      <View style={FULL}>
        <StartCalculation
          type={OrderTypeEnum.soulMate}
          goBack={goBack}
          navigationToCompability={() => navigation.navigate("soulMateCompatibility")}
        />
      </View>
    )
  },
)

const FULL: ViewStyle = { flex: 1 }
