import React, { useEffect, useRef } from "react"
import { ViewStyle, TouchableOpacity, View, Animated, TextStyle, Easing } from "react-native"
import { Icon } from "../icon/icon"
import { Text } from "../text/text"
import { color, spacing } from "../../theme"

export interface ICollapseData {
  id: number
  header: string
  content: string
}

export interface CollapseProps {
  data: ICollapseData
  currentIndex?: number | string
  setCurrentIndex(id: number | string): void
}

const COLLAPSE_ITEM: ViewStyle = {
  marginBottom: spacing[4],
  backgroundColor: color.palette.black500,
  borderRadius: 8,
}
const COLLAPSE_HEADER: ViewStyle = {
  paddingHorizontal: spacing[5],
  paddingVertical: spacing[4],
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}
const COLLAPSE_HEADER_TEXT: ViewStyle = {
  paddingRight: spacing[3],
}
const COLLAPSE_CONTENT: ViewStyle = {
  paddingHorizontal: spacing[5],
}
const COLLAPSE_CONTENT_TEXT: TextStyle = {
  paddingBottom: spacing[4],
}

export function Collapse(props: CollapseProps) {
  const { data, currentIndex, setCurrentIndex } = props

  const slideAnim = useRef(new Animated.Value(0)).current

  const slideUp = () => {
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: 200,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start()
  }

  const slideDown = () => {
    Animated.timing(slideAnim, {
      toValue: 1,
      duration: 400,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start()
  }

  useEffect(() => {
    if (currentIndex === data.id) {
      slideDown()
    } else {
      slideUp()
    }
  }, [currentIndex])

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={COLLAPSE_ITEM}
      onPress={() => setCurrentIndex(data.id === currentIndex ? null : data.id)}
    >
      <View style={COLLAPSE_HEADER}>
        <Text preset="h4" numberOfLines={3} style={COLLAPSE_HEADER_TEXT}>
          {data.header}
        </Text>
        <Animated.View
          style={{
            transform: [
              {
                rotateZ: slideAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: ["180deg", "0deg"],
                }),
              },
            ],
          }}
        >
          <Icon icon="collapseArrow" />
        </Animated.View>
      </View>
      <Animated.View
        style={[
          COLLAPSE_CONTENT,
          {
            opacity: slideAnim,
            maxHeight: slideAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 2000],
            }),
          },
        ]}
      >
        <Text preset="default" style={COLLAPSE_CONTENT_TEXT}>
          {data.content}
        </Text>
      </Animated.View>
    </TouchableOpacity>
  )
}
