import React, { FC } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { ExtraOrderStackScreenProps, TabsParamList } from "../../navigators"
import { OrderTypeEnum } from "../../models/extra-order-store/extra-order-store"
import { StartCalculation } from "./components/start-calculation"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"

export const ExtraOrderScreen: FC<ExtraOrderStackScreenProps<"extraOrderIndex">> = observer(
  ({ navigation }) => {
    const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()
    const goBack = () => {
      rootNavigation.navigate("home", { screen: "homeIndex" })
    }

    return (
      <View style={FULL}>
        <StartCalculation
          type={OrderTypeEnum.extra}
          goBack={goBack}
          navigationToCompability={() => navigation.navigate("extraOrderCompatibility")}
        />
      </View>
    )
  },
)

const FULL: ViewStyle = { flex: 1 }
