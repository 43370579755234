import React, { useEffect, useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import {
  Screen,
  Header,
  SkeletonContainer,
  SkeletonView,
  ScreenTitle,
  Text,
} from "../../../../components"
import { useStores } from "../../../../models"
import { color, spacing } from "../../../../theme"
import { ResultTabs } from "./result-tabs"
import { TabsParamList } from "../../../../navigators"
import { GenderEnum } from "../../../../constants/genders"
import { ResultContent } from "./result-content"
import { OrderTypeEnum } from "../../../../models/extra-order-store/extra-order-store"

interface IPageData {
  emoji: string
  id: string
  title: string
  blocks: any
}
interface IPartnerData {
  firstName: string
  lastName: string
  initial: string
  gender: string
}
interface IContentData {
  title: string
  partner1: IPartnerData
  partner2?: IPartnerData
  pages: IPageData[]
}

interface IResultsData {
  orderId: string
}

export const Results = observer((props: IResultsData) => {
  const { orderId } = props
  const { extraOrderStore } = useStores()
  const [content, setContent] = useState<IContentData>()
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState("")
  const [tabContent, setTabContent] = useState<IPageData>()
  const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()

  const goBack = () => {
    extraOrderStore.orderType === OrderTypeEnum.extra
      ? rootNavigation.navigate("home", {
          screen: "extraOrderStack",
          params: { screen: "extraOrderIndex" },
        })
      : rootNavigation.navigate("soulMate", {
          screen: "soulMateIndex",
        })
  }

  const getGenderBg = (gender: string) => {
    return gender === GenderEnum.Male ? CIRCLE_MALE : CIRCLE_FEMALE
  }

  const onTabPress = (tabId: string) => {
    setActiveTab(tabId)
  }

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      const result = await extraOrderStore.getResult(orderId)
      if (result) {
        setContent(result)
        setActiveTab(result.pages[0].id)
        setLoading(false)
      }
    })()
  }, [orderId])

  useEffect(() => {
    const page = content?.pages?.filter((r) => r.id === activeTab)
    if (page?.length) {
      setTabContent(page[0])
    }
  }, [content, activeTab])

  return (
    <Screen preset="scroll" header={<Header leftIcon="back" onLeftPress={goBack} />}>
      {loading ? (
        <SkeletonContainer>
          <View style={BLOCKS_SKELETON}>
            <SkeletonView style={BLOCKS_SKELETON_TITLE} />
            <View style={{ marginBottom: spacing[3] } as ViewStyle}>
              <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
              <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
              <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
              <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
              <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
            </View>
          </View>
        </SkeletonContainer>
      ) : (
        <>
          <View style={RESULT_HEADER_CONTAINER}>
            <ScreenTitle headerText={content.title} />
            <View style={CALCULATIONS_ITEM_NAMES_ROW}>
              <View style={content.partner2 && CALCULATIONS_ITEM_NAMES_CONTAINER}>
                <View style={[CALCULATIONS_ITEM_NAMES_FIRST, getGenderBg(content.partner1.gender)]}>
                  <Text
                    preset="small"
                    text={content.partner1.initial}
                    style={CALCULATIONS_ITEM_NAMES_TEXT}
                  />
                </View>
                {content.partner2 && (
                  <View style={CALCULATIONS_ITEM_NAMES_SECOND}>
                    <View
                      style={[
                        CALCULATIONS_ITEM_NAMES_SECOND_WRAPPER,
                        getGenderBg(content.partner2.gender),
                      ]}
                    >
                      <Text
                        preset="small"
                        text={content.partner2.initial}
                        style={CALCULATIONS_ITEM_NAMES_TEXT}
                      />
                    </View>
                  </View>
                )}
              </View>
              <View>
                <Text
                  preset="h4Text"
                  text={`${content.partner1.firstName}${
                    content?.partner2?.firstName && " & " + content?.partner2?.firstName
                  }`}
                />
              </View>
            </View>
          </View>
          <ResultTabs data={content.pages} activeTabId={activeTab} onPress={onTabPress} />
          <View style={RESULT_CONTENT}>
            {tabContent.blocks.map((block, index: number) => (
              <ResultContent key={index} block={block} />
            ))}
          </View>
        </>
      )}
    </Screen>
  )
})

const RESULT_CONTENT: ViewStyle = {
  backgroundColor: color.palette.black500,
  paddingBottom: spacing[4],
}
const RESULT_HEADER_CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const CALCULATIONS_ITEM_NAMES_ROW: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginBottom: spacing[4],
  position: "relative",
}
const CALCULATIONS_ITEM_NAMES_CONTAINER: ViewStyle = {
  width: 60,
}
const CALCULATIONS_ITEM_NAMES_FIRST: TextStyle = {
  width: 24,
  height: 24,
  backgroundColor: color.palette.purple,
  borderRadius: 100,
  paddingRight: 2,
}
const CALCULATIONS_ITEM_NAMES_SECOND: ViewStyle = {
  paddingHorizontal: spacing[1],
  paddingVertical: spacing[1],
  backgroundColor: color.palette.black,
  borderRadius: 100,
  zIndex: 1,
  position: "absolute",
  top: -4,
  left: 21,
}
const CIRCLE_MALE: ViewStyle = {
  backgroundColor: color.palette.blue,
}
const CIRCLE_FEMALE: ViewStyle = {
  backgroundColor: color.palette.purple,
}
const CALCULATIONS_ITEM_NAMES_SECOND_WRAPPER: ViewStyle = {
  width: 24,
  height: 24,
  backgroundColor: color.palette.blue,
  borderRadius: 100,
  justifyContent: "center",
}
const CALCULATIONS_ITEM_NAMES_TEXT: TextStyle = {
  color: color.palette.white,
  fontWeight: "500",
  lineHeight: 24,
  textAlign: "center",
}

// SKELETON FOR BLOCKS
const BLOCKS_SKELETON: ViewStyle = {
  marginBottom: 24,
  paddingHorizontal: 18,
  paddingVertical: 24,
}
const BLOCKS_SKELETON_TITLE: ViewStyle = {
  marginBottom: spacing[4],
  width: "100%",
  height: 50,
}
const BLOCKS_SKELETON_DESCRIPTION: ViewStyle = {
  width: "100%",
  height: 15,
  marginBottom: 7,
}
