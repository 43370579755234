import { LinearGradient } from "expo-linear-gradient"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState, useRef } from "react"
import {
  Animated,
  Modal,
  Pressable,
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Linking,
} from "react-native"
import { Checkbox } from "../checkbox/checkbox"
import { Button } from "../button/button"
import { Icon } from "../icon/icon"
import { Text } from "../text/text"
import { RenderHtmlWrapper } from "../render-html-wrapper/render-html-wrapper"
import { useStores } from "../../models"
import { ApiRoutes } from "../../services/api/api-config"
import { color, spacing } from "../../theme"
import { IS_MOBILE, useWindowWidth } from "../../utils/dimension"
import { EmailVerificationModal } from "../email-verification/email-verification"

export interface PurchaseModalProps {
  visible: boolean
  closeModal?(): void
}

const BORDER_TURQUOISE = {
  borderRadius: 16,
  borderWidth: 2,
  borderColor: color.palette.brightTurquoise,
}
const BORDER_TRANSPARENT = {
  borderRadius: 16,
  borderWidth: 2,
  borderColor: color.palette.black500,
}
const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const BG_OVERLAY: ViewStyle = {
  ...OVERLAY,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}
const CONTAINER: ViewStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  paddingTop: spacing[5],
  paddingBottom: spacing[7],
  paddingHorizontal: spacing[4],
  backgroundColor: color.background,
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  maxHeight: "100%",
  marginHorizontal: "auto",
}
const LOADER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  padding: spacing[8],
}
const HEADER: ViewStyle = {
  flexDirection: "row",
  paddingBottom: spacing[5],
  alignItems: "center",
  justifyContent: "space-between",
}
const TITLE: ViewStyle = {
  flex: 1,
}
const SUB_TITLE: ViewStyle = {
  paddingBottom: spacing[5],
}
const CLOSE_BUTTON: ViewStyle = { width: 17 }
const CHECKBOX: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  width: 56,
}
const PURCHASE_CARD: ViewStyle = {
  flexDirection: "row",
  position: "relative",
  borderRadius: 16,
  justifyContent: "space-between",
  paddingTop: spacing[4],
  paddingBottom: spacing[2],
  marginBottom: spacing[5],
  backgroundColor: color.palette.black500,
}
const PURCHASE_CARD_GRADIENT: ViewStyle = {
  borderRadius: 16,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}
const PURCHASE_CARD_CONTENT: ViewStyle = {
  flex: 1,
  flexDirection: "column",
}
const PURCHASE_PRICE_CONTENT_WRAPPER: ViewStyle = {
  borderRadius: 37,
  paddingHorizontal: spacing[4],
  alignItems: "center",
  justifyContent: "center",
}
const PURCHASE_PRICE_CONTENT: ViewStyle = {
  ...BORDER_TURQUOISE,
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[2],
}
const CARD_TITLE: TextStyle = {
  paddingBottom: spacing[1],
  color: color.palette.white,
}
const BONUS_WRAPPER: ViewStyle = {
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  position: "absolute",
  top: -16,
  right: 7,
  borderWidth: 2,
  borderRadius: 40,
  borderColor: color.palette.green,
  backgroundColor: color.palette.greenDark,
  zIndex: 2,
}
const BONUS_TEXT: TextStyle = {
  color: color.palette.white,
  paddingRight: spacing[2],
  paddingLeft: spacing[1],
  paddingVertical: 2,
}
const GIFT_ICON: ViewStyle = {
  marginLeft: -10,
}
interface IPurchase {
  id: string
  title: string
  description: string
  price: string
  bonus?: string
}

export const PurchaseModal = observer((props: PurchaseModalProps) => {
  const { profileStore } = useStores()

  const screenWidth = useWindowWidth()

  const { visible, closeModal } = props
  const [loading, setLoading] = useState(true)

  const [subscriptions, setSubscriptions] = useState<IPurchase[]>([])
  const [active, setActive] = useState("")
  const scrollViewRef = useRef(null)
  const [verificationVisible, setVerificationVisible] = useState(false)

  useEffect(() => {
    if (active) {
      scrollViewRef.current.scrollToEnd({ animated: true })
    }
  }, [active])

  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }

  const fadeOut = () => {
    fadeAnim.setValue(0)
  }

  const getSubscriptions = async () => {
    setLoading(true)
    const subs = await profileStore.getSubscriptions()
    if (subs) {
      setSubscriptions(subs)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (visible) {
      getSubscriptions()
      fadeIn()
    } else {
      fadeOut()
    }
  }, [visible])

  const buySubscription = async () => {
    const stripeUrl = ApiRoutes.PAY_STRIPE_SUBSCRIPTION_URL(profileStore.id, active)
    IS_MOBILE ? Linking.openURL(stripeUrl) : (window.location.href = stripeUrl)
    setLoading(true)
  }

  const checkConfirmation = () => {
    if (profileStore.isConfirmed) {
      buySubscription()
    } else {
      setVerificationVisible(true)
    }
  }

  return (
    <>
      {visible && (
        <Animated.View
          style={[
            BG_OVERLAY,
            {
              opacity: fadeAnim,
            },
          ]}
        />
      )}
      <Modal animationType="slide" transparent={true} visible={visible} onRequestClose={closeModal}>
        <Pressable style={OVERLAY} onPress={closeModal} />
        <View style={[CONTAINER, { maxWidth: screenWidth }]}>
          <View style={HEADER}>
            <Text preset="h3" tx="purchaseModal.title" style={TITLE} numberOfLines={1} />
            <Button preset="unstyled" onPress={closeModal} style={CLOSE_BUTTON}>
              <Icon icon="close" />
            </Button>
          </View>
          <ScrollView ref={scrollViewRef}>
            {loading ? (
              <View style={LOADER}>
                <ActivityIndicator size="large" color={color.primary} />
              </View>
            ) : subscriptions.length ? (
              <>
                <Text tx="purchaseModal.subtitle" style={SUB_TITLE} />
                {subscriptions.map((purchase) => (
                  <TouchableOpacity
                    key={purchase.id}
                    style={[
                      PURCHASE_CARD,
                      active === purchase.id ? BORDER_TURQUOISE : BORDER_TRANSPARENT,
                    ]}
                    onPress={() => {
                      setActive(purchase.id)
                    }}
                  >
                    {active === purchase.id && (
                      <LinearGradient
                        start={[0, 0]}
                        end={[1, 0]}
                        colors={["rgba(0, 230, 219, 0.2)", "rgba(0, 230, 219, 0)"]}
                        style={PURCHASE_CARD_GRADIENT}
                      />
                    )}
                    <Checkbox style={CHECKBOX} value={active === purchase.id} />

                    <View style={PURCHASE_CARD_CONTENT}>
                      <Text preset="h4" text={purchase.title} style={CARD_TITLE} />
                      <RenderHtmlWrapper html={purchase.description} />
                    </View>

                    <View style={PURCHASE_PRICE_CONTENT_WRAPPER}>
                      <LinearGradient
                        start={[-0.1, -0.2]}
                        colors={["rgba(0, 230, 217, 0.2)", color.palette.black500]}
                        style={PURCHASE_PRICE_CONTENT}
                      >
                        <Text preset="h4" text={purchase.price} />
                      </LinearGradient>
                    </View>

                    {purchase.bonus ? (
                      <View style={BONUS_WRAPPER}>
                        <Icon
                          icon="gift"
                          containerStyle={GIFT_ICON}
                          svgProps={{ width: 24, height: 24 }}
                        />
                        <Text
                          preset="medium"
                          style={BONUS_TEXT}
                          text={purchase.bonus}
                          numberOfLines={1}
                        />
                      </View>
                    ) : null}
                  </TouchableOpacity>
                ))}
                <Button
                  preset="primary"
                  tx="purchaseModal.purchaseNow"
                  disabled={!active}
                  onPress={checkConfirmation}
                />
              </>
            ) : (
              <Text tx="purchaseModal.noSubscriptions" style={SUB_TITLE} />
            )}
          </ScrollView>
        </View>
      </Modal>
      <EmailVerificationModal
        visible={verificationVisible}
        closeModal={() => {
          setVerificationVisible(false)
        }}
        onSuccess={buySubscription}
      />
    </>
  )
})
