import React, { FC, useState } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Button, ScreenTitle, Header, Select } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { useStores } from "../../models"
import { locales } from "../../constants/locales"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
  flex: 1,
}
const CONTENT: ViewStyle = {
  flexGrow: 1,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}

export const ProfileEditLanguageScreen: FC<ProfileStackScreenProps<"profileEditLanguage">> =
  observer(({ navigation }) => {
    const { profileStore } = useStores()

    const [locale, setLocale] = useState(profileStore.preferredLocale)

    const goBack = () => navigation.goBack()

    const saveChanges = () => {
      profileStore.updateCustomerPrefferedLocale(locale)
      navigation.navigate("profileEdit")
    }

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileEditLanguageScreen.title" style={TITLE} />

          <View style={CONTENT}>
            <Select data={locales} selectedCode={locale} setSelectedCode={setLocale} />
          </View>

          <Button tx="profileEditLanguageScreen.save" onPress={saveChanges} />
        </Screen>
      </View>
    )
  })
