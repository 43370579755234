import * as React from "react"
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg"

const Present = (props: SvgProps) => (
  <Svg width={26} height={24} viewBox="0 0 26 24" {...props}>
    <G clipPath="url(#a)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.893 5.83h24.262a.849.849 0 0 1 .848.847V8.71a.846.846 0 0 1-.848.847H.893a.849.849 0 0 1-.848-.847V6.677a.846.846 0 0 1 .848-.847Z"
        fill="#B7E2E0"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.389 9.493h23.27v13.345a1.1 1.1 0 0 1-1.099 1.098H2.488a1.104 1.104 0 0 1-1.1-1.098V9.493Z"
        fill="#B7E2E0"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.024 9.493H24.66v13.345a1.1 1.1 0 0 1-1.1 1.098h-9.436a1.103 1.103 0 0 1-1.1-1.098V9.493Z"
        fill="#00E6DB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.024 5.83h12.13a.849.849 0 0 1 .848.847V8.71a.846.846 0 0 1-.849.847H13.024a.849.849 0 0 1-.848-.847V6.677a.846.846 0 0 1 .848-.847Z"
        fill="#00E6DB"
      />
      <Path d="M24.658 9.555H1.388v1.298h23.27V9.555Z" fill="#00C1B8" />
      <Path d="M24.66 9.555H13.024v1.298H24.66V9.555Z" fill="#00C1B8" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.046 9.555h2.32v13.347A1.102 1.102 0 0 0 9.469 24H7.145a1.104 1.104 0 0 1-1.1-1.098V9.555Z"
        fill="#fff"
      />
      <Path d="M8.367 9.555H6.044v1.298h2.323V9.555Z" fill="#D4E2F6" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.007 9.555H17.68v13.347a1.1 1.1 0 0 1-1.1 1.103h2.323a1.104 1.104 0 0 0 1.1-1.098l.004-13.352Z"
        fill="#D8FFFD"
      />
      <Path d="M20.002 9.555H17.68v1.298h2.322V9.555Z" fill="#CCC" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.046 5.83h2.32a.849.849 0 0 0-.847.847V8.71a.846.846 0 0 0 .848.847H6.046a.849.849 0 0 1-.848-.847V6.677a.846.846 0 0 1 .848-.847Z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.008 5.83H17.68a.849.849 0 0 1 .848.847V8.71a.846.846 0 0 1-.848.847h2.328a.849.849 0 0 0 .848-.847V6.677a.846.846 0 0 0-.849-.847Z"
        fill="#D8FFFD"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.814 5.83H8.168C6.68 4.982 5.963 3.86 6.02 2.546c.123-3.092 5.077-4.328 6.787 1.927l.007 1.357Z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.223 5.83c-1.956-1.245-3.407-2.424-3.515-3.482-.112-1.09.712-1.655 1.795-.847 1.328.995 1.866 2.931 2.31 4.324l-.59.005Z"
        fill="#75FEF7"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.235 5.83h4.645c1.488-.848 2.206-1.97 2.148-3.284-.122-3.092-5.076-4.328-6.787 1.927l-.006 1.357Z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.825 5.83c1.956-1.245 3.407-2.424 3.515-3.482.112-1.09-.712-1.655-1.796-.847-1.327.995-1.867 2.931-2.31 4.324l.59.005Z"
        fill="#75FEF7"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.297 5.83h3.45V4.632a.823.823 0 0 0-.82-.82h-1.809a.825.825 0 0 0-.821.82V5.83Z"
        fill="#fff"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <path fill="#fff" transform="translate(.047)" d="M0 0h25.953v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Present
