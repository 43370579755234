import { observer } from "mobx-react-lite"
import React, { useState, useEffect } from "react"
import {
  Modal,
  View,
  ViewStyle,
  ScrollView,
  ActivityIndicator,
  Pressable,
  Platform,
} from "react-native"
import { useStores } from "../../models"
import { color, spacing } from "../../theme"
import { Text } from "../text/text"
import { Button } from "../button/button"
import { Icon } from "../icon/icon"
import { RenderHtmlWrapper } from "../render-html-wrapper/render-html-wrapper"

export enum TermsTypeEnum {
  UserAgreement = "userAgreement",
  PrivacyPolicy = "privacyPolicy",
}

export interface TermsModalProps {
  visible: boolean
  closeModal?(): void
  termsType: TermsTypeEnum
}
const MODAL_WRAPPER: ViewStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  paddingTop: spacing[7],
  paddingHorizontal: spacing[4],
  backgroundColor: color.background,
  maxHeight: "100%",
  maxWidth: "100%",
  marginHorizontal: "auto",
}
const LOADER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  padding: spacing[8],
}
const HEADER: ViewStyle = {
  flexDirection: "row",
  paddingBottom: spacing[4],
  alignItems: "center",
  justifyContent: "space-between",
}
const TITLE: ViewStyle = {
  flex: 1,
}
const CLOSE_BUTTON: ViewStyle = { width: 17 }

interface ITerms {
  title: string
  content: string
}

export const UserAgreementModal = observer((props: TermsModalProps) => {
  const { commonStore } = useStores()

  const { visible, closeModal, termsType } = props
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState<ITerms>({
    title: "",
    content: "",
  })

  const getContent = async () => {
    setLoading(true)
    let result = null
    if (termsType === TermsTypeEnum.UserAgreement) {
      result = await commonStore.getUserAgreement()
    } else if (termsType === TermsTypeEnum.PrivacyPolicy) {
      result = await commonStore.getPrivacyPolicy()
    }
    if (result) {
      setContent({
        title: result?.title,
        content: result?.content,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (visible) {
      getContent()
    }
  }, [visible, termsType])

  return (
    <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={closeModal}>
      <View style={MODAL_WRAPPER}>
        <View style={HEADER}>
          <Text
            preset="h3"
            tx={
              termsType === TermsTypeEnum.UserAgreement
                ? "userAgreementModal.type1"
                : "userAgreementModal.type2"
            }
            style={TITLE}
            numberOfLines={1}
          />
          <Button preset="unstyled" onPress={closeModal} style={CLOSE_BUTTON}>
            <Icon icon="close" />
          </Button>
        </View>
        <ScrollView>
          {loading ? (
            <View style={LOADER}>
              <ActivityIndicator size="large" color={color.primary} />
            </View>
          ) : Platform.OS === "web" ? (
            <>
              <Pressable>
                <Text preset="h4" text={content.title} style={{ marginBottom: spacing[2] }} />
                <RenderHtmlWrapper html={content.content} />
              </Pressable>
            </>
          ) : (
            <View>
              <Text preset="h4" text={content.title} style={{ marginBottom: spacing[2] }} />
              <RenderHtmlWrapper html={content.content} />
            </View>
          )}
        </ScrollView>
      </View>
    </Modal>
  )
})
