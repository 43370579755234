import React from "react"
import { View, ViewStyle } from "react-native"
import { spacing } from "../../../theme"
import { CustomLineChart, LineChartPoints } from "../../charts/line-chart/line-chart"

interface GraphicFragmentProps {
  content: IGraphicFragment
}

export interface IGraphicFragment {
  title?: string
  type: "graphic"
  content: IGraphicContent
}

interface IGraphicContent {
  typeChart: "Line" | "Bar"
  graphicPoints?: LineChartPoints[]
  legend: string[]
  labels: string[]
  age?: number
  averageLine?: number[]
  averageLineLabels?: string[]
  labelsXTitle?: string
  resolution: number
  xOffset?: number
}

export const GraphicFragment = (props: GraphicFragmentProps) => {
  const {
    content: { content },
  } = props

  return content?.typeChart === "Line" ? (
    <View style={WRAPPER}>
      <CustomLineChart
        data={content.graphicPoints}
        resolution={content.resolution}
        xOffset={content.xOffset}
        legends={content.legend}
        averageLine={content.averageLine}
        averageLineLabels={content.averageLineLabels}
        labels={content.labels}
        age={content.age}
        labelsXTitle={content.labelsXTitle}
      />
    </View>
  ) : null
}

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
}
