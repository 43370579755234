import React, { FC } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Text, Screen, CategoryButtonCompact, ScreenTitle, Header } from "../../components"
import { ProfileStackParamList, ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { TxKeyPath, translate } from "../../i18n"
import { useStores } from "../../models"
import { genders } from "../../constants/genders"
import { locales } from "../../constants/locales"
import { format, isValid, parse } from "date-fns"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingTop: spacing[4],
  paddingHorizontal: spacing[4],
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}

interface IProfileEditLinks {
  id: number
  titleTx: TxKeyPath
  subtitle: string
  link: keyof ProfileStackParamList
}

export const ProfileEditScreen: FC<ProfileStackScreenProps<"profileEdit">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()
    const { profileStore } = useStores()
    const TIME_FORMAT_12 = "hh:mm a"
    const TIME_FORMAT_24 = "HH:mm"

    const genderText = genders.find((elem) => elem.code === profileStore.gender)?.text

    const getUserSideValue = (value: string) => {
      let result = ""
      if (value?.length) {
        const convertedTime = parse(value, TIME_FORMAT_24, new Date())
        if (isValid(convertedTime)) {
          result = format(convertedTime, TIME_FORMAT_12)
        }
      }
      return result
    }

    const convertedTimeOfBirth = getUserSideValue(profileStore.timeOfBirth)

    const profileEditLinks: IProfileEditLinks[] = [
      {
        id: 1,
        titleTx: "profileEditScreen.name",
        subtitle: `${profileStore.firstName} ${profileStore.lastName}`,
        link: "profileEditName",
      },
      {
        id: 2,
        titleTx: "profileEditScreen.birthName",
        subtitle: `${profileStore.birthFirstName} ${profileStore.birthLastName}`,
        link: "profileEditBirthName",
      },
      // {
      //   id: 3,
      //   titleTx: "profileEditScreen.socialConnect",
      //   subtitle: "Facebook",
      //   link: "profileIndex",
      // },
      {
        id: 4,
        titleTx: "profileEditScreen.gender",
        subtitle: `${genderText ? translate(genderText) : "-"}`,
        link: "profileEditGender",
      },
      {
        id: 5,
        titleTx: "profileEditScreen.dateOfBirth",
        subtitle: `${profileStore.dateOfBirth}, ${convertedTimeOfBirth}`,
        link: "profileEditDateAndTime",
      },
      {
        id: 6,
        titleTx: "profileEditScreen.placeOfBirth",
        subtitle: `${profileStore.placeOfBirth?.name || "-"}`,
        link: "profileEditPlaceOfBirth",
      },
      {
        id: 7,
        titleTx: "profileEditScreen.language",
        subtitle: `${translate(
          locales.find((elem) => elem.code === profileStore.preferredLocale)?.text,
        )}`,
        link: "profileEditLanguage",
      },
    ]

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileEditScreen.title" style={TITLE} />
          <View>
            {profileEditLinks.map((item) => (
              <CategoryButtonCompact key={item.id} onPress={() => navigation.navigate(item.link)}>
                <Text tx={item.titleTx} />
                <Text text={item.subtitle} preset="h4" />
              </CategoryButtonCompact>
            ))}
          </View>
        </Screen>
      </View>
    )
  },
)
