import React from "react"
import {
  StyleProp,
  TextInput,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native"
import { color, spacing } from "../../theme"
import { translate, TxKeyPath } from "../../i18n"
import { IconTypes } from "../icon/icons"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { texts } from "../../styles"

// the base styling for the TextInput
const INPUT: TextStyle = {
  ...texts.H4_TEXT,
  color: color.palette.white,
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[2],
  minHeight: 60,
  backgroundColor: color.palette.black500,
  borderWidth: 2,
  borderRadius: 8,
  borderColor: color.borderColor,
}
const INPUT_WITH_ICON: TextStyle = {
  paddingLeft: 52,
}
const INPUT_WITH_PLACEHOLDER: TextStyle = {
  paddingTop: spacing[3],
  paddingBottom: 0,
}
const TEXT_INPUT_WRAPPER: ViewStyle = {
  position: "relative",
}
const TEXT_ICON: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 20,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
}

const FIELD_LABEL: TextStyle = {
  position: "absolute",
  top: 10,
  left: spacing[4],
  zIndex: 1,
}
const FIELD_LABEL_WITH_ICON: TextStyle = {
  left: 52,
}

const CLEAR_INPUT_ICON: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  right: spacing[4],
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
}

// currently we have no presets, but that changes quickly when you build your app.
const PRESETS: { [name: string]: ViewStyle } = {
  default: {},
}

export interface TextFieldProps extends TextInputProps {
  /**
   * The placeholder i18n key.
   */
  placeholderTx?: TxKeyPath

  /**
   * The Placeholder text if no placeholderTx is provided.
   */
  placeholder?: string

  /**
   * Optional container style overrides useful for margins & padding.
   */
  style?: StyleProp<ViewStyle>

  /**
   * Optional style overrides for the input.
   */
  inputStyle?: StyleProp<TextStyle>

  /**
   * Various look & feels.
   */
  preset?: keyof typeof PRESETS

  forwardedRef?: any

  textIcon?: IconTypes

  editable?: boolean

  onChangeText(text: string): void

  disableClearButton?: boolean

  disableLabel?: boolean
}

/**
 * A component which has a label and an input together.
 */
export function TextField(props: TextFieldProps) {
  const {
    placeholderTx,
    placeholder,
    textIcon,
    preset = "default",
    style: styleOverride,
    inputStyle: inputStyleOverride,
    forwardedRef,
    editable = true,
    disableLabel = false,
    disableClearButton = false,
    value,
    onChangeText,
    ...rest
  } = props

  const containerStyles = [PRESETS[preset], styleOverride]
  const inputStyles = [INPUT, inputStyleOverride]
  const actualPlaceholder = placeholderTx ? translate(placeholderTx) : placeholder

  return (
    <View style={containerStyles}>
      <View style={TEXT_INPUT_WRAPPER}>
        {textIcon && <Icon icon={textIcon} containerStyle={TEXT_ICON} />}
        {!disableLabel && actualPlaceholder && value?.length > 0 && (
          <Text
            preset="fieldLabel"
            text={actualPlaceholder}
            style={[FIELD_LABEL, textIcon && FIELD_LABEL_WITH_ICON]}
          />
        )}
        <TextInput
          placeholder={actualPlaceholder}
          placeholderTextColor={color.palette.lighterGrey}
          underlineColorAndroid={color.transparent}
          value={value}
          onChangeText={(text) => onChangeText(text)}
          {...rest}
          editable={editable}
          style={[
            inputStyles,
            textIcon && INPUT_WITH_ICON,
            actualPlaceholder && value?.length > 0 && INPUT_WITH_PLACEHOLDER,
          ]}
          ref={forwardedRef}
        />
        {!disableClearButton && value?.length > 0 && (
          <TouchableOpacity style={CLEAR_INPUT_ICON} onPress={() => onChangeText("")}>
            <Icon icon="closeCircle" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
