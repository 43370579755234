import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Edit = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="#00E6DB" {...props}>
    <Path d="m9.455 2.75 3.795 3.795L5.797 14a1.688 1.688 0 0 1-.75.435L1.21 15.481a.563.563 0 0 1-.69-.69l1.047-3.838c.077-.283.227-.541.435-.749L9.454 2.75h.001Zm5.318-1.522a2.683 2.683 0 0 1 0 3.795l-.728.727-3.795-3.795.727-.728a2.684 2.684 0 0 1 3.796 0Z" />
  </Svg>
)

export default Edit
