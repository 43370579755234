import React, { FC, useState } from "react"
import { View, ViewStyle, TextStyle, KeyboardAvoidingView, ScrollView } from "react-native"
import { observer } from "mobx-react-lite"
import { Text, Screen, Stepper, FormRow, TextField, Button, Icon, Select } from "../../components"
import { color, spacing } from "../../theme"
import { WelcomeStackScreenProps } from "../../navigators"
import { useStores } from "../../models"
import { genders } from "../../constants/genders"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  flex: 1,
  padding: spacing[4],
}
const TEXT_CONTENT: ViewStyle = {
  marginBottom: 30,
}
const TEXT_CONTENT_TITLE: TextStyle = {
  marginBottom: spacing[2],
}
const INFO_CONTENT: ViewStyle = {
  flexGrow: 1,
}
const BUTTON_CONTENT: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
}
const BUTTON_BACK: ViewStyle = {
  width: "35%",
}
const BUTTON_BACK_CONTENT: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const BUTTON_NEXT: ViewStyle = {
  width: "60%",
  justifyContent: "center",
}
const BUTTON_NEXT_CONTENT: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}
const BUTTON_NEXT_TEXT: TextStyle = {
  color: color.palette.black,
  flexGrow: 1,
  textAlign: "center",
}

export const WelcomeForm3Screen: FC<WelcomeStackScreenProps<"welcomeForm3">> = observer(
  ({ navigation }) => {
    const { welcomeStore, alertStore } = useStores()

    const [firstName, setFirstName] = useState(welcomeStore.firstName)
    const [lastName, setLastName] = useState(welcomeStore.lastName)
    const [selectedGender, setSelectedGender] = useState("")

    const goBack = () => {
      welcomeStore.step3("", "", "")
      navigation.navigate("welcomeForm2")
    }

    const nextStep = () => {
      if (firstName && lastName) {
        welcomeStore.step3(firstName, lastName, selectedGender)
        navigation.navigate("welcomeForm4")
      } else {
        alertStore.showError("Please fill in all the fields and choose gender")
      }
    }

    const close = () => navigation.navigate("welcome")

    return (
      <View style={FULL}>
        <Screen style={CONTAINER} preset="scroll">
          <Stepper step={3} onClosePress={close} stepTx="stepper.step3" />

          <View style={TEXT_CONTENT}>
            <Text style={TEXT_CONTENT_TITLE} preset="h2" tx="welcomeForm3Screen.title" />
            <Text preset="h4Text" tx="welcomeForm3Screen.subtitle" />
          </View>

          <KeyboardAvoidingView behavior="height" keyboardVerticalOffset={0} style={INFO_CONTENT}>
            <ScrollView>
              <FormRow preset="bottom">
                <TextField
                  placeholderTx="welcomeForm3Screen.firstName"
                  value={firstName}
                  onChangeText={(text) => setFirstName(text)}
                />
              </FormRow>
              <FormRow preset="bottom">
                <TextField
                  placeholderTx="welcomeForm3Screen.lastName"
                  value={lastName}
                  onChangeText={(text) => setLastName(text)}
                />
              </FormRow>
              <FormRow preset="bottom">
                <Select
                  data={genders}
                  selectedCode={selectedGender}
                  setSelectedCode={setSelectedGender}
                />
              </FormRow>
            </ScrollView>
          </KeyboardAvoidingView>

          <View style={BUTTON_CONTENT}>
            <Button onPress={goBack} preset="cancel" style={BUTTON_BACK}>
              <View style={BUTTON_BACK_CONTENT}>
                <Icon icon="back" />
                <Text preset="h4" tx="common.back" />
              </View>
            </Button>
            <Button preset="primary" onPress={nextStep} style={BUTTON_NEXT}>
              <View style={BUTTON_NEXT_CONTENT}>
                <Text preset="h4" tx="welcomeForm3Screen.nextStep" style={BUTTON_NEXT_TEXT} />
                <Icon icon="next" />
              </View>
            </Button>
          </View>
        </Screen>
      </View>
    )
  },
)
