import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Rate = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="#00E6DB" {...props}>
    <Path d="M8.788 1.102c.495-1.003 1.926-1.003 2.421 0l2.358 4.778 5.273.766c1.107.16 1.549 1.522.748 2.303l-3.816 3.719.901 5.25c.19 1.104-.968 1.945-1.959 1.424l-4.716-2.48-4.715 2.48c-.99.52-2.148-.32-1.96-1.423l.901-5.251-3.815-3.72c-.801-.78-.359-2.141.748-2.302L6.43 5.88l2.358-4.778Z" />
  </Svg>
)

export default Rate
