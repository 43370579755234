import React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing } from "../../../../theme"
import {
  CustomLineChart,
  LineChartPoints,
} from "../../../../components/charts/line-chart/line-chart"
import { Text } from "../../../../components"
import { CustomBarChart } from "../../../../components/charts/bar-chart/bar-chart"

interface IChartData {
  typeChart: "Line" | "Bar"
  graphicPoints?: LineChartPoints[]
  legend: string[]
  labels: string[]
  age?: number
  averageLine?: number[]
  averageLineLabels?: string[]
  labelsXTitle?: string
  resolution: number
  xOffset?: number
}

interface ResultContentGraphicProps {
  title: string
  content: IChartData
}

export const ResultContentGraphic = observer((props: ResultContentGraphicProps) => {
  const { title, content } = props
  return (
    <View style={WRAPPER}>
      <Text preset="h3" text={title} style={GRAPH_TITLE} />
      {content.typeChart === "Line" ? (
        <CustomLineChart
          data={content.graphicPoints}
          resolution={content.resolution}
          xOffset={content.xOffset}
          legends={content.legend}
          averageLine={content.averageLine}
          averageLineLabels={content.averageLineLabels}
          labels={content.labels}
          age={content.age}
          labelsXTitle={content.labelsXTitle}
        />
      ) : (
        <CustomBarChart
          graphicPoints={content.graphicPoints}
          legends={content.legend}
          averageLine={content.averageLine}
          averageLineLabels={content.averageLineLabels}
          labels={content.labels}
          labelsXTitle={content.labelsXTitle}
        />
      )}
    </View>
  )
})

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
  paddingVertical: spacing[4],
}
const GRAPH_TITLE: TextStyle = {
  marginBottom: spacing[4],
}
