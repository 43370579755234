import { observer } from "mobx-react-lite"
import React, { useEffect, useState, useRef } from "react"
import { Animated, Modal, Pressable, TextStyle, View, ViewStyle, ImageStyle } from "react-native"
import { Button } from "../button/button"
import { Icon } from "../icon/icon"
import { Text } from "../text/text"
import { useStores } from "../../models"
import { color, spacing } from "../../theme"
import { useWindowWidth } from "../../utils/dimension"
import { AutoImage } from "../auto-image/auto-image"
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field"

const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const BG_OVERLAY: ViewStyle = {
  ...OVERLAY,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}
const CONTAINER: ViewStyle = {
  position: "absolute",
  right: 0,
  left: 0,
  bottom: 0,
  paddingTop: spacing[5],
  paddingBottom: spacing[7],
  paddingHorizontal: spacing[4],
  backgroundColor: color.background,
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  maxHeight: "100%",
  marginHorizontal: "auto",
}
const HEADER_CLOSE: ViewStyle = {
  flexDirection: "row",
  justifyContent: "flex-end",
}
const TITLE: TextStyle = {
  marginBottom: spacing[5],
  textAlign: "center",
}
const SUB_TITLE: TextStyle = {
  paddingBottom: spacing[5],
  textAlign: "center",
}
const CLOSE_BUTTON: ViewStyle = { width: 17 }
const VERIFICATION_BUTTON: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginBottom: spacing[5],
}
const VERIFICATION_BUTTON_IMAGE: ImageStyle = {
  marginRight: 11,
}
const CODE_CONTENT_TITLE: TextStyle = {
  textAlign: "center",
  marginBottom: spacing[4],
}
const CODE_INPUTS_WRAPPER: ViewStyle = {
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: spacing[4],
}
const INPUT: TextStyle = {
  marginHorizontal: spacing[2],
  textAlign: "center",
  width: 55,
  height: 60,
  fontSize: 16,
  color: color.palette.white,
  lineHeight: 57,
  backgroundColor: color.palette.black500,
  borderRadius: 8,
  borderColor: color.palette.blackBorder,
  borderWidth: 2,
  overflow: "hidden",
}
const INPUT_FOCUSED: ViewStyle = {
  borderColor: color.palette.brightTurquoise,
}
const CODE_CONTENT_SUBTITLE: TextStyle = {
  textAlign: "center",
  marginBottom: spacing[4],
}
const BUTTON_NEXT: ViewStyle = {
  flexDirection: "row",
  width: "60%",
  marginTop: spacing[2],
  justifyContent: "center",
}
const CONTENT: ViewStyle = {
  flexDirection: "column",
  alignItems: "center",
}

const letter = require("../../screens/welcome/images/letter.png")

interface EmailVerificationModalProps {
  visible: boolean
  closeModal?(): void
  onSuccess(): void
}

export const EmailVerificationModal = observer((props: EmailVerificationModalProps) => {
  const { visible, closeModal, onSuccess } = props
  const screenWidth = useWindowWidth()

  const [isCodeFieldVisible, setIsCodeFieldVisible] = useState(false)
  const CELL_COUNT = 5
  const [value, setValue] = useState("")
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT })
  const [codeProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  })
  const { welcomeStore, profileStore, alertStore } = useStores()
  const [loading, setLoading] = useState(false)

  const sendEmail = async () => {
    const result = await welcomeStore.sendConfirmation(profileStore.email)
    if (!result) {
      alertStore.showError("")
    }
    setIsCodeFieldVisible(true)
  }

  const verifyEmail = async () => {
    if (value.length !== 5) {
      alertStore.showError("The code must contain 5 digits. Please enter all digits")
      return
    }

    setLoading(true)
    const result = await welcomeStore.login(value)
    if (result?.customer?.isSuccess) {
      onSuccess()
    }
    if (result && result?.message) {
      alertStore.showError(result.message)
    }
    setLoading(false)
  }

  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }

  const fadeOut = () => {
    fadeAnim.setValue(0)
  }

  useEffect(() => {
    if (visible) {
      fadeIn()
    } else {
      fadeOut()
    }
  }, [visible])

  return (
    <>
      <Modal animationType="slide" transparent={true} visible={visible} onRequestClose={closeModal}>
        <Pressable style={OVERLAY} onPress={closeModal}>
          <Animated.View
            style={[
              BG_OVERLAY,
              {
                opacity: fadeAnim,
              },
            ]}
          />
        </Pressable>
        <View style={[CONTAINER, { maxWidth: screenWidth }]}>
          <View style={HEADER_CLOSE}>
            <Button preset="unstyled" onPress={closeModal} style={CLOSE_BUTTON}>
              <Icon icon="closeCircle" svgProps={{ fill: color.palette.white }} />
            </Button>
          </View>
          <Text preset="h2" tx="emailVerificationModal.title" style={TITLE} numberOfLines={2} />
          {isCodeFieldVisible ? (
            <View style={CONTENT}>
              <Text
                preset="h4Text"
                tx="welcomeForm2Screen.enterFiveDigits"
                style={CODE_CONTENT_TITLE}
              />
              <View style={CODE_INPUTS_WRAPPER}>
                <CodeField
                  ref={ref}
                  {...codeProps}
                  value={value}
                  onChangeText={setValue}
                  cellCount={CELL_COUNT}
                  keyboardType="number-pad"
                  textContentType="oneTimeCode"
                  renderCell={({ index, symbol, isFocused }) => (
                    <Text
                      key={index}
                      onLayout={getCellOnLayoutHandler(index)}
                      style={[INPUT, isFocused && INPUT_FOCUSED]}
                    >
                      {symbol || (isFocused ? <Cursor /> : null)}
                    </Text>
                  )}
                />
              </View>
              <Text
                preset="default"
                tx="emailVerificationModal.cantFind"
                style={CODE_CONTENT_SUBTITLE}
              />
              <Button
                preset="primary"
                onPress={verifyEmail}
                style={[
                  BUTTON_NEXT,
                  (loading || value.length !== 5) && ({ opacity: 0.8 } as ViewStyle),
                ]}
                disabled={loading || value.length !== 5}
                tx="emailVerificationModal.verifyNow"
              />
            </View>
          ) : (
            <View>
              <Button style={VERIFICATION_BUTTON} preset="outline" onPress={sendEmail}>
                <AutoImage style={VERIFICATION_BUTTON_IMAGE} source={letter} />
                <Text preset="h4" tx="emailVerificationModal.buttonText" />
              </Button>
              <Text tx="emailVerificationModal.subtitle" style={SUB_TITLE} />
            </View>
          )}
        </View>
      </Modal>
    </>
  )
})
