import React, { useEffect, useState } from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, Platform } from "react-native"
import DateTimePickerModal from "react-native-modal-datetime-picker"
import { TextInputMask } from "react-native-masked-text"
import { format, isMatch, isValid, parse } from "date-fns"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { color, spacing } from "../../theme"
import { translate, TxKeyPath } from "../../i18n"
import { texts } from "../../styles"
import { SelectTimeFormat } from "../select-timeformat/select-timeformat"

export interface TimePickerProps {
  placeholderTx: TxKeyPath
  value: string
  setValue(text: string): void
}

const INPUT_WRAPPER: ViewStyle = {
  position: "relative",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const INPUT_CONTAINER: ViewStyle = {
  flex: 1,
  marginRight: spacing[2],
}
const INPUT_ICON: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 20,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
}
const INPUT: TextStyle = {
  ...texts.H4_TEXT,
  paddingLeft: 55,
  paddingVertical: spacing[2],
  backgroundColor: color.palette.black500,
  borderWidth: 2,
  borderRadius: 8,
  borderColor: color.borderColor,
  minHeight: 60,
}
const INPUT_WITH_PLACEHOLDER: TextStyle = {
  paddingTop: spacing[3],
  paddingBottom: 0,
}
const FIELD_LABEL: TextStyle = {
  position: "absolute",
  top: 10,
  left: 55,
  zIndex: 1,
}
const SELECT_FORMAT: ViewStyle = {
  width: 80,
  overflow: "hidden",
}

// Todo support web
export function TimePicker(props: TimePickerProps) {
  const { placeholderTx, value, setValue } = props

  const TIME_FORMAT_12_FULL = "hh:mm a"
  const TIME_FORMAT_12 = "hh:mm"
  const TIME_FORMAT_24 = "HH:mm"

  const getUserSideValue = (value: string) => {
    let result = ""
    if (value?.length) {
      const convertedTime = parse(value, TIME_FORMAT_24, new Date())
      if (isValid(convertedTime)) {
        result = format(convertedTime, TIME_FORMAT_12)
      }
    }
    return result
  }

  const getTimeFormat = (value: string) => {
    let result = "AM"
    if (value?.length) {
      const convertedTime = parse(value, TIME_FORMAT_24, new Date())
      if (isValid(convertedTime)) {
        result = format(convertedTime, "a")
      }
    }
    return result
  }

  const [userSideValue, setUserSideValue] = useState(getUserSideValue(value))
  const [timeFormat, setTimeFormat] = useState(getTimeFormat(value))
  const [isTimePickerVisible, setTimePickerVisibility] = useState(false)
  const [focused, setFocused] = useState(false)

  const showTimePicker = () => {
    setTimePickerVisibility(true)
  }
  const hideTimePicker = () => {
    setTimePickerVisibility(false)
  }
  const handleTimeConfirm = (time: Date) => {
    setUserSideValue(format(time, TIME_FORMAT_12))
    hideTimePicker()
  }
  const onFocusChange = (isFocused: boolean) => {
    setFocused(isFocused)
    if (!isMatch(userSideValue, TIME_FORMAT_12)) {
      setUserSideValue("")
    }
  }

  useEffect(() => {
    if (userSideValue?.length === 5 && isMatch(userSideValue, TIME_FORMAT_12)) {
      const parsedTime = parse(userSideValue + " " + timeFormat, TIME_FORMAT_12_FULL, new Date())

      if (isValid(parsedTime)) {
        setValue(format(parsedTime, TIME_FORMAT_24))
      }
    } else {
      setValue("")
    }
  }, [userSideValue, timeFormat])

  return (
    <View style={INPUT_WRAPPER}>
      <TouchableOpacity onPress={showTimePicker} activeOpacity={1} style={INPUT_CONTAINER}>
        <Text
          preset="fieldLabel"
          text={
            placeholderTx && userSideValue?.length > 0 && userSideValue?.length < 5
              ? `${translate(placeholderTx)} (HH:MM)`
              : userSideValue?.length > 0
              ? translate(placeholderTx)
              : ""
          }
          style={FIELD_LABEL}
        />
        <View style={INPUT_ICON}>
          <Icon icon="time" />
        </View>

        <View pointerEvents={Platform.OS === "web" ? "auto" : "box-only"}>
          <TextInputMask
            type={"datetime"}
            options={{
              format: TIME_FORMAT_24,
            }}
            value={userSideValue}
            onChangeText={(time) => setUserSideValue(time)}
            onFocus={() => onFocusChange(true)}
            onBlur={() => {
              onFocusChange(false)
            }}
            style={[INPUT, placeholderTx && userSideValue?.length > 0 && INPUT_WITH_PLACEHOLDER]}
            placeholder={!focused ? translate(placeholderTx) : "HH:MM"}
            placeholderTextColor={color.palette.lighterGrey}
          />
        </View>
      </TouchableOpacity>
      <View style={SELECT_FORMAT}>
        <SelectTimeFormat selectedCode={timeFormat} setSelectedCode={setTimeFormat} />
      </View>
      <DateTimePickerModal
        isVisible={isTimePickerVisible}
        mode="time"
        is24Hour={false}
        onConfirm={handleTimeConfirm}
        onCancel={hideTimePicker}
      />
    </View>
  )
}
