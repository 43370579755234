import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Check = (props: SvgProps) => (
  <Svg width={18} height={13} viewBox="0 0 18 13" fill="#091023" {...props}>
    <Path d="M5.5 10.586 1.707 6.793A1 1 0 0 0 .293 8.207l4.5 4.5a1 1 0 0 0 1.414 0l11-11A1 1 0 0 0 15.793.293L5.5 10.586Z" />
  </Svg>
)

export default Check
