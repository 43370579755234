import React, { useEffect, useState } from "react"
import { FlatList, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import {
  Screen,
  Header,
  ScreenTitle,
  SkeletonView,
  SkeletonContainer,
  PurchaseModal,
} from "../../../components"
import { useStores } from "../../../models"
import { color, spacing } from "../../../theme"
import { PurchaseReportModal } from "./purchase-report-modal"
import { Card } from "../../../components/card/card"
import { OrderTypeEnum } from "../../../models/extra-order-store/extra-order-store"

export interface IProduct {
  bonus: {
    available: boolean
    remains: number
  }
  contentType: string
  description: string
  payment: string
  price: string
  product: string
  title: string
  index: number
}

export interface IDataItem {
  id: string
  backgroundUrl: string
  color: string
  description: string
  emoji: string
  products: IProduct[]
  slug: string
  title: string
  numOfPersons: number
}

interface CalculationTypeProp {
  goBack(): void
  navigateToCalculation(): void
}

export const CalculationType = observer(
  ({ goBack, navigateToCalculation }: CalculationTypeProp) => {
    const { profileStore, extraOrderStore } = useStores()

    const [selectedItem, setItem] = useState<IDataItem>()
    const [purchaseReportModalVisible, setPurchaseReportModalVisible] = useState(false)
    const [compatibilityTypesData, setCompatibilityTypesData] = useState<IDataItem[]>()
    const [loading, setLoading] = useState(true)

    const [purchaseVisible, setPurchaseVisible] = useState(false)

    const openModal = (item: IDataItem) => {
      if (profileStore.isNotSubscribed) {
        setPurchaseVisible(true)
      } else {
        setItem(item)
        setPurchaseReportModalVisible(true)
      }
    }

    useEffect(() => {
      ;(async () => {
        const response = await extraOrderStore.getCalculationTypes()
        if (response) {
          const tempResponse = response.map((type) => {
            if (type.products) {
              type.products = type.products.map((product, index: number) => ({
                index: index + 1,
                ...product,
              }))
            }
            return type
          })
          setCompatibilityTypesData(tempResponse)
        }

        setLoading(false)
      })()
    }, [])

    const navigateTo = (reportProduct: string, reportPrice: string, useBonus: boolean) => {
      extraOrderStore.init(selectedItem.title, selectedItem.slug, selectedItem.numOfPersons)
      extraOrderStore.setReport(reportProduct, reportPrice, useBonus)
      navigateToCalculation()
    }

    return (
      <>
        <Screen style={CONTAINER}>
          <Header leftIcon="back" onLeftPress={goBack} />
          <View style={WRAPPER}>
            {loading ? (
              <SkeletonContainer>
                <View>
                  {[1, 2, 3].map((index) => (
                    <View style={BLOCKS_SKELETON} key={index}>
                      <SkeletonView style={BLOCKS_SKELETON_ICON} />
                      <SkeletonView style={BLOCKS_SKELETON_TITLE} />
                      <View style={{ marginBottom: spacing[3] } as ViewStyle}>
                        <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                        <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                        <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                      </View>
                      <SkeletonView style={BLOCKS_SKELETON_BUTTON} />
                    </View>
                  ))}
                </View>
              </SkeletonContainer>
            ) : (
              <FlatList
                ListHeaderComponent={
                  <ScreenTitle
                    headerTx={
                      extraOrderStore.orderType === OrderTypeEnum.extra
                        ? "extraOrderCompatibilityScreen.title"
                        : "soulMateOrderCompatibilityScreen.title"
                    }
                  />
                }
                data={compatibilityTypesData}
                keyExtractor={(_, index) => String(index)}
                showsHorizontalScrollIndicator={false}
                renderItem={({ item }) => (
                  <Card
                    {...item}
                    buttonName={"extraOrderCompatibilityScreen.calculateNow"}
                    buttonAction={(item) => openModal(item)}
                  />
                )}
              />
            )}
          </View>
        </Screen>

        {selectedItem && (
          <PurchaseReportModal
            id={selectedItem.id?.toString()}
            visible={purchaseReportModalVisible}
            title={selectedItem.title}
            type={selectedItem.slug}
            products={selectedItem.products}
            closeModal={() => setPurchaseReportModalVisible(false)}
            callbackSelectedReport={(product) => {
              navigateTo(product.product, product.price, product.payment === "Bonus")
            }}
          />
        )}

        <PurchaseModal
          visible={purchaseVisible}
          closeModal={() => {
            setPurchaseVisible(false)
          }}
        />
      </>
    )
  },
)

const CONTAINER: ViewStyle = {
  paddingTop: spacing[4],
}
const WRAPPER: ViewStyle = {
  flex: 1,
  flexGrow: 1,
  paddingHorizontal: spacing[4],
}

// SKELETON FOR BLOCKS
const BLOCKS_SKELETON: ViewStyle = {
  marginBottom: 24,
  paddingHorizontal: 18,
  paddingVertical: 36,
  borderRadius: 16,
  backgroundColor: color.palette.black500,
}
const BLOCKS_SKELETON_ICON: ViewStyle = {
  marginBottom: spacing[4],
  width: 30,
  height: 30,
}
const BLOCKS_SKELETON_TITLE: ViewStyle = {
  marginBottom: spacing[2],
  width: "55%",
  height: 32,
}
const BLOCKS_SKELETON_DESCRIPTION: ViewStyle = {
  width: "90%",
  height: 15,
  marginBottom: 5,
}
const BLOCKS_SKELETON_BUTTON: ViewStyle = {
  width: "30%",
  height: 24,
  borderRadius: 18,
}
