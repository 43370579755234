import * as Localization from "expo-localization"
import * as storage from "../utils/storage"
import i18n from "i18n-js"
import en from "./en.json"
import es from "./es.json"

export const LANG_STORAGE_KEY = "language"

i18n.fallbacks = true
i18n.translations = { en, es }

i18n.locale = Localization.locale || "en"
storage.load(LANG_STORAGE_KEY).then((lang) => {
  i18n.locale = lang
})

export const changeLanguage = (lang: string) => {
  i18n.locale = lang
  storage.save(LANG_STORAGE_KEY, lang)
}

/**
 * Builds up valid keypaths for translations.
 * Update to your default locale of choice if not English.
 */
type DefaultLocale = typeof en
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>

// via: https://stackoverflow.com/a/65333050
type RecursiveKeyOf<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<TObj[TKey], `${TKey}`>
}[keyof TObj & (string | number)]

type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `['${TKey}']` | `.${TKey}`
  >
}[keyof TObj & (string | number)]

type RecursiveKeyOfHandleValue<TValue, Text extends string> = TValue extends any[]
  ? Text
  : TValue extends object
  ? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
  : Text
