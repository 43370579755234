import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Redeem = (props: SvgProps) => (
  <Svg width={21} height={21} viewBox="0 0 21 21" fill="#00E6DB" {...props}>
    <Path d="M6 0H3a3 3 0 0 0-3 3v3h2.708A2.5 2.5 0 0 1 6 2.708V0ZM0 7.5V12a3 3 0 0 0 3 3h3V8.56l-1.72 1.72a.75.75 0 0 1-1.06-1.06L4.94 7.5H0ZM7.5 15h1.519A6.5 6.5 0 0 1 20 10.81V7.5H8.56l1.72 1.72a.75.75 0 1 1-1.06 1.06L7.5 8.56V15ZM20 6h-9.208A2.5 2.5 0 0 0 7.5 2.708V0H17a3 3 0 0 1 3 3v3ZM8.5 6h-1V5a1 1 0 1 1 1 1ZM6 6H5a1 1 0 1 1 1-1.008V6Zm15 9.5a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0Zm-5 .5.001 2.503a.5.5 0 0 1-1 0V16h-2.505a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 1 0V15h2.497a.5.5 0 0 1 0 1H16Z" />
  </Svg>
)

export default Redeem
