import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Settings = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="#00E6DB" {...props}>
    <Path d="M10.013.25c.734.008 1.465.093 2.181.253a.75.75 0 0 1 .582.649l.17 1.527a1.385 1.385 0 0 0 1.928 1.116l1.4-.615a.75.75 0 0 1 .85.174 9.793 9.793 0 0 1 2.204 3.792.75.75 0 0 1-.271.825l-1.242.916a1.38 1.38 0 0 0 .001 2.226l1.243.915a.75.75 0 0 1 .271.826 9.798 9.798 0 0 1-2.203 3.792.75.75 0 0 1-.849.175l-1.406-.617a1.38 1.38 0 0 0-1.927 1.114l-.169 1.526a.75.75 0 0 1-.572.647 9.517 9.517 0 0 1-4.405 0 .75.75 0 0 1-.572-.647l-.17-1.524a1.382 1.382 0 0 0-1.924-1.11l-1.407.616a.75.75 0 0 1-.849-.175 9.798 9.798 0 0 1-2.203-3.796.75.75 0 0 1 .271-.826l1.244-.916a1.38 1.38 0 0 0 0-2.226L.946 7.973a.75.75 0 0 1-.272-.826 9.793 9.793 0 0 1 2.205-3.792.75.75 0 0 1 .849-.174l1.4.615a1.387 1.387 0 0 0 1.93-1.118l.17-1.526a.75.75 0 0 1 .583-.65C8.529.343 9.261.259 10.013.25ZM10 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" />
  </Svg>
)

export default Settings
