import { ApisauceInstance, create } from "apisauce"
import i18n from "i18n-js"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"
import * as storage from "../../utils/storage"

export const USER_TOKEN_STORAGE_KEY = "userToken"

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance

  /**
   * Configurable options.
   */
  config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    })

    this.apisauce.addAsyncRequestTransform(async (request) => {
      const authKey = await storage.load(USER_TOKEN_STORAGE_KEY)
      if (authKey) {
        request.headers.Authorization = "Bearer " + authKey
      }

      const currentLocale = i18n.currentLocale()
      if (currentLocale) {
        request.headers.Locale = currentLocale
      }
    })
  }

  addAuthResponseTransform(transformation: () => Promise<void>) {
    this.apisauce.addAsyncResponseTransform(async (response) => {
      if (response.status === 401) {
        await transformation()
      }
    })
  }
}
