import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Delete = (props: SvgProps) => (
  <Svg width={12} height={12} fill="#00E6DB" viewBox="0 0 12 12" {...props}>
    <Path d="M8 2h3a.5.5 0 0 1 0 1h-.553l-.752 6.776A2.5 2.5 0 0 1 7.21 12H4.79a2.5 2.5 0 0 1-2.485-2.224L1.552 3H1a.5.5 0 0 1 0-1h3a2 2 0 1 1 4 0ZM6 1a1 1 0 0 0-1 1h2a1 1 0 0 0-1-1ZM4.5 5v4a.5.5 0 1 0 1 0V5a.5.5 0 1 0-1 0ZM7 4.5a.5.5 0 0 0-.5.5v4a.5.5 0 1 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
  </Svg>
)

export default Delete
