import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Horoscope = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#C6D1E1" {...props}>
    <Path d="M18.5 19.13C20 17.77 20 15.18 20 14a4 4 0 0 0-4-4c-.7 0-1.4.2-2 .56V6a3 3 0 0 0-3-3c-.75 0-1.45.29-2 .78a2.997 2.997 0 0 0-4 0C4.45 3.28 3.74 3 3 3v2a1 1 0 0 1 1 1v10h2V6a1 1 0 0 1 2 0v10h2V6a1 1 0 0 1 2 0v8c0 1.18 0 3.77 1.5 5.13-.78.41-1.62.71-2.5.87v2c1.29 0 3.84-1.26 5-1.87 1.16.61 3.71 1.87 5 1.87v-2c-.88-.16-1.72-.46-2.5-.87ZM16 12a2 2 0 0 1 2 2c0 2.92-.54 4-2 4s-2-1.08-2-4a2 2 0 0 1 2-2Z" />
  </Svg>
)

export default Horoscope
