import React, { FC, useCallback, useEffect, useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { useFocusEffect } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import {
  Header,
  Screen,
  Text,
  Feedback,
  Video,
  PullUpScrollView,
  SkeletonContainer,
  SkeletonView,
  RenderHtmlWrapper,
} from "../../components"
import { color, spacing } from "../../theme"
import { HomeStackScreenProps } from "../../navigators"
import { useStores } from "../../models"

const FULL: ViewStyle = {
  flex: 1,
}
const CONTAINER: ViewStyle = {
  paddingHorizontal: spacing[4],
  paddingBottom: spacing[4],
  backgroundColor: color.background,
}
const FEEDBACK_CONTAINER: ViewStyle = {
  marginHorizontal: spacing[4],
}
const VIDEO_WRAPPER: ViewStyle = {
  marginLeft: -24,
  marginRight: -24,
  borderRadius: 0,
}
const TITLE: TextStyle = {
  paddingVertical: spacing[4],
}

const SKELETON_POST: ViewStyle = {
  width: "100%",
}
const SKELETON_POST_VIDEO: ViewStyle = {
  width: "100%",
  height: 310,
  marginBottom: spacing[4],
  backgroundColor: color.palette.black500,
}
const SKELETON_POST_CONTENT: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const SKELETON_POST_TITLE: ViewStyle = {
  width: "70%",
  height: 25,
  marginBottom: spacing[5],
}
const SKELETON_POST_TEXT: ViewStyle = {
  width: "100%",
  height: 11,
  marginBottom: spacing[2],
}

interface INextPost {
  id: string
  backgroundUrl: string
  emoji: string
}

interface IMedia {
  preview: string
  video: string
}

interface IPost {
  id: string
  media: IMedia
  title: string
  text: string
  next: INextPost | null
}

export const HomeIntroDetailsScreen: FC<HomeStackScreenProps<"homeIntroDetails">> = observer(
  ({ navigation, route }: any) => {
    const { id } = route.params
    const goBack = () => navigation.goBack()
    const [isFeedbackExpanded, setIsFeedbackExpanded] = useState(false)

    const { commonStore } = useStores()
    const [loading, setLoading] = useState(false)
    const [playAllowed, setPlayAllowed] = useState(true)
    const [post, setPost] = useState<IPost>({
      id: "",
      title: "",
      text: "",
      next: {
        id: "",
        backgroundUrl: null,
        emoji: "",
      },
      media: {
        preview: null,
        video: "",
      },
    })

    const loadMore = () => {
      setPlayAllowed(false)
      if (post.next?.id) {
        navigation.push("homeIntroDetails", { id: post.next.id })
      }
    }

    useFocusEffect(
      useCallback(() => {
        setPlayAllowed(true)
      }, [setPlayAllowed]),
    )

    useEffect(() => {
      if (id) {
        setLoading(true)
        ;(async () => {
          const result = await commonStore.getIntroSingleBlock(id)
          const post = result?.data?.data
          if (post) {
            setPost({
              id: post.id,
              title: post.title,
              text: post.text,
              next: post.next
                ? {
                    id: post.next.id,
                    backgroundUrl: post.next.backgroundUrl,
                    emoji: post.next.emoji,
                  }
                : null,
              media: {
                preview: post.media.preview,
                video: post.media.url,
              },
            })
          }
          setLoading(false)
        })()
      }
    }, [])

    return (
      <View style={FULL}>
        <Screen header={<Header headerText={post.title} leftIcon="back" onLeftPress={goBack} />}>
          {loading ? (
            <SkeletonContainer>
              <View style={SKELETON_POST}>
                <SkeletonView style={SKELETON_POST_VIDEO} />
                <View style={SKELETON_POST_CONTENT}>
                  <SkeletonView style={SKELETON_POST_TITLE} />
                  {[...Array(20).keys()].map((index) => (
                    <SkeletonView key={index} style={SKELETON_POST_TEXT} />
                  ))}
                </View>
              </View>
            </SkeletonContainer>
          ) : (
            <PullUpScrollView
              pullUpBackgroundUrl={post.next?.backgroundUrl}
              pullUpIcon={post.next?.emoji}
              pullUpIsLastPost={!post.next?.id}
              loadMore={loadMore}
              isFeedbackExpanded={isFeedbackExpanded}
            >
              <Video
                src={post.media.video}
                previewSrc={post.media.preview}
                wrapperStyle={VIDEO_WRAPPER}
                playAllowed={playAllowed}
              />

              <View style={CONTAINER}>
                <Text preset="h2" text={post.title} style={TITLE} />
                <RenderHtmlWrapper html={post.text} />
              </View>

              <View style={FEEDBACK_CONTAINER}>
                <Feedback
                  contentId={id}
                  isExpanded={isFeedbackExpanded}
                  setIsExpanded={setIsFeedbackExpanded}
                />
              </View>
            </PullUpScrollView>
          )}
        </Screen>
      </View>
    )
  },
)
