import React from "react"
import { View, ViewStyle, ImageStyle, TextStyle, ImageSourcePropType } from "react-native"
import { AutoImage, Text } from "../../../../components"
import { spacing } from "../../../../theme"
import { TxKeyPath } from "../../../../i18n"

const pointerImage = require("../images/pointer.png")

interface IFormulaHeadingProps {
  title: TxKeyPath
  description: TxKeyPath
  image: ImageSourcePropType
}

export function FormulaHeading(props: IFormulaHeadingProps) {
  return (
    <View style={FORMULA_CONTAINER}>
      <View style={IMAGE_CONTAINER}>
        <AutoImage style={HEADER_IMG} source={props.image} />
      </View>

      <Text style={MT_4} preset="h2" tx={props.title} />
      <Text style={MT_4} preset="default" tx={props.description} />
      <AutoImage style={POINTER_IMG} source={pointerImage} />
    </View>
  )
}

const FORMULA_CONTAINER: ViewStyle | TextStyle = {
  textAlign: "center",
  marginHorizontal: spacing[4],
  alignSelf: "center",
}
const IMAGE_CONTAINER: ViewStyle = {
  borderRadius: 100,
  alignSelf: "center",
}
const HEADER_IMG: ImageStyle = {
  alignSelf: "center",
  width: 140,
  height: 140,
}
const MT_4: TextStyle = { marginTop: spacing[4], textAlign: "center" }
const POINTER_IMG: ImageStyle = {
  position: "absolute",
  right: 30,
  bottom: -27,
  height: 25,
  width: 25,
}
