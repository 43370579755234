import React, { FC } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { PostView } from "../../components"
import { HomeStackScreenProps } from "../../navigators"

export const MyCodeOfSamsaraDetailsScreen: FC<HomeStackScreenProps<"myCodeOfSamsaraDetails">> =
  observer(({ route, navigation }: any) => {
    const goBack = () => navigation.goBack()

    const navigationToNextPost = (orderId: string, id: string) => {
      navigation.push("myCodeOfSamsaraDetails", { orderId, id })
    }

    return (
      <View style={FULL}>
        <PostView
          orderId={route.params.orderId}
          id={route.params.id}
          goBack={goBack}
          navigationToNextPost={navigationToNextPost}
        />
      </View>
    )
  })

const FULL: ViewStyle = { flex: 1 }
