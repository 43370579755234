import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Views = (props: SvgProps) => (
  <Svg width={35} height={26} viewBox="0 0 35 26" fill="#fff" {...props}>
    <Path d="M12.844 12.895c0 1.175.473 2.302 1.317 3.132a4.53 4.53 0 0 0 3.178 1.298 4.53 4.53 0 0 0 3.18-1.298 4.398 4.398 0 0 0 1.316-3.132 4.398 4.398 0 0 0-1.317-3.133 4.53 4.53 0 0 0-3.179-1.297 4.53 4.53 0 0 0-3.178 1.297 4.398 4.398 0 0 0-1.317 3.133Zm21.924-1.02C30.963 3.974 25.211 0 17.5 0 9.785 0 4.037 3.975.232 11.878a2.356 2.356 0 0 0 0 2.037C4.037 21.815 9.789 25.79 17.5 25.79c7.715 0 13.463-3.976 17.268-11.879.31-.64.31-1.384 0-2.037ZM17.34 19.855c-3.901 0-7.064-3.117-7.064-6.961 0-3.845 3.163-6.962 7.064-6.962 3.902 0 7.065 3.117 7.065 6.962 0 3.844-3.163 6.961-7.065 6.961Z" />
  </Svg>
)

export default Views
