import React, { FC, useState } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Header, ScreenTitle, Pills, IPillData } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { AboutSamsaraProfile } from "./profile-about/about-samsara-screen"
/* import { FoundersProfile } from "./profile-about/founders"
import { ExpertsProfile } from "./profile-about/experts" */
import { spacing } from "../../theme"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
}
const ABOUT_TITLE: ViewStyle = {
  paddingVertical: 0,
}

interface IAboutUs {
  id: number
  name: string
  component: any
}

const AboutUsData: IAboutUs[] = [
  {
    id: 1,
    name: "About Samsara",
    component: AboutSamsaraProfile,
  },
  /* {
    id: 2,
    name: "Founders",
    component: FoundersProfile,
  },
  {
    id: 3,
    name: "Experts",
    component: ExpertsProfile,
  }, */
]

export const ProfileAboutScreen: FC<ProfileStackScreenProps<"profileAbout">> = observer(
  ({ navigation }) => {
    const goBack = () => navigation.goBack()

    const [activeCategory, setActiveCategory] = useState<string | number>(1)
    const [currentProfileData, setCurrentProfileData] = useState<IAboutUs>(AboutUsData[0])

    const filterCategory = (data: IPillData) => {
      setActiveCategory(data.id)
      const index = AboutUsData.findIndex((d) => d.id === data.id)
      setCurrentProfileData(AboutUsData[index])
    }

    return (
      <View style={FULL}>
        <Screen preset="scroll" header={<Header leftIcon="back" onLeftPress={goBack} />}>
          <View style={CONTAINER}>
            <ScreenTitle headerTx="profileAboutScreen.title" style={ABOUT_TITLE} />
          </View>
          <View>
            <Pills data={AboutUsData} activeId={activeCategory} onPress={filterCategory} />
          </View>
          <View style={CONTAINER}>{currentProfileData.component()}</View>
        </Screen>
      </View>
    )
  },
)
