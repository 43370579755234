import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const About = (props: SvgProps) => (
  <Svg width={21} height={21} viewBox="0 0 21 21" fill="#00E6DB" {...props}>
    <Path d="M10-.001c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10.001-10.002 10.001-5.524 0-10-4.478-10-10.001C-.001 4.477 4.476-.001 10-.001Zm-.004 8.25a1 1 0 0 0-.992.885l-.007.116.004 5.502.006.117a1 1 0 0 0 1.987-.002L11 14.75l-.004-5.501-.007-.117a1 1 0 0 0-.994-.882l.001-.001Zm.005-3.749a1.251 1.251 0 1 0 .002 2.503A1.251 1.251 0 0 0 10 4.5h.001Z" />
  </Svg>
)

export default About
