import React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { Text } from "../../../../components"
import { color, spacing } from "../../../../theme"

export interface ISolutionStepItem {
  numbers: string
  type: string
}

interface ISolutionStepsProps {
  result: string
  steps: ISolutionStepItem[]
}

export function FormulaSolutionSteps(props: ISolutionStepsProps) {
  return (
    <View style={FORMULA_CONTAINER}>
      <Text preset="h4" tx="freeFormulasScreen.yourNumber" style={TEXT_CENTER} />
      <View style={RESULT_CONTAINER}>
        <Text preset="h3" style={RESULT_VALUE} text={props.result} />
      </View>

      <View style={SOLUTION_CONTAINER}>
        {props.steps.map((step, index, arr) => (
          <View key={index} style={SOLUTION_STEPS}>
            {arr.length !== 1 && step.type !== "month" && (
              <Text style={STEPS_PLUS} preset="defaultLight" text="+" />
            )}
            <View>
              <Text preset="defaultLight" text={step.numbers} />
              <View style={STEPS_BORDER} />
              <Text style={STEPS_DATE} preset="fieldLabel" text={step.type} />
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

const TEXT_CENTER: TextStyle = { textAlign: "center" }
const FORMULA_CONTAINER: ViewStyle = { marginTop: spacing[4] }
const RESULT_CONTAINER: ViewStyle = {
  borderRadius: 100,
  backgroundColor: color.palette.brightTurquoise,
  width: 50,
  height: 50,
  alignSelf: "center",
  justifyContent: "center",
  marginTop: spacing[4],
  ...TEXT_CENTER,
}
const RESULT_VALUE: TextStyle = { color: color.palette.black, textAlign: "center" }
const SOLUTION_CONTAINER: ViewStyle = {
  ...TEXT_CENTER,
  marginTop: spacing[4],
  alignSelf: "center",
  flexDirection: "row",
}
const SOLUTION_STEPS: ViewStyle = { flexDirection: "row" }
const STEPS_PLUS: ViewStyle = { marginHorizontal: spacing[3] }
const STEPS_BORDER: ViewStyle = {
  marginTop: spacing[2],
  height: 5,
  borderColor: color.palette.moderateBlue,
  borderWidth: 1,
  borderTopWidth: 0,
}
const STEPS_DATE: TextStyle = {
  marginTop: spacing[2],
  color: color.palette.brightTurquoise,
}
