import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"

export interface IPlaceOfBirth {
  geonameId?: number
  toponymName?: string
  name: string
  displayName?: string
}

export const PlaceOfBirthModel = types.model("PlaceOfBirth").props({
  geonameId: types.maybeNull(types.number),
  toponymName: types.maybeNull(types.string),
  name: types.string,
  displayName: types.optional(types.string, ""),
})

export interface PlaceOfBirthStore extends Instance<typeof PlaceOfBirthModel> {}
export interface PlaceOfBirthStoreSnapshotOut extends SnapshotOut<typeof PlaceOfBirthModel> {}
export interface PlaceOfBirthStoreSnapshotIn extends SnapshotIn<typeof PlaceOfBirthModel> {}
export const createCharacterDefaultModel = () => types.optional(PlaceOfBirthModel, {})
