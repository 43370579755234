import React from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity } from "react-native"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { PersonCircle } from "../person-circle/person-circle"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"
import { OrderTypeEnum } from "../../models/extra-order-store/extra-order-store"
export interface UserCardProps {
  firstName: string
  lastName: string
  fullName: string
  userCardDefault: boolean
  date: string
  time: string
  genderCode: string
  editProfile(): any
  deleteProfile?: any
}

export function UserCard(props: UserCardProps) {
  const {
    firstName,
    lastName,
    fullName,
    userCardDefault,
    date,
    time,
    genderCode,
    editProfile,
    deleteProfile,
  } = props

  const { extraOrderStore } = useStores()

  return (
    <View style={CONTAINER}>
      <View style={HEADER}>
        <TouchableOpacity
          style={userCardDefault ? ACTION_BUTTON_WRAPPER_HIDE : ACTION_BUTTON_WRAPPER}
          onPress={deleteProfile}
        >
          <View style={ACTION_BUTTON}>
            <Icon icon="delete" />
            <Text tx="userCard.delete" style={ACTION_BUTTON_TEXT} />
          </View>
        </TouchableOpacity>
        <PersonCircle
          firstName={firstName}
          lastName={lastName}
          genderCode={genderCode}
          style={PERSON_CIRCLE_STYLE}
        />
        <TouchableOpacity style={ACTION_BUTTON_WRAPPER} onPress={editProfile}>
          <View style={ACTION_BUTTON}>
            <Icon icon="edit" />
            <Text tx="userCard.edit" style={ACTION_BUTTON_TEXT} />
          </View>
        </TouchableOpacity>
      </View>
      <View style={CARD_TITLE}>
        <Text preset="h3" text={fullName} style={CARD_TITLE_TEXT} />
        {extraOrderStore.orderType === OrderTypeEnum.soulMate && (
          <Text
            preset="h3"
            tx="userCard.you"
            style={userCardDefault ? NEW_USER_CALL : NEW_USER_CALL_HIDE}
          />
        )}
      </View>
      <View style={INTERACT_BUTTONS_ROW}>
        <View style={DATE_WRAPPER}>
          <Icon icon="calendarLight" />
          <Text text={date} style={USER_BIRTH_DATE} />
        </View>
        <View style={DATE_WRAPPER}>
          <Icon icon="timeLight" />
          <Text text={time} style={USER_BIRTH_DATE} />
        </View>
      </View>
    </View>
  )
}

const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[6],
  backgroundColor: color.palette.black500,
  borderRadius: 8,
}
const HEADER: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing[4],
}
const ACTION_BUTTON_WRAPPER: ViewStyle = {
  padding: spacing[3],
  backgroundColor: color.palette.inputColor,
  borderRadius: 28,
}
const ACTION_BUTTON_WRAPPER_HIDE: ViewStyle = {
  opacity: 0,
}
const ACTION_BUTTON: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const ACTION_BUTTON_TEXT: TextStyle = {
  marginLeft: 8,
  fontSize: 12,
  fontWeight: "500",
  lineHeight: 16,
  color: color.palette.white,
}
const PERSON_CIRCLE_STYLE: ViewStyle = {
  marginRight: 0,
}
const CARD_TITLE: ViewStyle = {
  flexDirection: "row",
  justifyContent: "center",
  marginBottom: spacing[4],
}
const CARD_TITLE_TEXT: TextStyle = {
  maxWidth: 300,
  lineHeight: 28,
  textAlign: "center",
}
const INTERACT_BUTTONS_ROW: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
}
const DATE_WRAPPER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}
const USER_BIRTH_DATE: TextStyle = {
  marginLeft: 6,
  fontSize: 12,
  lineHeight: 16,
  color: color.palette.white,
}
const NEW_USER_CALL_HIDE: ViewStyle = {
  display: "none",
}
const NEW_USER_CALL: ViewStyle = {
  display: "flex",
}
