import { LinearGradient } from "expo-linear-gradient"
import React, { useEffect, useRef, useState } from "react"
import {
  Animated,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native"
import { Button, Checkbox, Text } from "../../../components"
import { color, spacing } from "../../../theme"
import { useWindowWidth } from "../../../utils/dimension"
import { IProduct } from "./calculation-type"

interface PurchaseReportModalProps {
  id: string
  visible: boolean
  title: string
  type: string
  products: IProduct[]
  closeModal(): void
  callbackSelectedReport(product: IProduct): void
}

enum PaymentMethodTypesEnum {
  Paid = "purchaseReportModal.payNow",
  Bonus = "purchaseReportModal.useBonuses",
}

export function PurchaseReportModal(prop: PurchaseReportModalProps) {
  const screenWidth = useWindowWidth()

  const fadeAnim = useRef(new Animated.Value(0)).current
  const paymentMethodTypes = [PaymentMethodTypesEnum.Paid, PaymentMethodTypesEnum.Bonus]

  const [selectedReport, setSelectedReport] = useState(0)
  const [activeTab, setActiveTab] = useState<string>(PaymentMethodTypesEnum.Paid)

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }

  const fadeOut = () => fadeAnim.setValue(0)

  useEffect(() => {
    prop.visible ? fadeIn() : fadeOut()
  }, [prop.visible])

  const selectType = () => {
    if (selectedReport) {
      const selectedProduct = prop.products.find((p) => p.index === selectedReport)
      if (selectedProduct) {
        prop.callbackSelectedReport(selectedProduct)
        prop.closeModal()
      }
    }
  }

  return (
    <>
      {prop.visible && (
        <Animated.View
          style={[
            BG_OVERLAY,
            {
              opacity: fadeAnim,
            },
          ]}
        />
      )}
      <Modal
        animationType="fade"
        transparent={true}
        visible={prop.visible}
        onRequestClose={prop.closeModal}
      >
        <Pressable style={OVERLAY} onPress={prop.closeModal} />
        {prop.products && (
          <View style={MODAL_CENTER}>
            <ScrollView style={[CONTAINER, { maxWidth: screenWidth - 2 * spacing[4] }]}>
              <View style={HEADER}>
                <Text
                  preset="h2"
                  tx="chosenReportType.title"
                  numberOfLines={1}
                  style={HEADER_TITLE}
                />
                <Text preset="h4" text={prop.title} style={HEADER_SUB_TITLE} numberOfLines={1} />
                <Text
                  tx={
                    prop.type === "code"
                      ? "purchaseReportModal.descriptionForAnalysis"
                      : "purchaseReportModal.descriptionForReport"
                  }
                />
              </View>

              <View style={TAB_CONTROL}>
                {paymentMethodTypes.map((type, index) => (
                  <View key={index} style={[TAB_ITEM, activeTab === type && TAB_ITEM_ACTIVE]}>
                    <TouchableOpacity onPress={() => setActiveTab(type)}>
                      <Text
                        style={[TAB_TEXT, activeTab === type && TAB_TEXT_ACTIVE]}
                        preset="h4"
                        tx={type}
                      />
                    </TouchableOpacity>
                  </View>
                ))}
              </View>

              {activeTab &&
                prop.products
                  .filter((f) =>
                    activeTab === PaymentMethodTypesEnum.Paid
                      ? f.payment === "Paid"
                      : f.payment === "Bonus",
                  )
                  .map((purchase) => (
                    <TouchableOpacity
                      key={purchase.index}
                      style={[
                        PURCHASE_CARD,
                        selectedReport === purchase.index ? BORDER_TURQUOISE : BORDER_TRANSPARENT,
                      ]}
                      onPress={() => setSelectedReport(purchase.index)}
                      disabled={purchase.bonus && !purchase.bonus.available}
                    >
                      {selectedReport === purchase.index && (
                        <LinearGradient
                          start={[0, 0]}
                          end={[1, 0]}
                          colors={["rgba(0, 230, 219, 0.2)", "rgba(0, 230, 219, 0)"]}
                          style={PURCHASE_CARD_GRADIENT}
                        />
                      )}
                      <Checkbox style={CHECKBOX} value={selectedReport === purchase.index} />

                      <View style={PURCHASE_CARD_CONTENT}>
                        <Text preset="h4" text={purchase.title} style={CARD_TITLE} />
                        <Text text={purchase.description} />
                      </View>

                      <View style={PURCHASE_PRICE_CONTENT_WRAPPER}>
                        <LinearGradient
                          start={[-0.1, -0.2]}
                          colors={["rgba(0, 230, 217, 0.5)", color.palette.black500]}
                          style={[
                            PURCHASE_PRICE_CONTENT,
                            purchase.bonus && !purchase.bonus.available && OPACITY,
                          ]}
                        >
                          <Text preset="bold" text={purchase.price} />
                        </LinearGradient>

                        {purchase.bonus && (
                          <Text style={REMAINING_BONUS} text={purchase.bonus.remains.toString()} />
                        )}
                      </View>
                    </TouchableOpacity>
                  ))}
              <Button
                style={BUTTON_SELECT}
                preset="primary"
                tx="chosenReportType.select"
                onPress={selectType}
              />
              <Button preset="cancel" tx="common.cancel" onPress={prop.closeModal} />
            </ScrollView>
          </View>
        )}
      </Modal>
    </>
  )
}

const TAB_CONTROL: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  borderRadius: 8,
  backgroundColor: color.palette.black,
  marginBottom: spacing[5],
}
const TAB_ITEM: ViewStyle = {
  flex: 1,
  borderRadius: 8,
  paddingVertical: spacing[2],
}
const TAB_ITEM_ACTIVE: ViewStyle = {
  borderRadius: 8,
  backgroundColor: color.palette.brightTurquoise,
}
const TAB_TEXT: TextStyle = {
  textAlign: "center",
}
const TAB_TEXT_ACTIVE: TextStyle = {
  color: color.palette.black,
}
const BORDER_TURQUOISE = {
  borderRadius: 16,
  borderWidth: 2,
  borderColor: color.palette.brightTurquoise,
}
const BORDER_TRANSPARENT = {
  borderRadius: 16,
  borderWidth: 2,
  borderColor: color.palette.black500,
}
const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const BG_OVERLAY: ViewStyle = {
  ...OVERLAY,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}
const MODAL_CENTER: ViewStyle = {
  justifyContent: "center",
  alignItems: Platform.OS === "web" ? "center" : "stretch",
  height: "90%",
}
const CONTAINER: ViewStyle = {
  flexGrow: 0,
  width: "100%",
  maxHeight: "90%",
  paddingHorizontal: spacing[4],
  backgroundColor: color.palette.black500,
  borderRadius: 16,
  marginHorizontal: spacing[4],
}
const HEADER: TextStyle = {
  textAlign: "center",
  paddingVertical: spacing[5],
}
const HEADER_TITLE: TextStyle = {
  paddingBottom: spacing[6],
}
const HEADER_SUB_TITLE: TextStyle = {
  paddingBottom: spacing[4],
}
const CHECKBOX: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  width: 56,
}
const PURCHASE_CARD: ViewStyle = {
  position: "relative",
  borderRadius: 16,
  flexDirection: "row",
  justifyContent: "space-between",
  paddingVertical: spacing[4],
  marginBottom: spacing[3],
  backgroundColor: color.palette.black,
}
const PURCHASE_CARD_GRADIENT: ViewStyle = {
  borderRadius: 16,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}
const PURCHASE_CARD_CONTENT: ViewStyle = {
  flex: 1,
  flexDirection: "column",
}
const PURCHASE_PRICE_CONTENT_WRAPPER: ViewStyle = {
  borderRadius: 40,
  paddingHorizontal: spacing[4],
  alignItems: "center",
  justifyContent: "center",
}
const PURCHASE_PRICE_CONTENT: ViewStyle = {
  ...BORDER_TURQUOISE,
  paddingHorizontal: spacing[3],
  paddingVertical: spacing[1],
}
const OPACITY: ViewStyle = { opacity: 0.3 }
const CARD_TITLE: TextStyle = {
  paddingBottom: spacing[1],
}
const BUTTON_SELECT: ViewStyle = {
  marginTop: spacing[2],
  marginBottom: spacing[1],
}
const REMAINING_BONUS: TextStyle = {
  textAlign: "center",
  paddingVertical: spacing[2],
}
