import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Star = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="#C6D1E1" {...props}>
    <Path
      opacity={0.5}
      d="M8.788 1.103c.495-1.004 1.926-1.004 2.421 0l2.358 4.777 5.273.766c1.107.161 1.549 1.522.748 2.303l-3.816 3.72.901 5.25c.19 1.103-.968 1.944-1.959 1.424l-4.716-2.48-4.715 2.48c-.99.52-2.148-.32-1.96-1.424l.901-5.25-3.815-3.72c-.801-.78-.359-2.142.748-2.303L6.43 5.88l2.358-4.777Z"
    />
  </Svg>
)

export default Star
