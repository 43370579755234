import React, { FC, useState } from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Text, Screen, Stepper, FormRow, TextField, Button, Icon } from "../../components"
import { color, spacing } from "../../theme"
import { WelcomeStackScreenProps } from "../../navigators"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  flex: 1,
  padding: spacing[4],
}
const TITLE: TextStyle = {
  marginBottom: spacing[2],
}
const ROW: ViewStyle = {
  flexGrow: 1,
  justifyContent: "center",
}
const BUTTON_CONTENT: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
}
const BUTTON_NEXT: ViewStyle = {
  width: "100%",
  justifyContent: "center",
}
const BUTTON_NEXT_CONTENT: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}
const BUTTON_NEXT_TEXT: TextStyle = {
  color: color.palette.black,
  flexGrow: 1,
  textAlign: "center",
}

export const WelcomeForm1Screen: FC<WelcomeStackScreenProps<"welcomeForm1">> = observer(
  ({ navigation }) => {
    const close = () => navigation.navigate("welcome")
    const [email, setEmail] = useState("")

    const { welcomeStore, alertStore } = useStores()

    const nextStep = async () => {
      const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/
      if (email) {
        if (reg.test(email)) {
          const result = await welcomeStore.sendConfirmation(email)
          if (result) {
            navigation.navigate("welcomeForm2")
          } else {
            alertStore.showError("")
          }
        } else if (!reg.test(email)) {
          alertStore.showError("Email is not correct")
        }
      } else {
        alertStore.showError("Enter your email")
      }
      welcomeStore.step3("", "", "")
    }

    return (
      <View style={FULL}>
        <Screen style={CONTAINER} preset="scroll">
          <Stepper step={1} onClosePress={close} stepTx="stepper.step1" />

          <View>
            <Text style={TITLE} preset="h2" tx="welcomeForm1Screen.title" />
            <Text preset="h4Text" tx="welcomeForm1Screen.subtitle" />
          </View>

          <FormRow preset="bottom" style={ROW}>
            <TextField
              textIcon="letter"
              placeholderTx="welcomeForm1Screen.yourEmail"
              onChangeText={(text) => setEmail(text)}
              defaultValue={email}
            />
          </FormRow>

          <View style={BUTTON_CONTENT}>
            <Button preset="primary" onPress={nextStep} style={BUTTON_NEXT}>
              <View style={BUTTON_NEXT_CONTENT}>
                <Text preset="h4" tx="welcomeForm2Screen.nextStep" style={BUTTON_NEXT_TEXT} />
                <Icon icon="next" />
              </View>
            </Button>
          </View>
        </Screen>
      </View>
    )
  },
)
