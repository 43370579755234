import React, { createContext, ReactNode, useEffect, useRef } from "react"
import { Animated } from "react-native"
import { color } from "../../theme"

export interface SkeletonContextProps {
  backgroundColor?: string | Animated.AnimatedInterpolation
}

export const SkeletonContext = createContext<SkeletonContextProps>({
  backgroundColor: color.palette.black500,
})

export interface SkeletonContainerProps {
  children?: ReactNode
}

export function SkeletonContainer(props: SkeletonContainerProps) {
  const { children } = props

  const animation = useRef(new Animated.Value(0)).current

  const loop = Animated.loop(
    Animated.sequence([
      Animated.timing(animation, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      }),
      Animated.timing(animation, {
        toValue: 0,
        duration: 500,
        useNativeDriver: false,
      }),
    ]),
  )

  useEffect(() => {
    loop.start()

    return () => loop.stop()
  }, [animation])

  const backgroundColor = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [color.palette.black500, color.skeletonColor],
  })

  return <SkeletonContext.Provider value={{ backgroundColor }}>{children}</SkeletonContext.Provider>
}
