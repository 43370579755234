import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const PersonCircle = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#C6D1E1" {...props}>
    <Path d="M12 24C5.372 24 0 18.628 0 12S5.372 0 12 0s12 5.372 12 12-5.372 12-12 12Zm6-10.2a1.8 1.8 0 0 0-1.8-1.8H7.8A1.8 1.8 0 0 0 6 13.8v.6c0 2.365 2.232 4.8 6 4.8s6-2.435 6-4.8v-.6Zm-2.7-6.3a3.3 3.3 0 1 0-6.6 0 3.3 3.3 0 0 0 6.6 0Z" />
  </Svg>
)

export default PersonCircle
