import React, { FC, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, Animated, FlatList, Platform } from "react-native"
import {
  Screen,
  Header,
  Pills,
  Subscribe,
  PurchaseModal,
  SkeletonContainer,
  SkeletonView,
  IPillData,
} from "../../components"
import { color, spacing } from "../../theme"
import { HomeStackScreenProps } from "../../navigators"
import { useStores } from "../../models"
import { Card } from "../../components/card/card"
import { MyCodeOfSamsaraTabBar } from "./components/tab-bar/tab-bar"
import { useWebOnScroll } from "../../utils/use-web-on-scroll-hook"

export const MyCodeOfSamsaraScreen: FC<HomeStackScreenProps<"myCodeOfSamsara">> = observer(
  ({ navigation, route }) => {
    const { commonStore } = useStores()
    const goBack = () => navigation.goBack()

    const [categories, setCategories] = useState([])
    const [orderId, setOrderId] = useState<string>()
    const [activeCategory, setActiveCategory] = useState<string>()
    const [blocks, setBlocks] = useState([])
    const [listData, setListData] = useState([])
    const [loading, setLoading] = useState(true)
    const [purchaseVisible, setPurchaseVisible] = useState(false)

    const flatListRef = useRef<FlatList>(null)

    useEffect(() => {
      ;(async () => {
        setActiveCategory("")
        setCategories([])
        setOrderId("")
        setBlocks([])
        setListData([])
        await loadBlock()
      })()
    }, [route])

    const loadBlock = async () => {
      setLoading(true)
      try {
        const result = await commonStore.getCodeOfSamsaraBlocks()

        if (result) {
          setCategories(result.categories)
          setBlocks(result.blocksData)
          setListData(result.blocksData)
          setOrderId(result.orderId)
        }
      } finally {
        setLoading(false)
      }
    }

    const filterCategory = (data: IPillData) => {
      if (data.id === activeCategory) {
        setActiveCategory(null)
        setListData(blocks)
      } else {
        setActiveCategory(data.id.toString())
        if (data.name.toLowerCase() === "available reports") {
          setListData(blocks.filter((item) => item.status.toLowerCase() === "unlocked"))
        } else {
          setListData(
            blocks.filter(
              (item) => item.category.toLowerCase() === data.id.toString().toLowerCase(),
            ),
          )
        }
      }
    }

    const navigateTo = (card) => {
      navigation.navigate("myCodeOfSamsaraDetails", { orderId, id: card.id })
    }

    const scrollY = useRef(new Animated.Value(0))
    const fixedHeaderHeight = 56
    const pillsHeight = 58
    const tabBarHeight = 88
    const clampOffsetHeight = 40 // delay animation
    const clampHeight = clampOffsetHeight + 2 * pillsHeight
    const scrollYClamped = Animated.diffClamp(scrollY.current, 0, clampHeight)

    const onScroll = Animated.event([{ nativeEvent: { contentOffset: { y: scrollY.current } } }], {
      useNativeDriver: true,
    })

    const translateY = scrollYClamped.interpolate({
      inputRange: [clampOffsetHeight, clampHeight],
      outputRange: [0, -pillsHeight],
      extrapolate: "clamp",
    })

    const opacity = scrollYClamped.interpolate({
      inputRange: [clampOffsetHeight, (clampOffsetHeight + clampHeight) / 2, clampHeight],
      outputRange: [1, 0.4, 0],
      extrapolate: "clamp",
    })

    const headerBlurHeight = scrollYClamped.interpolate({
      inputRange: [clampOffsetHeight, clampHeight],
      outputRange: [fixedHeaderHeight + pillsHeight, fixedHeaderHeight],
      extrapolate: "clamp",
    })

    const translateYTabBar = scrollYClamped.interpolate({
      inputRange: [clampOffsetHeight, clampHeight],
      outputRange: [0, tabBarHeight],
      extrapolate: "clamp",
    })

    const translateYNumber = useRef()
    translateY.addListener(({ value }) => {
      translateYNumber.current = value
    })

    const getCloser = (value: number, checkOne: number, checkTwo: number) =>
      Math.abs(value - checkOne) < Math.abs(value - checkTwo) ? checkOne : checkTwo

    const onScrollEnd = ({ event }) => {
      const offsetY = event.nativeEvent.contentOffset.y
      if (!(translateYNumber.current === 0 || translateYNumber.current === -pillsHeight)) {
        if (flatListRef.current) {
          flatListRef.current.scrollToOffset({
            offset:
              getCloser(translateYNumber.current, -pillsHeight, 0) === -pillsHeight
                ? offsetY + pillsHeight
                : offsetY - pillsHeight,
          })
        }
      }
    }

    const handleWebScroll = useWebOnScroll({ onScroll, onScrollEnd })

    return (
      <View style={FULL}>
        <Screen>
          {loading ? (
            <View style={CONTAINER}>
              <SkeletonContainer>
                <View>
                  <View style={PILLS_SKELETON}>
                    {[1, 2, 3].map((index) => (
                      <SkeletonView key={index} style={PILLS_SKELETON_ITEM} />
                    ))}
                  </View>
                  {[1, 2].map((index) => (
                    <View style={BLOCKS_SKELETON} key={index}>
                      <SkeletonView style={BLOCKS_SKELETON_ICON} />
                      <SkeletonView style={BLOCKS_SKELETON_TITLE} />

                      <View style={{ marginBottom: spacing[3] } as ViewStyle}>
                        <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                        <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                        <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                      </View>
                      <SkeletonView style={BLOCKS_SKELETON_BUTTON} />
                    </View>
                  ))}
                </View>
                {[1, 2].map((index) => (
                  <View style={BLOCKS_SKELETON} key={index}>
                    <SkeletonView style={BLOCKS_SKELETON_ICON} />
                    <SkeletonView style={BLOCKS_SKELETON_TITLE} />

                    <View style={{ marginBottom: spacing[3] } as ViewStyle}>
                      <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                      <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                      <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                    </View>
                    <SkeletonView style={BLOCKS_SKELETON_BUTTON} />
                  </View>
                ))}
              </SkeletonContainer>
            </View>
          ) : (
            <>
              <Animated.FlatList
                scrollEventThrottle={16}
                data={listData}
                ref={flatListRef}
                onScroll={Platform.OS === "web" ? handleWebScroll : onScroll}
                keyExtractor={(item, index) => String(item.id + index)}
                ListHeaderComponent={
                  <>
                    <Animated.View style={[BLUR_WRAPPER, { height: headerBlurHeight }]} />
                    <Header
                      headerTx="homeMyCodeOfSamsaraScreen.headerText"
                      leftIcon="back"
                      style={FLATLIST_HEADER}
                      onLeftPress={goBack}
                    />
                    <Animated.View
                      style={[
                        PILLS_WRAPPER,
                        {
                          transform: [{ translateY }],
                          opacity,
                        },
                      ]}
                    >
                      <Pills
                        style={PILLS_CONTAINER}
                        data={categories}
                        activeId={activeCategory}
                        onPress={filterCategory}
                      />
                    </Animated.View>
                  </>
                }
                stickyHeaderIndices={[0]}
                ListFooterComponent={
                  <View style={CONTAINER}>
                    <Subscribe onPress={() => setPurchaseVisible(!purchaseVisible)} />
                  </View>
                }
                renderItem={({ item }) => (
                  <View style={CONTAINER}>
                    <Card
                      {...item}
                      buttonName={
                        item.status.toLowerCase() === "unlocked"
                          ? "homeMyCodeOfSamsaraScreen.buttonReadMode"
                          : "homeMyCodeOfSamsaraScreen.buttonReadModeLocked"
                      }
                      buttonAction={(card) => navigateTo(card)}
                    />
                  </View>
                )}
              />
              <Animated.View
                style={[
                  TAB_BAR_WRAPPER,
                  {
                    transform: [{ translateY: translateYTabBar }],
                  },
                ]}
              >
                <MyCodeOfSamsaraTabBar />
              </Animated.View>
            </>
          )}
          <PurchaseModal
            visible={purchaseVisible}
            closeModal={() => {
              setPurchaseVisible(false)
            }}
          />
        </Screen>
      </View>
    )
  },
)

// STYLES
const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = { paddingHorizontal: spacing[4] }

const PILLS_SKELETON: ViewStyle = {
  flexDirection: "row",
  marginVertical: spacing[5],
}
const PILLS_SKELETON_ITEM: ViewStyle = {
  width: "30%",
  height: 36,
  marginRight: spacing[4],
  borderRadius: 40,
}
const BLOCKS_SKELETON: ViewStyle = {
  marginBottom: 24,
  paddingHorizontal: 18,
  paddingVertical: 36,
  borderRadius: 16,
  backgroundColor: color.palette.black500,
}
const BLOCKS_SKELETON_ICON: ViewStyle = {
  marginBottom: spacing[4],
  width: 30,
  height: 30,
}
const BLOCKS_SKELETON_TITLE: ViewStyle = {
  marginBottom: spacing[2],
  width: "55%",
  height: 32,
}
const BLOCKS_SKELETON_DESCRIPTION: ViewStyle = {
  width: "90%",
  height: 15,
  marginBottom: 5,
}
const BLOCKS_SKELETON_BUTTON: ViewStyle = {
  width: "35%",
  height: 36,
  borderRadius: 18,
}
const BLUR_WRAPPER: any = {
  position: "absolute",
  width: "100%",
  height: 120,
  zIndex: 1,
  background: "rgba(13, 16, 23, 0.5)",
  backdropFilter: "blur(16px)",
}
const PILLS_WRAPPER: ViewStyle = {
  position: "relative",
  zIndex: 1,
}
const PILLS_CONTAINER: ViewStyle = {
  marginVertical: 0,
}
const FLATLIST_HEADER: ViewStyle = {
  position: "relative",
  zIndex: 10,
}
const TAB_BAR_WRAPPER: any = {
  position: "absolute",
  width: "100%",
  bottom: 0,
  left: 0,
  backgroundColor: "rgba(13, 16, 23, 0.5)",
  backdropFilter: "blur(16px)",
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
}
