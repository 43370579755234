import AddCircle from "./add-circle"
import AddCircleLight from "./add-circle-light"
import ChevronCircleRight from "./chevron-circle-right"
import BackArrow from "./back-arrow"
import NextArrow from "./next-arrow"
import Close from "./close"
import CloseCircle from "./close-circle"
import CollapseArrow from "./collapse-arrow"
import Delete from "./delete"
import Edit from "./edit"
import Letter from "./letter"
import Library from "./library"
import Logo from "./logo"
import PersonCircle from "./person-circle"
import Search from "./search"
import SoulMate from "./soul-mate"
import Star from "./star"
import Play from "./play"
import Gift from "./gift"
import Calendar from "./calendar"
import CalendarLight from "./calendar-light"
import Time from "./time"
import TimeLight from "./time-light"
import Check from "./check"
import Share from "./share"
import ArrowDown from "./arrow-down"
import Subscriptions from "./subscriptions"
import AvailableChecks from "./available-checks"
import InviteFriends from "./invite-friends"
import Redeem from "./redeem"
import Settings from "./settings"
import Rate from "./rate"
import Contact from "./contact"
import About from "./about"
import TapSingle from "./tap-single"
import TermsOfUse from "./terms-of-use"
import Warning from "./warning"
import Bell from "./bell"
import Forward from "./forward"
import Reload from "./reload"
import ArrowTop from "./arrow-top"
import ChevronRight from "./chevron-right"
import Notification from "./notification"
import Views from "./views"
import Checkbox from "./checkbox"
import ReadNotification from "./read-notification"
import UnreadNotification from "./unread-notification"
import ArrowRight from "./arrow-right"
import ArrowLeft from "./arrow-left"
import Eye from "./eye"
import Horoscope from "./horoscope"
import Present from "./present"
import BellWithOutDot from "./bell-with-out-dot"

// https://docs.expo.dev/versions/latest/sdk/svg/
export const icons = {
  addCircle: AddCircle,
  addCircleLight: AddCircleLight,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  eye: Eye,
  chevronRight: ChevronRight,
  chevronCircleRight: ChevronCircleRight,
  delete: Delete,
  back: BackArrow,
  next: NextArrow,
  library: Library,
  logo: Logo,
  personCircle: PersonCircle,
  star: Star,
  collapseArrow: CollapseArrow,
  edit: Edit,
  soulMate: SoulMate,
  close: Close,
  closeCircle: CloseCircle,
  letter: Letter,
  search: Search,
  play: Play,
  gift: Gift,
  calendar: Calendar,
  calendarLight: CalendarLight,
  time: Time,
  timeLight: TimeLight,
  check: Check,
  share: Share,
  arrowDown: ArrowDown,
  subscriptions: Subscriptions,
  availableChecks: AvailableChecks,
  inviteFriends: InviteFriends,
  redeem: Redeem,
  settings: Settings,
  rate: Rate,
  contact: Contact,
  about: About,
  tapSingle: TapSingle,
  termsOfUse: TermsOfUse,
  warning: Warning,
  bell: Bell,
  bellWithOutDot: BellWithOutDot,
  forward: Forward,
  reload: Reload,
  arrowTop: ArrowTop,
  notification: Notification,
  views: Views,
  checkbox: Checkbox,
  readNotification: ReadNotification,
  unreadNotification: UnreadNotification,
  horoscope: Horoscope,
  present: Present,
}

export type IconTypes = keyof typeof icons
