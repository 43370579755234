import React, { useRef } from "react"
import { observer } from "mobx-react-lite"
import { ScrollView, TextStyle, View, ViewStyle } from "react-native"
import { RenderHtmlWrapper, Text, Button, Icon } from "../../../../components"
import { useWindowWidth } from "../../../../utils/dimension"
import { spacing } from "../../../../theme"

interface ISlideProps {
  color: string
  htmlContent: string
  title: string
}

interface ResultContentSliderProps {
  title: string
  content: ISlideProps[]
}

export const ResultContentSlider = observer((props: ResultContentSliderProps) => {
  const { title, content } = props

  const scrollViewRef = useRef<ScrollView>(null)
  const screenWidth = useWindowWidth()

  return (
    <>
      <View style={WRAPPER}>
        <View style={SLIDER_HEADER}>
          <Text preset="h3" text={title} style={SLIDER_TITLE} />
          <View style={SLIDER_BUTTONS}>
            <Button
              preset="unstyled"
              style={SLIDER_BUTTON_NAV}
              onPress={() => scrollViewRef.current.scrollTo({ x: 0, animated: true })}
            >
              <Icon icon="arrowLeft" />
            </Button>
            <Button
              preset="unstyled"
              style={SLIDER_BUTTON_NAV}
              onPress={() => scrollViewRef.current.scrollTo({ x: screenWidth, animated: true })}
            >
              <Icon icon="arrowRight" />
            </Button>
          </View>
        </View>
      </View>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} ref={scrollViewRef}>
        {content.map((slide, index) => (
          <View
            key={index}
            style={[LIST_WRAPPER, index + 1 === content.length ? LIST_WRAPPER_LAST : {}]}
          >
            <View style={[LIST_CONTAINER, { backgroundColor: slide.color } as ViewStyle]}>
              <Text preset="h4" text={slide.title} />
              <RenderHtmlWrapper html={slide.htmlContent} />
            </View>
          </View>
        ))}
      </ScrollView>
    </>
  )
})

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
  marginBottom: spacing[4],
}
const LIST_WRAPPER: ViewStyle = {
  width: 294,
  marginLeft: spacing[4],
}
const LIST_WRAPPER_LAST: ViewStyle = {
  marginRight: spacing[4],
}
const LIST_CONTAINER: ViewStyle = {
  padding: 16,
  borderRadius: 16,
}
const SLIDER_HEADER: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing[4],
}
const SLIDER_TITLE: TextStyle = { flexShrink: 1 }
const SLIDER_BUTTONS: ViewStyle = {
  display: "flex",
  flexDirection: "row",
}
const SLIDER_BUTTON_NAV: ViewStyle = {
  alignItems: "center",
  paddingVertical: 7,
  marginLeft: spacing[2],
  width: 32,
  height: 32,
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  borderRadius: 32,
}
