import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models"
import { ExtraOrderStackScreenProps, TabsParamList } from "../../navigators"
import { FlatList, TextStyle, View, ViewStyle } from "react-native"
import {
  Header,
  IPillData,
  Pills,
  Screen,
  ScreenTitle,
  SkeletonContainer,
  SkeletonView,
  Text,
  UnlockBlockModal,
} from "../../components"
import { color, spacing } from "../../theme"
import { Card } from "../../components/card/card"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"

interface ICompatibilityCodeData {
  id: string
  emoji: string
  title: string
  description: string
  backgroundUrl: string
  color: string
  status: string
  category: string
}

export const ExtraOrderCompatibilityCode: FC<
  ExtraOrderStackScreenProps<"extraOrderCompatibilityCode">
> = observer(({ navigation, route }) => {
  const { orderId } = route.params
  const isDemo = orderId === "demo"

  const { extraOrderStore } = useStores()
  const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()

  const [realOrderId, setRealOrderId] = useState("")
  const [title, setTitle] = useState("")
  const [fullName, setFullName] = useState("")
  const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState<string>()
  const [listData, setListData] = useState<ICompatibilityCodeData[]>()
  const [blocks, setBlocks] = useState<ICompatibilityCodeData[]>([])
  const [loading, setLoading] = useState(true)
  const [unlockData, setUnlockData] = useState({
    visible: false,
    blockTitle: "",
    blockId: "",
  })

  const goBack = () => {
    if (isDemo) {
      rootNavigation.navigate("home", { screen: "homeIndex" })
    } else {
      navigation.navigate("extraOrderIndex")
    }
  }

  useEffect(() => {
    ;(async () => {
      setTitle("")
      setFullName("")
      setRealOrderId("")
      setActiveCategory("")
      setCategories([])
      setBlocks([])
      setListData([])
      await loadBlock()
    })()
  }, [route])

  const loadBlock = async () => {
    setLoading(true)
    if (orderId) {
      try {
        const result = isDemo
          ? await extraOrderStore.getCodeOfSamsaraDemoBlocks()
          : await extraOrderStore.getCodeOfSamsaraBlocks(orderId)

        if (result) {
          setCategories(result.categories)
          setBlocks(result.blocksData)
          setListData(result.blocksData)
          setTitle(result.title)
          setFullName(result.fullName)
          setRealOrderId(result.orderId)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const filterCategory = (data: IPillData) => {
    if (data.id === activeCategory) {
      setActiveCategory(null)
      setListData(blocks)
    } else {
      setActiveCategory(data.id.toString())
      if (data.name.toLowerCase() === "available reports") {
        setListData(blocks.filter((item) => item.status.toLowerCase() === "unlocked"))
      } else {
        setListData(
          blocks.filter((item) => item.category.toLowerCase() === data.id.toString().toLowerCase()),
        )
      }
    }
  }

  const navigateTo = (card) => {
    if (card.status.toLowerCase() === "unlocked") {
      navigateAfterUnlock(card.id)
    } else {
      setUnlockData({
        visible: true,
        blockTitle: card.title,
        blockId: card.id,
      })
    }
  }

  const navigateAfterUnlock = (blockId: string) => {
    navigation.navigate("extraOrderCompatibilityResultsPost", { orderId: realOrderId, id: blockId })
  }

  return (
    <View style={FULL}>
      <Screen header={<Header leftIcon="back" onLeftPress={goBack} />}>
        <View style={CONTAINER}>
          <ScreenTitle headerText={title} style={HEADER_TITLE} />
          <Text preset="h4Text" text={fullName} style={FULL_NAME_TEXT} />
        </View>

        {loading ? (
          <View style={CONTAINER}>
            <SkeletonContainer>
              <View>
                <View style={PILLS_SKELETON}>
                  {[1, 2, 3].map((index) => (
                    <SkeletonView key={index} style={PILLS_SKELETON_ITEM} />
                  ))}
                </View>
                {[1, 2].map((index) => (
                  <View style={BLOCKS_SKELETON} key={index}>
                    <SkeletonView style={BLOCKS_SKELETON_ICON} />
                    <SkeletonView style={BLOCKS_SKELETON_TITLE} />

                    <View style={{ marginBottom: spacing[3] } as ViewStyle}>
                      <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                      <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                      <SkeletonView style={BLOCKS_SKELETON_DESCRIPTION} />
                    </View>
                    <SkeletonView style={BLOCKS_SKELETON_BUTTON} />
                  </View>
                ))}
              </View>
            </SkeletonContainer>
          </View>
        ) : (
          <>
            <View>
              <Pills data={categories} activeId={activeCategory} onPress={filterCategory} />
            </View>

            <FlatList
              data={listData}
              contentContainerStyle={CONTAINER}
              keyExtractor={(item, index) => String(item.id + index)}
              renderItem={({ item }) => (
                <Card
                  {...item}
                  buttonName={
                    item.status.toLowerCase() === "unlocked"
                      ? "homeMyCodeOfSamsaraScreen.buttonReadMode"
                      : "homeMyCodeOfSamsaraScreen.buttonReadModeLocked"
                  }
                  buttonAction={(card) => navigateTo(card)}
                />
              )}
            />
          </>
        )}
        <UnlockBlockModal
          visible={unlockData.visible}
          closeModal={() => {
            setUnlockData({
              visible: false,
              blockTitle: "",
              blockId: "",
            })
          }}
          blockTitle={unlockData.blockTitle}
          blockId={unlockData.blockId}
          onUnlock={(blockId) => {
            navigateAfterUnlock(blockId)
          }}
        />
      </Screen>
    </View>
  )
})

// STYLES
const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = { paddingHorizontal: spacing[4] }
const HEADER_TITLE: ViewStyle = {
  paddingBottom: 0,
}
const FULL_NAME_TEXT: TextStyle = {
  color: color.palette.white,
}

const PILLS_SKELETON: ViewStyle = {
  flexDirection: "row",
  marginVertical: spacing[5],
}
const PILLS_SKELETON_ITEM: ViewStyle = {
  width: "30%",
  height: 36,
  marginRight: spacing[4],
  borderRadius: 40,
}
const BLOCKS_SKELETON: ViewStyle = {
  marginBottom: 24,
  paddingHorizontal: 18,
  paddingVertical: 36,
  borderRadius: 16,
  backgroundColor: color.palette.black500,
}
const BLOCKS_SKELETON_ICON: ViewStyle = {
  marginBottom: spacing[4],
  width: 30,
  height: 30,
}
const BLOCKS_SKELETON_TITLE: ViewStyle = {
  marginBottom: spacing[2],
  width: "55%",
  height: 32,
}
const BLOCKS_SKELETON_DESCRIPTION: ViewStyle = {
  width: "90%",
  height: 15,
  marginBottom: 5,
}
const BLOCKS_SKELETON_BUTTON: ViewStyle = {
  width: "35%",
  height: 36,
  borderRadius: 18,
}
