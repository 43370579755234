import React, { useCallback, useState } from "react"
import { FlatList, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { useFocusEffect, useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import {
  Screen,
  ScreenTitle,
  Header,
  Button,
  Icon,
  Text,
  SkeletonContainer,
  SkeletonView,
  PurchaseModal,
} from "../../../components"
import { color, spacing } from "../../../theme"
import { useStores } from "../../../models"
import {
  OrderTypeEnum,
  TemplateTypeEnum,
} from "../../../models/extra-order-store/extra-order-store"
import { translate } from "../../../i18n"
import { TabsParamList } from "../../../navigators"
import { GenderEnum } from "../../../constants/genders"
import { parseAndFormatDateLocal } from "../../../utils/date"

interface IStartCalculationProps {
  type: OrderTypeEnum
  goBack(): void
  navigationToCompability(): void
}

export const StartCalculation = observer((props: IStartCalculationProps) => {
  const { profileStore, extraOrderStore } = useStores()

  const { type, goBack, navigationToCompability } = props
  const [history, setHistory] = useState(null)
  const [loading, setLoading] = useState(true)
  const [purchaseVisible, setPurchaseVisible] = useState(false)

  const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()

  const navigateToCompatibilityResult = (orderId: string, template: string) => {
    if (profileStore.isNotSubscribed) {
      setPurchaseVisible(true)
    } else {
      if (template === TemplateTypeEnum.CodeOfSamsara) {
        rootNavigation.navigate("home", {
          screen: "extraOrderStack",
          params: { screen: "extraOrderCompatibilityCode", params: { orderId } },
        })
      }
      if (template === TemplateTypeEnum.SoulMate) {
        rootNavigation.navigate("home", {
          screen: "extraOrderStack",
          params: {
            screen: "extraOrderCompatibilityResults",
            params: { orderId },
          },
        })
      }
    }
  }

  const getGenderBg = (gender: string) => {
    return gender === GenderEnum.Male ? CALCULATIONS_MALE_BG : CALCULATIONS_FEMALE_BG
  }

  const isSoulMate = () => type === OrderTypeEnum.soulMate

  useFocusEffect(
    useCallback(() => {
      setLoading(true)
      extraOrderStore.setOrder(type)
      ;(async () => {
        const result = await extraOrderStore.getHistory()
        if (result) {
          setHistory(result)
        }
        setLoading(false)
      })()
    }, [type]),
  )

  return (
    <Screen>
      <Header leftIcon="back" onLeftPress={goBack} />
      <View style={WRAPPER}>
        <ScreenTitle
          headerTx={isSoulMate() ? "soulMateScreen.title" : "extraOrderScreen.title"}
          subHeaderTx={isSoulMate() ? "soulMateScreen.subtitle" : "extraOrderScreen.subtitle"}
        />

        <Button preset="primary" onPress={navigationToCompability}>
          <View style={ADD_BUTTON}>
            <Icon icon="addCircle" />
            <Text
              preset="h4"
              text={translate(
                isSoulMate()
                  ? "soulMateScreen.startNewCalculation"
                  : "extraOrderScreen.startNewCalculation",
              )}
              style={ADD_BUTTON_TEXT}
            />
          </View>
        </Button>

        {loading ? (
          <>
            <Text preset="h4Text" style={RECENT_TEXT} tx="extraOrderScreen.recentCalculations" />
            <SkeletonContainer>
              {[1, 2, 3].map((index) => (
                <SkeletonView key={index} style={BLOCKS_SKELETON_TITLE} />
              ))}
            </SkeletonContainer>
          </>
        ) : (
          history && (
            <>
              <Text preset="h4Text" style={RECENT_TEXT} tx="extraOrderScreen.recentCalculations" />
              <FlatList
                data={history}
                keyExtractor={(item) => String(item.orderId)}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    activeOpacity={0.4}
                    onPress={() => navigateToCompatibilityResult(item.orderId, item.template)}
                  >
                    <View style={[{ backgroundColor: item.color } as ViewStyle, CALCULATIONS_ITEM]}>
                      <View style={CALCULATIONS_ITEM_TEXTS_ROW}>
                        <Text
                          preset="h4"
                          text={item.title}
                          style={CALCULATIONS_ITEM_TITLE}
                          numberOfLines={1}
                        />
                        <Text
                          preset="medium"
                          text={parseAndFormatDateLocal(item.purchaseDate, "dd.MM.yyyy")}
                        />
                      </View>
                      <View style={CALCULATIONS_ITEM_NAMES_ROW}>
                        <View
                          style={[
                            CALCULATIONS_ITEM_NAMES_FIRST,
                            getGenderBg(item.profiles[0].gender),
                          ]}
                        >
                          <Text
                            preset="bold"
                            style={INITIAL_TEXT}
                            text={item.profiles[0].initial}
                          />
                        </View>
                        {item.profiles[1] && (
                          <View
                            style={[
                              { backgroundColor: item.color } as ViewStyle,
                              CALCULATIONS_ITEM_NAMES_SECOND,
                            ]}
                          >
                            <View
                              style={[
                                CALCULATIONS_ITEM_NAMES_SECOND_WRAPPER,
                                getGenderBg(item.profiles[1].gender),
                              ]}
                            >
                              <Text
                                preset="bold"
                                style={INITIAL_TEXT}
                                text={item.profiles[1].initial}
                              />
                            </View>
                          </View>
                        )}
                      </View>
                    </View>
                  </TouchableOpacity>
                )}
              />
            </>
          )
        )}
      </View>
      <PurchaseModal
        visible={purchaseVisible}
        closeModal={() => {
          setPurchaseVisible(false)
        }}
      />
    </Screen>
  )
})

const WRAPPER: ViewStyle = {
  paddingHorizontal: spacing[4],
  flex: 1,
  flexGrow: 1,
}

const ADD_BUTTON: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}
const ADD_BUTTON_TEXT: TextStyle = {
  color: color.palette.black,
  textAlign: "center",
  marginLeft: spacing[3],
}

const RECENT_TEXT: TextStyle = {
  paddingTop: spacing[5],
  marginBottom: spacing[5],
}
const CALCULATIONS_ITEM: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing[4],
  padding: spacing[4],
  borderRadius: 16,
}
const CALCULATIONS_ITEM_TEXTS_ROW: ViewStyle = {
  flex: 1,
}
const CALCULATIONS_ITEM_TITLE: TextStyle = {
  marginBottom: spacing[1],
  paddingRight: spacing[4],
}
const CALCULATIONS_ITEM_NAMES_ROW: ViewStyle = {
  width: 70,
  position: "relative",
}
const CALCULATIONS_MALE_BG: ViewStyle = {
  backgroundColor: color.palette.blue,
}
const CALCULATIONS_FEMALE_BG: ViewStyle = {
  backgroundColor: color.palette.purple,
}
const CALCULATIONS_ITEM_NAMES_FIRST: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 100,
  zIndex: 0,
  position: "absolute",
  top: -18,
  right: 32,
  height: 32,
  width: 32,
}
const CALCULATIONS_ITEM_NAMES_SECOND: ViewStyle = {
  borderRadius: 100,
  zIndex: 1,
  position: "absolute",
  top: -21,
  right: -2,
  height: 38,
  width: 38,
  padding: 3,
}
const CALCULATIONS_ITEM_NAMES_SECOND_WRAPPER: ViewStyle = {
  borderRadius: 100,
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}
const INITIAL_TEXT: TextStyle = {
  color: color.palette.white,
}

// SKELETON FOR BLOCKS
const BLOCKS_SKELETON_TITLE: ViewStyle = {
  marginBottom: spacing[4],
  width: "100%",
  height: 50,
  borderRadius: 16,
}
