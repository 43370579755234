import { observer } from "mobx-react-lite"
import React, { useEffect, useRef } from "react"
import { Animated, Modal, Platform, Pressable, TextStyle, View, ViewStyle } from "react-native"
import { Button } from "../button/button"
import { Text } from "../text/text"
import { color, spacing } from "../../theme"
import { useWindowWidth } from "../../utils/dimension"
import { useStores } from "../../models"

export const AlertModal = observer(() => {
  const { alertStore } = useStores()
  const screenWidth = useWindowWidth()

  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
    }).start()
  }

  const fadeOut = () => fadeAnim.setValue(0)

  useEffect(() => {
    if (alertStore.isVisible) {
      fadeIn()
    } else {
      fadeOut()
    }
  }, [alertStore.isVisible])

  return (
    <>
      {alertStore.isVisible && (
        <Animated.View
          style={[
            BG_OVERLAY,
            {
              opacity: fadeAnim,
            },
          ]}
        />
      )}
      <Modal
        animationType="fade"
        transparent={true}
        visible={alertStore.isVisible}
        onRequestClose={alertStore.close}
      >
        <Pressable style={OVERLAY} onPress={alertStore.close} />
        <View style={MODAL_CENTER}>
          <View style={[CONTAINER, { maxWidth: screenWidth - 2 * spacing[4] }]}>
            <Text preset="h2" text={alertStore.title} style={TITLE} />
            {alertStore.message ? (
              <Text preset="default" text={alertStore.message} style={MESSAGE} />
            ) : null}

            <Button preset="cancel" tx="common.close" onPress={alertStore.close} />
          </View>
        </View>
      </Modal>
    </>
  )
})

const OVERLAY: ViewStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}
const BG_OVERLAY: ViewStyle = {
  ...OVERLAY,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}
const MODAL_CENTER: ViewStyle = {
  justifyContent: "center",
  alignItems: Platform.OS === "web" ? "center" : "stretch",
  height: "90%",
}
const CONTAINER: ViewStyle = {
  flexGrow: 0,
  width: "100%",
  maxHeight: "90%",
  paddingHorizontal: spacing[4],
  backgroundColor: color.palette.black500,
  borderRadius: 16,
  marginHorizontal: spacing[4],
}
const TITLE: TextStyle = {
  textAlign: "center",
  paddingVertical: spacing[4],
}
const MESSAGE: TextStyle = {
  textAlign: "center",
  paddingBottom: spacing[4],
}
