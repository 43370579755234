import { ViewStyle, TextStyle } from "react-native"
import { color, spacing } from "../../theme"
import { texts } from "../../styles"

/**
 * All text will start off looking like this.
 */
const BASE_VIEW: ViewStyle = {
  position: "relative",
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
  borderRadius: 36,
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 1,
  borderColor: color.palette.brightTurquoise,
  backgroundColor: color.palette.brightTurquoise,
  overflow: "hidden",
}

const BASE_TEXT: TextStyle = {
  ...texts.H4,
  paddingHorizontal: spacing[3],
}

/**
 * All the variations of text styling within the app.
 *
 * You want to customize these to whatever you need in your app.
 */
export const viewPresets: Record<string, ViewStyle> = {
  primary: {
    ...BASE_VIEW,
    backgroundColor: color.transparent,
    borderWidth: 0,
  } as ViewStyle,

  outline: {
    ...BASE_VIEW,
    backgroundColor: color.transparent,
    borderColor: color.palette.moderateBlue,
  } as ViewStyle,

  cancel: {
    ...BASE_VIEW,
    backgroundColor: color.palette.black500,
    borderColor: color.palette.black500,
  } as ViewStyle,

  small: {
    paddingVertical: spacing[2],
    paddingHorizontal: spacing[4],
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 36,
    backgroundColor: color.palette.black500,
    borderColor: color.palette.brightTurquoise,
    borderWidth: 1,
  } as ViewStyle,

  unstyled: {},
}

export const textPresets: Record<ButtonPresetNames, TextStyle> = {
  primary: {
    ...BASE_TEXT,
    color: color.palette.black,
  } as TextStyle,

  outline: {
    ...BASE_TEXT,
    color: color.palette.lightSteelBlue,
  } as TextStyle,

  cancel: {
    ...BASE_TEXT,
    color: color.palette.lightSteelBlue,
  } as TextStyle,

  small: {
    fontSize: 12,
    color: color.palette.brightTurquoise,
  } as TextStyle,

  unstyled: {},
}

/**
 * A list of preset names.
 */
export type ButtonPresetNames = keyof typeof viewPresets
