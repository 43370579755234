import { TextStyle } from "react-native"
import { color, typography } from "../theme"

const BOLD: TextStyle = {
  fontFamily: typography.secondary,
  fontWeight: "500",
}

const REGULAR: TextStyle = {
  fontFamily: typography.primary,
  fontWeight: "400",
}

const H2: TextStyle = {
  ...BOLD,
  fontSize: 24,
  lineHeight: 32,
  color: color.header,
}

const H3: TextStyle = {
  ...BOLD,
  fontSize: 20,
  lineHeight: 32,
  color: color.header,
}

const H4: TextStyle = {
  ...BOLD,
  fontSize: 16,
  lineHeight: 24,
  color: color.header,
}

const H4_TEXT: TextStyle = {
  ...H4,
  ...REGULAR,
  color: color.text,
}

const REGULAR_TEXT: TextStyle = {
  ...REGULAR,
  fontSize: 14,
  lineHeight: 20,
  color: color.text,
}

const ITALIC_TEXT: TextStyle = {
  ...REGULAR,
  fontSize: 14,
  lineHeight: 20,
  color: color.text,
  fontStyle: "italic",
}

const MEDIUM_TEXT: TextStyle = {
  ...REGULAR,
  fontSize: 12,
  lineHeight: 14,
  color: color.text,
}

const MEDIUM_BOLD: TextStyle = {
  ...BOLD,
  fontSize: 12,
  lineHeight: 14,
  color: color.text,
}

const SMALL_TEXT: TextStyle = {
  ...REGULAR,
  fontSize: 10,
  lineHeight: 14,
  color: color.text,
}

const TEXT_CENTER: TextStyle = { textAlign: "center" }

export const texts = {
  BOLD,
  REGULAR,
  H2,
  H3,
  H4,
  H4_TEXT,
  REGULAR_TEXT,
  MEDIUM_TEXT,
  MEDIUM_BOLD,
  SMALL_TEXT,
  TEXT_CENTER,
  ITALIC_TEXT,
}
