import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const CloseCircle = (props: SvgProps) => (
  <Svg width={16} height={16} fill="#3C4F6D" viewBox="0 0 16 16" {...props}>
    <Path d="M8 .5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15ZM6.168 5.299a.625.625 0 0 0-.798.071l-.071.087a.625.625 0 0 0 .071.798L7.116 8 5.37 9.745l-.071.088a.625.625 0 0 0 .071.797l.087.071a.625.625 0 0 0 .798-.071L8 8.884l1.745 1.746.088.071a.625.625 0 0 0 .797-.071l.071-.088a.625.625 0 0 0-.071-.797L8.884 8l1.746-1.745.071-.087a.625.625 0 0 0-.071-.798l-.088-.071a.625.625 0 0 0-.797.071L8 7.116 6.255 5.37l-.087-.071Z" />
  </Svg>
)

export default CloseCircle
