import React from "react"
import { ViewStyle, View, StyleProp } from "react-native"
import { Text } from "../text/text"
import { GenderEnum } from "../../constants/genders"
import { color, spacing } from "../../theme"

export interface PersonCircleProps {
  firstName: string
  lastName: string
  genderCode?: string
  style?: StyleProp<ViewStyle>
}

const CIRCLE: ViewStyle = {
  width: 48,
  height: 48,
  borderRadius: 100,
  backgroundColor: color.palette.purple,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginRight: spacing[5],
}
const CIRCLE_FEMALE: ViewStyle = {
  backgroundColor: color.palette.purple,
}
const CIRCLE_MALE: ViewStyle = {
  backgroundColor: color.palette.blue,
}

export function PersonCircle(props: PersonCircleProps) {
  const { firstName, lastName, genderCode, style } = props

  const initials = firstName.slice(0, 1) + lastName.slice(0, 1)

  return (
    <View
      style={[
        CIRCLE,
        genderCode === GenderEnum.Male && CIRCLE_MALE,
        genderCode === GenderEnum.Female && CIRCLE_FEMALE,
        style,
      ]}
    >
      <Text text={initials} preset="h4" />
    </View>
  )
}
