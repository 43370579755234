import React, { FC, useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import {
  Screen,
  Button,
  ScreenTitle,
  Header,
  FormRow,
  Text,
  TextField,
  Icon,
} from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { spacing } from "../../theme"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
  flex: 1,
}
const CONTENT: ViewStyle = {
  flexGrow: 1,
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}
const INPUT_WRAPPER: ViewStyle = {
  width: "100%",
}
const WARNING: ViewStyle = {
  padding: spacing[4],
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "rgba(230, 97, 0, 0.1)",
  borderRadius: 10,
}
const WARNING_ICON: ViewStyle = {
  width: "10%",
}
const WARNING_TEXT: TextStyle = {
  width: "90%",
  color: "#E5D5CA",
}

export const ProfileEditBirthNameScreen: FC<ProfileStackScreenProps<"profileEditBirthName">> =
  observer(({ navigation }) => {
    const { profileStore, alertStore } = useStores()

    const [firstName, setFirstName] = useState(profileStore.birthFirstName)
    const [lastName, setLastName] = useState(profileStore.birthLastName)

    const goBack = () => navigation.goBack()

    const saveChanges = async () => {
      if (firstName && lastName) {
        const result = await profileStore.updateCustomerBirthName(firstName, lastName)
        if (result?.customer) {
          navigation.navigate("profileEdit")
        } else {
          alertStore.showError(result.message)
        }
      } else {
        alertStore.showError("Please fill in all the fields")
      }
    }

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileEditBirthNameScreen.title" style={TITLE} />

          <View style={CONTENT}>
            <FormRow preset="middle">
              <TextField
                value={firstName}
                onChangeText={(text) => setFirstName(text.replace(/\d/gi, ""))}
                placeholderTx="profileEditBirthNameScreen.firstName"
                style={INPUT_WRAPPER}
              />
            </FormRow>
            <FormRow preset="middle">
              <TextField
                value={lastName}
                onChangeText={(text) => setLastName(text.replace(/\d/gi, ""))}
                placeholderTx="profileEditBirthNameScreen.lastName"
                style={INPUT_WRAPPER}
              />
            </FormRow>
            <View style={WARNING}>
              <Icon icon="warning" containerStyle={WARNING_ICON} />
              <Text tx="profileEditBirthNameScreen.warning" style={WARNING_TEXT} />
            </View>
          </View>

          <Button tx="profileEditBirthNameScreen.save" onPress={saveChanges} />
        </Screen>
      </View>
    )
  })
