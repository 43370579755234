import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { PlaceOfBirthModel } from "../profile-store/place-of-birth-model"

export const ExtraOrderUserModel = types.model("ExtraOrderUser").props({
  id: types.optional(types.string, ""),
  firstName: types.optional(types.string, ""),
  lastName: types.optional(types.string, ""),
  gender: types.optional(types.string, ""),
  dateOfBirth: types.optional(types.string, ""),
  timeOfBirth: types.optional(types.string, ""),
  placeOfBirth: types.maybeNull(PlaceOfBirthModel),
})

export interface ExtraOrderUserStore extends Instance<typeof ExtraOrderUserModel> {}
export interface ExtraOrderUserStoreSnapshotOut extends SnapshotOut<typeof ExtraOrderUserModel> {}
export interface ExtraOrderUserStoreSnapshotIn extends SnapshotIn<typeof ExtraOrderUserModel> {}
export const createCharacterDefaultModel = () => types.optional(ExtraOrderUserModel, {})
