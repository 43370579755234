import React, { useEffect, useState } from "react"
import * as WebBrowser from "expo-web-browser"
import * as Google from "expo-auth-session/providers/google"
import * as Facebook from "expo-auth-session/providers/facebook"
import * as AppleAuthentication from "expo-apple-authentication"
import { isAvailableAsync } from "expo-apple-authentication"
import { makeRedirectUri, ResponseType } from "expo-auth-session"
import { View, ViewStyle, Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import { LoginSocialTypeEnum } from "../../models/welcome-store/welcome-store"
import { useStores } from "../../models"
import { spacing } from "../../theme"
import { WelcomeStackParamList } from "../../navigators/app-navigation-routes"
import { Button } from "../button/button"
import { AutoImage } from "../auto-image/auto-image"

const google = require("./images/google.png")
const facebook = require("./images/facebook.png")
const apple = require("./images/apple.png")
const tiktok = require("./images/tiktok.png")

WebBrowser.maybeCompleteAuthSession()

const WRAPPER_FULL: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: spacing[2],
}
const WRAPPER_PARTIAL: ViewStyle = {
  ...WRAPPER_FULL,
  justifyContent: "space-evenly",
}
const BUTTON: ViewStyle = {
  width: "21%",
}

export const OAuthButtons = observer(() => {
  const { meStore, welcomeStore, alertStore } = useStores()
  const welcomeNavigation = useNavigation<NativeStackNavigationProp<WelcomeStackParamList>>()

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    expoClientId: "no-key",
    iosClientId: "292725887942-7193b28aa8dq1vp68n7eea61cq1evl8f.apps.googleusercontent.com",
    androidClientId: "292725887942-097ff617b4jgtfr4rc199jeadgter0qu.apps.googleusercontent.com",
    webClientId: "292725887942-8gbpv07lagbbt09e87bb35rhs8poio1g.apps.googleusercontent.com",
  })
  const [requestFb, responseFb, promptAsyncFb] =
    Platform.OS === "web"
      ? Facebook.useAuthRequest({
          clientId: "3257102654550550",
          responseType: ResponseType.Token,
        })
      : Facebook.useAuthRequest(
          {
            clientId: "3257102654550550",
            responseType: ResponseType.Token,
            redirectUri: makeRedirectUri({ useProxy: true }),
          },
          { useProxy: true },
        )

  const [appleAuthenticationLoaded, setAppleAuthenticationLoaded] = useState(false)

  const authSocial = async (token: string, loginType: string) => {
    await meStore.resetAuth()
    const result = await welcomeStore.loginSocial(token, loginType)
    if (result && result.isNewUser) {
      tryRedirect()
    } else if (result && result.message) {
      alertStore.showError(result.message)
    }
  }

  const tryRedirect = () => {
    if (welcomeNavigation.getState().routeNames.includes("welcomeForm3")) {
      welcomeNavigation.navigate("welcomeForm3")
    } else {
      setTimeout(() => tryRedirect(), 20)
    }
  }

  useEffect(() => {
    async function checkAvailability() {
      try {
        const available = await isAvailableAsync()
        setAppleAuthenticationLoaded(available)
      } catch (error: any) {}
    }

    if (Platform.OS === "ios" && !appleAuthenticationLoaded) {
      checkAvailability()
    }
  }, [])

  async function authWithApple() {
    const requestedScopes = [
      AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
      AppleAuthentication.AppleAuthenticationScope.EMAIL,
    ]
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes,
      })
      const { identityToken } = credential
      authSocial(identityToken, LoginSocialTypeEnum.Apple)

      if (!identityToken) {
        throw new Error("No identity token provided.")
      }
    } catch (e: any) {
      if (e.code === "ERR_CANCELED") {
        alertStore.showError("Apple authentication cancelled")
      } else {
        alertStore.showError("Something went wrong. Please try again later.")
      }
    }
  }

  useEffect(() => {
    if (response?.type === "success") {
      const { params } = response
      authSocial(params.id_token, LoginSocialTypeEnum.Google)
    }
  }, [response])

  useEffect(() => {
    if (responseFb?.type === "success") {
      const { authentication } = responseFb
      authSocial(authentication.accessToken, LoginSocialTypeEnum.Facebook)
    }
  }, [responseFb])

  return (
    <>
      <View style={appleAuthenticationLoaded ? WRAPPER_FULL : WRAPPER_PARTIAL}>
        {appleAuthenticationLoaded && (
          <Button style={BUTTON} preset="outline" onPress={authWithApple}>
            <AutoImage source={apple} />
          </Button>
        )}
        <Button
          style={BUTTON}
          preset="outline"
          disabled={!request}
          onPress={() => {
            promptAsync()
          }}
        >
          <AutoImage source={google} />
        </Button>
        {/* ToDo <Button style={BUTTON} preset="outline">
          <AutoImage source={tiktok} />
        </Button> */}
        <Button
          style={BUTTON}
          preset="outline"
          disabled={!requestFb}
          onPress={() => {
            promptAsyncFb()
          }}
        >
          <AutoImage source={facebook} />
        </Button>
      </View>
    </>
  )
})
