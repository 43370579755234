import * as React from "react"
import Svg, { SvgProps, G, Path, Circle, Defs, ClipPath } from "react-native-svg"

const Bell = (props: SvgProps) => (
  <Svg width={24} height={24} fill="#fff" viewBox="0 0 24 24" {...props}>
    <G clipPath="url(#a)">
      <Path d="M9.042 19.003h5.916a3 3 0 0 1-5.916 0Zm2.958-17a7.5 7.5 0 0 1 7.5 7.5v4l1.418 3.16A.95.95 0 0 1 20.052 18h-16.1a.95.95 0 0 1-.867-1.338l1.415-3.16V9.49l.005-.25A7.5 7.5 0 0 1 12 2.004v-.001Z" />
      <Circle cx={19.5} cy={3.5} r={5.5} fill="#00E6DB" stroke="#0D1017" strokeWidth={3} />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Bell
