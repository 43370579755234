import React, { useEffect, useState } from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models"
import { spacing } from "../../theme"
import { CustomLineChart, LineChartPoints } from "../charts/line-chart/line-chart"
import { SkeletonContainer } from "../skeleton/skeleton-container"
import { SkeletonView } from "../skeleton/skeleton-view"
import { PullUpScrollView } from "../pull-up-scroll-view/pull-up-scroll-view"
import { RenderHtmlWrapper } from "../render-html-wrapper/render-html-wrapper"
import { Subscribe } from "../subscribe/subscribe"
import { Text } from "../text/text"
import { PurchaseModal } from "../purchase-modal/purchase-modal"
import { Header } from "../header/header"
import { Screen } from "../screen/screen"
import { Feedback } from "../feedback/feedback"
import { FragmentsContent, FragmentType } from "./fragments/fragments-content"
import { UnlockBlockModal } from "../unlock-block-modal/unlock-block-modal"

interface INextPost {
  id: string
  backgroundUrl: string
  emoji: string
}

interface IChartData {
  typeChart: "Line" | "Bar"
  graphicPoints?: LineChartPoints[]
  legends: string[]
  labels: string[]
  age?: number
  averageLine?: number[]
  averageLineLabels?: string[]
  labelsXTitle?: string
  resolution: number
  xOffset?: number
}

interface IPost {
  id: string
  category: string
  title: string
  text: string
  chart: IChartData | null
  next: INextPost | null
  fragments?: FragmentType[]
}

interface IPostViewProps {
  orderId: string
  id: string
  goBack(): void
  navigationToNextPost(orderId: string, postId: string): void
}

export const PostView = observer((prop: IPostViewProps) => {
  const { orderId, id, goBack, navigationToNextPost } = prop

  const { commonStore } = useStores()
  const [isFeedbackExpanded, setIsFeedbackExpanded] = useState(false)
  const [purchaseVisible, setPurchaseVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [unlockVisible, setUnlockVisible] = useState(false)

  const [post, setPost] = useState<IPost>(null)

  const loadMore = () => {
    if (post.next?.id) {
      navigationToNextPost(orderId, post.next.id)
    }
  }

  const init = async () => {
    setLoading(true)

    if (!orderId || !id) {
      return
    }

    const result = await commonStore.getCodeOfSamsaraSingleBlock(orderId, id)
    const post = result?.data?.data
    if (post) {
      setPost({
        id: post.id,
        category: post.category,
        title: post.title,
        text: post.text,
        fragments: post.fragments,
        chart: post.chart
          ? {
              typeChart: post.chart.typeChart,
              averageLine: post.chart.averageLine,
              averageLineLabels: post.chart.averageLineLabels,
              graphicPoints: post.chart.graphicPoints,
              legends: post.chart.legend,
              labels: post.chart.labels,
              age: post.chart.age,
              labelsXTitle: post.chart.labelsXTitle,
              resolution: post.chart.resolution,
              xOffset: post.chart.xOffset,
            }
          : null,
        next: post.next
          ? {
              id: post.next.id,
              backgroundUrl: post.next.backgroundUrl,
              emoji: post.next.emoji,
            }
          : null,
      })
    }
    setLoading(false)
  }

  const onUnlock = async () => {
    await init()
  }

  useEffect(() => {
    ;(async () => {
      await init()
    })()
  }, [])

  return (
    <Screen header={<Header headerText={post?.title} leftIcon="back" onLeftPress={goBack} />}>
      {loading || !post ? (
        <View style={CONTAINER}>
          <SkeletonContainer>
            <View style={SKELETON_POST}>
              <SkeletonView style={SKELETON_POST_TITLE} />
              {[...Array(20).keys()].map((index) => (
                <SkeletonView key={index} style={SKELETON_POST_TEXT} />
              ))}
            </View>
          </SkeletonContainer>
        </View>
      ) : (
        <PullUpScrollView
          pullUpBackgroundUrl={post.next?.backgroundUrl}
          pullUpIcon={post.next?.emoji}
          pullUpIsLastPost={!post.next?.id}
          loadMore={loadMore}
          isFeedbackExpanded={isFeedbackExpanded}
        >
          {post.fragments ? (
            <>
              {post.fragments.map((fragment, index: number) => (
                <FragmentsContent
                  key={index}
                  fragment={fragment}
                  onUnlockRequest={() => setUnlockVisible(true)}
                />
              ))}
            </>
          ) : (
            <View style={CONTAINER}>
              <Text preset="h3" text={post.title} style={LIST_HEADING} numberOfLines={1} />
              {post.chart?.typeChart === "Line" ? (
                <CustomLineChart
                  data={post.chart.graphicPoints}
                  resolution={post.chart.resolution}
                  xOffset={post.chart.xOffset}
                  legends={post.chart.legends}
                  averageLine={post.chart.averageLine}
                  averageLineLabels={post.chart.averageLineLabels}
                  labels={post.chart.labels}
                  age={post.chart.age}
                  labelsXTitle={post.chart.labelsXTitle}
                />
              ) : null}
              <RenderHtmlWrapper html={post.text} />
            </View>
          )}
          <View style={CONTAINER}>
            <Feedback
              contentId={id}
              isExpanded={isFeedbackExpanded}
              setIsExpanded={setIsFeedbackExpanded}
            />
            <Subscribe onPress={() => setPurchaseVisible(!purchaseVisible)} />
          </View>
        </PullUpScrollView>
      )}
      <PurchaseModal
        visible={purchaseVisible}
        closeModal={() => {
          setPurchaseVisible(false)
        }}
      />
      <UnlockBlockModal
        visible={unlockVisible}
        closeModal={() => {
          setUnlockVisible(false)
        }}
        blockId={post?.id}
        blockTitle={post?.title}
        onUnlock={onUnlock}
      />
    </Screen>
  )
})

// STYLES
const CONTAINER: ViewStyle = { paddingHorizontal: spacing[4] }
const LIST_HEADING: TextStyle = { marginBottom: spacing[3] }

const SKELETON_POST: ViewStyle = {
  width: "100%",
}
const SKELETON_POST_TITLE: ViewStyle = {
  width: "55%",
  height: 25,
  marginBottom: spacing[5],
}
const SKELETON_POST_TEXT: ViewStyle = {
  width: "100%",
  height: 11,
  marginBottom: spacing[2],
}
