import React, { useEffect } from "react"
import { useNavigation } from "@react-navigation/native"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Platform } from "react-native"
import { observer } from "mobx-react-lite"
import { AppTabBar, AuthModal } from "../components"
import { useStores } from "../models"
import { TemplateTypeEnum } from "../models/extra-order-store/extra-order-store"
import { HomeStack } from "./app-navigator-home"
import { SoulMateStack } from "./app-navigator-soul-mate"
import { AcademyStack } from "./app-navigator-academy"
import { ProfileStack } from "./app-navigator-profile"
import { SubscriptionTypeEnum } from "../constants/enums"
import { TabsParamList } from "./app-navigation-routes"
import { onSignIn } from "../services/analytics/analytics"
import { HoroscopeStack } from "./app-navigator-horoscope"

const Tab = createBottomTabNavigator<TabsParamList>()

export const AppTabs = observer(() => {
  const { meStore, profileStore, alertStore, commonStore } = useStores()
  const rootNavigation = useNavigation<NativeStackNavigationProp<TabsParamList>>()

  const navigateToCompatibilityResult = (orderId: string, template: string) => {
    if (template === TemplateTypeEnum.CodeOfSamsara) {
      rootNavigation.navigate("home", {
        screen: "extraOrderStack",
        params: { screen: "extraOrderCompatibilityCode", params: { orderId } },
      })
    }
    if (template === TemplateTypeEnum.SoulMate) {
      rootNavigation.navigate("home", {
        screen: "extraOrderStack",
        params: {
          screen: "extraOrderCompatibilityResults",
          params: { orderId },
        },
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!meStore.isGuest) {
        const customerInfoAvailable = await profileStore.getCustomer()
        if (customerInfoAvailable) {
          onSignIn(profileStore.id)
        }
      }
    })()

    if (Platform.OS === "web") {
      const urlParams = new URLSearchParams(window.location.search)
      const subscriptionType = urlParams.get("subscriptionType")
      if (subscriptionType) {
        if (
          subscriptionType === SubscriptionTypeEnum.annual ||
          subscriptionType === SubscriptionTypeEnum.monthly
        ) {
          window.history.replaceState({}, document.title, "/")
          rootNavigation.navigate("home", {
            screen: "homeSubscription",
            params: { subscriptionType },
          })
        } else {
          alertStore.show("Subscription error", "Subscription error!")
        }
      }

      const template = urlParams.get("template")
      const orderId = urlParams.get("orderId")
      if (template) {
        if (template === TemplateTypeEnum.SoulMate || template === TemplateTypeEnum.CodeOfSamsara) {
          window.history.replaceState({}, document.title, "/")
          navigateToCompatibilityResult(orderId, template)
        } else {
          alertStore.showError("Wrong template")
        }
      }
    }
  }, [])

  useEffect(() => {
    if (!meStore.isGuest) {
      commonStore.academyNotificationHasNew()
    }
    const intervalId = setInterval(() => {
      if (!meStore.isGuest) {
        commonStore.academyNotificationHasNew()
      }
    }, 20000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <Tab.Navigator
        tabBar={(props) => <AppTabBar {...props} />}
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName="home"
      >
        <Tab.Screen name="horoscope" component={HoroscopeStack} />
        <Tab.Screen name="soulMate" component={SoulMateStack} />
        <Tab.Screen name="home" component={HomeStack} />
        <Tab.Screen name="academy" component={AcademyStack} />
        <Tab.Screen name="profile" component={ProfileStack} />
      </Tab.Navigator>
      <AuthModal
        visible={meStore.isAuthModalVisible}
        closeModal={() => {
          meStore.setAuthModalVisible(false)
        }}
      />
    </>
  )
})
