import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle } from "react-native"
import { ExtraOrderStackScreenProps } from "../../navigators"
import { ExtraOrderEditUser } from "./components/edit"
import { TxKeyPath } from "../../i18n"
import { ExtraOrderUserTypeEnum } from "../../models/extra-order-store/extra-order-store"

interface IEditCardRouteParams {
  title: TxKeyPath
  userType: ExtraOrderUserTypeEnum
}

export const ExtraOrderEditUserScreen: FC<ExtraOrderStackScreenProps<"extraOrderCompatibility">> =
  observer(({ route, navigation }) => {
    const params: IEditCardRouteParams = route.params

    return (
      <View style={FULL}>
        <ExtraOrderEditUser
          title={params.title}
          userType={params.userType}
          goBack={() => navigation.goBack()}
        />
      </View>
    )
  })

const FULL: ViewStyle = { flex: 1 }
