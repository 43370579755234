import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const CalendarLight = (props: SvgProps) => (
  <Svg width={12} height={12} fill="#00E6DB" viewBox="0 0 12 12" {...props}>
    <Path d="M3.248 6.997a.749.749 0 1 0 0-1.497.749.749 0 0 0 0 1.497Zm.749 1.752a.749.749 0 1 1-1.497 0 .749.749 0 0 1 1.497 0ZM6 6.996a.748.748 0 1 0 0-1.498.748.748 0 0 0 0 1.499v-.001Zm.749 1.752a.749.749 0 1 1-1.497 0 .749.749 0 0 1 1.497 0Zm2-1.752a.749.749 0 1 0 0-1.497.749.749 0 0 0 0 1.497ZM12 2.5A2.5 2.5 0 0 0 9.5 0h-7A2.5 2.5 0 0 0 0 2.5v7A2.5 2.5 0 0 0 2.5 12h7A2.5 2.5 0 0 0 12 9.5v-7ZM1 4h10v5.5A1.5 1.5 0 0 1 9.5 11h-7A1.5 1.5 0 0 1 1 9.5V4Zm1.5-3h7A1.5 1.5 0 0 1 11 2.5V3H1v-.5A1.5 1.5 0 0 1 2.5 1Z" />
  </Svg>
)

export default CalendarLight
