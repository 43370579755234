import React, { FC, useState } from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Text, Screen, Stepper, Button, Icon, DatePicker, TimePicker } from "../../components"
import { color, spacing } from "../../theme"
import { WelcomeStackScreenProps } from "../../navigators"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  flex: 1,
  padding: spacing[4],
}
const TEXT_CONTENT: ViewStyle = {
  marginBottom: 32,
}
const TEXT_CONTENT_TITLE: TextStyle = {
  marginBottom: spacing[2],
}
const INFO_CONTENT: ViewStyle = {
  flexGrow: 1,
}
const BUTTON_CONTENT: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
}
const BUTTON_BACK: ViewStyle = {
  width: "35%",
}
const BUTTON_BACK_CONTENT: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}
const BUTTON_NEXT: ViewStyle = {
  width: "60%",
  justifyContent: "center",
}
const BUTTON_NEXT_CONTENT: ViewStyle = {
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}
const BUTTON_NEXT_TEXT: TextStyle = {
  color: color.palette.black,
  flexGrow: 1,
  textAlign: "center",
}

export const WelcomeForm4Screen: FC<WelcomeStackScreenProps<"welcomeForm4">> = observer(
  ({ navigation }) => {
    const { welcomeStore, alertStore } = useStores()

    const [dateOfBirth, setDateOfBirth] = useState("")
    const [timeOfBirth, setTimeOfBirth] = useState("")

    const goBack = () => navigation.navigate("welcomeForm3")

    const nextStep = () => {
      if (dateOfBirth) {
        welcomeStore.step4(dateOfBirth, timeOfBirth)
        navigation.navigate("welcomeForm5")
      } else {
        alertStore.showError("Please fill in Date of Birth")
      }
    }

    const close = () => navigation.navigate("welcome")

    return (
      <View style={FULL}>
        <Screen style={CONTAINER} preset="scroll">
          <Stepper step={4} onClosePress={close} stepTx="stepper.step4" />

          <View style={TEXT_CONTENT}>
            <Text style={TEXT_CONTENT_TITLE} preset="h2" tx="welcomeForm4Screen.title" />
            <Text preset="h4Text" tx="welcomeForm4Screen.subtitle" />
          </View>

          <View style={INFO_CONTENT}>
            <DatePicker
              value={dateOfBirth}
              setValue={setDateOfBirth}
              placeholderTx="welcomeForm4Screen.dateOfBirth"
            />
            <TimePicker
              value={timeOfBirth}
              setValue={setTimeOfBirth}
              placeholderTx="welcomeForm4Screen.timeOfBirth"
            />
          </View>

          <View style={BUTTON_CONTENT}>
            <Button onPress={goBack} preset="cancel" style={BUTTON_BACK}>
              <View style={BUTTON_BACK_CONTENT}>
                <Icon icon="back" />
                <Text preset="h4" tx="common.back" />
              </View>
            </Button>
            <Button preset="primary" onPress={nextStep} style={BUTTON_NEXT}>
              <View style={BUTTON_NEXT_CONTENT}>
                <Text preset="h4" tx="welcomeForm4Screen.nextStep" style={BUTTON_NEXT_TEXT} />
                <Icon icon="next" />
              </View>
            </Button>
          </View>
        </Screen>
      </View>
    )
  },
)
