import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { CommonResult, CustomerResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { ApiRoutes } from "./api-config"
import { IPlaceOfBirth } from "../../models/profile-store/place-of-birth-model"

export class CustomerApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async isExist(userName: string): Promise<CustomerResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.CUSTOMER_IS_EXISTS(userName),
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data.results

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getCurrentCustomer(): Promise<CustomerResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(ApiRoutes.CUSTOMER_GET_CURRENT)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getSubscriptions(): Promise<CommonResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(ApiRoutes.GET_SUBSCRIPTIONS)

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getMySubscriptionsList(): Promise<CommonResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        ApiRoutes.GET_MY_SUBSCRIPTIONS_LIST,
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async unsubscribeStripe(subscriptionId: string): Promise<CommonResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.UNSUBSCRIBE_STRIPE(subscriptionId),
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async resumeStripe(subscriptionId: string): Promise<CommonResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.RESUME_STRIPE(subscriptionId),
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updatePaymentMethod(): Promise<CommonResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.CUSTOMER_UPDATE_PAYMENT_METHOD,
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updateCustomerName(
    id: string,
    firstName: string,
    lastName: string,
  ): Promise<CustomerResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.CUSTOMER_UPDATE_NAME(id),
        {
          firstName,
          lastName,
        },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data.results

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updateCustomerBirthName(
    id: string,
    firstName: string,
    lastName: string,
  ): Promise<CustomerResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.CUSTOMER_UPDATE_BIRTH_NAME(id),
        {
          firstName,
          lastName,
        },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updateCustomerGender(id: string, gender: string): Promise<CustomerResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.CUSTOMER_UPDATE_GENDER(id),
        {
          gender,
        },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data.results

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updateCustomerDateAndTime(id: string, date: string, time: string): Promise<CustomerResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.CUSTOMER_UPDATE_DATE_AND_TIME(id),
        {
          date,
          time,
        },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updatePlaceOfBirth(id: string, placeOfBirth: IPlaceOfBirth): Promise<CustomerResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.CUSTOMER_UPDATE_PLACE_OF_BIRTH(id),
        {
          ...placeOfBirth,
        },
      )

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async sendSupport(message: string): Promise<CustomerResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(ApiRoutes.CUSTOMER_SUPPORT, {
        requestText: message,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async updateCustomerLocale(id: string, locale: string): Promise<CustomerResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        ApiRoutes.CUSTOMER_UPDATE_LOCALE(id),
        {
          locale,
        },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
