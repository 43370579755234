import React from "react"
import { ScrollView, View, ViewStyle } from "react-native"
import { color, spacing, typography } from "../../../theme"
import { useWindowWidth } from "../../../utils/dimension"
import { LineChartPoints } from "../line-chart/line-chart"
import { ExtendedCustomStackedBarChart, ExtendedStackedBarChartData } from "./extended"

interface BarChartProp {
  graphicPoints: LineChartPoints[]
  legends: string[]
  labels: string[]
  averageLine: number[]
  averageLineLabels?: string[]
  labelsXTitle: string
}

const BAR_COLORS = [
  "rgba(0, 112, 192, 1)",
  "rgba(119, 178, 81, 1)",
  "rgba(198, 209, 225, 0.2)",
  "rgba(231, 76, 60, 1)",
]

export const CustomBarChart = ({
  graphicPoints,
  legends,
  labels,
  averageLine,
  averageLineLabels,
  labelsXTitle,
}: BarChartProp) => {
  const screenWidth = useWindowWidth()
  const chartHeight = 220
  const chartWidth = screenWidth - 2 * spacing[4]

  const legendsArr =
    averageLine?.length && averageLineLabels?.length ? [...legends, ...averageLineLabels] : legends

  const getBarChartData = (graphicPoints: LineChartPoints[]) => {
    const firstPartnerPoints = graphicPoints[0].graphValuesY
    const secondPartnerPoints = graphicPoints[1].graphValuesY
    const result = []
    firstPartnerPoints.map((item, index) => {
      return result.push([item, secondPartnerPoints[index]])
    })
    return result
  }

  const chartConfig = {
    backgroundGradientFrom: color.backgroundBlack500,
    backgroundGradientFromOpacity: 1,
    backgroundGradientTo: color.backgroundBlack500,
    backgroundGradientToOpacity: 1,
    color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    strokeWidth: 1,
    decimalPlaces: 2,
    barPercentage: 0.5,
    propsForBackgroundLines: {
      strokeDasharray: "",
      strokeDashoffset: 15,
    },
    width: chartWidth,
    height: chartHeight,
  }

  const stackedBarChartData: ExtendedStackedBarChartData = {
    labels,
    legend: legendsArr,
    data: getBarChartData(graphicPoints),
    barColors: BAR_COLORS,
    averageLine: averageLine[0],
    labelsXTitle,
  }

  return (
    <View style={[CONTAINER, { width: chartWidth }]}>
      <ScrollView horizontal={true}>
        <ExtendedCustomStackedBarChart
          style={
            {
              fontFamily: typography.primary,
            } as ViewStyle
          }
          decimalPlaces={0}
          data={stackedBarChartData}
          width={chartWidth}
          height={chartHeight}
          chartConfig={chartConfig}
          segments={10}
          fromZero={true}
          hideLegend={true}
          withHorizontalLabels={true}
        />
      </ScrollView>
    </View>
  )
}

const CONTAINER: ViewStyle = {
  position: "relative",
  overflow: "hidden",
}
