import React, { FC, useEffect, useState } from "react"
import { View, ViewStyle, TextStyle, Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import * as StoreReview from "expo-store-review"
import { isValid, parseISO } from "date-fns"
import {
  Text,
  Screen,
  CategoryButtonCompact,
  Button,
  ScreenTitle,
  PersonCircle,
} from "../../components"
import { ProfileStackParamList, ProfileStackScreenProps, TabsParamList } from "../../navigators"
import { spacing } from "../../theme"
import { useStores } from "../../models"
import { parseAndFormatDateLocalRaw } from "../../utils/date"
import { getZodiacSignByDate } from "../../constants/zodiac"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingTop: spacing[4],
  paddingHorizontal: spacing[4],
}
const PROFILE_HEADER: ViewStyle = {
  marginBottom: spacing[6],
  flexDirection: "row",
  alignItems: "center",
}
const PROFILE_INFO: ViewStyle = {
  flexGrow: 1,
  width: "60%",
}
const PROFILE_INFO_NAME: ViewStyle = {
  marginBottom: spacing[1],
}
const PROFILE_CONTENT: ViewStyle = {
  marginBottom: spacing[4],
}
const PROFILE_CONTENT_GENERAL: ViewStyle = {
  marginBottom: spacing[4],
}
const PROFILE_CONTENT_TITLE: TextStyle = {
  marginBottom: spacing[4],
}
const PROFILE_ACTIONS: ViewStyle = {
  marginBottom: spacing[4],
}

export const ProfileScreen: FC<ProfileStackScreenProps<"profileIndex">> = observer(
  ({ navigation }) => {
    const navigationLinks = useNavigation<NativeStackNavigationProp<TabsParamList>>()
    const fromScreen = "profile"

    const { meStore, profileStore, alertStore } = useStores()

    const name = `${profileStore.firstName} ${profileStore.lastName}`
    const [showRateButton, setShowRateButton] = useState(false)

    const getZodiacSign = () => {
      const parsedDate = parseISO(profileStore.dateOfBirth)
      if (isValid(parsedDate)) {
        const day = parsedDate.getDate()
        const month = parsedDate.getMonth() + 1

        return getZodiacSignByDate(day, month)
      }

      return ""
    }

    const date =
      parseAndFormatDateLocalRaw(profileStore.dateOfBirth, "MMMM d, yyyy") + " " + getZodiacSign()

    const editProfile = () => {
      navigation.navigate("profileEdit")
    }

    const logOut = async () => {
      const result = await meStore.logOut()
      if (!result) {
        alertStore.showError("")
      }
    }

    const openShareLink = () => {
      if (StoreReview.isAvailableAsync()) {
        StoreReview.requestReview()
      }
    }

    const navigateToScreen = (link: keyof ProfileStackParamList) => {
      navigation.navigate(link)
    }

    useEffect(() => {
      ;(async () => {
        if (await StoreReview.hasAction()) {
          setShowRateButton(true)
        }
      })()
    }, [])

    return (
      <View style={FULL}>
        <Screen style={CONTAINER} preset="scroll">
          <ScreenTitle
            headerTx="profileScreen.title"
            main={!meStore.isGuest}
            goToSubscription={() => navigateToScreen("profileSubscriptionInfo")}
            goToNotifications={() =>
              navigationLinks.navigate("academy", {
                screen: "academyNotifications",
                params: { fromScreen },
              })
            }
          />
          <View style={PROFILE_HEADER}>
            <PersonCircle
              firstName={profileStore.firstName}
              lastName={profileStore.lastName}
              genderCode={profileStore.gender}
            />
            <View style={PROFILE_INFO}>
              <Text text={name} preset="h4" style={PROFILE_INFO_NAME} />
              <Text text={date} />
            </View>
            {!meStore.isGuest && (
              <Button tx="profileScreen.edit" preset="small" onPress={editProfile} />
            )}
          </View>

          <View style={PROFILE_CONTENT}>
            {!meStore.isGuest && (
              <View style={PROFILE_CONTENT_GENERAL}>
                <Text tx="profileScreen.personalSettings" style={PROFILE_CONTENT_TITLE} />
                <CategoryButtonCompact
                  leftIcon="subscriptions"
                  titleTx="profileScreen.subscription"
                  onPress={() => navigateToScreen("profileSubscriptionInfo")}
                />
                {/* <CategoryButtonCompact
                  leftIcon="inviteFriends"
                  titleTx="profileScreen.inviteFriends"
                  onPress={() => navigateToScreen("profileIndex")}
                /> */}
                {/* <CategoryButtonCompact
                  leftIcon="redeem"
                  titleTx="profileScreen.promoCode"
                  onPress={() => navigateToScreen("profilePromoCode")}
                /> */}
              </View>
            )}

            <View>
              <Text tx="profileScreen.generalSettings" style={PROFILE_CONTENT_TITLE} />
              {showRateButton && (
                <CategoryButtonCompact
                  leftIcon="rate"
                  titleTx="profileScreen.rateUs"
                  onPress={openShareLink}
                />
              )}
              {!meStore.isGuest && (
                <CategoryButtonCompact
                  leftIcon="contact"
                  titleTx="profileScreen.contactUs"
                  onPress={() => navigateToScreen("profileContactSupport")}
                />
              )}
              <CategoryButtonCompact
                leftIcon="about"
                titleTx="profileScreen.about"
                onPress={() => navigateToScreen("profileAbout")}
              />
              <CategoryButtonCompact
                leftIcon="termsOfUse"
                titleTx="profileScreen.userAgreement"
                onPress={() => navigateToScreen("profileUserAgreement")}
              />
              <CategoryButtonCompact
                leftIcon="termsOfUse"
                titleTx="profileScreen.policy"
                onPress={() => navigateToScreen("profilePrivacyPolicy")}
              />
              {Platform.OS !== "web" ? (
                <CategoryButtonCompact
                  leftIcon="termsOfUse"
                  titleTx="profileScreen.aboutApp"
                  onPress={() => navigateToScreen("profileAboutApp")}
                />
              ) : null}
            </View>
          </View>

          <View style={PROFILE_ACTIONS}>
            <Button preset="outline" tx="profileScreen.logOut" onPress={logOut} />
          </View>
        </Screen>
      </View>
    )
  },
)
