import Constants from "expo-constants"
import { Platform, useWindowDimensions } from "react-native"

export const MAX_DESKTOP_WIDTH = 640
export const useWindowWidth = () => {
  const { width } = useWindowDimensions()
  return Platform.OS === "web" ? Math.min(width, MAX_DESKTOP_WIDTH) : width
}

export const IS_MOBILE = ["ios", "android"].includes(Platform.OS)
export const IS_MOBILE_NON_EXPO = IS_MOBILE && Constants.appOwnership !== "expo"
