import React from "react"
import { View, ViewStyle, TouchableOpacity } from "react-native"
import { ScreenTitleProps } from "./screen-title.props"
import { Icon } from "../icon/icon"
import { Text } from "../text/text"
import { color, spacing } from "../../theme"
import { translate } from "../../i18n/"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models"

// static styles
const ROOT: ViewStyle = {
  paddingVertical: spacing[5],
  alignItems: "flex-start",
  backgroundColor: color.palette.black,
}
const TOP: ViewStyle = {
  width: "100%",
  marginBottom: spacing[1],
  flexDirection: "row",
  justifyContent: "space-between",
}
const TOP_CONTENT: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
}
const TOP_CONTENT_GIFT: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginRight: spacing[5],
  paddingHorizontal: spacing[2],
  paddingVertical: 3,
  backgroundColor: color.palette.black500,
  borderColor: color.borderColor,
  borderWidth: 2,
  borderRadius: 8,
}
const BOTTOM: ViewStyle = { marginTop: spacing[1], maxWidth: "80%" }

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const ScreenTitle = observer((props: ScreenTitleProps) => {
  const {
    headerText,
    headerTx,
    subHeaderText,
    subHeaderTx,
    style,
    main,
    goToSubscription,
    goToNotifications,
  } = props
  const { commonStore } = useStores()
  const header = headerText || (headerTx && translate(headerTx)) || ""
  const subHeader = subHeaderText || (subHeaderTx && translate(subHeaderTx)) || ""

  return (
    <View style={[ROOT, style]}>
      <View style={TOP}>
        <Text preset="h2" text={header} numberOfLines={2} />
        {main && (
          <View style={TOP_CONTENT}>
            <TouchableOpacity style={TOP_CONTENT_GIFT} onPress={goToSubscription}>
              <Icon icon="gift" />
            </TouchableOpacity>
            <TouchableOpacity onPress={goToNotifications}>
              <Icon icon={commonStore.hasNewNotifications ? "bell" : "bellWithOutDot"} />
            </TouchableOpacity>
          </View>
        )}
      </View>
      {!!subHeader && (
        <View style={BOTTOM}>
          <Text preset="h4Text" text={subHeader} numberOfLines={2} />
        </View>
      )}
    </View>
  )
})
