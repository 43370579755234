import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Search = (props: SvgProps) => (
  <Svg width={19} height={19} viewBox="0 0 19 19" fill="#00E6DB" {...props}>
    <Path d="M8 .5a7.5 7.5 0 0 1 5.964 12.048l4.743 4.745a1 1 0 0 1-1.32 1.497l-.094-.083-4.745-4.743A7.5 7.5 0 1 1 8 .5Zm0 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11Z" />
  </Svg>
)

export default Search
