import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ChevronLeft = (props: SvgProps) => (
  <Svg width={18} height={17} viewBox="0 0 18 17" fill="#fff" {...props}>
    <Path d="M7.296 1.284a1 1 0 1 1 1.403 1.424L3.33 8H17a1 1 0 1 1 0 2H3.335L8.7 15.285a1 1 0 0 1-1.403 1.424L.372 9.887a1.25 1.25 0 0 1 0-1.78l6.925-6.823h-.001Z" />
  </Svg>
)

export default ChevronLeft
