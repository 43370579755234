import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Close = (props: SvgProps) => (
  <Svg width={17} height={17} viewBox="0 0 17 17" fill="#fff" {...props}>
    <Path d="m.21 1.387.083-.094a1 1 0 0 1 1.32-.083l.094.083L8 7.585l6.293-6.292a1 1 0 1 1 1.414 1.414L9.415 9l6.292 6.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083L8 10.415l-6.293 6.292a1 1 0 0 1-1.414-1.414L6.585 9 .293 2.707a1 1 0 0 1-.083-1.32l.083-.094-.083.094Z" />
  </Svg>
)

export default Close
