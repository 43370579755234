import React from "react"
import { View, ViewStyle, ImageStyle, TextStyle, TouchableOpacity } from "react-native"
import { CategoryButtonProps } from "./category-button.props"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { AutoImage } from "../auto-image/auto-image"
import { color, spacing } from "../../theme"
import { translate } from "../../i18n"

const LIST_CONTAINER: ViewStyle = {
  alignItems: "center",
  flexDirection: "row",
  marginBottom: 16,
  paddingHorizontal: 18,
  paddingVertical: 36,
  backgroundColor: color.backgroundBlack500,
  borderRadius: 16,
}
const LIST_IMAGE_WRAPPER: ViewStyle = {
  width: 50,
  height: 50,
  marginRight: 24,
  borderRadius: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
const LIST_IMAGE: ImageStyle = {
  width: "100%",
  height: "100%",
}
const LIST_CONTENT: ViewStyle = {
  flex: 1,
}
const LIST_HEADING: TextStyle = {
  marginBottom: 5,
}
const LIST_DESCRIPTION: TextStyle = {
  paddingRight: spacing[2],
}

export function CategoryButton(props: CategoryButtonProps) {
  const { icon, titleTx, textTx, onPress } = props

  const title = translate(titleTx)
  const description = translate(textTx)

  return (
    <TouchableOpacity onPress={onPress} style={LIST_CONTAINER}>
      <View style={LIST_IMAGE_WRAPPER}>
        <AutoImage source={icon} style={LIST_IMAGE} />
      </View>
      <View style={LIST_CONTENT}>
        <Text preset="h4" style={LIST_HEADING} text={title} numberOfLines={1} />
        <Text preset="default" text={description} numberOfLines={2} style={LIST_DESCRIPTION} />
      </View>
      <Icon icon={"chevronCircleRight"} />
    </TouchableOpacity>
  )
}
