import Hls from "hls.js"
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react"
import { Pressable, View, ViewStyle } from "react-native"
import { Icon, SkeletonContainer, SkeletonView } from "../../../components"

interface AcademyVideoProps {
  srcHls: string
  src: string
  playAllowed?: boolean
  autoplayEnabled: boolean
  setAutoplayEnabled: (state: boolean) => void
}

const VIDEO_WRAPPER: ViewStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
}
const VIDEO: CSSProperties = {
  width: "100%",
  height: "100%",
  justifyContent: "center",
}
const PLAY_BUTTON: ViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginLeft: -32,
  marginTop: -32,
  zIndex: 2,
}

const FLEX_ROW_CENTER: ViewStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}
const VIDEO_SKELETON_PLAY: ViewStyle = {
  width: 64,
  height: 64,
  zIndex: 2,
  borderRadius: 32,
  opacity: 0.8,
}

const hlsConfig = {
  maxBufferSize: 20,
  lowLatencyMode: true,
}
const HlsSupported = Hls.isSupported()

export function AcademyVideo(props: AcademyVideoProps) {
  const { srcHls, src, playAllowed = false, autoplayEnabled, setAutoplayEnabled } = props
  const [init, setInit] = useState(false)
  const videoRef = useRef(null)
  const hlsRef = useRef<Hls | null>(null)

  const playVideo = useCallback(() => {
    if (videoRef.current.paused) {
      if (playAllowed) {
        hlsRef?.current?.startLoad()
        videoRef.current.play()
        setAutoplayEnabled(true)
      }
    } else {
      hlsRef?.current?.stopLoad()
      videoRef.current.pause()
      setAutoplayEnabled(false)
    }
  }, [videoRef.current, autoplayEnabled, playAllowed])

  useEffect(() => {
    function _initPlayer() {
      if (hlsRef.current != null || !init) {
        return
      }

      const newHls = new Hls(hlsConfig)
      if (videoRef.current != null) {
        newHls.attachMedia(videoRef.current)
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(srcHls)

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (playAllowed && autoplayEnabled) {
            videoRef?.current?.play().catch(() => {
              setAutoplayEnabled(false)
              console.log("Unable to autoplay prior to user interaction with the dom.")
            })
          }
        })
      })

      newHls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad()
              break
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError()
              break
            default:
              _initPlayer()
              break
          }
        }
      })

      hlsRef.current = newHls
    }

    // Check for Media Source support
    if (HlsSupported) {
      _initPlayer()
    }
  }, [init])

  useEffect(() => {
    if (playAllowed) {
      setInit(true)
    }

    if (playAllowed && autoplayEnabled) {
      hlsRef?.current?.startLoad()
      videoRef.current.play()
    }
    if (!playAllowed) {
      hlsRef?.current?.stopLoad()
      videoRef.current.pause()
    }
  }, [playAllowed, hlsRef.current])

  return (
    <>
      <Pressable style={VIDEO_WRAPPER} onPress={playVideo}>
        <video ref={videoRef} src={HlsSupported ? srcHls : src} loop playsInline style={VIDEO} />
        {init && !autoplayEnabled && (
          <View style={PLAY_BUTTON}>
            <Icon icon="play" />
          </View>
        )}
      </Pressable>
      {!init ? (
        <SkeletonContainer>
          <View style={FLEX_ROW_CENTER}>
            <SkeletonView style={VIDEO_SKELETON_PLAY} />
          </View>
        </SkeletonContainer>
      ) : null}
    </>
  )
}
