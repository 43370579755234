import { Instance, SnapshotOut, types, SnapshotIn } from "mobx-state-tree"
import { translate } from "../../i18n"

export const AlertStoreModel = types
  .model("AlertStore")
  .props({
    isVisible: types.optional(types.boolean, false),
    title: types.optional(types.string, ""),
    message: types.optional(types.string, ""),
  })
  .actions((self) => ({
    show: (title: string, message: string) => {
      self.isVisible = true
      self.title = title || translate("common.error")
      self.message = message
    },
    close: () => {
      self.isVisible = false
      self.title = ""
      self.message = ""
    },
  }))
  .actions((self) => ({
    showError: (message: string) => {
      self.show("", message)
    },
  }))
export interface AlertStore extends Instance<typeof AlertStoreModel> {}
export interface AlertStoreSnapshotOut extends SnapshotOut<typeof AlertStoreModel> {}
export interface AlertStoreSnapshotIn extends SnapshotIn<typeof AlertStoreModel> {}
export const createAlertStoreDefaultModel = () => types.optional(AlertStoreModel, {})
