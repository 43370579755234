import React, { FC, useEffect, useState } from "react"
import { ActivityIndicator, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Text, Screen, Header, ScreenTitle, RenderHtmlWrapper } from "../../components"
import { ProfileStackScreenProps } from "../../navigators"
import { color, spacing } from "../../theme"
import { useStores } from "../../models"

const FULL: ViewStyle = { flex: 1 }
const CONTAINER: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
}
const TITLE: ViewStyle = {
  paddingTop: 0,
}

interface ITerms {
  title: string
  content: string
}

export const ProfileUserAgreementScreen: FC<ProfileStackScreenProps<"profileUserAgreement">> =
  observer(({ navigation }) => {
    const goBack = () => navigation.goBack()
    const [userAgreement, setUserAgreement] = useState<ITerms>({
      title: "",
      content: "",
    })

    const { commonStore } = useStores()

    useEffect(() => {
      ;(async () => {
        const result = await commonStore.getUserAgreement()
        if (result) {
          setUserAgreement({
            title: result?.title,
            content: result?.content,
          })
        }
      })()
    }, [])

    return (
      <View style={FULL}>
        <Screen
          style={CONTAINER}
          preset="scroll"
          header={<Header leftIcon="back" onLeftPress={goBack} />}
        >
          <ScreenTitle headerTx="profileScreen.userAgreement" style={TITLE} />
          {userAgreement ? (
            <View>
              <Text preset="h4" text={userAgreement.title} style={{ marginBottom: spacing[2] }} />
              <RenderHtmlWrapper html={userAgreement.content} />
            </View>
          ) : (
            <ActivityIndicator size="large" color={color.primary} />
          )}
        </Screen>
      </View>
    )
  })
