import React, { FC } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { ExtraOrderStackScreenProps } from "../../navigators"
import { CalculationType } from "./components/calculation-type"

export const ExtraOrderCompatibilityScreen: FC<
  ExtraOrderStackScreenProps<"extraOrderCompatibility">
> = observer(({ navigation }) => {
  return (
    <View style={FULL}>
      <CalculationType
        goBack={() => navigation.goBack()}
        navigateToCalculation={() => navigation.navigate("extraOrderCompatibilityCalculation")}
      />
    </View>
  )
})

const FULL: ViewStyle = { flex: 1 }
