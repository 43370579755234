import { Instance, SnapshotOut, types, flow, SnapshotIn } from "mobx-state-tree"
import { withEnvironment } from "../extensions/with-environment"
import { USER_TOKEN_STORAGE_KEY } from "../../services/api"
import { AuthApi } from "../../services/api/auth-api"
import * as storage from "../../utils/storage"
import { withRootStore } from "../extensions/with-root-store"

export const MeStoreModel = types
  .model("MeStore")
  .props({
    token: types.optional(types.string, ""),
    refreshToken: types.optional(types.string, ""),
    isAuthenticated: types.optional(types.boolean, false),
    isGuest: types.optional(types.boolean, false),
    shouldLogOutGuest: types.optional(types.boolean, false),
    isAuthModalVisible: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    setTokens: flow(function* (token: string, refreshToken: string) {
      self.token = token
      self.refreshToken = refreshToken
      self.isGuest = false
      yield storage.save(USER_TOKEN_STORAGE_KEY, self.token)
    }),
    setGuest: (isGuest: boolean) => {
      self.isGuest = isGuest
    },
    setShouldLogOutGuest: (shouldLogOutGuest: boolean) => {
      self.shouldLogOutGuest = shouldLogOutGuest
    },
    setAuthModalVisible: (visible: boolean) => {
      self.isAuthModalVisible = visible
    },
    setAuthenticated: (isAuthed: boolean) => {
      self.isAuthenticated = isAuthed
    },
  }))
  .actions((self) => ({
    resetAuth: flow(function* () {
      self.setTokens("", "")
      self.setShouldLogOutGuest(false)
      self.setAuthModalVisible(false)
      self.setGuest(false)
      self.setAuthenticated(false)
      self.rootStore.profileStore.clearProfile()
    }),
  }))
  .actions((self) => ({
    resetAuthOnError: flow(function* () {
      if (!self.isGuest) {
        self.resetAuth()
      }
    }),
    callRefreshToken: flow(function* () {
      if (!self.token || !self.token) {
        self.resetAuth()
        return false
      }

      const authApi = new AuthApi(self.environment.api)
      const result = yield authApi.refreshToken(self.refreshToken)
      if (result.kind === "ok") {
        self.setTokens(result.customer.data.token, result.customer.data.refreshToken)
        self.setAuthenticated(true)
        return true
      } else {
        self.resetAuth()
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    logOut: flow(function* () {
      if (self.isGuest) {
        self.resetAuth()
        return true
      }

      const authApi = new AuthApi(self.environment.api)
      const result = yield authApi.logOut(self.refreshToken)

      if (result.kind === "ok") {
        self.resetAuth()
        return true
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
  }))

export interface MeStore extends Instance<typeof MeStoreModel> {}
export interface MeStoreSnapshotOut extends SnapshotOut<typeof MeStoreModel> {}
export interface MeStoreSnapshotIn extends SnapshotIn<typeof MeStoreModel> {}
export const createMeStoreDefaultModel = () => types.optional(MeStoreModel, {})
