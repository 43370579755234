import React, { FC, useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, ImageStyle, TextStyle, Animated, BackHandler } from "react-native"
import { useFocusEffect } from "@react-navigation/native"
import { Screen, Header, Text, Icon, AutoImage, Button } from "../../components"
import { spacing } from "../../theme"
import { HomeStackScreenProps } from "../../navigators"
import { useStores } from "../../models"
import { translate } from "../../i18n"
import { SubscriptionTypeEnum } from "../../constants/enums"

const background = require("./images/subscription-bg.png")
const logo = require("./images/logo.png")

export const HomeSubscriptionScreen: FC<HomeStackScreenProps<"homeSubscription">> = observer(
  ({ navigation, route }: any) => {
    const { profileStore } = useStores()
    const { subscriptionType } = route.params

    const navigateToMyCodeOfSamsara = () =>
      navigation.navigate("myCodeOfSamsara", { reload: Date.now() })
    const navigateToSoulmate = () =>
      navigation.navigate("soulMate", {
        screen: "soulMateIndex",
      })
    const navigateToExtraOrder = () =>
      navigation.navigate("home", {
        screen: "extraOrderStack",
        params: { screen: "extraOrderIndex" },
      })
    const goBack = () => navigation.navigate("homeIndex")

    const spinValue = useRef(new Animated.Value(0)).current

    const circleAnimated = () => {
      spinValue.setValue(0)
      Animated.loop(
        Animated.sequence([
          Animated.timing(spinValue, {
            toValue: 1,
            duration: 20000,
            useNativeDriver: false,
          }),
          Animated.timing(spinValue, {
            toValue: 0,
            duration: 20000,
            useNativeDriver: false,
          }),
          Animated.timing(spinValue, {
            toValue: 0,
            delay: 8000,
            useNativeDriver: false,
          }),
        ]),
      ).start()
    }

    const spin = spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ["0deg", "360deg"],
    })

    useEffect(() => {
      circleAnimated()
    }, [])

    // Prevent hardware back button
    useFocusEffect(
      React.useCallback(() => {
        const onBackPress = () => {
          return true
        }

        BackHandler.addEventListener("hardwareBackPress", onBackPress)

        return () => BackHandler.removeEventListener("hardwareBackPress", onBackPress)
      }, [route]),
    )

    return (
      <View style={FULL}>
        <View style={FULL_INNER}>
          <Screen style={CONTAINER} backgroundColor="transaprent" preset="scroll">
            <Header leftIcon="back" onLeftPress={goBack} />
            <View style={ICON_WRAPPER}>
              <Animated.Image source={logo} style={[ICON, { transform: [{ rotate: spin }] }]} />
            </View>
            <View style={CONTENT}>
              <View style={GREETING}>
                <Text
                  style={GREETING_TITLE}
                  preset="h4"
                  text={`${translate("homeSubscriptionScreen.greeting")} ${
                    profileStore.firstName
                  }!`}
                />
                <Text preset="h2" tx="homeSubscriptionScreen.thanksForSubscribtion" />
              </View>

              <Text style={INFO_TITLE} preset="h4" tx="homeSubscriptionScreen.accessTo" />
              {subscriptionType === SubscriptionTypeEnum.monthly && (
                <>
                  <Button preset="unstyled" style={INFO_ITEM} onPress={navigateToMyCodeOfSamsara}>
                    <Icon icon="checkbox" containerStyle={INFO_ITEM_ICON} />
                    <Text
                      preset="default"
                      numberOfLines={2}
                      tx="homeSubscriptionScreen.monthlyAccess1"
                    />
                  </Button>
                  <Button preset="unstyled" style={INFO_ITEM} onPress={navigateToSoulmate}>
                    <Icon icon="checkbox" containerStyle={INFO_ITEM_ICON} />
                    <Text
                      preset="default"
                      numberOfLines={2}
                      tx="homeSubscriptionScreen.monthlyAccess2"
                    />
                  </Button>
                  <Button preset="unstyled" style={INFO_ITEM} onPress={navigateToExtraOrder}>
                    <Icon icon="checkbox" containerStyle={INFO_ITEM_ICON} />
                    <Text
                      preset="default"
                      numberOfLines={2}
                      tx="homeSubscriptionScreen.monthlyAccess3"
                    />
                  </Button>
                </>
              )}
              {subscriptionType === SubscriptionTypeEnum.annual && (
                <>
                  <Button preset="unstyled" style={INFO_ITEM} onPress={navigateToMyCodeOfSamsara}>
                    <Icon icon="checkbox" containerStyle={INFO_ITEM_ICON} />
                    <Text
                      preset="default"
                      numberOfLines={2}
                      tx="homeSubscriptionScreen.annualAccess1"
                    />
                  </Button>
                  <Button preset="unstyled" style={INFO_ITEM} onPress={navigateToSoulmate}>
                    <Icon icon="checkbox" containerStyle={INFO_ITEM_ICON} />
                    <Text
                      preset="default"
                      numberOfLines={2}
                      tx="homeSubscriptionScreen.annualAccess2"
                    />
                  </Button>
                  <Button preset="unstyled" style={INFO_ITEM} onPress={navigateToSoulmate}>
                    <Icon icon="checkbox" containerStyle={INFO_ITEM_ICON} />
                    <Text
                      preset="default"
                      numberOfLines={2}
                      tx="homeSubscriptionScreen.annualAccess3"
                    />
                  </Button>
                  <Button preset="unstyled" style={INFO_ITEM} onPress={navigateToExtraOrder}>
                    <Icon icon="checkbox" containerStyle={INFO_ITEM_ICON} />
                    <Text
                      preset="default"
                      numberOfLines={2}
                      tx="homeSubscriptionScreen.annualAccess4"
                    />
                  </Button>
                </>
              )}
              <Button tx="homeSubscriptionScreen.startRead" onPress={navigateToMyCodeOfSamsara} />
            </View>
          </Screen>
        </View>
        <View style={BACKGROND}>
          <AutoImage
            source={background}
            resizeMode="cover"
            style={{ width: "100%" } as ImageStyle}
          />
        </View>
      </View>
    )
  },
)

// STYLES
const FULL: ViewStyle = { flex: 1 }
const FULL_INNER: ViewStyle = { flex: 1, zIndex: 1 }
const CONTAINER: ViewStyle = { minHeight: "100%" }
const CONTENT: ViewStyle = {
  paddingHorizontal: spacing[4],
  paddingBottom: spacing[4],
  flex: 1,
  justifyContent: "flex-end",
  marginTop: "auto",
}
const BACKGROND: ViewStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
}
const ICON_WRAPPER: ViewStyle = {
  marginTop: spacing[8],
  marginBottom: spacing[6],
  paddingTop: spacing[3],
  justifyContent: "center",
  alignItems: "center",
}
const ICON: ImageStyle = { width: 107, height: 115 }
const GREETING: ViewStyle = { marginBottom: 40 }
const GREETING_TITLE: TextStyle = { marginBottom: spacing[2] }
const INFO_TITLE: TextStyle = { marginBottom: spacing[4] }
const INFO_ITEM: ViewStyle = {
  marginBottom: spacing[5],
  flexDirection: "row",
  alignItems: "center",
}
const INFO_ITEM_ICON: ImageStyle = { marginRight: spacing[2] }
