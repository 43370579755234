import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const AvailableChecks = (props: SvgProps) => (
  <Svg width={17} height={20} viewBox="0 0 17 20" fill="#00E6DB" {...props}>
    <Path d="M0 2.5A2.5 2.5 0 0 1 2.5 0H14a2.5 2.5 0 0 1 2.5 2.5v14.25a.75.75 0 0 1-.75.75H1.5a1 1 0 0 0 1 1h13.25a.75.75 0 1 1 0 1.5H2.5A2.5 2.5 0 0 1 0 17.5v-15Zm6.197 2.964C5.622 5.739 5 6.24 5 7s.622 1.26 1.197 1.536C6.819 8.833 7.634 9 8.5 9c.866 0 1.681-.167 2.303-.464C11.378 8.261 12 7.76 12 7s-.621-1.26-1.197-1.536C10.18 5.167 9.366 5 8.5 5c-.866 0-1.681.167-2.303.464Zm5.798 3.426C11.17 9.567 9.91 10 8.5 10c-1.41 0-2.67-.433-3.495-1.11A1.164 1.164 0 0 0 5 9c0 1.105 1.567 2 3.5 2s3.5-.895 3.5-2a1.35 1.35 0 0 0-.005-.11ZM8.5 12c-1.41 0-2.67-.433-3.495-1.11A1.165 1.165 0 0 0 5 11c0 1.105 1.567 2 3.5 2s3.5-.895 3.5-2c0-.037-.002-.073-.005-.11C11.17 11.567 9.91 12 8.5 12Z" />
  </Svg>
)

export default AvailableChecks
